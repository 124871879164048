import { useState, useEffect, useRef } from "react";
import { Box, Card, CardContent, List, ListItem } from "@mui/material";
import ChatForm from "./ChatForm";
import Button from "react-bootstrap/Button";
import Message from "./Message";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import { toast } from "react-toastify";

export default function ChatRoom({
  showChatModal,
  setShowChatModal,
  generateQueryMessageObject,
  setGenerateQueryMessageObject,
  setQueryLogData,
}) {
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));

  const [messages, setMessages] = useState([]);
  const [incomingMessage, setIncomingMessage] = useState(null);
  const [isConversationClosed, setIsConversationClosed] = useState(false);
  const [lastReceivedMessage, setLastReceivedMessage] = useState();

  const scrollRef = useRef();

  useEffect(() => {
    if (generateQueryMessageObject.queryStatus === "Complete") {
      setIsConversationClosed(true);
    }
  }, [generateQueryMessageObject.queryStatus]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const receivedMessages = messages.filter(
      (msg) => msg?.sender?.email !== user_data.email
    );
    if (receivedMessages.length > 0) {
      const lastMessage = receivedMessages[receivedMessages.length - 1];
      setLastReceivedMessage(lastMessage);
    }
  }, [messages]);

  useEffect(() => {
    if (incomingMessage) {
      setMessages((prev) => [...prev, incomingMessage]);
    }
  }, [incomingMessage]);

  const handleSend = (message) => {
    const newMessage = {
      id: messages.length + 1,
      createdAt: new Date(),
      message,
      isSender: true,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    if (message === "Conversation is closed.") {
      setIsConversationClosed(true);
    }
  };

  const handleReopen = () => {
    setIsConversationClosed(false);
  };

  const getQueryMessages = () => {
    axios
      .get(
        `${__API_URL__}/api/ecrf/get_query_messages/?query_id=${generateQueryMessageObject.queryId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setMessages(response.data);
      })
      .catch(() => {
        toast.error("Query ID not found");
      });
  };

  const GetQueryData = () => {
    axios
      .get(
        `${__API_URL__}/api/ecrf/get_query_logs/?queryId=${generateQueryMessageObject.queryId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setGenerateQueryMessageObject((prev) => {
          return {
            ...prev,
            queryStatus: res.data.status,
          };
        });

        setQueryLogData((prev) => {
          const filteredQueries = prev.filter(
            (obj) => obj.query_status?.id !== generateQueryMessageObject.queryId
          );
          const filteredQuery = prev.filter(
            (obj) => obj.query_status?.id === generateQueryMessageObject.queryId
          );
          return [
            ...filteredQueries,
            {
              ...filteredQuery[0],
              query_status: res.data,
            },
          ];
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (generateQueryMessageObject.queryId) {
      getQueryMessages();
    }
  }, []);

  return (
    <Modal
      size="xxl"
      show={showChatModal}
      fullscreen={true}
      onHide={() => setShowChatModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Conversation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid" style={{ height: "80vh", padding: 0 }}>
        <div className="container-fluid">
          <Box
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <Card
              sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <CardContent sx={{ flexGrow: 1, p: 0 }}>
                <Box
                  sx={{
                    p: 2,
                    height: "calc(100vh - 150px)",
                    overflowY: "auto",
                    bgcolor: "background.paper",
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <List sx={{ padding: 0 }}>
                    {messages.map((message) => (
                      <ListItem
                        key={message.id}
                        ref={scrollRef}
                        sx={{
                          padding: 0,
                          justifyContent:
                            message?.sender?.email === user_data.email
                              ? "flex-end"
                              : "flex-start",
                        }}
                      >
                        <Message message={message} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </CardContent>
              <Box sx={{ p: 2 }}>
                {isConversationClosed ? (
                  <>
                    <Box sx={{ textAlign: "center", p: 2 }}>
                      This Query is closed.
                    </Box>
                    <Button variant="primary" onClick={handleReopen}>
                      Start Again
                    </Button>
                  </>
                ) : (
                  <ChatForm
                    onSend={handleSend}
                    isConversationClosed={isConversationClosed}
                    queryId={generateQueryMessageObject.queryId}
                    fromUserId={generateQueryMessageObject.fromUserId}
                    getQueryMessages={getQueryMessages}
                    GetQueryData={GetQueryData}
                    lastReceivedMessage={lastReceivedMessage}
                  />
                )}
              </Box>
            </Card>
          </Box>
        </div>
      </Modal.Body>
    </Modal>
  );
}
