import React, { useEffect, useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router";


const Participant_Contact_Info = (props) => {
const token = localStorage.getItem("token");
const { _id_ } = useParams();

const user = useUser();
const user_data = JSON.parse(localStorage.getItem("user_data"));
const role = user_data.role
const setMessage = props.setMessage
const setTypeOfMessage = props.setTypeOfMessage
let disable=false
const [selectedBlock, setSelectedBlock] = useState({})
  const handleInformGp = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      inform_gp: !selectedBlock.inform_gp,
    });
    props.setHasChanged(true)
  };
  const handleGp = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      has_gp: e.target.checked,
    });
    props.setHasChanged(true)

  };
  const handleEmailOnly = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      email_only: e.target.checked,
    });
    props.setHasChanged(true)

  };
  const handleSmsOnly = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      sms_only: e.target.checked,
    });
    props.setHasChanged(true)

  };
  const handleEmail_Sms = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      email_and_sms: e.target.checked,
    });
    props.setHasChanged(true)
  };
  const handleFieldChange = (e) => {
  const { name, value } = e.target;
  setSelectedBlock((selectedBlock) => ({
  ...selectedBlock,
  [name]: value,
  }));
  props.setHasChanged(true)
};
const handleGpYes = (e) => {
  setSelectedBlock({ ...selectedBlock, 
    has_gp: true, });
};
const handleGpNo = (e) => {
  setSelectedBlock({ ...selectedBlock, 
    has_gp: false, });
};

const GetSelectedBlockData = () => {
  axios
  .get(`${__API_URL__}/api/ecrf/get_contact_info/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
  headers: {
  Authorization: `Token ${token}`,
  },
  })
  .then((res) => {
    setSelectedBlock(res.data);
  }).catch((err) => {
  if (err == 'Error: Request failed with status code 401') {
  }
  });
  }

const AllVisitsData = () => {
    const createData = {
            'visit_number':props?.visit_number,
            'patient_id': props?.patient_id,
            ...selectedBlock,
            'sessionId': props.selectedSessionId,
            'inform_gp': selectedBlock?.inform_gp === undefined ? true : selectedBlock?.inform_gp,
        }
        axios
          .patch(`${__API_URL__}/api/ecrf/update_contact_info/${props.trialId}/`,createData, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((res) => {
            props.patientDetailApi()
            setSelectedBlock(res.data);
            props.setModalVisitChangeShow(false)
            props.setHasChanged(false)
            props.patientDetailApi()
            props.globalVisits(false);
            setMessage("Block updated successfully");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        }).catch((err) => {
            if(err == 'Error: Request failed with status code 401'){
            user.actions.logout()
            }
            setMessage("Something Went Wrong");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        });
      };

      React.useEffect(() => {
        if((props?.patient_id && props?.photo_session)){
          GetSelectedBlockData();
        }
      }, [])
                    return <>   
                    {
                           (role ==='CRO Admin' || role ==='Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined)) ? 
                              disable=true : ""
                          }
                          <fieldset disabled={disable}>
                    {
                        (props.photo_session || props.patient_id == undefined ) ?
                        <>
                                {
                                  (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                    <div className="col-md-12 text-end">
                                      <button onClick={() => AllVisitsData()} className='btn btn-primary'>Update</button>
                                    </div>
                                  )
                                }
                                    <div className='row'>

                                          <div className='col-md-12'>
                                                <label> Participant's Address:</label>
                                                <textarea 
                                                        style={{marginLeft: "0em"}}
                                                        class="form-control" 
                                                        type="text"
                                                        name='address' 
                                                        id="flexSwitchCheckDefault" 
                                                        onChange={(e) => handleFieldChange(e)} 
                                                        value={selectedBlock.address}>
                                                  </textarea>
                                            </div>
                                            <div className='col-md-10'>
                                                <label> Cell Phone: </label>
                                                <input 
                                                style={{marginLeft: "0em"}}
                                                  class="form-control" 
                                                  type="tel" 
                                                  name='cell_phone' 
                                                  id="flexSwitchCheckDefault" 
                                                  onChange={ handleFieldChange}
                                                  value={selectedBlock?.cell_phone || props?.infoData?.phone}
                                                  >
                                                  </input>
                                            </div>
                                            <div className='col-md-12'>
                                                <label> Email: </label>
                                                <input 
                                                style={{marginLeft: "0em"}} 
                                                class="form-control" 
                                                type="email" 
                                                name='email' 
                                                disabled='true'
                                                id="flexSwitchCheckDefault" 
                                                onChange={handleFieldChange}
                                                value={props.patient?.email}
                                                >
                                                </input>
                                            </div>
                                            <div>
                                              <div>
                                              <label> In addition to the app notifications, do you prefer to receive reminders about visit by: </label>
                                                <div className ='col-md'>
                                              <input 
                                                    className="form-check-input mx-3" 
                                                    type="checkbox" 
                                                    name="email_only" 
                                                    checked = {selectedBlock?.email_only  || false}  
                                                    onChange={(e) => handleEmailOnly(e)}
                                                    /> 
                                                    <label> Email Only </label>
                                                </div>
                                                <div className ='col-md'>
                                                
                                              <input 
                                                    className="form-check-input mx-3" 
                                                    type="checkbox" 
                                                    name="sms_only" 
                                                    checked = {selectedBlock?.sms_only || false}  
                                                    onChange={(e) => handleSmsOnly(e)}
                                                    /> 
                                              <label> SMS Only </label>
                                                </div>
                                                <div className ='col-md'>
                                                
                                              <input 
                                                    className="form-check-input mx-3" 
                                                    type="checkbox" 
                                                    name="email_and_sms" 
                                                    checked = {selectedBlock?.email_and_sms  || false}  
                                                    onChange={(e) => handleEmail_Sms(e)}
                                                    /> 
                                              <label> Email and SMS </label>
                                                </div>
                                              </div>
                                              <div>
                                              </div>
                                            <>
                                            
                                            <label style={{ marginRight: '10px' }}>Does the patient have GP?</label>
                                            <Button  
                                              style={{ marginRight: '10px' }} 
                                              variant="primary" 
                                              className="btn btn-sm"
                                              name="has_gp"  
                                              checked = {!selectedBlock?.has_gp == true}
                                              value={selectedBlock?.has_gp == true}
                                              onClick={(e) => handleGpYes(e)}>
                                                 Yes
                                            </Button>
                                            <Button  
                                              style={{ marginRight: '10px' }} 
                                              variant="primary" 
                                              name="has_gp" 
                                              className="btn btn-sm"
                                              checked = {!selectedBlock?.has_gp == false}
                                              value={selectedBlock?.has_gp == false}
                                              onClick={(e) => handleGpNo(e)}>
                                                 NO
                                            </Button>
                                            {selectedBlock.has_gp ?
                                            <>
                                            <div className='col-md-6'>
                                                <label> GP Name: </label>
                                                <input 
                                                      style={{marginLeft: "0em"}} 
                                                      class="form-control" 
                                                      type="text" 
                                                      name='gp_name' 
                                                      id="flexSwitchCheckDefault" 
                                                      onChange={(e) => handleFieldChange(e)}
                                                      value={selectedBlock.gp_name}
                                                      >
                                                  </input>
                                            </div>
                                            <div className='col-md-6'>
                                                <label> GP Contact Phone: </label>
                                                <input 
                                                      style={{marginLeft: "0em"}} 
                                                      class="form-control" 
                                                      type="tel" 
                                                      name='gp_contact_phone' 
                                                      id="flexSwitchCheckDefault" 
                                                      onChange={(e) => handleFieldChange(e)}
                                                      value={selectedBlock.gp_contact_phone}
                                                      >
                                                </input>
                                            </div>
                                            <div className='col-md-12'>
                                                <label> GP Address: </label>
                                              <textarea 
                                                      style={{marginLeft: "0em"}} 
                                                      class="form-control" 
                                                      type="text" 
                                                      name='gp_address' 
                                                      id="flexSwitchCheckDefault" 
                                                      onChange={(e) => handleFieldChange(e)}
                                                      value={selectedBlock.gp_address}
                                                      >
                                                      </textarea>
                                            </div>
                                            
                                            </>:''}
                                            
                                            </>
                                    <tr>         
                                    <td> Is the participant willing to inform his/her GP of his/her participation in the trial?
                                        <th scope="row">
                                        </th>
                                    </td>
                                    <th>
                                <td> Yes
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="inform_gp"
                                        checked = {selectedBlock?.inform_gp == true}
                                        id="radio1"
                                        value={selectedBlock?.inform_gp == true}
                                        onChange={(e) =>handleInformGp(e)}
                                    />
                                </td>
                                </th>
                                <th>
                                <td> No
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="inform_gp"
                                    checked = {selectedBlock?.inform_gp == false}
                                    id="radio1"
                                    value={selectedBlock?.inform_gp == false}
                                    onChange={(e) =>handleInformGp(e)}
                                    />
                                </td>
                                </th>
                        </tr>
                          </div>
                            {(selectedBlock.inform_gp === true) ?
                              
                              <p>If Yes, please mail a letter to the GP stating the participant's participation in the trial. Keep a copy in the file.</p>:''}
                              
                              
                          </div>
                          </> :'Visit is not started' 
    }
                          </fieldset>
     </>
     
}   
export default Participant_Contact_Info;