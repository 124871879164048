import axios from "axios";
import React, { useState, useRef } from "react";
import { __API_URL__ } from "../../SERVER_URL"
import useUser from "../../hooks/user";
import { toast, ToastContainer } from "react-toastify";

const AddTrialNotification = ({ token, getTrialsNotification, getAdverseNotification,  trialId }) => {
  const [noti, setNoti] = useState("");
  const [title, setTitle] = useState("");
  const [customData, setCustomData] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [isApp, setIsApp] = useState(false);
  const [isSms, setIsSms] = useState(false);
  const [hours, setHours] = useState([]);
  const [controlDays, setControlDays] = useState(false);
  const [value, setValue] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [frequency, setFrequency] = useState("");
  const [startDateOffset, setStartDateOffset] = useState(0)
  const [endDateOffset, setEndDateOffset] = useState(0)
  const [useOffset, setUseOffset] = useState(false);
  const user = useUser();
  const [trialsList, setTrialsList] = useState([]);
  const popupRef = useRef(null);

  const handleHoursChange = (e) => {
    setHours(e.target.value);
  }

  const handleAddHours = () => {
    setControlDays(true);
    setValue(data => [...data, Number(hours)]);
    setHours("");
  }

  const handleDaysButton = (id) => {
    const newValue = value.filter((item, index) => index !== id);
    setValue(newValue);
  }

  const handleAppChange = () => {
    setIsApp(!isApp);
  }

  const handleSmsChange = () => {
    setIsSms(!isSms);
  }

  const handleEmailChange = () => {
    setIsEmail(!isEmail);
  }

  const handleCustomDataChange = (e) => {
    setCustomData(e.target.value);
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  }

  const handleNoti = (e) => {
    setNoti(e.target.value);
  }

  const handleOffsetChange = () => {
    setUseOffset(!useOffset);
  }
  function resetState() {
    setTitle("");
    setCustomData("");
    setIsEmail(false);
    setIsApp(false);
    setIsSms(false);
    setValue([]);
    setHours("");
    setStartDate("");
    setEndDate("");
    setFrequency("");
    setStartDateOffset("");
    setEndDateOffset("");
  }

  const createInstance = (e) => {
    e.preventDefault();
    const obj = {
      is_email: isEmail,
      is_app: isApp,
      is_sms: isSms,
      custom_title: title,
      custom_data: customData,
      notification_type: noti,
      hours: value,
      trial: trialId,
      start_date: startDate,
      end_date: endDate,
      start_date_offset: startDateOffset,
      end_date_offset: endDateOffset,
      frequency: frequency
    }
    saveTrialNotification(obj);
    resetState();
    popupClose();
  };

  const optionsArray = [
    'TRIAL_VISIT_NOTIFICATION',
    'ADVERSE_EVENT_NOTIFICATION',
  ];

  const saveTrialNotification = (data) => {
    let endpoint;
    endpoint = 'api/appointments/add_trial_notification/';
    axios
      .post(`${__API_URL__}/${endpoint}`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        getTrialsNotification([]);
        getAdverseNotification([])
        // toast.success(`${noti} Added Successfully`);
        toast.success(`Notification Added Successfully`);
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
  };


  const popupClose = () => {
    document.getElementById("messageSend").style.display = "none";
    resetState();
  }

  const getTrialsList = () => {
    axios
      .get(`${__API_URL__}/api/photos/trials_list_view`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTrialsList(res.data);
      }).catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
        }
      });
  }
  React.useEffect(() => {
    getTrialsList();
  }, []);


  React.useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        popupClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div id="messageSend" ref={popupRef} style={{ zIndex: '999999', maxWidth: '600px', width: '80%', maxHeight: '85vh', overflowY: 'auto' }}>
      <span class="btn-close btn-close-black" onClick={popupClose} disabled aria-label="Close" style={{ position: "absolute", right: 16, top: 15 }}></span>
      <form className="form-outline" onSubmit={createInstance}>
        <h5>Add Notifications</h5>
        <label>Notification</label>
        <select id="language" onChange={handleNoti} className="form-control">
          <option value=''>Select Type</option>
          {optionsArray.map((item, index) =>
            <option value={item} key={index} >{item}</option>
          )}
        </select>
        {noti !== 'APPOINTMENT_REMINDER' && (
          <>
            <label>Custom Title</label>
            <input
              type="text"
              onChange={handleTitleChange}
              name="cusTitle"
              value={title}
              className="form-control"
            />
            <label className="form-label" htmlFor="textAreaExample">Custom Data</label>
            <textarea
              className="form-control"
              id="textAreaExample"
              rows="3"
              onChange={handleCustomDataChange}
              name="cusData"
              value={customData}
            />
          </>
        )}
        {noti !== 'ADVERSE_EVENT_NOTIFICATION' && (
          <>
            <label>Hours (before)</label>
            <div className="row">
              <div className="col-8">
                <input
                  type="number"
                  onChange={handleHoursChange}
                  value={hours}
                  name="date"
                  className="form-control"
                />
              </div>
              <div className="col-4">
                <span className="btn btn-danger" onClick={handleAddHours} > Add Hours </span>
              </div>
            </div>
            <div className="d-flex flex-row flex-wrap">
              {controlDays ? value.map((item, index) => (
                <div key={index} className="p-2">
                  <span>{item}</span>
                  <div className="p-2 border">
                    <button onClick={() => handleDaysButton(index)} type="button" className="btn btn-primary btn-sm px-1 py-0"> x </button>
                  </div>
                </div>
              ))
                :
                null
              }
            </div>
            <br />
          </>
        )}
        <div>
          <label>Notification:</label>
          <div className="row">
            <div className="col-md-4">
              <label>In App</label>
            </div>
            <div className="col-md-8">
              <input type="checkbox" name="typeApp" checked={isApp} onChange={handleAppChange} style={{ width: "auto" }} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>SMS</label>
            </div>
            <div className="col-md-8">
              <input type="checkbox" name="typeSms" checked={isSms} onChange={handleSmsChange} style={{ width: "auto" }} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Email</label>
            </div>
            <div className="col-md-8">
              <input type="checkbox" name="typeEmail" checked={isEmail} onChange={handleEmailChange} style={{ width: "auto" }} />
            </div>
          </div>
        </div>
        {noti === 'ADVERSE_EVENT_NOTIFICATION' && (
          <>
          <div className="row">
          <label>Select One: </label>
            <div className="col-md-3">
            <label htmlFor="offsetRadio" className="mx-2">Use Offset</label>
            </div>
            <div className="col-md-4">
            <input
                type="radio"
                name="offsetRadio"
                value="offset"
                checked={useOffset}
                style={{ width: "auto" }}
                onChange={handleOffsetChange}
              />
            </div>
          </div>
          <div className="row">
          <div className="col-md-3">
          <label htmlFor="offsetRadio" className="mx-2">Use Date</label>
          </div>
          <div className="col-md-4">
              <input
                type="radio"
                name="offsetRadio"
                value="date"
                checked={!useOffset}
                style={{ width: "auto" }}
                onChange={handleOffsetChange}
              />
            </div>
          </div>
            {useOffset ? (
              <>
                <label>Offset to the start time of Baseline Visit (in hours)</label>
                <input
                  type="number"
                  onChange={(e) => setStartDateOffset(e.target.value)}
                  value={startDateOffset}
                  name="start_date_offset"
                  className="form-control"
                />
                <label>Offset to the end time of Last Visit (in hours)</label>
                <input
                  type="number"
                  onChange={(e) => setEndDateOffset(e.target.value)}
                  value={endDateOffset}
                  name="end_date_offset"
                  className="form-control"
                />
              </>
            ) : (
              <>
                <label>Start Date</label>
                <input
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                  name="startDate"
                  className="form-control"
                />
                <label>End Date</label>
                <input
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                  name="endDate"
                  className="form-control"
                />
              </>
            )}
            <label>Frequency (in hours)</label>
            <input
              type="number"
              onChange={(e) => setFrequency(e.target.value)}
              value={frequency}
              name="frequency"
              className="form-control"
              min={1}
            />
          </>
        )}
        <span className="btn btn-danger" onClick={popupClose}>Close</span> &nbsp;
        <button type="submit" className="btn btn-primary my-3">
          Send
        </button>
      </form>
    </div>
  );
}

export default AddTrialNotification;
