import {useState, React} from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";


const AddDrug = ({addDrug, setAddDrug, setMessage, setTypeOfMessage, trial, GetDrugKitsAPI, newDrug, setNewDrug, setDeleteObjid, manual_drug, trialDetailApi }) => {
    const token = localStorage.getItem("token");const user_data = JSON.parse(localStorage.getItem("user_data"));

  const handleRealDrug = (e) => {
    const { name, value } = e.target;
    setNewDrug((newDrug) => ({
      ...newDrug,
      'is_real_drug': !newDrug.is_real_drug,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDrug((newDrug) => ({
      ...newDrug,
      [name]: value,
    }));
  };

    const CreateNewDrug = (e) => {
        e.preventDefault();
        axios
        .post(`${__API_URL__}/api/add_drug/`, {...newDrug, 'trial':trial}, {
            headers: {
            Authorization: `Token ${token}`,
            },
        })
        .then((res) => {
            setAddDrug(false)
            GetDrugKitsAPI()
            setMessage("Drug Created Successfully!");
            setNewDrug({is_real_drug:true})
            trialDetailApi()
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        }).catch((err) => {
            setMessage(err.response.data.message);
            setTypeOfMessage("error");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 5000);
        });
    }

  return (
    <div>
       <Modal size="md" show onHide={() => {setAddDrug(false); setDeleteObjid(0)}} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Add Drug
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">

        <form
            onSubmit={(e) => {
                CreateNewDrug(e);
            }}
          >
            <div className="form-group">
              <label htmlFor="question">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={newDrug?.name}
                onChange={(e) => {
                    handleChange(e);
                }}
                placeholder="Enter Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="question">Serial Number</label>
              <input
                type="text"
                className="form-control"
                id="serial_number"
                name="serial_number"
                value={newDrug?.serial_number}
                onChange={(e) => {
                    handleChange(e);
                }}
                placeholder="Enter Serial Number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="question">Units/Dosage</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="dosage"
                value={newDrug?.dosage}
                onChange={(e) => {
                    handleChange(e);
                }}
                placeholder="Enter Units/Dosage"
                required
              />
            </div>

            <div className="row mt-1">
              <div className="col-md-3">
              <input
                type="radio"
                className="form-check-input mx-2"
                id="exampleCheck1"
                name="is_real_drug"
                checked={newDrug.is_real_drug == true ? true : false}
                value={true}
                onChange={(e) => {
                  handleRealDrug(e);
                }}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
              Real Drug
              </label>
              </div>
              <div className="col-md-4">

              <input
                type="radio"
                className="form-check-input mx-2"
                id="exampleCheck1"
                name="is_real_drug"
                checked={newDrug.is_real_drug == false ? true : false}
                value={false}
                onChange={(e) => {
                  handleRealDrug(e);
                }}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
              Fake Drug
              </label>
              </div>

            </div>

            <div className="container MX-5 d-flex justify-content-end">
              <div className="row">
              <div className="col-sm">
                  <button 
                  onClick={() => {setAddDrug(false); setDeleteObjid(0)}} 
                  className="btn btn-secondary my-2">
                    Cancel
                  </button>
                </div>
                <div className="col-sm">
                  <button type="submit" className="btn btn-primary my-2">
                    Save
                  </button>
                </div>
                
              </div>
            </div>
          </form>
        </Modal.Body>
    </Modal>
    </div>
  )
}

export default AddDrug
