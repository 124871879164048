import Table from 'react-bootstrap/Table';
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Concomitant_Medication = (props) => 
{
const [concomitantMedication, setConcomitantMedication] = useState({})
const token = localStorage.getItem("token");
const user = useUser();
const user_data = JSON.parse(localStorage.getItem("user_data"));
const role = user_data.role
const setMessage = props.setMessage
const setTypeOfMessage = props.setTypeOfMessage
let disable = false
const [disableButton, setDisableButton] = useState(false)
const [showAddButton, setShowAddButton] = useState(true);
const [showTooltip, setShowTooltip] = useState(false);

const STOP_REASON_CHOICES = [
    { value: 'Ongoing at Study Exit', label: 'Ongoing at Study Exit'},
    { value: 'Unknown', label: 'Unknown'},
]
const DOSE_CHOICES = [
    { value: 'mg', label: 'mg'},
    { value: 'mcg', label: 'mcg'},
    { value: 'mL', label: 'mL'},
    { value: 'g', label: 'g'},
    { value: 'IU', label: 'IU'},
    { value: 'Other', label: 'Other'},
]
const FREQUENCY_CHOICES = [
    { value: 'QD', label: 'QD'},
    { value: 'BID', label: 'BID'},
    { value: 'TID', label: 'TID'},
    { value: 'QID', label: 'QID'},
    { value: 'QOD', label: 'QOD'},
    { value: 'QM', label: 'QM'},
    { value: 'PRN', label: 'PRN'},
    { value: 'UNK', label: 'UNK'},
    { value: 'N/A', label: 'N/A'},
    { value: 'Other', label: 'Other'},
]
const ROUTE_CHOICES = [
    { value: 'Oral', label: 'Oral'},
    { value: 'Topical', label: 'Topical'},
    { value: 'Subcutaneous', label: 'Subcutaneous'},
    { value: 'Transdermal', label: 'Transdermal'},
    { value: 'Intraocular', label: 'Intraocular'},
    { value: 'Intramuscular', label: 'Intramuscular'},
    { value: 'Inhalation', label: 'Inhalation'},
    { value: 'Intralesional', label: 'Intralesional'},
    { value: 'Intraperitoneal', label: 'Intraperitoneal'},
    { value: 'Sublingual', label: 'Sublingual'},
    { value: 'Nasal', label: 'Nasal'},
    { value: 'Vaginal', label: 'Vaginal'},
    { value: 'Rectal', label: 'Rectal'},
    { value: 'Intravenous', label: 'Intravenous'},
    { value: 'Auricular', label: 'Auricular'},
    { value: 'Intrauterine', label: 'Intrauterine'},
    { value: 'Unknown', label: 'Unknown'},
    { value: 'Other', label: 'Other'},
]
 const RELATIONSHIP_CHOICES = [
    { value: 'Adverse Event', label: 'Adverse Event'},
    { value: 'Medical History', label: 'Medical History'},
    { value: 'Neither', label: 'Neither'},
]
const ConcomitantMedicationData = () =>{
    setDisableButton(true)
    props.setHasChanged(true);
    setShowAddButton(true)
    setShowTooltip(true);
    setConcomitantMedication({
    ...concomitantMedication,
    concomitant_data: [
    ...concomitantMedication.concomitant_data,
    {
    id: null,
    ae_specify: '',
    cm_number: '',
    dose: '',
    dose_quantity: '',
    dose_specification: '',
    frequency: '',
    frequency_specification: '',
    indication: '',
    relationship: '',
    route: '',
    route_specification: null,
    start_date: null,
    stop_date:null,
    stop_reason: null,
}
    ],
    })
}
const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItem = { ...concomitantMedication.concomitant_data[index], [name]: value };
    const updatedLogData = [...concomitantMedication.concomitant_data];
    updatedLogData[index] = updatedItem;
    setConcomitantMedication({ ...
    concomitantMedication, concomitant_data: updatedLogData });
    props.setHasChanged(true)
    };

const handleIsMedication = (e) => {
    setConcomitantMedication({
            ...concomitantMedication,
            is_medication_report: !concomitantMedication.is_medication_report,
    }); 
    };

const handleDelete = (id) =>{
        setDisableButton(false)
        const data = concomitantMedication.concomitant_data.filter((item,index) => index !== id)
        setConcomitantMedication({
        ...concomitantMedication,
        concomitant_data:data
        }) 
        props.setHasChanged(false)
        }
const ConcomitantMedication = (id) => {
       const createData = {
        'concomitant_data' : concomitantMedication.concomitant_data[id],
        'patient_id':props.patient_id,
        'visit_number':props.visit_number,
        'is_medication_report':concomitantMedication?.is_medication_report,
        'sessionId': props.selectedSessionId,
          }
          axios
            .patch(`${__API_URL__}/api/ecrf/update_concomitant/${props.trialId}/`,createData, {
              headers: {
                Authorization: `Token ${token}`,
              },
            })
            .then((res) => {
                setConcomitantMedication(res.data);
                props.globalVisits(false);
                props.setHasChanged(false)
                setMessage("Block updated successfully");
                setTypeOfMessage("success");
                setDisableButton(false)
                setTimeout(() => {
              document.getElementById("cusMessages").style.right = "2rem";
              }, 100);
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "-99rem";
              }, 3000);
            }).catch((err) => {
              if(err == 'Error: Request failed with status code 401'){
              user.actions.logout()
              }
              setMessage("Something Went Wrong");
              setTypeOfMessage("success");
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "2rem";
              }, 100);
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "-99rem";
              }, 3000);
          });
        };
        const getConcomitantData = () => {
            axios
              .get(`${__API_URL__}/api/ecrf/get_concomitant/${props.trialId}/?visit_number=${props.visit_number}&patient_id=${props.patient_id}&sessionId=${props?.selectedSessionId}`, {
                headers: {
                  Authorization: `Token ${token}`,
                },
              })
              .then((res) => {
                setConcomitantMedication(res.data);
              }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                }
              });
          }
          const CreateBlockData = () => {
                const createData = {
                        'visit_number':props.visit_number,
                        'patient_id':props.patient_id,
                        'trial':props.trialId,
                        'is_medication_report':concomitantMedication?.is_medication_report,
                        'sessionId': props.selectedSessionId,
                        }
                axios
                .post(`${__API_URL__}/api/ecrf/create_concomitant/`, createData, {
                        headers: {
                        Authorization: `Token ${token}`,
                        },
                })
                .then((res) => {
                        if (concomitantMedication?.is_medication_report) {
                                props.globalVisits();
                        }
                        getConcomitantData();
                }).catch((err) => {
                        if(err == 'Error: Request failed with status code 401'){
                        user.actions.logout()
                        }
                });
        };
        const DeleteBlockData = (id) => {
            axios
            .delete(`${__API_URL__}/api/ecrf/delete_concomitant/${id}/`,{
                    headers: {
                    Authorization: `Token ${token}`,
                    },
            })
            .then((res) => {
                getConcomitantData();
            }).catch((err) => {
                    if(err == 'Error: Request failed with status code 401'){
                    user.actions.logout()
                    }
            });
            };

            
        React.useEffect(() => {
                if((props?.patient_id && props?.photo_session)){
                        getConcomitantData();
                }
        }, [])

        useEffect(() => {
                if((props?.patient_id && props?.photo_session)){
                        CreateBlockData();
                }
            }, [concomitantMedication.is_medication_report]);
            
            
    return <>
                {
                        ((role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') && (props.patient_id === undefined)) ?
                                disable = true : ""

                        }
                        <fieldset disabled={disable}>
                        {
                                (props.photo_session || props.patient_id == undefined) ?
                                <>
                            <tr>  
                                    <td> • Were any concomitant medications/treatments reported for this participant?
                                    </td>
                                    <th>
                                    <td> <label for='radioYes'> Yes</label>
                                    <input
                                        class="form-check-input mx-1"
                                        type="radio"
                                        name="is_medication_report"
                                        checked = {concomitantMedication?.is_medication_report === true}
                                        value={concomitantMedication?.is_medication_report === true}
                                        id="radioYes"
                                        onChange={handleIsMedication}
                                    />
                                </td>
                                </th>
                                <th>
                                <td><label for='radioNo'> No</label> 
                                    <input
                                    class="form-check-input mx-1"
                                    type="radio"
                                    name="is_medication_report"
                                    checked = {concomitantMedication?.is_medication_report === false}
                                    value={concomitantMedication?.is_medication_report === false}
                                    id="radioNo"
                                    onChange={handleIsMedication}
                                    />
                                </td>
                                </th>
                            </tr>
{concomitantMedication.is_medication_report === true ? (
            <> 
                {Array.isArray(concomitantMedication.concomitant_data) && concomitantMedication.concomitant_data.map((item, index) => {
                return <>
                  {
                                (props.patient_id != undefined && role === 'Investigator' || role === 'CRO Admin' || role === 'Inv_coordinator' || role === 'Cro_coordinator' || role === 'Data Manager' || role === 'Super Admin') && <>
                                  <div className="col-md-12 text-end border mt-4">
                                { item.id ?
                                        <>
                                  <span onClick={() => DeleteBlockData(item.id)}>
                                        <i className="fa fa-trash fa-lg text-danger"></i>
                                </span>
                                </>:
                                  <span onClick={() => handleDelete(index)}>
                                        <i className="fa fa-trash fa-lg text-danger"></i>
                                </span>
                }
                                    <button onClick={() => ConcomitantMedication(index)} 
                                    setShowTooltip={false}
                                    className='btn btn-primary mx-3 my-3'>Save</button>
                                  </div>
                                </>
                              }
        <Table className="table table-responsive ecrf-medication-table">
                                <thead>
                                        <tr class="table table-bordered table-sm" >
                                            <th className='border'>NAME & INDICATION OF MEDICATION 
                                                    <br></br>/ TREATMENT 
                                            <br></br>(Please avoid using abbreviations)</th>
                                            <th className='border'>START DATE</th>
                                            <th className='border'>STOP DATE</th>
                                            <th className='border'>DOSE</th>
                                            <th className='border'>FREQUENCY</th>
                                            <th className='border'>ROUTE</th>
                                            <th className='border'>RELATIONSHIP</th>
                                        </tr>
                                </thead>
                                    <tbody>
                                        <tr>
                                        <td>
                                                    <div className='col-md-10'>
                                                                <label> CM#:  </label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name='cm_number'
                                                                id="flexSwitchCheckDefault"
                                                                value={item?.cm_number} 
                                                                onChange={(e) => handleChange(e, index)}
                                                                >
                                                                </input>
                                                    </div>
                                            <div className='col-md-10'>
                                            <label> Generic Name:  </label>

                                                        <input
                                                        class="form-control"
                                                        type="text"
                                                        name="generic_name"
                                                        id="flexSwitchCheckDefault"
                                                        value={item?.generic_name} 
                                                        onChange={(e) => handleChange(e, index)}
                                                        >
                                                        </input>
                                            <hr></hr>
                                            </div>
                                            <div className='col-md-10'>
                                            <label>Indication</label>

                                                        <input
                                                        class="form-control"
                                                        type="text"
                                                        name="indication"
                                                        id="flexSwitchCheckDefault"
                                                        value={item?.indication} 
                                                        onChange={(e) => handleChange(e, index)}
                                                        >
                                                        </input>
                                            </div>
                                            </td>
                                            <td>
                                                     <div className="col-md-12">
                                                        <input
                                                        class='form-control'                                                        
                                                        onChange={(e) => handleChange(e, index)}
                                                        type="date"
                                                        name="start_date"
                                                        value={item?.start_date}
                                                        inputProps={{placeholder:'mm/dd/yy 00:00 AM', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined) }}
                                                />
                                                    </div>
                                                    <div className='col-md-10'>
                                                            <input 
                                                                    className="form-check-input mx-3" 
                                                                    style={{marginLeft: "1em"}}
                                                                    type="checkbox" 
                                                                    name="unknown" 
                                                                    checked = {item?.start_date? false : true}
                                                                    disabled
                                                                    /> 
                                                                <label> Unknown </label>
                                                    </div>
                                                    </td>
                                                    <td>
                                                     <div className="col-md-12">
                                                     <input
                                                        class='form-control'                                                        
                                                        onChange={(e) => handleChange(e, index)}
                                                        type="date"
                                                        name="stop_date"
                                                        value={item?.stop_date}
                                                        inputProps={{placeholder:'mm/dd/yy 00:00 AM', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined) }}
                                                />
                                                    </div>
                                                    {STOP_REASON_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='stop_reason' 
                                                                        checked={item.stop_reason === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                                    </td>
                                            <td>
                                            <div className='col-md-12'>
                                            <label></label>
                                                <textarea
                                                        class="form-control"
                                                        type="textarea"
                                                        name="dose"
                                                        style={{width:'250px',height:'50px'}}
                                                        id="flexSwitchCheckDefault"
                                                        value={item?.dose} 
                                                        onChange={(e) => handleChange(e, index)}
                                                        >
                                                        </textarea>
                                                </div>
                                            <div className='col-md-10'>
                                            {DOSE_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='dose_quantity' 
                                                                        checked={item.dose_quantity === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                        </div>
                                        {item.dose_quantity === "Other" ?
                                        
                                                <div className='col-md-12'>
                                                <label> Specify:  </label>
                                                <textarea
                                                style={{width:'250px',height:'50px'}}
                                                className="form-control"
                                                type="text"
                                                name="dose_specification"
                                                id="flexSwitchCheckDefault"
                                                value={item?.dose_specification} 
                                                onChange={(e) => handleChange(e, index)}
                                                >
                                                </textarea>
                                            </div>
                                            :''
                }
                                                    </td>
                                        <td>
                                            <div className='col-md-10'>
                                            {FREQUENCY_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='frequency' 
                                                                        checked={item.frequency === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                        </div>
                                        {item.frequency === "Other" ?
                                        
                                                <div className='col-md-12'>
                                                <label> Specify:  </label>
                                                <textarea
                                                style={{width:'250px',height:'50px'}}
                                                className="form-control"
                                                type="text"
                                                name="frequency_specification"
                                                id="flexSwitchCheckDefault"
                                                value={item?.frequency_specification} 
                                                onChange={(e) => handleChange(e, index)}
                                                >
                                                </textarea>
                                            </div>
                                            :''
                }
                                                    </td>
                                                    <td>
                                                    <div className='col-md-10'>
                                            {ROUTE_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='route' 
                                                                        checked={item.route === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                        </div>
                                        {item.route === "Other" ?
                                        
                                                <div className='col-md-12'>
                                                <label> Specify:  </label>
                                                <textarea
                                                style={{width:'250px',height:'50px'}}
                                                className="form-control"
                                                type="text"
                                                name="route_specification"
                                                id="flexSwitchCheckDefault"
                                                value={item?.route_specification} 
                                                onChange={(e) => handleChange(e, index)}
                                                >
                                                </textarea>
                                            </div>
                                            :''
                }
                                                    </td>
                                                    <td>

                                        <div className='col-md-10'>
                                            {RELATIONSHIP_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='relationship' 
                                                                        checked={item.relationship === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                        </div>
                                        {item.relationship === "Adverse Event" ?
                                        
                                                <div className='col-md-12'>
                                                <label> Specify:  </label>
                                                <textarea
                                                style={{width:'250px',height:'50px'}}
                                                className="form-control"
                                                type="text"
                                                name="ae_specify"
                                                id="flexSwitchCheckDefault"
                                                value={item?.ae_specify} 
                                                onChange={(e) => handleChange(e, index)}
                                                >
                                                </textarea>
                                            </div>
                                            :''
                }
                                                    </td>
                                        </tr>
  </tbody>
  
  </Table>
  </>
})
}
                                <div className="col-md-12 d-flex justify-content-end my-3">
                                        
                                        <button
                                                type="button"
                                                disabled={disableButton}
                                                className="btn btn-primary float-end disabled-cursor"
                                                id='disabled-cursor-for-blocks'
                                                onClick={() => {
                                                    ConcomitantMedicationData();
                                                }}

                                                title={disableButton ? 'Please save the information first' : 'Click to add concomitant medication'}

                                        >
                                                Add +
                                        </button>
                                       
                        </div>
                        </> ) : ''
                     }
                </> 
                        : 'Visit is not started'
                }
                </fieldset>
                </>
                                  }
                                  
export default Concomitant_Medication;