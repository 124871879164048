import Table from 'react-bootstrap/Table';
import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Adverse_Event = (props) => 
{
 const [adverseEvent,setAdverseEvent] = useState({})
 const [showAddButton, setShowAddButton] = useState(true);

 const token = localStorage.getItem("token");
 const user = useUser();
 const user_data = JSON.parse(localStorage.getItem("user_data"));
 const role = user_data.role
 const setMessage = props.setMessage
 const setTypeOfMessage = props.setTypeOfMessage
 let disable = false
 const [disableButton, setDisableButton] = useState(false)
 const [showTooltip, setShowTooltip] = useState(false);

        const EVENT_SERIOUS = [
                { value: 'Death', label: 'Death'},
                { value: 'Life-Threatening', label: 'Life-Threatening'},
                { value: 'Hospitalization', label: 'Hospitalization'},
                { value: 'Significant Disability/incapacity', label: 'Significant Disability/incapacity'},
                { value: 'Congenital abnormality or birth defect', label: 'Congenital abnormality or birth defect'},
                { value: 'Important medical event', label: 'Important medical event'},
        ]
        const AE_LOCATION_CHOICES = [
                {value:'Target Lesion',label:'Target Lesion'},
                {value:'Other', label:'Other'},
        ]
        const STOP_REASON_CHOICES =[
                {value:'Ongoing at Study Exit', label:'Ongoing at Study Exit'},
                {value:'Unknown(LTFU)', label:'Unknown(LTFU)'},
        ]
        const ASSESSMENT_CHOICES =[
                {value:'Related', label:'Related'},
                {value:'Not Related', label:'Not Related'},
        ]
        const ACTION_TAKEN_CHOICES =[
                {value:'No Action Taken', label:'No Action Taken'},
                {value:'Interrupted Usage', label:'Interrupted Usage'},
                {value:'Other', label:'Other'},
        ]
        const SEVERITY_CHOICES = [
                {value:'Mild', label:'Mild'},
                {value:'Moderate', label:'Moderate'},
                {value:'Severe', label:'Severe'},
        ]
        const OUTCOME_CHOICES = [
                {value:'Resolved (no sequelae)', label:'Resolved (no sequelae)'},
                {value:'Resolved (with sequelae)', label:'Resolved (with sequelae)'},
                {value:'Persisted', label:'Persisted'},
                {value:'Unknown (LTFU))', label:'Unknown (LTFU)'},
                {value:'Fatal', label:'Fatal'},
        ]               
        const MEDICATION_CHOICES = [
                {value:'Yes', label:'Yes'},
                {value:'No', label:'No'},
                {value:'Unknown', label:'Unknown'},
        ]
        
    
        const AdverseEventList = () =>{
                setDisableButton(true)
                props.setHasChanged(true)
                setShowAddButton(true)
                setShowTooltip(true);
                setAdverseEvent({
                ...adverseEvent,
                log_data: [
                ...adverseEvent.log_data,
                {
                id: null,
                description: '',
                action_taken: '',
                action_taken_specification: '',
                ae_location: '',
                ae_location_specification: '',
                ae_number: '',
                any_medication: '',
                assessment_causuality: '',
                event_serious_reason: '',
                is_event_serious: false,
                onset_date: null,
                outcome_event: '',
                severity: '',
                stop_date: null,
                stop_reason: ''},
                ],
                })
      }
        const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedItem = { ...adverseEvent.log_data[index], [name]: value };
        const updatedLogData = [...adverseEvent.log_data];
        updatedLogData[index] = updatedItem;
        setAdverseEvent({ ...
                adverseEvent, log_data: updatedLogData });
                props.setHasChanged(true)
        };
        const handleEvent = (e, index) => {
                const updatedItem = { ...adverseEvent.log_data[index], 'is_event_serious': !adverseEvent.log_data[index].is_event_serious };
                const updatedLogData = [...adverseEvent.log_data];
                updatedLogData[index] = updatedItem;
                setAdverseEvent({ ...
                        adverseEvent, log_data: updatedLogData });
                        props.setHasChanged(true)
                };

        const handleAdverseEvent = (e) => {
                setAdverseEvent({
                        ...adverseEvent,
                        is_event_report: !adverseEvent.is_event_report,
                }); 
                };


        const CreateBlockData = () => {
                const createData = {
                        'visit_number':props.visit_number,
                        'patient_id':props.patient_id,
                        'trial':props.trialId,
                        'is_event_report':adverseEvent?.is_event_report,
                        'sessionId': props.selectedSessionId,

                        }
                axios
                .post(`${__API_URL__}/api/ecrf/create_logdata/`, createData, {
                        headers: {
                        Authorization: `Token ${token}`,
                        },
                })
                .then((res) => {
                        if (adverseEvent?.is_event_report) {
                                props.globalVisits();
                        }
                        getAdverseEvent();
                }).catch((err) => {
                        if(err == 'Error: Request failed with status code 401'){
                        user.actions.logout()
                        }
                });
        };


        const handleDelete = (id) =>{
                props.setHasChanged(false)
                setDisableButton(false)
                const data = adverseEvent.log_data.filter((item,index) => index !== id)
                setAdverseEvent({
                ...adverseEvent,
                log_data:data
                }) 
                }
        const AdverseEvent = (id) => {
                const sendData = {
                        'log_data' : adverseEvent.log_data[id],
                        'patient_id':props.patient_id,
                        'visit_number':props.visit_number,
                        'is_event_report':adverseEvent?.is_event_report,
                        'sessionId': props.selectedSessionId,
                        }      
                axios
                .patch(`${__API_URL__}/api/ecrf/update_adverse_event/${props.trialId}/`,sendData, {
                headers: {
                        Authorization: `Token ${token}`,
                },
                })
                .then((res) => {
                setDisableButton(false)
                setAdverseEvent(res.data);
                props.globalVisits(false);
                props.setHasChanged(false)
                setMessage("Block updated successfully");
                setTypeOfMessage("success");
                setTimeout(() => {
                document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
                }).catch((err) => {
                if(err == 'Error: Request failed with status code 401'){
                user.actions.logout()
                }
                setMessage("Something Went Wrong");
                setTypeOfMessage("success");
                setTimeout(() => {
                document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
                });
        };
        const getAdverseEvent = () => {
                axios
                  .get(`${__API_URL__}/api/ecrf/get_adverse_event/${props.trialId}/?visit_number=${props.visit_number}&patient_id=${props.patient_id}&sessionId=${props?.selectedSessionId}`, {
                    headers: {
                      Authorization: `Token ${token}`,
                    },
                  })
                  .then((res) => {
                    setAdverseEvent(res.data);
                  }).catch((err) => {
                    if (err == 'Error: Request failed with status code 401') {
                    }
                  });
              }

        React.useEffect(() => {
                if((props?.patient_id && props?.photo_session)){
                        getAdverseEvent();
        }
        
        }, [])

        React.useEffect(() => {
                if((props?.patient_id && props?.photo_session)){    
                        CreateBlockData();
                }
        
        }, [adverseEvent.is_event_report])


        const DeleteBlockData = (id) => {
                axios
                .delete(`${__API_URL__}/api/ecrf/delete_logdata/${id}/`,{
                        headers: {
                        Authorization: `Token ${token}`,
                        },
                })
                .then((res) => {
                getAdverseEvent();
                }).catch((err) => {
                        if(err == 'Error: Request failed with status code 401'){
                        user.actions.logout()
                        }
                });
                };
                return <>
                {
                        (role ==='CRO Admin') || (role==='Cro_coordinator') || (role==='Data Manager') || (props.patient_id === undefined)? 
                        disable=true : ""
                          }
                          <fieldset disabled={disable}>
                            {
                              (props.photo_session || props.patient_id === undefined ) ?
                            <div className='row'>
                              {/* {
                                (props.patient_id != undefined && role === 'Investigator' || role === 'CRO Admin' || role === 'Inv_coordinator' || role === 'Cro_coordinator' || role==='Data Manager' || role === 'Super Admin') && <>
                                  <div className="col-md-12 text-end">
                                    <button onClick={() => CreateBlockData()} className='btn btn-primary'>Update</button>
                                  </div>
                                </>
                              } */}
                            <tr>  
                                    <td> • Were any adverse events reported for this participant? 
                                    </td>
                                    <th>
                                <td> <label for='radioYes'> Yes</label>
                                    <input
                                       
                                        className="form-check-input mx-1"
                                        type="radio"
                                        name="is_event_report"
                                        checked = {adverseEvent?.is_event_report === true}
                                        value={adverseEvent?.is_event_report === true}
                                        id="radioYes"
                                        onChange={handleAdverseEvent}
                                    />
                                </td>
                                </th>
                                <th>
                                <td> <label for='radioNo'> No</label>
                                    <input
                                
                                        className="form-check-input mx-1"
                                        type="radio"
                                        name="is_event_report"
                                        checked = {adverseEvent?.is_event_report == false}
                                        id="radioNo"
                                        value={adverseEvent?.is_event_report === false}
                                        onChange={handleAdverseEvent}
                                    />
                                </td>
                                </th>
                            </tr>
                
{adverseEvent.is_event_report === true ? (
        <>
                {Array.isArray(adverseEvent.log_data) && adverseEvent.log_data?.map((item, index) => {
                return <>
                  {
                                (props.patient_id != undefined && role === 'Investigator' || role === 'CRO Admin' || role === 'Inv_coordinator' || role === 'Cro_coordinator' || role==='Data Manager' || role === 'Super Admin') && <>
                                  <div className="col-md-12 text-end border mt-4">
                                { item.id ?
                                        <>
                                  <span onClick={() => DeleteBlockData(item.id)}>
                                        <i className="fa fa-trash fa-lg text-danger"></i>
                                </span>
                                </>:
                                  <span onClick={() => handleDelete(index)}>
                                        <i className="fa fa-trash fa-lg text-danger"></i>
                                </span>
                }
                                    <button onClick={() => AdverseEvent(index)} 
                                    setShowTooltip={false}
                                    className='btn btn-primary p-1 mx-3 my-1 px-2'><i className="fa fa-save fa-lg text-white"></i> Save</button>
                                  </div>
                                </>
                              }
                    <Table id='patient_table' className="border_table table-sm border">
                                <thead>
                                        <tr>
                                            <th className='border'>Description of Adverse Event</th>
                                            <th className='border'>Onset Date</th>
                                            <th className='border'>Stop Date</th>
                                            <th className='border'>Is this event serious?</th>
                                            <th className='border'>Assessment of Causuality </th>
                                            <th className='border'>Action Taken Regarding Study Device</th>
                                            <th className='border'>Severity</th>
                                            <th className='border'>Outcome of Event</th>
                                            <th className='border'>Any medication to treat the AE?</th>
                                        </tr>
                                </thead>
                    <tbody>
                                <td>
                                        <div className='col-md-12'>
                                                        <label> AE#:  </label>
                                                        <input
                                                        className="form-control"
                                                        type="string"
                                                        name='ae_number'
                                                        id="flexSwitchCheckDefault"
                                                        value={item?.ae_number} 
                                                        onChange={(e) => handleChange(e, index)}
                                                        >
                                                        </input>
                                                    </div>
                                            <div className='col-md-12'>
                                            <label> Description:  </label>

                                                        <textarea
                                                        className="form-control"
                                                        type="text"
                                                        name="description"
                                                        id="flexSwitchCheckDefault"
                                                        value={item?.description} 
                                                        onChange={(e) => handleChange(e, index)}
                                                        >
                                                        </textarea>
                                            </div>
                                            <div className='col-md-10'>
                                            <label>AE Location:</label>
                                            {AE_LOCATION_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='ae_location' 
                                                                        checked={item.ae_location === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                        </div>
                                        {item.ae_location === "Other" ?
                                        
                                                <div className='col-md-12'>
                                                <label> Specify:  </label>
                                                <textarea
                                                className="form-control"
                                                type="text"
                                                name="ae_location_specification"
                                                id="flexSwitchCheckDefault"
                                                value={item?.ae_location_specification} 
                                                onChange={(e) => handleChange(e, index)}
                                                >
                                                </textarea>
                                            </div>
                                            :''
                }
                                            </td>
                                            <td>
                                                    <>
                                                <div className="col-md-12 calendarIcon">
                                                <input
                                                        class='form-control'                                                        
                                                        onChange={(e) => handleChange(e, index)}
                                                        type="date"
                                                        name="onset_date"
                                                        value={item?.onset_date}
                                                        inputProps={{placeholder:'mm/dd/yy 00:00 AM', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role==='Data Manager') ? true : false || (props.patient_id === undefined) }}
                                                />
                                                    </div>
                                                    </>
                                                    </td>
                                                    <td>
                                                    <>
                                                     <div className="col-md-12">
                                                        <input
                                                                onChange={(e) => handleChange(e, index)}
                                                                class='form-control'
                                                                type="date"
                                                                name="stop_date"
                                                                value={item?.stop_date}
                                                                inputProps={{placeholder:'mm/dd/yy 00:00 AM', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role==='Data Manager') ? true : false || (props.patient_id === undefined) }}
                                                        />
                                                    </div>
                                                    </>
                                                    {STOP_REASON_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='stop_reason' 
                                                                        checked={item.stop_reason === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                                    
                                                    </td>
                                                    <td>
                                                    <div className='col-md-10 p-0'>
                                                            <input 
                                                                    className="form-check-input mx-1" 
                                                                    style={{marginLeft: "1em"}}
                                                                    type="checkbox" 
                                                                    name="is_event_serious" 
                                                                    checked = {item?.is_event_serious === true}
                                                                    value={item?.is_event_serious === true} 
                                                                    onChange={(e) => handleEvent(e, index)}
                                                                    /> 
                                                                <label>Yes*</label>
                                                    </div>
                                                    <div className='col-md-10 p-0'>
                                                            <input 
                                                                    className="form-check-input mx-1" 
                                                                    style={{marginLeft: "1em"}}
                                                                    type="checkbox" 
                                                                    name="is_event_serious" 
                                                                    checked = {item?.is_event_serious === false}
                                                                    value={item?.is_event_serious === false} 
                                                                    onChange={(e) => handleEvent(e, index)}
                                                                    /> 
                                                                <label> No</label>

                                                    </div>
                                        {item.is_event_serious === true ?
                                        <>
                                                    <div className='col-md-10 p-0'>
                                                        <label>*If SAE:</label>
                                                    </div>
                                                    {EVENT_SERIOUS.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='event_serious_reason' 
                                                                        checked={item.event_serious_reason === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                                                </>
                                                                :""}
                                                    </td>
                                                    <td>
                                                    {ASSESSMENT_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='assessment_causuality' 
                                                                        checked={item.assessment_causuality === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                                    </td>
                                                    <td>
                                                    {ACTION_TAKEN_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='action_taken' 
                                                                        checked={item.action_taken === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                                {item.action_taken ==='Other' ?
                                                <>
                                                    <div className='col-md-12'>
                                                    <label> Specify  </label>
                                                            <textarea
                                                                className="form-control"
                                                                type="text"
                                                                name='action_taken_specification'
                                                                id="flexSwitchCheckDefault"
                                                                value={item?.action_taken_specification}
                                                                onChange={(e) => handleChange(e, index)}
                                                                >
                                                                </textarea>
                                                    </div>
                                                    </>:''}
                                                    </td>
                                                    <td>
                                                    {SEVERITY_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='severity' 
                                                                        checked={item.severity === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                                    </td>
                                                    <td>
                                                    {OUTCOME_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='outcome_event' 
                                                                        checked={item.outcome_event === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                                    </td>
                                                    <td>
                                                        {MEDICATION_CHOICES.map(event => (
                                                                <div className='col-md-10 p-0' key={event.value}>
                                                                        <input 
                                                                        className="form-check-input mx-1" 
                                                                        style={{marginLeft: "1em"}}
                                                                        type="checkbox" 
                                                                        name='any_medication' 
                                                                        checked={item.any_medication === event.value}
                                                                        value={event.value} 
                                                                        onChange={(e) => handleChange(e, index)}
                                                                        /> 
                                                                        <label>{event.label}</label>
                                                                </div>
                                                                ))}
                                                    </td>
  </tbody>
  </Table>
                                                                         
  </>
})
}
                        <div className="col-md-12 d-flex justify-content-end my-3">
                                       
                                        <button
                                                type="button"
                                                disabled={disableButton}
                                                className="btn btn-primary float-end disabled-cursor"
                                                onClick={() => {
                                                       AdverseEventList();
                                                }}
                                                title={disableButton? 'Please save the information first': 'Click to add Adverse Event'}
                                        >
                                                Add +
                                        </button>
                        </div>
        </> ) : ''
        }
               
        </div>
                            :'Visit is not started' 
                            }
                          </fieldset>
                            </>
                                  }

export default Adverse_Event