import React ,{useEffect} from 'react'
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Table from 'react-bootstrap/Table';

const Target_Lesion_Injection_Pain = (props) => {
const token = localStorage.getItem("token");
const [selectedBlock, setSelectedBlock] = React.useState({})
const user = useUser();
const user_data = JSON.parse(localStorage.getItem("user_data"));
const role = user_data.role
const setMessage = props.setMessage
const setTypeOfMessage = props.setTypeOfMessage
const extra_photo = props.extra_photo
const [value,newValue]=React.useState("list")

let disable = false
const handleIsInjectionOne = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_injection_one: !selectedBlock.is_injection_one,
    }); 
    props.setHasChanged(true)
  };
const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedBlock((selectedBlock) => ({
    ...selectedBlock,
    [name]: value,
    }));
    props.setHasChanged(true)
  };

const Injection = [
    { label: '0: No Pain', value: '0'},
    { label: '1: Very mild pain', value: '1'},
    { label: '2: Mild, annoying pain', value: '2'},
    { label: '3: More than mild pain', value: '3'},
    { label: '4: Nagging, uncomfortable, troublesome pain', value: '4'},
    { label: '5: Mildly distressing pain', value: '5'},
    { label: '6: Distressing, miserable pain ', value: '6'},
    { label: '7: Major distressing miserable pain', value: '7'},
    { label: '8: Intense, dreadful, horrible pain', value: '8'},
    { label: '9: Really intense dreadful pain', value: '9'},
    { label: '10: Worst possible, unbreable, excruciating pain', value: '10'},
    ]

    const GetSelectedBlockData = () => {
        axios
          .get(`${__API_URL__}/api/ecrf/get_lesion_injection_pain/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((res) => {
            setSelectedBlock(res.data);
          }).catch((err) => {
            if (err == 'Error: Request failed with status code 401') {
            }
          });
      }

    const AllVisitsData = () => {
    const createData = {
            'patient_id':props?.patient_id,
            'visit_number':props?.visit_number,
            ...selectedBlock,
            'injection_one_reason':selectedBlock?.injection_one_reason,
            'immediately_after_injection':selectedBlock?.immediately_after_injection,
            'five_min_injection':selectedBlock?.five_min_injection,
            'is_confirmed':selectedBlock?.is_confirmed,
            'is_injection_one': selectedBlock?.is_injection_one === undefined ? true : selectedBlock?.is_injection_one,
            'sessionId': props.selectedSessionId,
        }
        axios
          .patch(`${__API_URL__}/api/ecrf/update_lesion_injection_pain/${props.trialId}/`,createData, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((res) => {
            setSelectedBlock(res.data);
            props.setModalVisitChangeShow(false)
            props.setHasChanged(false)
            props.globalVisits(false);
            setMessage("Block updated successfully");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        }).catch((err) => {
            if(err == 'Error: Request failed with status code 401'){
            user.actions.logout()
            }
            setMessage("Something Went Wrong");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        });
      };
      React.useEffect(() => {
        if((props?.patient_id && props?.photo_session)){
          GetSelectedBlockData();
        }
      }, [])
                    return <>     
                    {
                           (role ==='CRO Admin' || role==='Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined)) ? 
                              disable=true : ""
                           
                          }
                          <fieldset disabled={disable}>
                    {
                        (props.photo_session || props.patient_id == undefined ) ?
                        <>
                            {
                                (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                <div className="col-md-12 text-end">
                                    <button onClick={() => AllVisitsData()} className='btn btn-primary'>Update</button>
                                </div>
                                )
                            }
                                
                <div className='col-md-12'>
                        <div className="row">
                              <div className="col-md-9">
                              <p> Was target lesion injection #1 pain assessed at this visit?</p>
                              </div>
                              <div className="col-md-1">
                              <p><small> Yes
                                    <input
                                        className="form-check-input mx-1"
                                        type="radio"
                                        name="is_injection_one"
                                        checked = {selectedBlock?.is_injection_one == true}
                                        id="radio1"
                                        value={selectedBlock?.is_injection_one == true}
                                        onChange={handleIsInjectionOne}
                                    />
                                </small>
                                </p>
                                </div>

                        <div className="col-md-1">
                              <p><small> No
                                <input
                                    className="form-check-input mx-1"
                                    type="radio"
                                    name="is_injection_one"
                                    checked = {selectedBlock?.is_injection_one == false}
                                    id="radio1"
                                    value={selectedBlock?.is_injection_one == false}
                                    onChange={handleIsInjectionOne}
                                    />
                                </small>
                                </p>
                        </div>
                        { selectedBlock.is_injection_one === false ?
                    <div className='col-md-8'>
                        <label> if No, specify: </label>
                            <input 
                                    class="form-control" 
                                    type="text" 
                                    style={{ borderWidth:'1px'}}
                                    name='injection_one_reason' 
                                    id="flexSwitchCheckDefault"
                                    value={selectedBlock.injection_one_reason}
                                    onChange={(e) => handleFieldChange(e)}>
                            </input>
                    </div>:''}
                                <div className='col-md-8'>
                                    <label>• Immediately after Injection </label>
                                        <select className='form-control'
                                                name = 'immediately_after_injection'
                                                value={selectedBlock.immediately_after_injection}
                                                style={{ borderWidth:'1px'}}
                                                onChange={(e) => handleFieldChange(e)}
                                                >
                                                                <option value="" selected>Select</option>
                                                        {
                                            Injection.map((immediately) => {
                                                return <>
                                                <option value ={immediately.value}>{immediately.label} </option>
                                                </>
                                            })
                                        }
                                    </select>
                                </div>

                                <div className='col-md-8'>
                                        <label> • 5 Minutes after injection </label>
                                        <select className='form-control'
                                                name = 'five_min_injection'
                                                style={{ borderWidth:'1px'}}
                                                value={selectedBlock.five_min_injection}
                                                onChange={(e) => handleFieldChange(e)}
                                                >
                                            <option value="" selected>Select</option>
                                        {
                                            Injection.map((min) => {
                                                return <>
                                                <option value ={min.value}>{min.label} </option>
                                                </>
                                            })
                                        }
                                    </select>
                            </div>
                            </div>
                            </div>
                            
                            </> :'Visit is not started' 
    }
                          </fieldset>
     </>
}
export default Target_Lesion_Injection_Pain
