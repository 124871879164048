import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
const SpeicalEventDeletePopUp = (props) => {
return (
    <>
{
    <Modal size="md" show backdrop="static" onHide={() => props.setPopMessage(false)}>
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Are You Sure?               
        </Modal.Title>
    </Modal.Header>
    <Modal.Body className="show-grid">
    <p className="text-center">
    <p className="text-center">"You wanted to Delete the Event. It will Delete it Permanently."</p>
    </p>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={(e) => {props.setPopMessage(false)}}>No</Button>
        <Button variant="primary" onClick={props.confirmDelete}>Yes</Button>
    </Modal.Footer>
    </Modal>
}
</>
    )
}
export default SpeicalEventDeletePopUp;