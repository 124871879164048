const VISIT_NAMES = {
  0: 'Baseline Visit',
  '-1': 'Adverse Event',
  '-2': 'Unscheduled Visit',
  '-3': 'Screening Visit',
  '-4': 'OTHER eCRFS',
};

const getCustomNames = (visitNumber) => {
  if (!visitNumber) return '';
  
  let customName = VISIT_NAMES[visitNumber.toString()];

  if (!customName) customName = 'Visit ' + visitNumber;

  return customName;
};

export default getCustomNames;
