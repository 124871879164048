import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "./PatientMenu";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import MessageBox from "../../components/Alert/MessageBox";
import Avatar from "../../assets/img/avatar.png";
import useUser from "../../hooks/user";
import RetakePhotoPopUp from "../../components/PopUp/RetakePhoto";
import ImageCompareSession from "../Patient/ImageCompareSession"

const PatientCase = () => {
  const { _id_, caseId } = useParams();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const pass_number = localStorage.getItem("pass_number");
  const token = localStorage.getItem("token");
  const role = user_data.role
  const [selectedUser, setSelectedUser] = useState();

  const { PatientData, Loader } = useFetch(`patient/patient/photo_session_view/?PatientId=${_id_}&sessionId=${caseId}&selected_user=${selectedUser}`);
  const user = useUser();
  const trial_id = PatientData.trial_id
  const visit_number = PatientData.visit_number
  const[blocksData,setBlocskData] = useState()
  const [modalRetake, setModalRetake] = useState(false);
  const [photoId, setPhotoId] = useState();
  const [scoreUser, setScoreUser] = useState();
  const [infoData, setInfoData] = useState({})

  const handleRetakeModal= (id) =>{
    setModalRetake(true)
    setPhotoId(id)
  }
  const defaultScore = {
    tle_score: {
        photo_type: "", score: "", id: ""
    },
    tls_score: {
      photo_type: "", score: "", id: ""
    },
    tli_score: {
      photo_type: "", score: "", id: ""
    },
}
  const[respData,setRespData] = useState(defaultScore)
  useEffect(() => {
      let photosObj = {}
      PatientData.photo?.map((pData) => {
          photosObj[pData.id] = pData
      })
      setRespData(photosObj)
  }, [PatientData])
  
  useEffect(() => {
    GetScoredUser()
    SinglePatient()
  }, [])
    const Blocks = () => {
      axios
      .get(`${__API_URL__}/api/ecrf/get_blocks/?trial_id=${trial_id}&visit_number=${visit_number}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setBlocskData(res.data);
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
      });
    };

    const GetScoredUser = () => {
      axios
      .get(`${__API_URL__}/api/ecrf/get_score_user/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setScoreUser(res.data)
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
      });
  }

//Updated for lesions score patch api created
      const UpdateScore = (photoId) => {
      const filteredPhoto = respData[photoId]
      axios
      .patch(`${__API_URL__}/api/ecrf/update_score/`, filteredPhoto, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setToastrMessage("Score Updated!")
        setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
        setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 400') {
          setToastrMessage("Please set the Score!")
          setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
          setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
        }
      });
    };
  const [photoForms, setPhotoForms] = useState({})
  const handleFormChange = (e, photoId) => {
      const {name, value} = e.target;
      const prevFormObject = photoForms[photoId]
      const updatedFormObject = {
         ...prevFormObject, [name]: value
      }
      setPhotoForms((prevState) => {
          return {
              ... prevState, [photoId]: updatedFormObject
          }
      })
  }
  const handleFieldChange = (e, photoId) => {
    const { name, value } = e.target;
    setRespData((prevData) => {
       return {
          ...prevData,
          [photoId]: {
              ...prevData[photoId],
              [name]: {...prevData[photoId][name], score: value},
          } 
       }
    })
  };
  const handleSubmission = (photoId) => {
    const postData = photoForms[photoId]
    const updatedData = {
        updated_score: '-1',
        glabellar_frown_pattern_choices: '0',
        evaluation_time: 0,
        extra_comments: '',
        photo_id: photoId,
        pass_number: pass_number ? pass_number : '',
        ...postData
    }
    if (updatedData['updated_score'] == -1 || updatedData['updated_score'] === '-' || updatedData['evaluation_time'] === 0) {
        setToastrMessage("Fill the compulsory fields!")
        setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
        setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
        return
      }
    axios
      .post(
        `${__API_URL__}/api/patient/patient/evaluation_update/`,
        updatedData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setToastrMessage("Evaluation Updated!")
        setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
        setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
        window.location.reload();
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 400') {
          setToastrMessage("Please set the pass number!")
          setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
          setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
        }
      });
  }
  const UpdateInstance = (e, ins) => {
    const score = document.getElementsByClassName(ins)[0];
    const time = document.getElementsByClassName(ins)[1];
    const classList = document.getElementsByClassName('glab'+ins).length > 0;
    var glab;
    if (classList) {
      glab = document.getElementsByClassName('glab'+ins)[0];
      glab = glab.value;
    } else {
      glab = "-";
    }
    const updateData = {
      score: score.value,
      evaluation_time: time.value,
      glabellarFrownPattern: glab,
      photo_id: ins,
    };
      axios
        .post(`${__API_URL__}/api/patient/patient/evaluation_update/`, updateData, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          alert("Evaluation updated");
        })
        .catch((err) => {
          console.log(err);
        });

    e.preventDefault();
  };
  const getSrc = (src) => {
    document.getElementsByClassName("fixImage")[0].src = src;
    document.getElementsByClassName("imageCompare")[0].style.display = "block";
  };

  useEffect(() => {
    !Loader && populateExistingPhotoScores();
    if (trial_id){
      !Loader && Blocks() ;
    }
  }, [Loader])


  const populateExistingPhotoScores = () => {
    PatientData.photo.map((photo) => {
          setPhotoForms((prev) => {
              const updatedObj = {
                updated_score: pass_number === '1' ? photo.updated_score : pass_number === '2' ? photo.updated_score_v1 : pass_number === '3' ? photo.updated_score_v2 : '-1',
                glabellar_frown_pattern_choices: pass_number === '1' ? photo.glabellar_frown_pattern_choices : pass_number === '2' ? photo.glabellar_frown_pattern_choices_v1 : pass_number === '3' ? photo.glabellar_frown_pattern_choices_V2 : '0',
                evaluation_time: pass_number === '1' ? photo.evaluation_time : pass_number === '2' ? photo.evaluation_time_v1 : pass_number === '3' ? photo.evaluation_time_v2 : 0,
                extra_comments: pass_number === '1' ? photo.extra_comments_v1 : pass_number === '2' ? photo.extra_comments_v2 : pass_number === '3' ? photo.extra_comments_v3 : '',
              }
              return {
                ... prev, 
                [photo.id]: {
                    ...updatedObj, 
                    disabled: updatedObj.updated_score == -1 ? false : updatedObj.updated_score === '-' ? false : updatedObj.evaluation_time === 0 ? false : true
                }
              }
          })

    })

  }

  const SinglePatient = () => {
    axios
    .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
        headers: {
        Authorization: `Token ${token}`,
        },
        
    })
    .then((res) => {
        setInfoData(res.data)
    });
}


  const handleScoreUser = (event) => {
    const selectedValue = event.target.value;
    setSelectedUser(selectedValue);
  };

  const handleRetakePhoto = () => {
      axios
      .get(`${__API_URL__}/api/photos/retake_photo/${photoId}/`, {
          headers: {
          Authorization: `Token ${token}`,
          },
      })
      .then((res) => {
          setToastrMessage("Photo Archived");
          setTypeOfMessage("success");
          setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
          }, 100);
          setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
          }, 3000);
          setTimeout(() => {
          window.location.reload();
          }, 1000);
      }).catch((err) => {
          if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
          setToastrMessage("Something Went Wrong");
          setTypeOfMessage("success");
          setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
          }, 100);
          setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
          }, 3000);
      });
  }
  const [toastrMessage, setToastrMessage] = useState('Evaluation Updated!')
  const [typeOfMessage, setTypeOfMessage] = useState('')
  return (
    <>
      <MessageBox msg={toastrMessage} type={typeOfMessage} />
      <NavBar active="patient" />
      <div className="container-fluid">
      <Breadcrumbs 
            B="Photos" 
            F={infoData?.first_name}
            L={infoData?.last_name}
            E={infoData?.email}
            P={infoData?.phone}
            participant_id={infoData?.participant_id}
            age={infoData?.age}
            birth_date={infoData?.birth_date}
          />
      <PatientMenu active="photos" data={infoData}/>
      <div className="caseSection">
        {Loader ? (
          <div className="loader">
            <img src="https://i.gifer.com/Yb3A.gif" alt="" />
          </div>
        ) : (
          <div className="container-fluid" style={{marginTop:"30px"}}>


            {
              (role === 'CRO Admin' || role === 'Super Admin') && <>
              <div className="row">
               
                <div className="col-md-3">
                  <label>Score of</label>
                  <select className="form-control" onChange={handleScoreUser} value={selectedUser}>

                  <option value=''>Select role</option>
                  {scoreUser?.map((user) => {
                    return <>
                     <option value={user?.id}>{user?.email}</option>

                    </>
                  })

                  }
                  </select>
                </div>
              </div>
              </>
            }



              <div className="row patient_table">
                <p>
                  {!PatientData.is_trial && <>
                    <div className="row">
                      <div className="col-md-3">
                        <strong>Pass Number: {pass_number}</strong>
                      </div>
                    </div>
                  </>}
                <span style={{ "padding-right": "40px" }}>
                  {new Date(PatientData.updated_at + "Z").toDateString()},&nbsp;
                  {new Date(PatientData.updated_at + "Z").toLocaleTimeString()}
                </span>
                  {
                    (!PatientData.is_trial && (role != 'Reviewer' && role !== 'Investigator' && role !== 'Inv_coordinator')) &&
                    <>
                        <span style={{'padding-right':'40px'}}>Repose Score : {PatientData.scores.repose_score}</span>
                        <span style={{'padding-right':'40px'}}>Active Score : {PatientData.scores.active_score}</span>
                    </>
                  }
                </p>
               
                {PatientData.photo.map((data) => (
                      <div className="col-md-3"  key={data.id}>
                        {
                          (role === 'Investigator' || role === 'Inv_coordinator' || role == 'Super Admin') && <>
                            <button style={{ width: "70px", height: "35px", float:"right"}} type="button" className="btn btn-primary btn-sm" onClick={() => getSrc(__API_URL__+ data.image)}>
                              <i>Zoom</i>
                            </button>
                          </>
                        }
                    {
                      data.image ? <>
                      <img 
                          src = { __API_URL__+ data.image}
                          alt=""
                          /> 
                      </> :
                        <img
                        src={Avatar}
                        alt=""
                        />
                      }
                      {(role === 'Investigator' || role === 'Inv_coordinator' || role === 'Reviewer') && (
                          <button style={{marginTop: "1em"}} type="button" className="btn btn-primary" onClick={() => {handleRetakeModal(data.id)}} disabled = {PatientData.is_completed == true}>
                              Re-Take Photo
                          </button>
                      )}
                    <div className="caseBox">
                    <h6>{data.photo_type.name}</h6>
                    <label>Captured At - {new Date(data.captured_at  +'Z').toDateString()},&nbsp;
                      {new Date(data.captured_at+'Z').toLocaleTimeString()}</label>
                    { (role === 'Reviewer' || role === 'CRO Admin' || role == 'Cro_coordinator' || role == 'Data Manager' || role === 'Investigator' || role === 'Inv_coordinator' || role === 'Super Admin' ) && <>
                      {
                        PatientData.is_trial ? <>
                          <div className="row">
                            {
                              PatientData?.score_block?.map((item) => (item?.name?.includes("Target Lesion Erythema Assessment") && <>
                                <div className="col-md-12">
                                  <label>TLE Score</label>
                                  <select className="form-control"
                                          value={respData[data.id].tle_score?.score}
                                          name='tle_score'
                                  onChange={(e) => handleFieldChange(e, data.id)}
                                  >
                                    <option value="">Select</option>
                                    {
                                      item?.option?.map((opt) => {
                                      return <>
                                        <option value={opt.title}>{opt.title}</option>
                                      </>
                                      })
                                    }
                                  </select>
                                </div>
                              </>
                              ))
                            }
                              {
                                PatientData?.score_block?.map((item) => (item?.name?.includes("Target Lesion Severity Assessment") && <>
                                <div className="col-md-12">
                                <label>TLS Score</label>
                                <select className="form-control"
                                        value={respData[data.id].tls_score?.score}
                                        name='tls_score'
                                        onChange={(e) => handleFieldChange(e, data.id)}
                                >
                                  
                                  <option value="">Select</option>
                                  {
                                    item?.option?.map((opt) => {
                                    return <>
                                      <option value={opt.title}>{opt.title}</option>
                                    </>
                                    })
                                  }
                                </select>
                              </div>
                              </>
                                ))
                              }
                              {
                           PatientData?.score_block?.map((item) => (item?.name?.includes("Target Lesion Improvement Assessment") && <>
                            <div className="col-md-12">
                              <label>TLI Score</label>
                              <select className="form-control"
                                      value={respData[data.id].tli_score?.score}
                                      name='tli_score'
                                      onChange={(e) => handleFieldChange(e, data.id)}
                              >
                                <option value="">Select</option>
                                {
                                  item?.option?.map((opt) => {
                                  return <>
                                    <option value={opt.title}>{opt.title}</option>
                                  </>
                                  })
                                }
                              </select>
                            </div>
                                </>
                           ))
                              }
                            {
                              (blocksData?.includes("Target Lesion Erythema Assessment") || blocksData?.includes("Target Lesion Severity Assessment") || blocksData?.includes("Target Lesion Improvement Assessment")) && <>
                                  <div className="col-md-12">
                                    {(role !== 'CRO Admin' && !photoForms[data.id]?.disabled)  &&  (role !== 'Cro_coordinator' && role !== 'Data Manager' && !photoForms[data.id]?.disabled) && <>
                            <button type="button" className="btn btn-primary" onClick={() => UpdateScore(data.id)}>
                                          Save
                                        </button>
                                    </>}
                                  </div>
                              </>
                            }
                          </div>
                         </> : <>
                            <form >
                            <label>Updated Score</label>
                            <select className={"form-control " + data.id}  disabled={(role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || photoForms[data.id]?.disabled) && 'disabled'}
                              name='updated_score' value={photoForms[data.id]?.updated_score} onChange={(e) => handleFormChange(e, data.id)}>
                              <option value='-1'>-</option>
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                            </select>         
                            {data.photo_type.name != 'Repose full frontal' && (
                              <>
                            <label>Glabellar Frown Pattern (Optional)</label>
                            <select
                              className={"form-control " + "glab" + data.id}
                              disabled={(role === 'CRO Admin'  || role === 'Cro_coordinator' || role === 'Data Manager' || photoForms[data.id]?.disabled) && 'disabled'}
                              name='glabellar_frown_pattern_choices' value={photoForms[data.id]?.glabellar_frown_pattern_choices} onChange={(e) => handleFormChange(e, data.id)}
                            >
                              <option value='0'>-</option>  
                              <option value='1'>U</option>
                              <option value='2'>V</option>
                              <option value='3'>Omega</option>
                              <option value="4">
                                Inverted Omega
                              </option>
                              <option value="5">
                                Converging Arrows
                              </option>
                            </select>
                            </>
                            )}
                            <label>Evaluation Time</label>
                            <div className="d-flex">
                            <input
                              type="number"
                              min={0}
                              disabled={(role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || photoForms[data.id]?.disabled) && 'disabled'}
                              className={"form-control " + data.id}
                              name='evaluation_time' value={photoForms[data.id]?.evaluation_time} onChange={(e) => handleFormChange(e, data.id)}
                            />
                            </div>
                            {/* <label>Comments (Optional)</label>
                              <div className="d-flex">
                                <textarea 
                                  className={"form-control " + data.id}
                                  disabled={(role === 'CRO Admin' || photoForms[data.id]?.disabled) && 'disabled'}
                                  name='extra_comments' value={photoForms[data.id]?.extra_comments} onChange={(e) => handleFormChange(e, data.id)}
                                />
                              </div> */}
                            {(role !== 'CRO Admin' && !photoForms[data.id]?.disabled) && <>
                                <button type="button" className="btn btn-primary" onClick={() => handleSubmission(data.id)}>
                                  Save
                                </button>
                            </>}
                            </form>
                         </>
                      }
                    </>
                    }
                  </div>
                  </div>
                ))}
              </div>
          </div>
        )}
         <ImageCompareSession Images={PatientData?.photo} />
        </div>
      </div>
      {
            modalRetake && <>
               < RetakePhotoPopUp
                  setModalRetake={setModalRetake}
                  handleRetakePhoto={handleRetakePhoto}
               ></RetakePhotoPopUp>
            </>
        }
    </>
  );
};

export default PatientCase;
