import React, { useEffect, useState } from "react";

const MaximumPhotoTypes = ({
  trialDetails,
  setTrialDetails,
  handleBasicInfoUpdate,
  maximumPhototypes,
  setMaximumPhototypes,
}) => {
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const [isValueUpdated, setIsValueUpdated] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTrialDetails((prevDetails) => ({
      ...prevDetails,
      maximum_photo_types: value,
    }));
    setMaximumPhototypes(true);
    setIsValueUpdated(true); 
  };

  const handleSave = () => {
    handleBasicInfoUpdate();
    setIsValueUpdated(false); 
  };

  return (
    <div className="row mx-5 mb-3">
      <div className="col-md-12">
        <div className="row">
          <div className="w-100"></div>
          <div className="col">
            <div className="row">
              <div className="tableHeader">
                <div className="row d-flex align-items-end">
                  <div className="col-md-6"></div>
                  <div className="col-md-6 text-end d-flex justify-content-end">
                    {role !== "Investigator" &&
                      role !== "Inv_coordinator" &&
                      role !== "Reviewer" && (
                        <button
                          type="button"
                          className="btn btn-primary mt-2"
                          onClick={handleSave}
                          disabled={!maximumPhototypes || !isValueUpdated} 
                        >
                          Save
                        </button>
                      )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="maximum_photo_types" className="form-label">
                    Maximum Photo Types
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="maximum_photo_types"
                    name="photo_types"
                    value={trialDetails.maximum_photo_types}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaximumPhotoTypes;
