import React, { useState, useEffect } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Date from "react-datetime";
import moment from 'moment';



const Demographics = (props) => {

  const [consentFormOption, setConsentFormOption] = React.useState("list")
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  let disable = false
  const [selectedBlock, setSelectedBlock] = useState({})
  const user = useUser();
  const GENDER_CHOICES = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ]
  const RACE_CHOICES = [

    { value: 'American Indian or Alaska Native', label: 'American Indian or Alaska Native' },
    { value: 'Black or African American', label: 'Black or African American' },
    { value: 'Native Hawaiian or Pacific Islander', label: 'Native Hawaiian or Pacific Islander' },
    { value: 'Asian', label: 'Asian' },
    { value: 'White', label: 'White' },
    { value: 'Other', label: 'Other' }
  ]
  const ETHNICITY_CHOICES = [
    { value: 'Hispanic or Latino', label: 'Hispanic or Latino' },
    { value: 'Not Hispanic or Latino', label: 'Not Hispanic or Latino' },
    { value: 'Unknown', label: 'Unknown' },
    { value: 'Not reported', label: 'Not reported' }
  ]
  const handleGender = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      gender: e.target.value,
    });
  props.setHasChanged(true)
  };
  const handleBody_Mass_Index = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      bmi: e.target.value,
    });
  props.setHasChanged(true)
  };
  const handleHeight = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      height: e.target.value,
    });
  props.setHasChanged(true)
  };
  const handleWeight = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      weight: e.target.value,
    });
    props.setHasChanged(true)
  };
  const handleOther_Race = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      other_race: e.target.value,
    });
    props.setHasChanged(true)
  };
  const handleRace = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      race: e.target.value,
    });
    props.setHasChanged(true)
  };
  const handleethnicity = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      ethnicity: e.target.value,
    });
    props.setHasChanged(true)
  };

  const GetSelectedBlockData = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_demographic/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }

const handleDate = (e) => {
  setSelectedBlock({
    ...selectedBlock,
    date_of_birth: e.target.value,
  });
props.setHasChanged(true)
};

  const AllVisitsData = () => {
    const createData = {
      'patient_id': props?.patient_id,
      'visit_number':props?.visit_number,
      ...selectedBlock,
      'gender': selectedBlock?.gender === undefined ? "Male" : selectedBlock?.gender,
      "bmi":selectedBlock.bmi===null ? 0 :selectedBlock.bmi,
      'date_of_birth': moment(selectedBlock?.date_of_birth)?.format('YYYY-MM-DD'),         
      'sessionId': props.selectedSessionId,
    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_demographic/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        setSelectedBlock(res.data);
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
        document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
        document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
    }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
        user.actions.logout()
        }
        setMessage(err.response.data.message);
        setTypeOfMessage("success");
        setTimeout(() => {
        document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
        document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
    });
  };

  React.useEffect(() => {
    if((props?.patient_id && props?.photo_session)){
      GetSelectedBlockData();
    }
  }, [])
                          return <>
                          {
                           (role ==='CRO Admin') || (role==='Cro_coordinator') || (role === 'Data Manager') || (props.patient_id === undefined)? 
                              disable=true : ""
                           
                          }
                          <fieldset disabled={disable}>
                                                
                            {
                              (props.photo_session || props.patient_id === undefined ) ?
                            <div className='row'>
                              {
                                (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                  <div className="col-md-12 text-end">
                                    <button onClick={() => AllVisitsData()} className='btn btn-primary'>Update</button>
                                  </div>
                                )
                              }
                              <div className="col-md-6 calendarIcon">
                                  <label>DOB:</label>
                                                <input
                                                        onChange={handleDate}
                                                        class='form-control'
                                                        type="date"
                                                        value={selectedBlock?.date_of_birth}
                                                        timeFormat={false}
                                                        inputProps={{placeholder:'mm/dd/yy 00:00 AM', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined) }}
                                                                    />
                              </div>
                              <div className='col-md-6'>
                                <label> Age (yrs): (autofill) </label>
                                <input 
                                        style={{ marginLeft: "0em" }} 
                                        class="form-control" 
                                        type="number" 
                                        name='Age' 
                                        checked={consentFormOption} 
                                        id="flexSwitchCheckDefault"  
                                        disabled={true} 
                                        value={selectedBlock.age}
                                        >
                                </input>
                              </div>
                              <div className='col-md-6'>
                                <label> Gender: </label>
                                <select className='form-control'
                                  type="text"
                                  name="gender"
                                  value={selectedBlock?.gender}
                                  onChange={handleGender}
                                  >
                                            <option value="" disabled={true} selected>Select</option>
                                  {
                                    GENDER_CHOICES.map((gender) => {
                                      return <>
                                        <option value={gender.value} key={gender}>{gender.label} </option>
                                      </>
                                    })
                                  }
                                </select>
                              </div>
                              <div className='col-md-6'>
                                <label> Body Mass Index(BMI)  kg/m²: </label>
                                <input 
                                      style={{ marginLeft: "0em" }} 
                                      class="form-control" 
                                      unit="kg/m²" 
                                      type="number" 
                                      name='bmi' 
                                      checked={consentFormOption} 
                                      id="flexSwitchCheckDefault" 
                                      value={selectedBlock.bmi} 
                                      onChange={(e) => handleBody_Mass_Index(e)}
                                      ></input>
                              </div>
                              {/* <div className='col-md-6'>
                                <label> Height(cm): </label>
                                <input style={{ marginLeft: "0em" }} class="form-control" type="number" name='height' checked={consentFormOption} id="flexSwitchCheckDefault" value={selectedBlock.height} onChange={(e) => handleHeight(e)}></input>
                              </div> */}
                              {/* <div className='col-md-6'>
                                <label> Weight(kg): </label>
                                <input style={{ marginLeft: "0em" }} class="form-control" type="number" name='weight' checked={consentFormOption} id="flexSwitchCheckDefault" value={selectedBlock.weight} onChange={(e) => handleWeight(e)}></input>
                              </div> */}
                              <div className='col-md-6'>
                                <label> RACE CHOICES (Check predominant race if of mixed race): </label>
                                <select className='form-control'
                                  type="text"
                                  name='race'
                                  value={selectedBlock.race}
                                  onChange={handleRace}
                                >
                                            <option value="" selected>Select</option>
                                  {
                                    RACE_CHOICES.map((race) => {
                                      return <>
                                        <option value={race.value} key={race}>{race.label}</option>
                                      </>
                                    })
                                  }
                                </select>
                              </div>
                              <div className='col-md-6'>
                                <label> ETHNICITY CHOICES: </label>
                                <select className='form-control'
                                        name='ethnicity'
                                        value={selectedBlock.ethnicity}
                                        onChange={handleethnicity}
                                  >
                                  <option value="" selected >Select</option>
                                  {
                                    ETHNICITY_CHOICES.map((ethnicity) => {
                                      return <>
                                        <option value={ethnicity.value} key={ethnicity}>{ethnicity.label}</option>
                                      </>
                                    })
                                  }
                                </select>
                                {selectedBlock.race ==='Other' ? 
                                <div className='col-md-12'>
                                  <label> Other race: </label>
                                  <input 
                                      
                                      style={{ marginLeft: "0em" }} 
                                      class="form-control" 
                                      type="text" 
                                      name='Other race' 
                                      checked={consentFormOption} 
                                      id="flexSwitchCheckDefault" 
                                      value={selectedBlock.other_race} 
                                      onChange={(e) => handleOther_Race(e)}
                                      />
                                </div> : ''
                                }
                              </div>
                            </div>
                            :'Visit is not started' 
                            }
                          </fieldset>
                          </>
}
export default Demographics;