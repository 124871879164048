import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import "react-datetime/css/react-datetime.css";
import { Pane, FileUploader, FileCard } from "evergreen-ui";
import Datetime from "react-datetime";
import AddNewModal from "../../components/Modals/AddNewTrial";
import { toast, ToastContainer } from "react-toastify";

const UploadFilesModal = ({ uploadType, showModal, setShowModal, files, setFiles, captureDateTime, setCaptureDateTime, acceptedMimeTypes, typeList, setTypeList, caseId, getExternalMedia, trialId }) => {
    const [fileRejections, setFileRejections] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [modalTypeShow, setModalTypeShow] = useState(false)
    const [title, setTitle] = useState("")
    const token = localStorage.getItem("token");
    const [selectedTypeId, setSelectedTypeId] = useState();
    const [isLoading, setIsLoading] = useState(false); // Added loading state

    const handleRemove = (fileToRemove) => {
        setFiles(files.filter((file) => file !== fileToRemove));
        setFileRejections(
          fileRejections.filter((rejection) => rejection.file !== fileToRemove)
        );
    };

    const addExternalTypeApi = () => {
        setModalTypeShow(false)
        const Createdata = {
            'title': title,
            'type': uploadType,
            'trial': trialId
        };
        axios
            .post(`${__API_URL__}/api/photos/create_external_media_type/`, Createdata, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                toast.success("New Type Added!");
                setTypeList(res.data)
                setTitle("")
            }).catch((err) => {
                toast.error("Something Went Wrong!");
            });
    }

    useEffect(() => {
      if (fileRejections.length > 0) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    }, [fileRejections])

    useEffect(() => {
      if (files.length === 0) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    }, [files])

    const saveExternalMedia = () => {
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }
        if (setSelectedTypeId) {
            formData.append("type_id", selectedTypeId);
        }
        formData.append("uploadType", uploadType);
        formData.append("photo_session_id", caseId);
        if (captureDateTime) {
            formData.append("capture_date_time", captureDateTime);
        }

        const requestOptions = {
            method: "POST",
            body: formData,
            headers: {
                Authorization: `Token ${token}`,
            },
        };
        setIsLoading(true); // Set loading to true
        fetch(`${__API_URL__}/api/photos/save_external_media/`, requestOptions)
            .then((response) => {
                toast.success("Media Uploaded Successfully");
                getExternalMedia();
                setIsLoading(false); // Set loading to false
                setShowModal((prev) => !prev)
            })
            .catch((err) => {
                setIsLoading(false); // Set loading to false on error
                console.log("ERROR IN UPLOADING MEDIA => ", err);
                toast.error("Error on uploading photo");
            });
    };


    return (
        <div>
            <Modal
                show={showModal}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                backdrop="static"
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {
                      uploadType === "photos" ? "Add External Image" : "Add External Video"
                    }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <div>
                        <label>Media</label>
                        <div className="col-md-5 mx-auto form-group my-4">
                            <Pane>
                                <FileUploader
                                    label="Upload Files"
                                    description="You can upload multiple files. Each file can be up to 500 MB."
                                    maxSizeInBytes={500 * 1024 ** 2} // 500 MB converted to bytes
                                    acceptedMimeTypes={acceptedMimeTypes}
                                    onChange={(newFiles) =>
                                        setFiles((prevFiles) => [...prevFiles, ...newFiles])
                                    }
                                    onRejected={(fileRejections) =>
                                        setFileRejections([...fileRejections])
                                    }
                                    renderFile={(file) => {
                                        const { name, size, type } = file;
                                        const fileRejection = fileRejections.find(
                                            (fileRejection) => fileRejection.file === file
                                        );
                                        const { message } = fileRejection || {};
                                        return (
                                            <FileCard
                                                key={name}
                                                isInvalid={fileRejection != null}
                                                name={name}
                                                onRemove={() => handleRemove(file)}
                                                sizeInBytes={size}
                                                type={type}
                                                validationMessage={message}
                                            />
                                        );
                                    }}
                                    values={files}
                                />
                            </Pane>
                        </div>

                        <label>Type</label>
                        <select
                            className="form-control"
                            onChange={(e) => setSelectedTypeId(e.target.value)}
                            name="file_type"
                            value={selectedTypeId}
                        >
                            <option value="">Select</option>
                            {typeList.map((type) => {
                                return (
                                  <>
                                    <option value={type.id} key={type.id}>
                                        {type.title}
                                    </option>
                                  </>
                                );
                            })}
                        </select>
                        <button
                            class="btn btn-primary btn-sm"
                            onClick={() => setModalTypeShow(true)}
                        >
                            Add New Type
                        </button>
                        {modalTypeShow && (
                            <AddNewModal
                                show={modalTypeShow}
                                onHide={() => setModalTypeShow(false)}
                                formSubmissionApi={addExternalTypeApi}
                                modalShow={setModalTypeShow}
                                title={title}
                                setTitle={setTitle}
                            />
                        )}

                        <div className="calendarIcon">
                            <label>Capture Date & Time</label>
                            <i className="fa fa-calendar-o" aria-hidden="true"></i>
                            <Datetime
                                onChange={setCaptureDateTime}
                                value={captureDateTime}
                                inputProps={{ placeholder: "dd/mm/yy 00:00 AM" }}
                            />
                        </div>
                        <div className="col-md-12" style={{ textAlign: "end" }}>
                            <button
                                type="submit"
                                className={`btn btn-primary btn-sm m-4${isLoading ? " btn-loading" : ""}`} // Add loading class when isLoading is true
                                onClick={saveExternalMedia}
                                disabled={isDisabled || isLoading} // Disable button when loading
                            >
                                {isLoading ? "Uploading..." : "Upload"}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UploadFilesModal;
