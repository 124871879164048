import { editorLocalization } from "../editorLocalization";

export var jaStrings = {
  ed: {
    addNewPage: "新規ページを追加する",
    bold: "太字",
    chooseElement: "要素を選択…",
    copy: "コピー",
    copyTooltip: "選択範囲をクリップボードにコピーする",
    correctJSON: "JSONを修正してください。",
    cut: "カット",
    cutTooltip: "選択範囲をクリップボードにカットする",
    defaultLocale: "デフォルト（{0}）",
    deletePage: "ページを削除する",
    designer: "アンケートの設計者",
    edit: "編集",
    editPage: "ページを編集する",
    embedSurvey: "アンケートを埋め込む",
    fpAddQuestion: "質問を追加…",
    generateReadableJSON: "読み取り可能なJSONを生成する",
    generateValidJSON: "有効なJSONを生成する",
    hidePanel: "パネルを非表示にする",
    italic: "イタリック",
    jsonEditor: "JSONエディタ",
    lg: {
      actionInvalid: "アクションの問題を解決してください。",
      actions: "アクション",
      actionsEditorTitle: "アクションを定義する",
      addNewAction: "新しいアクションを追加する",
      addNewItem: "新しいルールを追加",
      completedHtmlOnConditionDescription: "論理式がtrue（真）を返した場合、「サンキューページ」のデフォルトテキストは指定されたものに変更されます。",
      completedHtmlOnConditionName: "カスタムの「サンキューページ」テキスト",
      completedHtmlOnConditionText: "「サンキューページ」のカスタムテキストを表示します。",
      conditions: "条件",
      deleteAction: "アクションを削除する",
      expressionEditorTitle: "条件を定義する",
      expressionInvalid: "論理式が空または無効です。修正してください。",
      itemExpressionText: "式：「{0}」がtrue（真）を返した場合：",
      noActionError: "少なくとも1つのアクションを追加してください。",
      page_visibilityDescription: "論理式がtrue（真）を返した場合、ページを表示にします。それ以外の場合は、非表示にしておきます。",
      page_visibilityName: "ページの可視性",
      page_visibilityText: "ページ{0}を表示にする",
      panel_enableDescription: "論理式がtrue（真）を返した場合、パネルとその中のすべての要素を有効にします。それ以外の場合は、無効にしておきます。",
      panel_enableName: "パネルの有効化/無効化",
      panel_enableText: "パネル{0}を有効にする",
      panel_visibilityDescription: "論理式がtrue（真）を返した場合、パネルを表示にします。それ以外の場合は、非表示にしておきます。",
      panel_visibilityName: "パネルの可視性",
      panel_visibilityText: "パネル{0}を表示にする",
      question_enableDescription: "論理式がtrue（真）を返した場合、質問を有効にします。それ以外の場合は、無効にしておきます。",
      question_enableName: "質問の有効化/無効化",
      question_enableText: "質問{0}を有効にする",
      question_requireDescription: "論理式がtrue（真）を返した場合、質問が必須になります。",
      question_requireName: "質問オプションが必要",
      question_requireText: "質問{0}を必須にする",
      question_visibilityDescription: "論理式がtrue（真）を返した場合、質問を表示にします。それ以外の場合は、非表示にしておきます。",
      question_visibilityName: "質問の可視性",
      question_visibilityText: "質問{0}を表示にする",
      selectedActionCaption: "追加するアクションを選択…",
      trigger_completeDescription: "論理式がtrue（真）を返した場合、アンケートが完了し、エンドユーザーに「サンキューページ」が表示されます。",
      trigger_completeName: "アンケートを完了する",
      trigger_completeText: "アンケートが完了する",
      trigger_copyvalueDescription: "論理式で使用される質問の値が変更され、論理式がtrue（真）を返した場合、選択された1つの質問の値が別の選択された質問にコピーされます。",
      trigger_copyvalueName: "質問の値をコピーする",
      trigger_copyvalueText: "質問{1}から質問：{0}値にコピーする",
      trigger_runExpressionDescription: "論理式がtrue（真）を返した場合、カスタム式が実行されます。オプションで、この式の結果を選択した質問に設定することができます。",
      trigger_runExpressionName: "カスタム式を実行する",
      trigger_runExpressionText1: "式：「{0}」を実行する",
      trigger_runExpressionText2: " そして、その結果を質問：{0}に設定する",
      trigger_setvalueDescription: "論理式で使用される質問の値が変更され、論理式がtrue（真）を返した場合、その値は選択された質問に設定されます。",
      trigger_setvalueName: "質問の値を設定する",
      trigger_setvalueText: "質問：{0}値{1}に設定する",
      trigger_skipDescription: "論理式がtrue（真）を返した場合、アンケートは選択された質問にスキップ/フォーカスされます。",
      trigger_skipName: "質問にスキップする",
      trigger_skipText: "アンケートは質問{0}にスキップする"
    },
    logic: "アンケートのロジック",
    modified: "修正済み",
    moveLeft: "左にスクロールする",
    moveRight: "右にスクロールする",
    navigateToMsg: "次の場所に移動する必要がありました：",
    newPageName: "ページ",
    newPanelName: "パネル",
    newQuestionName: "質問",
    newTextItemName: "テキスト",
    options: "オプション",
    paste: "貼り付ける",
    pasteTooltip: "クリップボードから貼り付ける",
    "property-grid": "プロパティ",
    propertyEditorError: "エラー：",
    redo: "やり直す",
    redoTooltip: "変更をやり直す",
    resultsDisplayValue: "表示値",
    resultsName: "質問名",
    resultsTitle: "質問のタイトル",
    resultsValue: "回答値",
    saved: "保存済み",
    saveError: "エラー！ エディタのコンテンツは保存されません。",
    saveSurvey: "アンケートを保存する",
    saveSurveyTooltip: "アンケートを保存する",
    saving: "保存",
    selectPage: "ページを選択...",
    settings: "アンケートの設定",
    settingsTooltip: "アンケートの設定を開く",
    showPanel: "パネルを表示する",
    survey: "アンケート",
    surveyResults: "アンケート結果： ",
    surveyResultsJson: "JSONとして",
    surveyResultsTable: "表として",
    testSurvey: "アンケートをテストする",
    testSurveyAgain: "アンケートを再度テストする",
    testSurveyWidth: "アンケートの幅： ",
    toolbox: "ツールボックス",
    toolboxGeneralCategory: "一般",
    translation: "翻訳",
    translationAddLanguage: "翻訳する言語を選択する",
    translationExportToSCVButton: "CSVにエクスポートする",
    translationImportFromSCVButton: "CSVからインポートする",
    translationMergeLocaleWithDefault: "{0}をデフォルトのロケールにマージする",
    translationNoStrings: "翻訳する文字列がありません。フィルターを変更してください。",
    translationShowAllPages: "すべてのページを表示する",
    translationShowAllStrings: "すべての文字列を表示する",
    underline: "下線",
    undo: "元に戻す",
    undoTooltip: "最後の変更を元に戻す"
  },
  ew: {
    angular: "Angularバージョンを使用",
    bootstrap: "Bootstrapフレームワーク向け",
    darkblue: "ダークブルーテーマ",
    darkrose: "ダークローズテーマ",
    default: "デフォルトテーマ",
    jquery: "jQueryバージョンを使用",
    knockout: "Knockoutバージョンを使用",
    loadFromServer: "サーバーからアンケートのJSONファイルを読み込む",
    modern: "モダンテーマ",
    orange: "オレンジテーマ",
    react: "Reactバージョンを使用",
    showInWindow: "1つのウィンドウにアンケートを表示",
    showOnPage: "1つのページにアンケートを表示",
    stone: "ストーンテーマ",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript",
    titleScript: "スクリプトとスタイル",
    vue: "Vueバージョンを使用",
    winter: "ウィンターテーマ",
    winterstone: "ウィンターストーンテーマ"
  },
  op: {
    allof: "次のすべて",
    and: "および",
    anyof: "次のいずれか",
    contains: "含む",
    empty: "は空白です",
    equal: "等しい",
    greater: "より大きい",
    greaterorequal: "等しいかより大きい",
    less: "より小さい",
    lessorequal: "等しいかより小さい",
    notcontains: "含まない",
    notempty: "は空白ではありません",
    notequal: "等しくない",
    or: "または"
  },
  p: {
    title: {
      title: "「名前」と同じ場合は空白のままにしてください"
    }
  },
  pe: {
    aceEditorHelp: "表現の補完のヒントを取得するには「ctrl+space」を押してください",
    aceEditorPanelTitle: "現在のパネル",
    aceEditorRowTitle: "現在の行",
    add: "追加",
    addCondition: "条件を追加する",
    addItem: "クリックしてアイテムを追加…",
    addLogo: "ロゴを追加...",
    addNew: "新規追加:",
    addNone: "なし",
    addOther: "その他",
    addRowLocation: "列ボタンのロケーションを追加",
    addRowText: "行ボタンのテキストを追加",
    addSelectAll: "すべて選択",
    allowAddPanel: "パネルの追加を許可",
    allowDigits: "数字を許可",
    allowEmptyResponse: "空の応答を許可",
    allowRemovePanel: "パネルの削除を許可",
    and: "および",
    apply: "適用",
    assistantTitle: "利用できる質問:",
    back: "保存せずに戻る",
    backTooltip: "保存せずに戻る",
    buildExpression: "構築する",
    cancel: "キャンセル",
    cellsDefaultRow: "デフォルトのセルテキスト",
    cellsEmptyRowsColumns: "少なくとも1つの列または行を含む必要があります",
    cellType: "セルタイプ",
    changeLogo: "ロゴを変更...",
    choicesMax: "自動生成アイテムの最大値",
    choicesMin: "自動生成アイテムの最小値",
    choicesOrder: "選択順",
    choicesStep: "自動生成アイテムの差",
    clearInvisibleValues: "非表示の値をクリアする",
    colCount: "行の数",
    cols: "行の数",
    columnEdit: "行編集: {0}",
    columnLayout: "行のレイアウト",
    completeText: "「完了」ボタンのテキスト",
    conditionActionEmpty: "アクションを選択してください",
    conditionSelectPage: "ページを選択...",
    conditionSelectPanel: "パネルを選択...",
    conditionSelectQuestion: "質問を選択...",
    conditionValueQuestionTitle: "値を入力/選択してください",
    cookieName: "Cookie名（ローカルで2回アンケートを実行しないようにするため）",
    createtrigger: "トリガーを作成してください。",
    defaultValue: "デフォルト値",
    delete: "削除",
    descriptionPlaceholder: "詳細を入力",
    edit: "編集",
    editChoices: "選択肢を編集",
    editExpression: "編集",
    editProperty: "プロパティ'{0}'を編集",
    editText: "「編集」ボタンのテキスト",
    enterNewValue: "値を入力してください。",
    expandCollapseTitle: "タイトルを展開/折りたたむ",
    expression: "表現",
    expressionHelp: "波括弧を使用して質問の値にアクセスできます: {質問1} + {質問2}, ({値段}*{数量}) * (100 - {割引})。iif(), today(), age(), min(), max(), count(), avg() およびその他の関数を使用できます。",
    expressionIsEmpty: "表現が空白です",
    fastEntry: "高速入力",
    firstPageIsStarted: "アンケートの最初のページは、開始ページです。",
    focusFirstQuestionAutomatic: "ページを変える際に最初の質問に焦点を合わせる",
    formEntry: "フォーム入力",
    goNextPageAutomatic: "すべての質問に回答すると、自動的に次のページに移動します",
    showNoneItem: "ノーアイテム",
    showOtherItem: "他のアイテム",
    hasSelectAll: "アイテムを全て選択",
    html: "HTML",
    imageHeight: "イメージの高さ",
    imageLink: "画像リンク",
    imageWidth: "イメージの幅",
    inputType: "入力タイプ",
    isAllRowRequired: "全ての列で回答必須",
    isRequired: "必須",
    isSinglePage: "1ページ上にすべてのエレメントを表示",
    itemEdit: "アイテム編集: {0}",
    items: "[ アイテム: {0} ]",
    itemSelectorEmpty: "エレメントを選択してください",
    itemView: "アイテム表示: {0}",
    keyboardAdornerTip: "アイテムを編集するには「enter」キーを押してください、アイテムを削除するには「delete」キーを押してください、アイテムを移動するには「alt」+上矢印キーまたは「alt」+下矢印キーを押してください",
    keyboardMoveItemsTip: "アイテムを移動するには「alt」+上矢印キーまたは「alt」+下矢印キーを押してください",
    landscapeOrientation: "横向き",
    listIsEmpty: "新しいアイテムを追加",
    locale: "デフォルトの言語",
    logoPosition: "ロゴの位置",
    logoPositions: {
      bottom: "下に",
      left: "左",
      none: "ロゴを削除",
      right: "右",
      top: "上に"
    },
    maxCount: "最大数",
    maxLength: "最大長",
    maxRateDescription: "最大レートの説明",
    maxSize: "ファイルの最大サイズ（byte）",
    maxTimeToFinish: "アンケート終了までの最長時間",
    maxTimeToFinishPage: "アンケートの1ページを終了するまでの最長時間",
    maxValue: "最大値",
    minCount: "最小数",
    minLength: "最小長",
    minRateDescription: "最小レートの詳細",
    minValue: "最小値",
    mode: "モード（編集/読み取り専用）",
    move: "移動",
    name: "名前",
    noFile: "ファイルが選択されていません",
    noneText: "ノーアイテムのテキスト",
    noquestions: "アンケートに質問はありません。",
    ok: "OK",
    optionsCaption: "オプションのキャプション",
    or: "または",
    otherText: "他のアイテムのテキスト",
    pageDescriptionPlaceholder: "ページの詳細を入力",
    pageNextText: "「次のページへ」ボタンのテキスト",
    pagePrevText: "「前のページへ」ボタンのテキスト",
    pageTitlePlaceholder: "ページタイトルを入力",
    panelAddText: "パネルテキストを追加しています",
    panelRemoveText: "パネルテキストを削除しています",
    path: "パス",
    placeholder: "プレースホルダの入力",
    previewText: "「プレビュー」ボタンのテキスト",
    propertyIsEmpty: "値を入力してください",
    propertyIsNoUnique: "固有値を入力してください",
    propertyNameIsNotUnique: "固有名を入力してください",
    qEditorTitle: "編集： {0}",
    questionErrorLocation: "質問エラーの場所",
    questionsOrder: "ページ上の要素の順序",
    questionStartIndex: "質問開始インデックス（1、2または「A」、「a」）",
    questionTitleLocation: "質問のタイトルの場所",
    questionTitleTemplate: "質問タイトルのテンプレート、デフォルトは「{no}. {require} {title}」です",
    rateMax: "最大レート",
    rateMin: "最小レート",
    rateStep: "レートステップ",
    refresh: "リフレッシュ",
    regex: "正規表現",
    remove: "削除する",
    removeAll: "すべて削除",
    removeRowText: "行ボタンのテキストを削除",
    renderMode: "レンダーモード",
    requiredErrorText: "必要なエラーテキスト",
    requiredText: "質問には記号が必要",
    reset: "リセット",
    rowCount: "列数",
    rowid: "行ID",
    rows: "列数",
    save: "保存",
    saveAndBack: "保存して戻る",
    saveAndBackTooltip: "保存して戻る",
    saveTooltip: "保存",
    selectAllText: "アイテムを全て選択のテキスト",
    sendResultOnPageNext: "次のページにアンケート結果を送信する",
    showChoices: "選択肢を表示",
    showCompletedPage: "完了したページを最後に表示する（completedHtml）",
    showMore: "詳細は文書をご確認ください",
    showNavigationButtons: "ナビゲーションボタンを表示する（デフォルトのナビゲーション）",
    showOptionsCaption: "オプションのキャプションを表示する",
    showPageNumbers: "ページ番号を表示する",
    showPageTitles: "ページタイトルを表示する",
    showPrevButton: "「前へ」ボタンを表示する（ユーザーは前のページに戻ることができます）",
    showPreview: "プレビューエリア表示",
    showProgressBar: "プログレスバーを表示する",
    showQuestionNumbers: "質問番号を表示する",
    showTimerPanel: "タイマーパネルを表示する",
    showTimerPanelMode: "タイマーパネルモードを表示",
    showTitle: "タイトルの表示/非表示",
    simulator: "デバイスを選択する",
    startSurveyText: "「開始」ボタンのテキスト",
    startWithNewLine: "ニューラインで開始",
    storeDataAsText: "ファイルのコンテンツをテキストとしてJSONの結果に保存",
    storeOthersAsComment: "「その他」の値を別のフィールドに保存する",
    surveyDescriptionPlaceholder: "調査の詳細を入力",
    surveyEditorTitle: "アンケート設定を編集する",
    surveyTitlePlaceholder: "調査タイトルを入力",
    tabs: {
      calculatedValues: "算出された値",
      cells: "セル",
      choices: "選択",
      choicesByUrl: "ウェブの選択",
      columns: "列",
      completedHtml: "完了したHTML",
      completedHtmlOnCondition: "完成した条件付きHTML",
      data: "データ",
      enableIf: "以下の場合、有効にする：",
      fileOptions: "オプション",
      general: "一般",
      html: "HTMLエディター",
      items: "アイテム",
      layout: "レイアウト",
      loadingHtml: "HTMLを読み込んでいます",
      logic: "ロジック",
      logo: "アンケートタイトルのロゴ",
      matrixChoices: "デフォルトの選択",
      multipleTextItems: "テキスト入力",
      navigation: "ナビゲーション",
      numbering: "番号付け",
      others: "その他",
      pages: "ページ",
      question: "質問",
      rateValues: "レート値",
      requiredIf: "以下の場合、必須：",
      rows: "行",
      showOnCompleted: "完成したものに表示",
      slider: "スライダー",
      templateTitle: "テンプレートタイトル",
      timer: "タイマー/クイズ",
      totals: "合計",
      triggers: "トリガー",
      validation: "検証",
      validators: "バリデータ",
      visibleIf: "以下の場合、見える："
    },
    testService: "サービスをテスト",
    text: "テキスト",
    title: "タイトル",
    titleKeyboardAdornerTip: "編集するには「enter」キーを押してください",
    titleName: "タイトル名",
    titlePlaceholder: "タイトルを入力",
    totalCurrency: "通貨の合計",
    totalDisplayStyle: "表示スタイルの合計",
    totalExpression: "表現の合計",
    totalFormat: "形式の合計",
    totalText: "テキストの合計",
    totalType: "タイプの合計",
    triggerCompleteText: "うまくいけばアンケートが完了します。",
    triggerFromName: "次から値をコピー: ",
    triggerGotoName: "質問に移動:",
    triggerIsVariable: "アンケートの結果に変数を入れないでください。",
    triggerMakePagesVisible: "ページを可視化:",
    triggerMakeQuestionsVisible: "エレメントを可視化:",
    triggerNotSet: "トリガーが設定されていません",
    triggerOn: "オン ",
    triggerRunExpression: "この表現を実行:",
    triggerRunExpressionEmpty: "有効な表現を入力してください",
    triggerRunIf: "次の場合、実行",
    triggerSetToName: "次の値を変更: ",
    triggerSetValue: "宛先: ",
    url: "URL",
    value: "値",
    valueName: "値名",
    view: "表示",
    visible: "表示"
  },
  pv: {
    all: "すべて",
    asc: "昇順",
    both: "両方",
    bottom: "下",
    buttons: "ボタン",
    collapsed: "折り畳んだ",
    color: "色",
    correctQuestions: "正しい質問",
    currency: "通貨",
    date: "日付",
    datetime: "日時",
    "datetime-local": "datetime-local",
    decimal: "小数",
    default: "デフォルト",
    desc: "降順",
    display: "表示",
    edit: "編集",
    email: "メール",
    expanded: "展開した",
    false: "偽",
    firstExpanded: "firstExpanded",
    hidden: "非表示",
    hide: "非表示にする",
    horizontal: "水平",
    indeterminate: "不確定",
    inherit: "継承する",
    initial: "最初の",
    left: "左",
    list: "リスト",
    month: "月",
    none: "なし",
    noPreview: "プレビューなし",
    number: "数字",
    off: "オフ",
    on: "オン",
    onComplete: "onComplete",
    onHidden: "onHidden",
    onNextPage: "onNextPage",
    onPage: "onPage",
    onPanel: "onPanel",
    onSurvey: "onSurvey",
    onValueChanged: "onValueChanged",
    page: "ページ",
    pages: "ページ",
    password: "パスワード",
    percent: "パーセント",
    progressBottom: "progressBottom",
    progressTop: "progressTop",
    progressTopBottom: "progressTopBottom",
    questionPerPage: "ページあたりの質問",
    questions: "質問",
    random: "ランダム",
    range: "範囲",
    requiredQuestions: "必須の質問",
    show: "表示する",
    showAllQuestions: "すべての質問を含むプレビューを表示",
    showAnsweredQuestions: "回答された質問を含むプレビューを表示",
    singlePage: "シングルページ",
    standard: "スタンダード",
    survey: "アンケート",
    tel: "電話",
    text: "テキスト",
    time: "時間",
    top: "上",
    topBottom: "上と下",
    true: "真",
    underInput: "インプットの下",
    underTitle: "タイトルの下",
    url: "URL",
    vertical: "垂直",
    week: "週"
  },
  qt: {
    boolean: "ブール",
    checkbox: "チェックボックス",
    comment: "コメント",
    default: "デフォルト",
    dropdown: "ドロップダウン",
    expression: "式（読み取り専用）",
    file: "ファイル",
    flowpanel: "フローパネル",
    html: "HTML",
    image: "画像",
    imagepicker: "画像ピッカー",
    matrix: "マトリックス（単一選択）",
    matrixdropdown: "マトリックス（多項選択）",
    matrixdynamic: "マトリックス（動的行）",
    multipletext: "複数のテキスト",
    panel: "パネル",
    paneldynamic: "パネル（動的パネル）",
    radiogroup: "ラジオグループ",
    ranking: "ランキング",
    rating: "評価",
    signaturepad: "署名パッド",
    text: "単一入力"
  },
  survey: {
    addLogicItem: "アンケートの流れをカスタマイズするルールを作成します。",
    addToToolbox: "ツールボックスに追加する",
    convertTo: "に変換する",
    copy: "コピー",
    deletePanel: "パネルを削除する",
    deleteQuestion: "質問を削除する",
    drag: "要素をドラッグする",
    dropQuestion: "ツールボックスからここに質問をドロップしてください。",
    duplicate: "複製",
    edit: "編集",
    externalHelpLink: "アンケートの作成方法を見て学ぶ",
    externalHelpLinkUrl: "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber"
  },
  triggers: {
    completetrigger: "アンケートを完了",
    copyvaluetrigger: "値をコピー",
    runexpressiontrigger: "数式を実行",
    setvaluetrigger: "値を設定",
    skiptrigger: "質問へスキップ",
    visibletrigger: "可視性を変更 (非推奨)"
  },
  ts: {
    selectPage: "それをテストするためのページを選択:",
    showInvisibleElements: "不可視エレメントを表示"
  },
  validators: {
    answercountvalidator: "答えの数",
    emailvalidator: "メール",
    expressionvalidator: "表現",
    numericvalidator: "数字の",
    regexvalidator: "正規表現",
    textvalidator: "テキスト"
  }
};

editorLocalization.locales["ja"] = jaStrings;
