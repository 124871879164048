import React, { useState, useEffect } from "react";
import NavBar from "../../components/navBar/navBar";
import { __API_URL__ } from "../../SERVER_URL";
import { ApiManager } from '../../servicemanager/apimanager';
import AddQuestion from './AddQuestion'
import { toast, ToastContainer } from "react-toastify";
import CreateSurvey from './CreateSurvey'
import { type } from "@testing-library/user-event/dist/type";

const SurveyPoem = ({setAddNewSurvey, AllSurveyData, trial_detail}) => {
  const [surveyList, setSurveyList] = useState([]);
  const [surveyId, setSurveyId] = useState(0);
  const [survey, setSurvey] = useState({
    question:[],
  });
  const TOKEN = localStorage.getItem("token");
  const [Loader, setLoader] = useState(false);
  const [tabChanged, settabChanged] = useState(false);
  const [activeTab, setActiveTab] = useState("viewCase"); 
  const [questionCount, setQuestionCount] = useState([1])

  // API function...
const GetSurveys = async () => {
  setLoader(true)
  try {
    const response = await ApiManager.get(`${__API_URL__}/api/survey/get_survey`, TOKEN);
    setSurveyList(response.data)
    setLoader(false)
    AllSurveyData()
  } catch (error) {
    console?.error(error);
    return []
  }
};

  useEffect(() => {
    GetSurveys();
  }, []);

  useEffect(() => {
    if (trial_detail){
      setActiveTab('addSurvey')
    }
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSurveyId(0);
    settabChanged(false)
    setSurvey((previous) => ({
      ...previous,
      name: '',
      type: '',
      available: '',
      question: [],
    }));
    setQuestionCount([1])
  };
  
  const handleRowClick = (id)=>{
    setSurveyId(id)
    setActiveTab("addSurvey")
    settabChanged(true)
  }

  return (
    <div>
      <ToastContainer />
      {trial_detail? '':<NavBar active="survey" />}
      <div className="container-fluid">
        <div className="row patient_table">
          <div className="col-md-12">
            <div className="tableHeader">
              <div className="row">
                <div className="col-md-3 mx-1">
                  <h5>Survey</h5>
                  {trial_detail? '':
                    <button 
                        type="button" 
                        style={{ marginRight: '10px' }}
                        className={`btn btn-sm ${activeTab === "viewCase" ? "btn-primary" : ""} mb-2 `}
                        onClick={() => handleTabClick("viewCase")}
                        >
                      View Survey
                    </button>
          }
                    <button 
                          type="button"
                          className={`btn btn-sm ${activeTab === "addSurvey" ? "btn-primary" : ""} mb-2 ${tabChanged ? " disabled" : ""}`}
                          onClick={() => handleTabClick("addSurvey")}
                          >
                            {surveyId?'Edit Survey':'Add Survey'}
                    </button>
                    </div>
              </div>
            </div>
              {activeTab === "addSurvey" ?
              <CreateSurvey  
              GetSurveys={GetSurveys}
              toast={toast}
              survey={survey}
              setSurvey={setSurvey}
              setActiveTab={setActiveTab}
              surveyId={surveyId}
              setSurveyId={setSurveyId}
              activeTab={activeTab}
              setQuestionCount={setQuestionCount}
              questionCount={questionCount}
              settabChanged={settabChanged}
              setAddNewSurvey={setAddNewSurvey}
              setLoader={setLoader}
              /> 
              :
              <>
              <table className="mx-4 my-5"style={{ height: "50vh" }}>
              {Loader ? (
                <div className="loader">
                  <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                </div>
              ) : (
                <>
                  <thead className="caretOrder">
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                           Type
                        </th>
               
                        <th>
                        Available
                        </th>
                        <th>
                          Created at
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    {surveyList?.map((data, index) => {
                      return (
                        <tr key={index} onClick={()=>{handleRowClick(data.id)}}>
                          <td style={{ whiteSpace: 'pre-wrap' }}>{data?.name}</td>
                          <td>{data?.type}</td>
                          <td>{data?.available ? "True" : "False"}</td>
                          <td>
                          {new Date(data.created_at + "Z").toDateString()},&nbsp;
                            {new Date(data.created_at + "Z").toLocaleTimeString()}&nbsp;&nbsp;
                          
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              )}
                </table>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    );
};

export default SurveyPoem;