import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Table from 'react-bootstrap/Table';

const Dermatologic_Assessment = (props) => {
    const [selectedBlock, setSelectedBlock] = useState({});
    const [showField, setShowField] = useState(false);
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role
    const token = localStorage.getItem("token");
    const user = useUser();
    const setMessage = props.setMessage
    const setTypeOfMessage = props.setTypeOfMessage
    let disable = false
    const DERMATOLOGICAL_CHOICES = [
        { value: 'Plaque psoriasis', label: 'Plaque psoriasis' },
        { value: 'Atopic dermatitis', label: 'Atopic dermatitis' },
        { value: 'Dermatitis induced by cancer therapy (chemotherapy or radiation)', label: 'Dermatitis induced by cancer therapy (chemotherapy or radiation)' },
        { value: 'Vitiligo', label: 'Vitiligo' },
        { value: 'Alopecia areata', label: 'Alopecia areata' },
        { value: 'Hidradenitis suppurativa', label: 'Hidradenitis suppurativa' },
        { value: 'Lichen planus', label: 'Lichen planus' },
        { value: 'Bullous pemphigoid', label: 'Bullous pemphigoid' },
        { value: 'Chronic spontaneous urticaria', label: 'Chronic spontaneous urticaria' },
        { value: 'Actinic keratosis', label: 'Actinic keratosis' },
        { value: 'Seborrheic keratosis', label: 'Seborrheic keratosis' },
    ]
    const SEVERITY_CHOICES = [
        { value: 'Mild', label: 'Mild' },
        { value: 'Moderate', label: 'Moderate' },
        { value: 'Severe', label: 'Severe' },
    ]
    const ATOPIC_GRADE_CHOICES = [
        { value :'Clear',label:'Clear'},
        { value :'Almost Clear',label:'Almost Clear'},
        { value :'Mild',label:'Mild'},
        { value :'Moderate',label:'Moderate'},
        { value :'Severe',label:'Severe'},
    ]
    const PLAQUE_GRADE_CHOICES = [
        { value :'Clear',label:'Clear'},
        { value :'Almost Clear',label:'Almost Clear'},
        { value :'Mild',label:'Mild'},
        { value :'Moderate',label:'Moderate'},
        { value :'Severe',label:'Severe'},
        { value :'Very Severe',label:'Very Severe'},
    ]
    const ALOPIC_GRADE_CHOICES = [
        { value :'None',label:'None'},
        { value :'Limited',label:'Limited'},
        { value :'Moderate',label:'Moderate'},
        { value :'Severe',label:'Severe'},
        { value :'Very Severe',label:'Very Severe'},
    ]
    const handleFieldChange = (e) => {
        const { name, value, checked, type } = e.target;
        setSelectedBlock((prevBlock) => ({
          ...prevBlock,
          [name]: type === 'checkbox' ? (checked ? value : '') : (type === 'radio' ? (value === 'true') : value),
        }));
        props.setHasChanged(true)
        };
    const All_Dermatologic_Assessment_Data = () => {
        const createData = {
            'patient_id': props?.patient_id,
            'visit_number': props?.visit_number,
            'sessionId': props.selectedSessionId,
            ...selectedBlock
        }
        axios
            .patch(`${__API_URL__}/api/ecrf/update_dermatologic/${props.trialId}/`, createData, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                props.setModalVisitChangeShow(false)
                props.setHasChanged(false)
                setSelectedBlock(res.data);
                setMessage("Block updated successfully");
                setTypeOfMessage("success");
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                    user.actions.logout()
                }
                setMessage("Something Went Wrong");
                setTypeOfMessage("error");
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            });
        };
        const get_Dermatologic_Assessment_Data = () => {
            axios
                .get(`${__API_URL__}/api/ecrf/get_dermatologic/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                })
                .then((res) => {
                    setSelectedBlock(res.data);
                }).catch((err) => {
                    if (err == 'Error: Request failed with status code 401') {
                    }
                });
        }
        React.useEffect(() => {
            if((props?.patient_id && props?.photo_session)){
                get_Dermatologic_Assessment_Data();
            }
            if (role === 'CRO Admin') {
                setShowField(true)
            } 
        }, [])
        return <>
            {
                (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props?.patient_id === undefined)) ?
                    disable = true : ""
            }
            <fieldset disabled={disable}>
                {
                    (props.photo_session || props.patient_id == undefined) ?
                        <>
                            {
                                (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                    <div className="col-md-12 text-end">
                                        <button onClick={() => All_Dermatologic_Assessment_Data()} className='btn btn-primary'>Update</button>
                                    </div>
                                )
                            }
                            <div>
                                <div className='col-md-12'>
                                    <div className="col-md-12" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>Subject diagnosed with the following dermatological conditions:</div>
                                    <br></br>
                                    {DERMATOLOGICAL_CHOICES?.map(event => (
                                        <div className='col-md-12 p-0' key={event?.value}>
                                            <input
                                                className="form-check-input mx-1"
                                                style={{ marginLeft: "1em" }}
                                                type="checkbox"
                                                name='dermatological_condition'
                                                checked={selectedBlock?.dermatological_condition === event?.value}
                                                value={event?.value}
                                                onChange={(e) => handleFieldChange(e)}
                                            />
                                            <label>{event?.label}</label>
                                            {((event?.value === "Atopic dermatitis"  && (selectedBlock?.dermatological_condition === event?.value || showField )))&& (
                                            <Table id='patient_table' className="border_table table-sm border">
                                                <thead><th>Atopic dermatitis Investigator Global Assessment (IGA)</th></thead>
                                                    <tr>
                                                    <thead>
                                                        <th className='border'>Score</th>
                                                        <th className='border'>Grade</th>
                                                        <th className='border'>Definition</th>
                                                        <th className='border'>Select</th>
                                                    </thead>
                                                    <tbody>
                                                        <td>
                                                            <div className='col-md-10 p-0'>
                                                            <label>0</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>1</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>2</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>3</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>4</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='col-md-10 p-0'>
                                                            <label>Clear</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>Almost Clear</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>Mild</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>Moderate</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>Severe</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='col-md-10 p-0'>
                                                            <label>Minor, residual discoloration; no erythema or induration/papulation; nooozing/crusting; no edema.</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>Trace, faint pink erythema with barely perceptible induration/papulation and no oozing/crusting; no edema.</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>Faint-pink erythema with papulation and edema perceptible upon palpation and no oozing/crusting; minimal induration.</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>Pink-red erythema with definite edema of skin papules and plaques; there may be some oozing/crusting; palpable induration.</label>
                                                            </div>
                                                            <div className='col-md-10 p-0'>
                                                            <label>Deep/bright red erythema with significant swelling and obvious raised borders of papules and plaques with oozing/crusting; significant induration.</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {ATOPIC_GRADE_CHOICES?.map(event => (
                                                            <div className='col-md-10 mt-1 mx-2' key={event?.value}>
                                                                <input
                                                                className="form-check-input mx-2 mt-1"
                                                                type="checkbox"
                                                                name='atopic_grade'
                                                                checked={selectedBlock?.atopic_grade === event?.value}
                                                                value={event?.value}
                                                                onChange={(e) => handleFieldChange(e)}
                                                                />
                                                            </div>
                                                            ))}
                                                        </td>
                                                    </tbody>
                                                </tr>
                                            </Table>
                                        )}
                                        {((event?.value === "Alopecia areata"  && (selectedBlock?.dermatological_condition === event?.value || showField )))&& (
                                        <div className="vertical-table">
                                            <table id="patient_table" className="border_table table-sm border">
                                            <thead><th>Alopecia Areata Investigator Global Assessment (AA-IGA)</th></thead>
                                                <tr>
                                                <thead>
                                                    <th className="mx-3">Category</th>
                                                    <th className="mx-3">Percentages</th>
                                                    <th className="mx-3">Select</th>
                                                </thead>
                                                <tbody>
                                                <td>
                                                        <div className="vertical-label">
                                                        <label>None</label>
                                                        </div>
                                                        <div className="vertical-label">
                                                        <label>Limited</label>
                                                        </div>
                                                        <div className="vertical-label">
                                                        <label>Moderate</label>
                                                        </div>
                                                        <div className="vertical-label">
                                                        <label>Severe</label>
                                                        </div>
                                                        <div className="vertical-label">
                                                        <label>Very Severe</label>
                                                        </div>
                                                    </td>
                                                <td>
                                                        <div className="vertical-label">
                                                        <label className="mx-3">0%</label>
                                                        </div>
                                                        <div className="vertical-label">
                                                        <label className="mx-3">1-20%</label>
                                                        </div>
                                                        <div className="vertical-label">
                                                        <label className="mx-3">21-49%</label>
                                                        </div>
                                                        <div className="vertical-label">
                                                        <label className="mx-3">50-94%</label>
                                                        </div>
                                                        <div className="vertical-label">
                                                        <label className="mx-3">95-100%</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {ALOPIC_GRADE_CHOICES?.map(event => (
                                                        <div className='col-md-10 mt-1 mx-2' key={event?.value}>
                                                            <input
                                                            className="form-check-input mx-2 mt-1"
                                                            type="checkbox"
                                                            name='alopecia_grade'
                                                            checked={selectedBlock?.alopecia_grade === event?.value}
                                                            value={event?.value}
                                                            onChange={(e) => handleFieldChange(e)}
                                                            />
                                                        </div>
                                                        ))}
                                                    </td>
                                                </tbody>
                                            </tr>
                                        </table>
                                    </div>
                                        )}
                                      <br></br>
                                {((event?.value === "Plaque psoriasis"  && (selectedBlock?.dermatological_condition === event?.value || showField)))&& (
                                    <>
                                    <Table id='patient_table' className="border_table table-sm border" >
                                         <thead>
                                            <th>Plaque Psoriasis Global Assessment </th>
                                        </thead>
                                        <tr>
                                        <thead>
                                            <th className='border'>Score</th>
                                            <th className='border'>Category</th>
                                            <th className='border'>Category Description</th>
                                            <th className='border'>Select</th>
                                        </thead>
                                        <tbody>
                                            <td>
                                                <div className='col-md-1 ' style={{ fontSize: 'small' }}>
                                                <label>0</label>
                                                        </div>
                                                    <br />
                                                <br />
                                                <div className='col-md-1 ' style={{ fontSize: 'small' }}>
                                                <label>1</label>
                                                        </div>
                                                    <br />
                                                <br />
                                                <div className='col-md-1 ' style={{ fontSize: 'small' }}>
                                                <label>2</label>
                                                        </div>
                                                    <br />
                                                <br />
                                                <div className='col-md-1 ' style={{ fontSize: 'small' }}>
                                                <label>3</label>
                                                        </div>
                                                    <br />
                                                <br />
                                                <div className='col-md-1 ' style={{ fontSize: 'small' }}>
                                                <label>4</label>
                                                        </div>
                                                    <br />
                                                <br />
                                                <div className='col-md-1 ' style={{ fontSize: 'small' }}>
                                                <label>5</label>
                                                        </div>
                                                    <br />
                                                <br />
                                            </td>
                                            <td>
                                                <div className='col-md-2 ' style={{ fontSize: 'small' }}>
                                                <label>Clear</label>
                                                        </div>
                                                    <br />
                                                <br />
                                                <div className='col-md-2 ' style={{ fontSize: 'small' }}>
                                                <label>Almost Clear</label>
                                                         </div>
                                                     <br />
                                                <br />
                                                <div className='col-md-2 ' style={{ fontSize: 'small' }}>
                                                <label>Mild</label>
                                                        </div>
                                                    <br />
                                                <br />
                                                <div className='col-md-2 ' style={{ fontSize: 'small' }}>
                                                <label>Moderate</label>
                                                        </div>
                                                    <br />
                                                <br />
                                                <div className='col-md-2 ' style={{ fontSize: 'small' }}>
                                                <label>Severe</label>
                                                        </div>
                                                        <br />
                                                <br />
                                                <div className='col-md-2 ' style={{ fontSize: 'small' }}>
                                                <label>Very Severe</label>
                                                        </div>
                                                    <br />
                                                <br />
                                                </td>
                                            <td>
                                                <div className='col-md-9 ' style={{ fontSize: 'small' }}>
                                                <label>
                                                    Plaque elevation = 0 (no elevation over normal skin) <br />
                                                    Scalinq = 0 (no scale) <br />
                                                    Erythema = no evidence of erythema; hyperpigmentation may be present
                                                </label>
                                                </div>
                                                <div className='col-md-9 ' style={{ fontSize: 'small' }}>
                                                <label>
                                                    Plaque elevation = ± (possible but difficult to ascertain whether there is a slight elevation above normal skin) <br />
                                                    Scalinq = ± (surface dryness with some white coloration) <br />
                                                    Erythema = light red coloration
                                                </label>
                                            </div>
                                            <div className='col-md-9 ' style={{ fontSize: 'small' }}>
                                                <label>
                                                    Plaque elevation = slight (slight but definite elevation, typically edges are indistinct or sloped) <br />
                                                    Scalinq = fine (fine scale partially or mostly covering lesions) <br />
                                                    Erythema = light red coloration
                                                </label>
                                            </div>
                                            <div className='col-md-9 ' style={{ fontSize: 'small' }}>
                                                <label>
                                                    Plaque elevation = moderate (moderate elevation with rough or sloped edges) <br />
                                                    Scaling = coarser (coarse scale covering most or all of the lesions) <br />
                                                    Erythema = moderate (definite red coloration)
                                                </label>
                                            </div>
                                            <div className='col-md-9 ' style={{ fontSize: 'small' }}>
                                                <label>
                                                    Plaque elevation = marked (marked elevation typically with hard or sharp edges) <br />
                                                    Scalinq = coarse (coarse, non tenacious scale predominates covering most or all of the lesions) <br />
                                                    Erythema = severe (very bright red coloration)
                                                </label>
                                            </div>
                                            <div className='col-md-9' style={{ fontSize: 'small' }}>
                                                <label>
                                                    Plaque elevation = very marked (very marked elevation typically with hard sharp edges) <br />
                                                    Scaling = very coarse (coarse, thick tenacious scale over most of all of the lesions; rough surface) <br />
                                                    Erythema = very severe (extreme red coloration; dusky to deep red coloration)
                                                </label>
                                            </div>
                                            </td>
                                            <td>
                                            <div className='col-md-10 p-0' style={{ fontSize: 'small' }}>
                                                {PLAQUE_GRADE_CHOICES?.map(event => (
                                                <div className='col-md-8 mt-5  mx-2 my-4' key={event?.value}>
                                                    <input
                                                    className="form-check-input mx-2 mt-3"
                                                    type="checkbox"
                                                    name='plaque_grade'
                                                    checked={selectedBlock?.plaque_grade === event?.value}
                                                    value={event?.value}
                                                    onChange={(e) => handleFieldChange(e)}
                                                    />
                                                </div>
                                                ))}
                                            </div>
                                        <br />
                                    <br />
                                </td>
                            </tbody>
                        </tr>
                    </Table>
                </>
            )}
    </div>
    ))}
    </div>
        <br></br>
            <div className='col-md-10'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>Severity: </div>
                    <div className='col-md-10 p-0' style={{ display: 'flex' }}>
                        {SEVERITY_CHOICES?.map(event => (
                            <div key={event?.value} style={{ marginRight: '1em' }}>
                                <input
                                    className="form-check-input mx-1"
                                    type="checkbox"
                                    name='severity'
                                    checked={selectedBlock?.severity === event?.value}
                                    value={event?.value}
                                    onChange={(e) => handleFieldChange(e)}
                                />
                                <label>{event?.label}</label>
                            </div>
                        ))}
                </div>
            </div>
        </div>
</div>
</> : 'Visit is not started'
            }
        </fieldset>
    </>
}
export default Dermatologic_Assessment