import { useEffect, useState, useRef } from "react";
import { __API_URL__ } from "../../SERVER_URL";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageCompareSession = ({ Images }) => {
  const [scale, setScale] = useState(1);
  const popUpRef = useRef(null);

  const hidePopUp = () => {
    if (popUpRef.current) {
      popUpRef.current.style.display = "none";
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        hidePopUp();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    const handleScroll = () => {
      const windowWidth = window.innerWidth;
      const scrollPosition = window.scrollX;
      const rightSidePosition = scrollPosition + windowWidth;

      if (rightSidePosition >= windowWidth && popUpRef.current) {
        hidePopUp();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
      <div className="imageCompare image-pop-u zoomImage" ref={popUpRef}>
        <div className="popup-content1" ref={popUpRef}>

          <div className="container">
            <div className="row">
              <div>
                <h5>
                  <span onClick={hidePopUp}>x</span>
                </h5>

                <div className="comRight">
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={0}
                    defaultPositionY={0}
                  >
                    {({ zoomIn, zoomOut, ...rest }) => (
                      <>
                        <div className="image-zoom">
                          <TransformComponent>
                            <div className="image-zoom">
                              <img
                                src=""
                                alt=""
                                width="100%"
                                className="fixImage fixImageZoom"
                                style={{ transform: `scale(${scale})` }}
                              />
                            </div>
                          </TransformComponent>
                        </div>
                        <div className="controls" color="primary" class="right">
                          <div className="container text-center mt-3 buttonsz-u">
                            <button
                              className="btn btn-primary btn-sm mx-2"
                              onClick={() => zoomIn()}
                            >
                              ZOOM IN
                            </button>

                            <button
                              className="btn btn-primary btn-sm mx-2"
                              onClick={() => zoomOut()}
                            >
                              ZOOM OUT
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </TransformWrapper>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ImageCompareSession;