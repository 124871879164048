import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import axios from 'axios';
import useUser from '../../hooks/user';
import { __API_URL__ } from '../../SERVER_URL';
import { useParams } from 'react-router';
import AddNewModal from '../Modals/AddNewTrial';
import TrialParticipantIdAccordion from '../TrialAccordions/TrialParticipantIdAccordion';
import DrugAssignmentAccordion from '../TrialAccordions/DrugAssignmentAccordion';
import DrugIdRangeAccordion from '../TrialAccordions/DrugIdRangeAccordion';
import Investigator_SiteId_Accordion from '../TrialAccordions/Investigator_SiteId_Accordion';
import TrialReviewersAccordion from '../TrialAccordions/TrialReviewersAccordion';
import { toast, ToastContainer } from 'react-toastify';
import MaximumPhotoTypesAccordion from '../TrialAccordions/MaximumPhotoTypesAccordion';

const Basic_Info = ({
  numberOfVisits,
  numberOfVisitsSetter,
  setMessage,
  setTypeOfMessage,
  isEvent,
  setisEvent,
  eventList,
  setEventList,
  setSelectedTrialType,
}) => {
  const token = localStorage.getItem('token');
  const user_data = JSON.parse(localStorage.getItem('user_data'));
  const role = user_data.role;
  const user = useUser();

  const [loader, setLoader] = useState(false);
  const { _id_ } = useParams();
  const [trialDetails, setTrialDetails] = useState({});
  const [allInvestigators, setAllInvestigators] = useState({});
  const [allReviewers, setAllReviewers] = useState({});
  const [minVisits, setMinVisits] = useState(0);
  const [localNumberOfVisits, setLocalNumberOfVisits] = useState(0);
  const [hasChanged, setHasChanged] = React.useState(false);
  const [modalTrialTypeShow, setModalTrialTypeShow] = useState(false);
  const [trialType, setTrialType] = useState([]);
  const [changeTrialSetting, setChangeTrialSetting] = useState({
    participant_ids: '',
    site_ids: '',
  });
  const [changeSetting, setChangeSetting] = useState(false);
  const [siteIdManual, setSiteIdManual] = useState(false);
  const [investigatorChanged, setInvestigatorChanged] = useState(false);
  const [trialSetting, setTrialSetting] = useState('Participant Id');
  const [infoSettingChanged, setInfoSettingChanged] = useState(false);
  const [reviewerChanged, setReviewerChanged] = useState(false);
  const [ParticipantIdChanged, setParticipantIdChanged] = useState(false);
  const [DrugChanged, setDrugChanged] = useState(false);
  const [DrugRangeChanged, setDrugRangeChanged] = useState(false);
  const [maximumPhototypes, setMaximumPhototypes] = useState(0);
  const [errorMsg, seterrorMsg] = useState('');
  const [title, setTitle] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  const options = useMemo(
    () => [
      {
        value: 'screening_visit',
        label: 'Screening Visit',
        isDisabled: ![
          'CRO Admin',
          'Cro_coordinator',
          'Data Manager',
          'Super Admin',
        ].includes(role),
      },
      {
        value: 'unscheduled_event',
        label: 'Unscheduled Event',
        isDisabled: ![
          'CRO Admin',
          'Cro_coordinator',
          'Data Manager',
          'Super Admin',
        ].includes(role),
      },
      {
        value: 'adverse_event',
        label: 'Adverse Event',
        isDisabled: ![
          'CRO Admin',
          'Cro_coordinator',
          'Data Manager',
          'Super Admin',
        ].includes(role),
      },
      {
        value: 'drug_assignment',
        label: 'Drug Assignment',
        isDisabled: ['Investigator', 'Inv_coordinator'].includes(role),
      },
      {
        value: 'is_maximum_photo_types',
        label: 'Maximum App Photos Per Subject',
        isDisabled: ['Investigator', 'Inv_coordinator'].includes(role),
      },
      {
        value: 'automatic_email_notification',
        label: 'Automatic Email Notifications',
        isDisabled: ['Investigator', 'Inv_coordinator'].includes(role),
      },
      {
        value: 'is_hide_image',
        label: 'Hide Patient Photos',
        isDisabled: !['CRO Admin', 'Cro_coordinator', 'Data Manager'].includes(
          role
        ),
      },
      {
        value: 'can_edit_instructions',
        label: 'INV Subject Instruction Edit',
        isDisabled: !['CRO Admin', 'Cro_coordinator', 'Data Manager'].includes(
          role
        ),
      },
      {
        value: 'blur_image_background',
        label: 'Blur Image Background',
        isDisabled: !['CRO Admin', 'Cro_coordinator', 'Data Manager'].includes(
          role
        ),
      },
      {
        value: 'add_censor_bar',
        label: 'Add Censor Bar',
        isDisabled: !['CRO Admin', 'Cro_coordinator', 'Data Manager'].includes(
          role
        ),
      },
      {
        value: 'individual_eye_block',
        label: 'Individual Eye Block',
        isDisabled: !['CRO Admin', 'Cro_coordinator', 'Data Manager'].includes(
          role
        ),
      },
    ],
    [role]
  );

  const optionsFunctionsMapping = {
    individual_eye_block: () => {
      setTrialDetails((prev) => {
        const updatedValue = !prev.individual_eye_block;
        return {
          ...prev,
          individual_eye_block: updatedValue,
          add_censor_bar: updatedValue ? false : prev.add_censor_bar,
        };
      });
    },
    add_censor_bar: () => {
      setTrialDetails((prev) => {
        const updatedValue = !prev.add_censor_bar;
        return {
          ...prev,
          add_censor_bar: updatedValue,
          individual_eye_block: updatedValue ? false : prev.individual_eye_block,
        };
      });
    },
    blur_image_background: () => {
      setTrialDetails((prev) => ({
        ...prev,
        blur_image_background: !prev.blur_image_background,
      }));
    },
    can_edit_instructions: () => {
      setTrialDetails((prev) => ({
        ...prev,
        can_edit_instructions: !prev.can_edit_instructions,
      }));
    },
    is_hide_image: () => {
      setTrialDetails((prev) => ({
        ...prev,
        is_hide_image: !prev.is_hide_image,
      }));
    },
    automatic_email_notification: () => {
      setTrialDetails((prev) => ({
        ...prev,
        automatic_email_notification: !prev.automatic_email_notification,
      }));
    },
    drug_assignment: () => {
      setTrialDetails((prev) => ({
        ...prev,
        drug_assignment: !prev.drug_assignment,
      }));
    },
    is_maximum_photo_types: () => {
      setTrialDetails((prev) => ({
        ...prev,
        is_maximum_photo_types: !prev.is_maximum_photo_types,
      }));
    },
    adverse_event: () => {
      setisEvent((prev) => ({ ...prev, adverse_event: !prev.adverse_event }));
      handleAdverseEventClick(!isEvent.adverse_event);
    },
    unscheduled_event: () => {
      setisEvent((prev) => ({
        ...prev,
        unscheduled_event: !prev.unscheduled_event,
      }));
      handleUnscheduledEventClick(!isEvent.unscheduled_event);
    },
    screening_visit: () => {
      setisEvent((prev) => ({
        ...prev,
        screening_visit: !prev.screening_visit,
      }));
      handleScreeningEventClick(!isEvent.screening_visit);
    },
  };

  useEffect(() => {
    if (!isMounted && trialDetails.trial_options) {
      setSelectedOptions(trialDetails.trial_options);
      setIsMounted(true);
    }
  }, [trialDetails]);

  const handleChange = (selected, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
        if(actionMeta.option.value === 'individual_eye_block') 
          selected = selected.filter((item) => item.value !== "add_censor_bar");
        else if(actionMeta.option.value === 'add_censor_bar')
          selected = selected.filter((item) => item.value !== "individual_eye_block");

        const selectedFunction =
          optionsFunctionsMapping[actionMeta.option.value];
        if (selectedFunction) {
          selectedFunction();
        }
        break;
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isDisabled) {
          return;
        }
        const removedFunction =
          optionsFunctionsMapping[actionMeta.removedValue.value];
        if (removedFunction) {
          removedFunction();
        }
        break;
    }

    setSelectedOptions(selected);
    setHasChanged(true);
  };

  const onInvestigatorChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
        if (actionMeta.removedValue.isFixed) {
          setMessage('Cannot remove the Investigator');
          setTypeOfMessage('success');
          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '2rem';
          }, 100);
          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '-99rem';
          }, 3000);
          return newValue;
        } else {
          setInvestigatorChanged(true);
          setChangeSetting(true);
          newValue = trialDetails.investigators.filter(
            (v) => v.value != actionMeta.removedValue.value
          );
          setTrialDetails((trial) => {
            return {
              ...trial,
              investigators: newValue,
            };
          });
        }
        break;
      case 'pop-value':
        break;
      case 'clear':
        setInvestigatorChanged(true);
        setChangeSetting(true);
        newValue = trialDetails.investigators.filter((v) => v.isFixed);
        setTrialDetails((trial) => {
          return {
            ...trial,
            investigators: newValue,
          };
        });
        break;
      case 'select-option':
        setInvestigatorChanged(true);
        setChangeSetting(true);
        setTrialDetails((trial) => {
          return {
            ...trial,
            investigators: newValue,
          };
        });
        break;
    }
    // AllInvestigatorsData()
  };

  const onReviewerChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
        if (actionMeta.removedValue.isFixed) {
          setMessage('Cannot remove the Reviewer');
          setTypeOfMessage('success');
          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '2rem';
          }, 100);
          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '-99rem';
          }, 3000);
          return newValue;
        } else {
          setReviewerChanged(true);
          newValue = trialDetails.reviewers.filter(
            (v) => v.value != actionMeta.removedValue.value
          );
          setTrialDetails((trial) => {
            return {
              ...trial,
              reviewers: newValue,
            };
          });
        }
        break;
      case 'pop-value':
        break;
      case 'clear':
        setReviewerChanged(true);
        newValue = trialDetails.reviewers.filter((v) => v.isFixed);
        setTrialDetails((trial) => {
          return {
            ...trial,
            reviewers: newValue,
          };
        });
        break;
      case 'select-option':
        setReviewerChanged(true);
        setTrialDetails((trial) => {
          return {
            ...trial,
            reviewers: newValue,
          };
        });
        break;
    }
    // AllReviewerData()
  };

  const handleFieldChange = (e) => {
    setHasChanged(true);
    const { name, value } = e.target;
    setTrialDetails((trial) => {
      return {
        ...trial,
        [name]: value,
      };
    });
  };

  const handleBasicInfoUpdate = () => {
    const updatedTrialDetails = {
      ...trialDetails,
      total_visits: localNumberOfVisits,
      special_visits: isEvent,
    };

    axios
      .patch(
        `${__API_URL__}/api/photos/edit_trial/${trialDetails.id}/`,
        updatedTrialDetails,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        res = res.data;
        let investigator_list = [];
        res.investigator_details.map((investigator) => {
          investigator_list.push({
            value: investigator.email,
            label: investigator.email,
            isFixed: true,
            site_number: investigator.site_number,
            minimum_drug: investigator.minimum_drug,
            max_patient: investigator.max_patient,
          });
        });
        let reviewer_list = [];
        res.reviewer_details.map((reviewer) => {
          reviewer_list.push({
            value: reviewer.email,
            label: reviewer.email,
            isFixed: true,
            site_number: reviewer.site_number,
          });
        });
        res = {
          ...res,
          investigators: investigator_list,
          reviewers: reviewer_list,
        };

        setSelectedTrialType(res.trial_type?.title);
        setTrialDetails({
          ...res,
          trial_type: res.trial_type?.id,
          trial_type_title: res.trial_type?.title,
        });
        setChangeSetting(false);
        setHasChanged(false);
        setInfoSettingChanged(false);
        setInvestigatorChanged(false);
        setReviewerChanged(false);
        setParticipantIdChanged(false);
        setDrugChanged(false);
        setDrugRangeChanged(false);
        setMessage('Updated Successfully');
        setTypeOfMessage('success');
        numberOfVisitsSetter(localNumberOfVisits);

        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '2rem';
        }, 100);
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '-99rem';
        }, 3000);
      })
      .catch((err) => {
        setMessage(err?.response?.data?.message);
        setTypeOfMessage('error');
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '2rem';
        }, 100);
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '-99rem';
        }, 3000);
      });
  };

  useEffect(() => {
    setLocalNumberOfVisits(numberOfVisits);
  }, [numberOfVisits]);

  const trialDetailApi = () => {
    axios
      .get(`${__API_URL__}/api/photos/get_trial/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        res = res.data;
        let investigator_list = [];
        res.investigator_details.map((investigator) => {
          investigator_list.push({
            value: investigator.email,
            label: investigator.email,
            isFixed: true,
            site_number: investigator.site_number,
            minimum_drug: investigator.minimum_drug,
            max_patient: investigator.max_patient,
          });
        });
        let reviewer_list = [];
        res.reviewer_details.map((reviewer) => {
          reviewer_list.push({
            value: reviewer.email,
            label: reviewer.email,
            isFixed: true,
            site_number: reviewer.site_number,
          });
        });
        res = {
          ...res,
          investigators: investigator_list,
          reviewers: reviewer_list,
        };
        setTrialDetails({
          ...res,
          trial_type: res.trial_type?.id,
          trial_type_title: res.trial_type?.title,
        });
        setLocalNumberOfVisits(res.total_visits);
        numberOfVisitsSetter(res.total_visits);
      });
  };

  const AllInvestigatorsData = () => {
    axios
      .get(`${__API_URL__}/api/investigatorsList/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        let customData = [];
        res.data.map((obj) => {
          customData.push({
            value: obj.email,
            label: obj.email,
          });
        });
        setAllInvestigators(customData);
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      });
  };

  const AllReviewerData = () => {
    axios
      .get(`${__API_URL__}/api/reviewerList/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAllReviewers(res.data);
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      });
  };

  const getTrialTypes = () => {
    axios
      .get(`${__API_URL__}/api/photos/get_trial_types/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        res = res.data;
        setTrialType(res);
      });
  };

  useEffect(() => {
    trialDetailApi();
    AllInvestigatorsData();
    getTrialTypes();
    AllReviewerData();
  }, []);

  const handleScreeningEventClick = (isChecked) => {
    if (isChecked) {
      if (!eventList.includes(-3)) {
        setEventList((prevList) => [...prevList, -3]);
      }
    } else {
      setEventList((prevList) => prevList.filter((event) => event !== -3));
    }
  };

  const handleUnscheduledEventClick = (isChecked) => {
    if (isChecked) {
      if (!eventList.includes(-2)) {
        setEventList((prevList) => [...prevList, -2]);
      }
    } else {
      setEventList((prevList) => prevList.filter((event) => event !== -2));
    }
  };

  const handleAdverseEventClick = (isChecked) => {
    if (isChecked) {
      if (!eventList.includes(-1)) {
        setEventList((prevList) => [...prevList, -1]);
      }
    } else {
      setEventList((prevList) => prevList.filter((event) => event !== -1));
    }
  };

  const addTrialTypeApi = () => {
    setModalTrialTypeShow(false);
    const Createdata = {
      title: title,
    };
    axios
      .post(`${__API_URL__}/api/photos/add_trial_type/`, Createdata, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        const newTrialType = res.data;
        setTrialType((prevTrialTypes) => [...prevTrialTypes, newTrialType]);
        setTrialDetails((prevDetails) => ({
          ...prevDetails,
          trial_type: newTrialType.id,
        }));
        toast.success('Trial Type Added');
        setTitle('');
      })
      .catch((err) => {
        toast.error('Something Went Wrong!');
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-md-6"></div>
        <>
          {role !== 'Investigator' &&
            role !== 'Inv_coordinator' &&
            role !== 'Reviewer' && (
              <>
                <div className="col-md-6 text-end">
                  <button
                    type="submit"
                    class="btn btn-primary btn-sm mx-5"
                    onClick={() => handleBasicInfoUpdate()}
                    disabled={!hasChanged}
                  >
                    {loader ? (
                      <i class="fa fa-spinner fa-spin"></i>
                    ) : (
                      'Update Basic Info'
                    )}
                  </button>
                </div>
              </>
            )}
        </>
      </div>
      <div className="row" key={trialDetails.id} style={{ padding: '1em' }}>
        <div className="col-md-6">
          <label>Custom Id</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleFieldChange(e)}
            name="trial_custom_id"
            value={trialDetails.trial_custom_id}
          ></input>
        </div>
        <div className="col-md-6">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleFieldChange(e)}
            name="name"
            value={trialDetails.name}
          ></input>
        </div>
        <div className="col-md-6">
          <label>Status</label>
          <select
            className="form-control"
            onChange={(e) => handleFieldChange(e)}
            name="status"
            value={trialDetails.status}
          >
            <option value="" disabled>
              Select
            </option>
            <option value="Active">Active</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
            <option value="New">New</option>
          </select>
        </div>

        {role !== 'Investigator' &&
          role !== 'Inv_coordinator' &&
          role !== 'Super Admin' &&
          role !== 'Reviewer' && (
            <>
              <div className="col-md-6">
                <label>Number Of Visits</label>
                <input
                  type="number"
                  min={minVisits}
                  className="form-control"
                  onChange={(e) => {
                    setLocalNumberOfVisits(e.target.value);
                    setHasChanged(true);
                  }}
                  name="total_visits"
                  value={localNumberOfVisits}
                ></input>
              </div>
            </>
          )}
        <div className="col-md-6">
          <label>Trial Type</label>
          {role === 'Investigator' ||
          role === 'Sub_investigator' ||
          role === 'Inv_coordinator' ? (
            <>
              <input
                className="form-control"
                value={trialDetails.trial_type_title}
                disabled={true}
              ></input>
            </>
          ) : (
            <>
              {
                <div className="row">
                  <div className="col-md-9">
                    <select
                      className="form-control"
                      onChange={handleFieldChange}
                      name="trial_type"
                      value={trialDetails?.trial_type}
                      required
                    >
                      <option value="">Select</option>
                      {trialType && trialType.length > 0 ? (
                        trialType.map((type) => (
                          <option value={type.id} key={type.id}>
                            {type.title}
                          </option>
                        ))
                      ) : (
                        <option value="">No trial type available</option>
                      )}
                    </select>
                  </div>
                  {role === 'Reviewer' ? (
                    ''
                  ) : (
                    <div className="col-md-3">
                      <button
                        class="btn btn-primary btn-sm"
                        onClick={() => setModalTrialTypeShow(true)}
                      >
                        Add New
                      </button>
                    </div>
                  )}
                  {modalTrialTypeShow && (
                    <AddNewModal
                      show={modalTrialTypeShow}
                      onHide={() => setModalTrialTypeShow(false)}
                      formSubmissionApi={addTrialTypeApi}
                      modalShow={setModalTrialTypeShow}
                      title={title}
                      setTitle={setTitle}
                    />
                  )}
                </div>
              }
            </>
          )}
        </div>

        <div style={{ marginBottom: '2rem' }}>
          <label>Trial Options</label>
          <Select
            value={selectedOptions}
            onChange={handleChange}
            options={options}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            isOptionDisabled={(option) => option.isDisabled}
            isClearable={false}
          />
        </div>

        {(role === 'CRO Admin' ||
          role === 'Cro_coordinator' ||
          role === 'Data Manager' ||
          (role === 'Super Admin' && role !== 'Inv_coordinator')) && (
          <>
            {trialDetails?.is_setting && (
              <TrialParticipantIdAccordion
                trialSetting={trialSetting}
                trialDetails={trialDetails}
                hasChanged={hasChanged}
                setChangeTrialSetting={setChangeTrialSetting}
                changeTrialSetting={changeTrialSetting}
                setHasChanged={setHasChanged}
                setTrialDetails={setTrialDetails}
                setSiteIdManual={setSiteIdManual}
                infoSettingChanged={infoSettingChanged}
                setInfoSettingChanged={setInfoSettingChanged}
                handleBasicInfoUpdate={handleBasicInfoUpdate}
                ParticipantIdChanged={ParticipantIdChanged}
                setParticipantIdChanged={setParticipantIdChanged}
              />
            )}

            <Investigator_SiteId_Accordion
              trialDetails={trialDetails}
              setTrialDetails={setTrialDetails}
              setChangeSetting={setChangeSetting}
              setHasChanged={setHasChanged}
              allInvestigators={allInvestigators}
              setAllInvestigators={setAllInvestigators}
              onInvestigatorChange={onInvestigatorChange}
              handleBasicInfoUpdate={handleBasicInfoUpdate}
              setChangeTrialSetting={setChangeTrialSetting}
              siteIdManual={siteIdManual}
              investigatorChanged={investigatorChanged}
              setInfoSettingChanged={setInfoSettingChanged}
              setInvestigatorChanged={setInvestigatorChanged}
              AllInvestigatorsData={AllInvestigatorsData}
              isDrug={trialDetails.drug_assignment}
            />

            <TrialReviewersAccordion
              role={role}
              trialDetails={trialDetails}
              allReviewers={allReviewers}
              onReviewerChange={onReviewerChange}
              handleBasicInfoUpdate={handleBasicInfoUpdate}
              AllReviewerData={AllReviewerData}
              setTrialDetails={setTrialDetails}
              setAllReviewers={setAllReviewers}
              setReviewerChanged={setReviewerChanged}
              reviewerChanged={reviewerChanged}
              errorMsg={errorMsg}
              seterrorMsg={seterrorMsg}
            />
          </>
        )}
        {trialDetails.drug_assignment &&
          (role === 'CRO Admin' ||
            role === 'Cro_coordinator' ||
            role === 'Data Manager' ||
            role === 'Super Admin') && (
            <DrugAssignmentAccordion
              trialDetails={trialDetails}
              hasChanged={hasChanged}
              setHasChanged={setHasChanged}
              setTrialDetails={setTrialDetails}
              handleBasicInfoUpdate={handleBasicInfoUpdate}
              setDrugChanged={setDrugChanged}
              DrugChanged={DrugChanged}
            />
          )}
        {trialDetails.drug_assignment &&
          (role === 'CRO Admin' ||
            role === 'Cro_coordinator' ||
            role === 'Data Manager' ||
            role === 'Super Admin') && (
            <DrugIdRangeAccordion
              trialDetails={trialDetails}
              hasChanged={hasChanged}
              setHasChanged={setHasChanged}
              setTrialDetails={setTrialDetails}
              handleBasicInfoUpdate={handleBasicInfoUpdate}
              DrugRangeChanged={DrugRangeChanged}
              setDrugRangeChanged={setDrugRangeChanged}
            />
          )}

        {trialDetails.is_maximum_photo_types &&
          (role === 'CRO Admin' ||
            role === 'Cro_coordinator' ||
            role === 'Data Manager' ||
            role === 'Super Admin') && (
            <MaximumPhotoTypesAccordion
              trialDetails={trialDetails}
              setTrialDetails={setTrialDetails}
              handleBasicInfoUpdate={handleBasicInfoUpdate}
              maximumPhototypes={maximumPhototypes}
              setMaximumPhototypes={setMaximumPhototypes}
            />
          )}
      </div>
    </>
  );
};

export default Basic_Info;
