import React, { useEffect, useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Button from 'react-bootstrap/Button';

const Exclusion_Criteria = (props) => {
        const [selectedBlock, setSelectedBlock] = useState({})
        const user_data = JSON.parse(localStorage.getItem("user_data"));
        const role = user_data.role
        const token = localStorage.getItem("token");
        const user = useUser();
        const setMessage = props.setMessage
        const setTypeOfMessage = props.setTypeOfMessage
        let disable=false

        const handleIsActiveViralInfection = (e) => {
        setSelectedBlock({
                ...selectedBlock,
                is_active_viral_infection: !selectedBlock.is_active_viral_infection,
        }); 
        props.setHasChanged(true)
        };
        const handleIsBreastFeeding = (e) => {
        setSelectedBlock({
                ...selectedBlock,
                is_breast_feeding: !selectedBlock.is_breast_feeding,
        }); 
        props.setHasChanged(true)
        };
        const handleIsConcomitantSkinDisease = (e) => {
        setSelectedBlock({
                ...selectedBlock,
                is_concomitant_skin_disease: !selectedBlock.is_concomitant_skin_disease,
        }); 
        props.setHasChanged(true)
        };
        const handleIsOtherInvestigational = (e) => {
        setSelectedBlock({
                ...selectedBlock,
                is_other_investigational: !selectedBlock.is_other_investigational,
        }); 
        props.setHasChanged(true)
        };
        const handleIsPreviousAllergicReaction = (e) => {
        setSelectedBlock({
                ...selectedBlock,
                is_previous_allergic_reaction: !selectedBlock.is_previous_allergic_reaction,
        }); 
        props.setHasChanged(true)
        };
        const handleIsSubjectSiteStaffMember = (e) => {
        setSelectedBlock({
                ...selectedBlock,
                is_subject_site_staff_member: !selectedBlock.is_subject_site_staff_member,
        }); 
        props.setHasChanged(true)
        };
        const handleIsUnreliability = (e) => {
        setSelectedBlock({
                ...selectedBlock,
                is_unreliability: !selectedBlock.is_unreliability,
        }); 
        props.setHasChanged(true)
        };
        const selectAll = () => {
                setSelectedBlock({
                    is_active_viral_infection: true,
                    is_breast_feeding: true,
                    is_concomitant_skin_disease: true,
                    is_other_investigational: true,
                    is_previous_allergic_reaction: true,
                    is_subject_site_staff_member: true,
                    is_unreliability: true,
                });
                props.setHasChanged(true)
                };
              const removeAll = () => {
                setSelectedBlock({
                    is_active_viral_infection: false,
                    is_breast_feeding: false,
                    is_concomitant_skin_disease: false,
                    is_other_investigational: false,
                    is_previous_allergic_reaction: false,
                    is_subject_site_staff_member: false,
                    is_unreliability: false,
                });
                props.setHasChanged(true)
                };

        const GetSelectedBlockData = () => {
                axios
                .get(`${__API_URL__}/api/ecrf/get_exclusion/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
                headers: {
                Authorization: `Token ${token}`,
                },
                })
                .then((res) => {
                  setSelectedBlock(res.data);
                }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                }
                });
                }

        const AllData = () => {
                const createData = {
                        'patient_id':props?.patient_id,
                        'visit_number':props?.visit_number,
                        'is_active_viral_infection': selectedBlock?.is_active_viral_infection === undefined ? true : selectedBlock?.is_active_viral_infection,
                        'is_breast_feeding': selectedBlock?.is_breast_feeding === undefined ? true : selectedBlock?.is_breast_feeding,
                        'is_concomitant_skin_disease': selectedBlock?.is_concomitant_skin_disease === undefined ? true : selectedBlock?.is_concomitant_skin_disease,
                        'is_other_investigational': selectedBlock?.is_other_investigational=== undefined ? true : selectedBlock?.is_other_investigational,
                        'is_previous_allergic_reaction': selectedBlock?.is_previous_allergic_reaction === undefined ? true : selectedBlock?.is_previous_allergic_reaction,
                        'is_subject_site_staff_member': selectedBlock?.is_subject_site_staff_member === undefined ? true : selectedBlock?.is_subject_site_staff_member,
                        'is_unreliability': selectedBlock?.is_unreliability === undefined ? true : selectedBlock?.is_unreliability,
                        'sessionId': props.selectedSessionId,
                    }
                    axios
                      .patch(`${__API_URL__}/api/ecrf/update_exclusion/${props.trialId}/`,createData, {
                        headers: {
                          Authorization: `Token ${token}`,
                        },
                      })
                      .then((res) => {
                        setSelectedBlock(res.data);
                        props.setModalVisitChangeShow(false)
                        props.setHasChanged(false)
                        setMessage("Block updated successfully");
                        setTypeOfMessage("success");
                        setTimeout(() => {
                        document.getElementById("cusMessages").style.right = "2rem";
                        }, 100);
                        setTimeout(() => {
                        document.getElementById("cusMessages").style.right = "-99rem";
                        }, 3000);
                    }).catch((err) => {
                        if(err == 'Error: Request failed with status code 401'){
                        user.actions.logout()
                        }
                        setMessage("Something Went Wrong");
                        setTypeOfMessage("success");
                        setTimeout(() => {
                        document.getElementById("cusMessages").style.right = "2rem";
                        }, 100);
                        setTimeout(() => {
                        document.getElementById("cusMessages").style.right = "-99rem";
                        }, 3000);
                    });
                  };
                  React.useEffect(() => {
                        if((props?.patient_id && props?.photo_session)){
                          GetSelectedBlockData();
                        }
                      }, [])
        return <>
        {
        (role ==='CRO Admin' || role ==='Cro_coordinator' || role ==='Data Manager' || (props.patient_id === undefined)) ? 
                disable=true : ""
                          }
                          <fieldset disabled={disable}>
                    {
                        (props.photo_session || props.patient_id == undefined ) ?
                        <>
                            {
                                (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                <div className="col-md-12 text-end">
                                    <button onClick={() => AllData()} className='btn btn-primary'>Update</button>
                                </div>
                                )
                            }
                             <thread> EXCLUSION CRITERIA</thread>
                             <div className="row d-flex align-items-end">
                <div className="col-md-8">
                  <p>Check Yes or No for each criterion</p>
                </div>
                <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={selectAll}
                >
                  Select all Yes's
                </button>
                  &nbsp;
                </div>
                <div className="col-md-2">
                  <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={removeAll}
                  >Select all No's</button>
                        &nbsp;
                </div>
              </div>

                 <div>
                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                  <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> 1. Female subjects who are pregnant or breast-feeding.</div>
                                <div className="col-md-4 mx-3">
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input mx-2"
                                                type="radio"
                                                name="is_active_viral_infection"
                                                checked = {selectedBlock?.is_active_viral_infection === true}
                                                id="radio1"
                                                value={selectedBlock?.is_active_viral_infection === true}
                                                onChange={handleIsActiveViralInfection}
                                    />
                                        <label>Yes</label>

                                        </td>
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input mx-2"
                                                type="radio"
                                                name="is_active_viral_infection"
                                                checked = {selectedBlock?.is_active_viral_infection === false}
                                                id="radio1"
                                                value={selectedBlock?.is_active_viral_infection === false}
                                                onChange={handleIsActiveViralInfection}
                                    />
                                    <label>No</label>
                                        </td>
                                </div>
                                </div>
                                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                  <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> 2. Known hypersensitivity or previous allergic reaction to any constituent of triamcinolone injection.
                                        </div>
                                        <div className="col-md-4 mx-3">
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_breast_feeding"
                                                checked = {selectedBlock?.is_breast_feeding === true}
                                                id="radio2"
                                                value={ selectedBlock?.is_breast_feeding === true}
                                                onChange={handleIsBreastFeeding}
                                    />
                                        <label>Yes</label>

                                        </td>
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input mx-2"
                                                type="radio"
                                                name="is_breast_feeding"
                                                checked = {selectedBlock?.is_breast_feeding === false}
                                                id="radio2"
                                                value={selectedBlock?.is_breast_feeding === false}
                                                onChange={handleIsBreastFeeding}
                                    />
                                    <label>No</label>
                                        </td>
                                </div>
                                </div>
                                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                  <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> 3. Active cutaneous viral infection in any treatment area at Baseline. </div>
                  <div className="col-md-4 mx-3">
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_concomitant_skin_disease"
                                                checked = {selectedBlock?.is_concomitant_skin_disease === true}
                                                id="radio3"
                                                value={selectedBlock?.is_concomitant_skin_disease === true}
                                                onChange={handleIsConcomitantSkinDisease}
                                    />
                                        <label>Yes</label>
                                        </td>
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_concomitant_skin_disease"
                                                checked = {selectedBlock?.is_concomitant_skin_disease === false}
                                                id="radio3"
                                                value={selectedBlock?.is_concomitant_skin_disease === false}
                                                onChange={handleIsConcomitantSkinDisease}
                                    />
                                    <label>No</label>
                                        </td>
                                        </div>
                                        </div>
                                        <div className="d-flex justify-content-between" style={{width: '100%'}}>
                  <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>4. Have concomitant skin disease or infection (other than acne) or presence of skin comorbidities in the areas of skin where study device will be used.</div>
                  <div className="col-md-4 mx-3">
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_other_investigational"
                                                checked = {selectedBlock?.is_other_investigational === true}
                                                id="radio4"
                                                value={selectedBlock?.is_other_investigational === true}
                                                onChange={handleIsOtherInvestigational}
                                                />
                                                <label>Yes</label>

                                        </td>
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_other_investigational"
                                                checked = {selectedBlock?.is_other_investigational === false}
                                                id="radio4"
                                                value={selectedBlock?.is_other_investigational === false}
                                                onChange={handleIsOtherInvestigational}
                                    />
                                    <label>No</label>
                                        </td>
                                        </div>
                                        </div>
                                        <div className="d-flex justify-content-between" style={{width: '100%'}}>
                  <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>5. History of poor cooperation or unreliability (Investigator discretion).</div>
                  <div className="col-md-4 mx-3">
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_previous_allergic_reaction"
                                                checked = {selectedBlock?.is_previous_allergic_reaction === true}
                                                id="radio5"
                                                value={selectedBlock?.is_previous_allergic_reaction === true}
                                                onChange={handleIsPreviousAllergicReaction}
                                    />
                                        <label>Yes</label>
                                        </td>
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_previous_allergic_reaction"
                                                checked = {selectedBlock?.is_previous_allergic_reaction === false}
                                                id="radio5"
                                                value={selectedBlock?.is_previous_allergic_reaction === false}
                                                onChange={handleIsPreviousAllergicReaction}
                                    />
                                     <label>No</label>
                                        </td>
                                        </div>
                                        </div>
                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                  <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>6. Subjects who are investigational site staff members or family members of such employees. </div>
                                        <div className="col-md-4 mx-3">
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_subject_site_staff_member"
                                                checked = {selectedBlock?.is_subject_site_staff_member === true}
                                                id="radio7"
                                                value={selectedBlock?.is_subject_site_staff_member === true}
                                                onChange={handleIsSubjectSiteStaffMember}
                                    />
                                        <label>Yes</label>
                                        </td>
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_subject_site_staff_member"
                                                checked = {selectedBlock?.is_subject_site_staff_member === false}
                                                id="radio7"
                                                value={selectedBlock?.is_subject_site_staff_member === false}
                                                onChange={handleIsSubjectSiteStaffMember}
                                    />
                                    <label>No</label>
                                        </td>
                                        </div>
                                        </div>
                                        <div className="d-flex justify-content-between" style={{width: '100%'}}>
                  <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>7.  Exposure to any other investigational /device within 30 days prior to Visit 1. </div>
                                        <div className="col-md-4 mx-3">
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_unreliability"
                                                checked = {selectedBlock?.is_unreliability === true}
                                                id="radio8"
                                                value={selectedBlock?.is_unreliability === true}
                                                onChange={handleIsUnreliability}
                                    />
                                        <label>Yes</label>
                                        </td>
                                        <td>
                                        <input
                                                style={{ borderWidth:'1px'}}
                                                class="form-check-input  mx-2"
                                                type="radio"
                                                name="is_unreliability"
                                                checked = {selectedBlock?.is_unreliability === false}
                                                id="radio8"
                                                value={selectedBlock?.is_unreliability === false}
                                                onChange={handleIsUnreliability}
                                    />
                                     <label>No</label>
                                        </td>
                                        </div>
                                        </div>
                        </div>
                            </> :'Visit is not started' 
}
                          </fieldset>

     </>
     
}


export default Exclusion_Criteria;