import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";

const Demographics_Information_Stem_Cell = (props) => {

  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  let disable = false

  const [selectedBlock, setSelectedBlock] = useState({})
  const user = useUser();

  const ETHNICITY_CHOICES = [
    { value: 'Hispanic or Latino', label: 'Hispanic or Latino' },
    { value: 'Not Hispanic or Latino', label: 'Not Hispanic or Latino' },
    { value: 'Not willing to provide', label: 'Not willing to provide' },
  ]
  const RACE_CHOICES = [
    { value: 'White or Caucasian', label: 'White or Caucasian' },
    { value: 'Black or African American', label: 'Black or African American' },
    { value: 'Asian', label: 'Asian' },
    { value: 'American Indian or Alaska Native', label: 'American Indian or Alaska Native' },
    { value: 'Native Hawaiian or Other Pacific Islander', label: 'Native Hawaiian or Other Pacific Islander' },
    { value: 'Other', label: 'Other' },
  ]

  const handleFieldChange = (e) => {
    const { name, value, checked, type } = e.target;  
    if (type === 'checkbox') {
      setSelectedBlock((prevBlock) => ({
        ...prevBlock,
        [name]: checked
          ? [...(prevBlock[name] || []), value] 
          : prevBlock[name].filter((item) => item !== value), 
      }));
    } else {
      setSelectedBlock((prevBlock) => ({
        ...prevBlock,
        [name]: value,
      }));
    }
  };
  
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setSelectedBlock((prevBlock) => ({
      ...prevBlock,
      [name]: type === 'checkbox' ? (checked ? value : '') : value,
    }));
  };

  const Demographics_Informed_Data = () => {
    const createData = {
      'patient_id': props?.patient_id,
      'visit_number': props?.visit_number,
      'sessionId': props.selectedSessionId,
      ...selectedBlock
    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_demographic_stemcell/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        props.globalVisits(false);
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        setSelectedBlock(res.data);
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      });
  };

  const get_Demographics_Informed_Data = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_demographic_stemcell/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }
  React.useEffect(() => {
    if((props?.patient_id && props?.photo_session)){
        get_Demographics_Informed_Data();
    }
  }, [])
  if (role === 'CRO Admin' && selectedBlock?.race !== "Other") {
    setSelectedBlock(prevSelectedBlock => ({
      ...prevSelectedBlock,
      race: "Other",
    }));
  }
  return <>
    {
      (role === 'CRO Admin') || (role === 'Cro_coordinator') || (role === 'Data Manager') || (props.patient_id === undefined) ?
        disable = true : ""
    }
    <fieldset disabled={disable}>
      {
        (props.photo_session || props.patient_id === undefined) ?
          <div className='row'>
            {
              (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                <div className="col-md-12 text-end">
                  <button onClick={() => Demographics_Informed_Data()} className='btn btn-primary'>Update</button>
                </div>
              )
            }
            <p>Patients must be 18 years or older at the time of consent.</p>
            <div className="col-md-12">
              <div className='col-md-10'>
                <label><p>Ethnicity: </p></label>
                {ETHNICITY_CHOICES?.map(event => (
                  <div className='col-md-10 p-0' key={event?.value}>
                    <input
                      className="form-check-input mx-1"
                      style={{ marginLeft: "1em" }}
                      type="checkbox"
                      name='ethnicity'
                      checked={selectedBlock?.ethnicity === event?.value}
                      value={event?.value}
                      onChange={(e) => handleChange(e)}
                    />
                    <label>{event?.label}</label>
                  </div>
                ))}
              </div>
              <div className="col-md-6">
              <label><p>Race: </p></label>
              {RACE_CHOICES?.map(event => (
                <div className='col-md-10 p-0' key={event?.value}>
                  <input
                    className="form-check-input mx-1"
                    style={{ marginLeft: "1em" }}
                    type="checkbox"
                    name='race'
                    checked={selectedBlock?.race?.includes(event?.value)} 
                    value={event?.value}
                    onChange={(e) => handleFieldChange(e)}
                  />
                  <label>{event?.label}</label>
                </div>
              ))}
            </div>
            {selectedBlock?.race?.includes("Other") && (
              <div className='col-md-12'>
                <label> Specify:  </label>
                <textarea
                  style={{ width: '250px', height: '50px' }}
                  className="form-control"
                  type="text"
                  name="specify_race"
                  id="flexSwitchCheckDefault"
                  value={selectedBlock?.specify_race}
                  onChange={(e) => handleFieldChange(e)}
                ></textarea>
              </div>
            )}
            </div>
          </div>
          : 'Visit is not started'
      }
    </fieldset>
  </>
}
export default Demographics_Information_Stem_Cell;