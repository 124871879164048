import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import TextField from '@mui/material/TextField';

const Informed_Consent_Stem_Cell = (props) => {
  const [selectedBlock, setSelectedBlock] = useState({})
  const token = localStorage.getItem("token");
  const user = useUser();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  let disable = false
  const handleFieldChange = (e) => {
    const { name, checked } = e.target;
    setSelectedBlock((selectedBlock) => ({
      ...selectedBlock,
      [name]: checked,
    }));
    props.setHasChanged(true)
    };
    const handleChange = (e) => {
      const { name, value, checked, type } = e.target;
      setSelectedBlock((prevBlock) => ({
        ...prevBlock,
        [name]: type === 'checkbox' ? (checked ? value : '') : (type === 'radio' ? (value === 'true') : value),
      }));
      props.setHasChanged(true)
      };
  const Informed_Consent_Data = () => {
    const createData = {
      'patient_id': props?.patient_id,
      'visit_number': props?.visit_number,
      'sessionId': props.selectedSessionId,
      ...selectedBlock
    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_informed_consent_stemcell/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
        props.globalVisits(false);
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      });
  };

  const get_Informed_Consent_Data = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_informed_consent_stemcell/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }
  React.useEffect(() => {
    if((props?.patient_id && props?.photo_session)){
        get_Informed_Consent_Data();
    }
  }, [])
  return <>
    {
      ((role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') || (props?.patient_id === undefined)) ?
        disable = true : ''
    }
    <fieldset disabled={disable}>
      {
        (props?.photo_session || props?.patient_id == undefined) ?
          <>
            {
              (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                <div className="col-md-12 text-end">
                  <button onClick={() => Informed_Consent_Data()} className='btn btn-primary'>Update</button>
                </div>
              )
            }
            <div className='col-md-10'>
              <div className="row">
                <div className="col-md-6">
                  <label> <p>The Informed Consent Form (ICF) has been explained to this volunteer subject.</p></label>
                  <input
                    style={{ marginLeft: "1em" }}
                    type="checkbox"
                    class="form-check-input"
                    name="is_inform_consent"
                    id="is_inform_consent"
                    checked={selectedBlock?.is_inform_consent}
                    onChange={handleFieldChange}
                  >
                  </input>
                </div>
                <div>
                  <label> <p>All questions have been answered and the subject acknowledged understanding of study details.</p></label>
                  <input
                    style={{ marginLeft: "1em" }}
                    type="checkbox"
                    class="form-check-input"
                    name="is_answered"
                    id="is_answered"
                    checked={selectedBlock?.is_answered}
                    onChange={handleFieldChange}
                  >
                  </input>
                </div>
                <div>
                  <label> <p>The ICF has been signed and dated by the subject prior to the implementation of any study related procedures.</p></label>
                  <input
                    style={{ marginLeft: "1em" }}
                    type="checkbox"
                    class="form-check-input"
                    name="is_icf_signed"
                    id="is_icf_signed"
                    checked={selectedBlock?.is_icf_signed}
                    onChange={handleFieldChange}
                  >
                  </input>
                </div>
                <div>
                  <label> <p>The signed/dated original ICF will be maintained with the subject’s records, and a copy of the signed/dated ICF has been provided to the subject.</p></label>
                  <input
                    style={{ marginLeft: "1em" }}
                    type="checkbox"
                    class="form-check-input"
                    name="signed_date_provided"
                    id="signed_date_provided"
                    checked={selectedBlock?.signed_date_provided}
                    onChange={handleFieldChange}
                  >
                  </input>
                </div>
                <div className="col-md-9">
                  <label> Has the subject signed an IRB approved ICF, to include HIPAA Authorization?</label>
                  <th scope="row">
                  </th>
                </div>
                <div className="col-md-1">
                  <p><small>Yes
                  <input
                        type="radio"
                        className="form-check-input mx-2"
                        name="is_irb_approved"
                        id="is_irb_approved_yes"
                        checked={selectedBlock?.is_irb_approved === true || (!selectedBlock && selectedBlock?.is_irb_approved === undefined)}
                        value={true}
                        onChange={handleChange}
                        key="is_irb_approved_yes"
                      />
                  </small>
                  </p>
                </div>
                <div className="col-md-1">
                  <p><small> No
                  <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_irb_approved"
                      checked={selectedBlock?.is_irb_approved === false || (!selectedBlock && selectedBlock?.is_irb_approved === undefined)}
                      id="is_irb_approved_no"
                      value={false}
                      onChange={handleChange}
                      key="is_irb_approved_no"
                    />
                  </small>
                  </p>
                </div>
                <div className="col-md-2">
                      <TextField
                          id="outlined-number"
                          label="Version #"
                          type="string"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="version_number"
                          onChange={handleChange}
                          value={selectedBlock?.version_number}
                      />
                  </div>
                  <div className="col-md-6 calendarIcon">
                                 <TextField
                                  id="outlined-number"
                                  label="Consent Date"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                  type="date"
                                  name="consent_date"
                                  timeFormat={false}
                                  value={selectedBlock?.consent_date}
                                  inputProps={{placeholder:'mm/dd/yy', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined) }}
                              >
                              </TextField>
                    </div>
              </div>
            </div>
          </> : 'Visit is not started'
      }
    </fieldset>
  </>
}
export default Informed_Consent_Stem_Cell;