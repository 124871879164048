import NavBar from "../../components/navBar/navBar";
import PatientMenu from "../../Pages/Patient/PatientMenu";
import Breadcrumbs from "../../components/breadcrumbs";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Pane, FileUploader, FileCard } from "evergreen-ui";
import { Card } from "@mui/material";
import MainAccordion from "../Accordion/accordion";
import { toast, ToastContainer } from "react-toastify";
import MessagePopUp from "../../components/PopUp/MessagePopUp";
import ReactPaginate from "react-paginate";

const UploadDocuments = () => {
  const { _id_ } = useParams();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const token = localStorage.getItem("token");
  const [infoData, setInfoData] = useState({});
  const [allTrial, setAllTrial] = useState([]);
  const [allSessions, setAllSessions] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [uploadDocs, setUploadDocs] = useState({});
  const [search, setSearch] = useState("");
  const [files, setFiles] = useState([]);
  const [fileRejections, setFileRejections] = useState([]);
  const [clinicDisable, setClinicDisable] = useState(false);
  const [trialDisable, setTrialDisable] = useState(false);
  const [popMessage, setPopMessage] = useState(false);
  const [documentToDeleteId, setDocumentToDeleteId] = useState();
  const [IsLoadingButton, setIsLoadingButton] = useState(false);
  const [Loader, setLoader] = useState(false);

  const DeleteDocument = (id) => {
    setDocumentToDeleteId(id);
    setPopMessage(true);
  };

  const handleChange = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRejected = (fileRejections) => {
    setFileRejections([...fileRejections]);
  };

  const handleRemove = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
    setFileRejections(
      fileRejections.filter((rejection) => rejection.file !== fileToRemove)
    );
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setUploadDocs({
      ...uploadDocs,
      [name]: value,
    });

    if (role !== "Clinic Admin" && role !== "Clinic Owner") {
      if (name === "trial_id" && value) {
        setClinicDisable(true);
      } else if (name === "clinics" && value) {
        setTrialDisable(true);
      } else if (name === "visit_id" && value) {
        setClinicDisable(true);
      } else if (name === "comment" && value) {
        setClinicDisable(true);
      } else {
        setClinicDisable(false);
        setTrialDisable(false);
      }
    }
  };
  const UploadAdditionalDocument = (event) => {
    setIsLoadingButton(true);
    event.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append(
      "clinic",
      uploadDocs?.clinics !== undefined ? uploadDocs?.clinics : ""
    );
    formData.append(
      "trial",
      uploadDocs?.trial_id !== undefined ? uploadDocs?.trial_id : ""
    );
    formData.append(
      "comment",
      uploadDocs?.comment !== undefined ? uploadDocs?.comment : ""
    );
    formData.append(
      "visit",
      uploadDocs?.visit_id !== undefined ? uploadDocs?.visit_id : ""
    );
    formData.append("patient", _id_);
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    fetch(
      `${__API_URL__}/api/patient/patient/create_additional_docs/`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          toast.error("Select a Clinic or Trial");
        } else {
          toast.success("File Uploaded Successfully");
          setIsLoadingButton(false);
          GetDocuments();
          setUploadDocs((prev) => ({
            ...prev,
            clinics: "",
            trial_id: "",
            visit_id: "",
            comment: "",
          }));
          setFiles([]);
          setTrialDisable(false);
          setClinicDisable(false);
        }
        return response.json();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  const SinglePatient = () => {
    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      });
  };

  const GetAllTrials = () => {
    axios
      .get(`${__API_URL__}/api/photos/get_patient_trials/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAllTrial(res.data);
      });
  };

  const GetAllFilterVisit = (trialId) => {
    axios
      .get(
        `${__API_URL__}/api/patient/patient/get_patient_sessions/?trialId=${trialId}&patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setAllSessions(res.data);
      })
      .catch((error) => {
        console.error("Error fetching visits:", error);
      });
  };

  const GetDocuments = () => {
    setLoader(true);
    axios
      .get(
        `${__API_URL__}/api/patient/patient/get_additional_docs/?document_type=${search}&patient=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setLoader(false);
        setDocumentList(res.data);
      })
      .catch((err) => {
        if (err === "Error: Request failed with status code 401") {
        }
      });
  };
  const handlePageClick = (event) => {
    const no = event.selected + 1;
    setLoader(true);
    axios
      .get(
        `${__API_URL__}/api/patient/patient/get_additional_docs/?document_type=${search}&patient=${_id_}&page=${no}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setLoader(false);
        setDocumentList(res.data);
      })
      .catch((err) => {
        if (err === "Error: Request failed with status code 401") {
        }
      });
  };
  const confirmDelete = () => {
    if (documentToDeleteId) {
      axios
        .delete(
          `${__API_URL__}/api/patient/patient/delete_additional_doc/${documentToDeleteId}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then(() => {
          toast.success("File Deleted Successfully");
          GetDocuments();
          setDocumentToDeleteId(0);
        })
        .catch(() => {
          toast.error("Something Went Wrong");
        })
        .finally(() => {
          setPopMessage(false);
        });
    }
  };
  function TruncateFileName({ fileName, maxLength }) {
    if (fileName.length <= maxLength) {
      return <span>{fileName}</span>;
    }
    const extIndex = fileName.lastIndexOf(".");
    const truncatedFileName = `${fileName.slice(
      0,
      maxLength - 4
    )}...${fileName.slice(extIndex + 1)}`;

    return <span title={fileName}>{truncatedFileName}</span>;
  }
  const maxLength = 20;

  useEffect(() => {
    SinglePatient();
    GetAllTrials();
  }, []);
  useEffect(() => {
    GetDocuments();
  }, [search]);

  useEffect(() => {
    if (role === "Clinic Admin" || role === "Clinic Owner") {
      setTrialDisable(true);
    }
  }, [role]);
  return (
    <form>
      <ToastContainer />
      <NavBar active="patient" />
      <div className="container-fluid">
        <div className="patient_table patient_photos">
          <Breadcrumbs
            B="Additional Documents"
            F={infoData?.first_name}
            L={infoData?.last_name}
            E={infoData?.email}
            P={infoData?.phone}
            participant_id={infoData?.participant_id}
            age={infoData?.age}
            birth_date={infoData?.birth_date}
          ></Breadcrumbs>
          <PatientMenu active="documents" data={infoData} />
          <div
            className="my-2"
            style={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <MainAccordion
              title="Uploaded Document"
              isOpen={true}
              data={
                <>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 text-end">
                        {(role === "Investigator" ||
                          role === 'Sub_investigator' ||
                          role === "Inv_coordinator" ||
                          role === "Clinic Admin" ||
                          role === "Clinic Owner" ||
                          role === "CRO Admin" ||
                          role === "Cro_coordinator" ||
                          role === "Data Manager" ||
                          role === "Super Admin") && (
                          <button
                            type="button"
                            className={`btn btn-primary btn-sm m-4${
                              IsLoadingButton ? " btn-loading" : ""
                            }`}
                            disabled={IsLoadingButton}
                            onClick={UploadAdditionalDocument}
                          >
                            {IsLoadingButton ? (
                              <>
                                <i class="fa fa-spinner fa-spin"></i> Loading
                              </>
                            ) : (
                              "Upload"
                            )}
                          </button>
                        )}
                      </div>
                      <div className="col-md-8 mx-auto form-group mx-3">
                        <label htmlFor="FormControlSelect1">Clinics:</label>
                        <select
                          className="form-control"
                          name="clinics"
                          id="FormControlSelect1"
                          disabled={clinicDisable}
                          value={uploadDocs?.clinics}
                          onChange={(e) => handleFieldChange(e)}
                        >
                          <option value=""> Select </option>
                          <option value={infoData?.clinic?.id}>
                            {infoData?.clinic?.name}
                          </option>
                        </select>
                      </div>
                      <div className="col-md-8 mx-auto form-group">
                        <label htmlFor="FormControlSelect2">Trial:</label>
                        <select
                          className="form-control"
                          id="FormControlSelect2"
                          name="trial_id"
                          disabled={trialDisable}
                          onChange={(e) => {
                            handleFieldChange(e);
                            GetAllFilterVisit(e.target.value);
                          }}
                          value={uploadDocs?.trial_id}
                        >
                          <option value="">Select</option>
                          {allTrial?.map((trial) => (
                            <option value={trial.id} key={trial.id}>
                              {trial.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {uploadDocs?.trial_id && (
                        <div className="col-md-8 mx-auto form-group">
                          <label htmlFor="FormControlSelect3">Visit:</label>
                          <select
                            className="form-control"
                            id="FormControlSelect3"
                            name="visit_id"
                            onChange={(e) => handleFieldChange(e)}
                            value={uploadDocs?.visit_id}
                          >
                            <option value="">Select</option>
                            {allSessions?.map((visit) => (
                              <option value={visit.id} key={visit.id}>
                                {
                                  (visit.visit_name && visit.visit_name !== "") ?
                                  visit.visit_name :   
                                  visit?.visit_number === 0
                                  ? "Baseline Visit"
                                  : visit?.visit_number === -3
                                  ? "Screening Visit"
                                  : visit?.visit_number === -4
                                  ? "Other Ecrfs"
                                  : visit?.visit_number === -1
                                  ? "Adverse Event"
                                  : visit?.visit_number === -2
                                  ? "Unscheduled Event" 
                                  : visit?.visit_number ?
                                  `Visit ${visit?.visit_number}`:""
                                }
                              </option>
                            ))}
                          </select>
                          <label>Comment</label>
                          <textarea
                            class="form-control"
                            name="comment"
                            rows={4}
                            type="text"
                            value={uploadDocs?.comment}
                            onChange={(e) => handleFieldChange(e)}
                          ></textarea>
                        </div>
                      )}
                    </div>
                    <div className="col-md-5 mx-auto form-group my-4">
                      <Pane>
                        <FileUploader
                          label="Upload Files"
                          description="You can upload multiple files. Each file can be up to 50 MB."
                          maxSizeInBytes={50 * 1024 ** 2}
                          onChange={handleChange}
                          onRejected={handleRejected}
                          renderFile={(file) => {
                            const { name, size, type } = file;
                            const fileRejection = fileRejections.find(
                              (fileRejection) => fileRejection.file === file
                            );
                            const { message } = fileRejection || {};
                            return (
                              <FileCard
                                key={name}
                                isInvalid={fileRejection != null}
                                name={name}
                                onRemove={() => handleRemove(file)}
                                sizeInBytes={size}
                                type={type}
                                validationMessage={message}
                              />
                            );
                          }}
                          values={files}
                        />
                      </Pane>
                    </div>
                  </div>
                </>
              }
            />
          </div>
          <MainAccordion
            title="Document List"
            isOpen={true}
            data={
              <Card className="col-md-12 my-3">
                {Loader ? (
                  <div className="loader">
                    <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                  </div>
                ) : (
                  <div className="row border" key="" style={{ padding: "1em" }}>
                    <div className="borderless table-wrapper-scroll-y my-custom-scrollbar">
                      <table className="table table-bordered table-striped mb-0 table-sm">
                        <thead>
                          <tr>
                            <th>File Name</th>
                            <th>
                              <>
                                <select
                                  className="form-control"
                                  name="category"
                                  value={search}
                                  onChange={(e) => setSearch(e.target.value)}
                                >
                                  <option value="">Category</option>
                                  <option value="Clinic">Clinic</option>
                                  <option value="Trial">Trial</option>
                                </select>
                              </>
                            </th>
                            <th>Updated By</th>
                            <th>Updated at</th>
                            <th>Created at</th>
                            <th>Comment</th>
                            <th>Download</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documentList?.results?.map((item, index) => (
                            <>
                              <tr key={index}>
                                <td style={{ whiteSpace: "pre-wrap" }}>
                                  <TruncateFileName
                                    fileName={item?.file_name}
                                    maxLength={maxLength}
                                  />
                                </td>
                                <td style={{ whiteSpace: "pre-wrap" }}>{`${
                                  item.trial ? "Trial" : "Clinic"
                                }`}</td>
                                <td style={{ whiteSpace: "pre-wrap" }}>
                                  {item.updated_by}
                                </td>
                                <td>
                                  {new Date(
                                    item.updated_at + "Z"
                                  ).toDateString()}
                                  ,&nbsp;
                                  {new Date(
                                    item.updated_at + "Z"
                                  ).toLocaleTimeString()}
                                </td>
                                <td>
                                  {new Date(
                                    item.created_at + "Z"
                                  ).toDateString()}
                                  ,&nbsp;
                                  {new Date(
                                    item.created_at + "Z"
                                  ).toLocaleTimeString()}
                                </td>
                                <td style={{ whiteSpace: "pre-wrap" }}>{`${
                                  item.comment || "-"
                                }`}</td>
                                <td style={{ whiteSpace: "pre-wrap" }}>
                                  <a
                                    href={`${item.document}`}
                                    target="_blank"
                                    download
                                  >
                                  <i className="fa fa-arrow-down" style={{ color: 'purple' }}></i>
                                  </a>
                                </td>
                                <td style={{ whiteSpace: "pre-wrap" }}>
                                  <a
                                    type="button"
                                    onClick={() => DeleteDocument(item?.id)}
                                  >
                                  <i className="fa fa-trash" style={{ color: 'navy' }}></i>
                                  </a>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                <div className="tableHeader">
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        Showing{" "}
                        {documentList ? documentList.results?.length : 0} out of{" "}
                        {documentList ? documentList.count : 0}
                      </p>
                    </div>

                    <div className="reactPagination col-md-6">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={Math.ceil(documentList.count) / 10}
                        previousLabel="previous"
                      />
                    </div>
                  </div>
                </div>
              </Card>
            }
          />
          {popMessage && (
            <MessagePopUp
              setPopMessage={setPopMessage}
              confirmDelete={confirmDelete}
            ></MessagePopUp>
          )}
        </div>
      </div>
    </form>
  );
};

export default UploadDocuments;
