import { useState } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const AddProductModal = (props) => {
  const [productData, setProductData] = useState({
    productName: "",
    GenericName: "",
    productType: "",
    productUnits: "",
    statusVal: "",
    stockUnits: 0,
    LowWaterMark: 10,
  });

  const resetstate = () => {
    setProductData({
      ...productData,
      productName: "",
      GenericName: "",
      productType: "",
      productUnits: "",
      statusVal: "",
      stockUnits: 0,
      LowWaterMark: 10,
    });
  };

  const handleNameChange = (e) => {
    setProductData({
      ...productData,
      productName: e.target.value,
    });
  };

  const handleGenericChange = (e) => {
    setProductData({
      ...productData,
      GenericName: e.target.value,
    });
  };

  const handleCurretunitChange = (e) => {
    setProductData({
      ...productData,
      stockUnits: e.target.value,
    });
  };

  const handleWaterMarkChange = (e) => {
    setProductData({
      ...productData,
      LowWaterMark: e.target.value,
    });
  };

  const handleProductChange = (e) => {
    setProductData({
      ...productData,
      productType: e.target.value,
    });
  };

  const handleunitChange = (e) => {
    setProductData({
      ...productData,
      productUnits: e.target.value,
    });
  };

  const handleStatusChange = (e) => {
    setProductData({
      ...productData,
      statusVal: e.target.value,
    });
  };
  
  const handleSendInfo = () => {
    props.ProductList(productData);
    resetstate();
    props.onHide();
  };

  let optionsArray = ["Neuromodulators", "Dosage", "Dilution", "fillers"];
  let unitsArray = ["Lbs", "kgs"];
  const CreateInstance = (e) => {};
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="addProduct">
          <form onSubmit={CreateInstance}>
            <label>Product Name</label>
            <input
              type="text"
              onChange={handleNameChange}
              name="productName"
              value={productData.productName}
              className="form-control"
            />
            <label>Generic Name</label>
            <input
              type="text"
              onChange={handleGenericChange}
              name="genericName"
              value={productData.GenericName}
              className="form-control"
            />
            <label>Product type</label>
            <select
              id="language"
              value={productData.productType}
              onChange={handleProductChange}
              className="form-control"
            >
              <option>Select Type</option>
              {optionsArray.map((item, index) => (
                <option value={item} Key={index}>
                  {item}
                </option>
              ))}
            </select>
            <label>Units of Product</label>
            <select
              id="language"
              value={productData.productUnits}
              onChange={handleunitChange}
              className="form-control"
            >
              <option value="">Select Type</option>
              {unitsArray.map((item, index) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            <label>Current no of units in stock</label>
            <input
              type="number"
              onChange={handleCurretunitChange}
              name="current-units"
              className="form-control"
            />
            <label>Low water mark</label>
            <input
              type="number"
              onChange={handleWaterMarkChange}
              name="cusData"
              className="form-control"
              value={productData.LowWaterMark}
            />
            <label>Status</label>
            <select
              value={productData.statusVal}
              onChange={handleStatusChange}
              className="form-control"
            >
              <option>Select</option>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <Button onClick={handleSendInfo}>Send</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProductModal;
