import * as React from "react";
import {
  ViewState,
  EditingState,
  GroupingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  MonthView,
  WeekView,
  DayView,
  Appointments,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  AllDayPanel,
  AppointmentTooltip,
  AppointmentForm,
  ConfirmationDialog,
  TodayButton,
  Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import { connectProps } from "@devexpress/dx-react-core";
import { styled } from "@mui/material/styles";
import PriorityHigh from "@mui/icons-material/PriorityHigh";
import LowPriority from "@mui/icons-material/LowPriority";
import Lens from "@mui/icons-material/Lens";
import Event from "@mui/icons-material/Event";
import AccessTime from "@mui/icons-material/AccessTime";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { NoteOutlined, LocalHospital, Phone, Tag } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import classNames from "clsx";

import NavBar from "../components/navBar/navBar";
import useCalendar from "../hooks/useCalendar";
import { Link } from "react-router-dom";

const grouping = [
  {
    resourceName: "practitioner",
  },
];

const filterTasks = (items, practitioner) => {
  if (practitioner != 0) {
    return items.filter(
      (task) => !practitioner || task.practitioner == practitioner
    );
  } else {
    return items;
  }
};

const formatData = (data) => {
  return data.map(
    ({
      time: startDate,
      duration,
      patient_details,
      practitioner_details,
      ...rest
    }) => ({
      startDate: new Date(startDate + ".000Z"),
      duration,
      endDate: new Date(
        new Date(startDate + ".000Z").getTime() + duration * 1000 * 60
      ),
      title:
        patient_details.first_name +
        " " +
        patient_details.last_name +
        "\n" +
        practitioner_details.name,
      patient_details,
      ...rest,
    })
  );
};

// const getIconById = (id) => {
//   if (id == 1) {
//     return LowPriority;
//   }
//   if (id == 2) {
//     return Event;
//   }
//   return PriorityHigh;
// };

const PREFIX = "Demo";
const classes = {
  flexibleSpace: `${PREFIX}-flexibleSpace`,
  doctorSelector: `${PREFIX}-doctorSelector`,
  content: `${PREFIX}-content`,
  contentContainer: `${PREFIX}-contentContainer`,
  text: `${PREFIX}-text`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  contentItemIcon: `${PREFIX}-contentItemIcon`,
  grayIcon: `${PREFIX}-grayIcon`,
  colorfulContent: `${PREFIX}-colorfulContent`,
  lens: `${PREFIX}-lens`,
  textCenter: `${PREFIX}-textCenter`,
  dateAndTitle: `${PREFIX}-dateAndTitle`,
  titleContainer: `${PREFIX}-titleContainer`,
  container: `${PREFIX}-container`,
  bullet: `${PREFIX}-bullet`,
  doctorSelectorItem: `${PREFIX}-doctorSelectorItem`,
  doctorText: `${PREFIX}-doctorText`,
  doctorShortText: `${PREFIX}-doctorShortText`,
  cellLowdoctor: `${PREFIX}-cellLowdoctor`,
  cellMediumdoctor: `${PREFIX}-cellMediumdoctor`,
  cellHighdoctor: `${PREFIX}-cellHighdoctor`,
  headerCellLowdoctor: `${PREFIX}-headerCellLowdoctor`,
  headerCellMediumdoctor: `${PREFIX}-headerCellMediumdoctor`,
  headerCellHighdoctor: `${PREFIX}-headerCellHighdoctor`,
  badge: `${PREFIX}-badge`,
};
// const stylesByDoctor = priorities.reduce(
//   (acc, doctor) => ({
//     ...acc,
//     [`cell${doctor.text.replace(" ", "")}`]: {
//       backgroundColor: alpha(doctor.color[400], 0.1),
//       "&:hover": {
//         backgroundColor: alpha(doctor.color[400], 0.15),
//       },
//       "&:focus": {
//         backgroundColor: alpha(doctor.color[400], 0.2),
//       },
//     },
//     [`headerCell${doctor.text.replace(" ", "")}`]: {
//       backgroundColor: alpha(doctor.color[400], 0.1),
//       "&:hover": {
//         backgroundColor: alpha(doctor.color[400], 0.1),
//       },
//       "&:focus": {
//         backgroundColor: alpha(doctor.color[400], 0.1),
//       },
//     },
//   }),
//   {}
// );
// const groupingStyles = ({ theme }) => ({
//   [`&.${classes.cellLowDoctor}`]: stylesByDoctor.cellLowDoctor,
//   [`&.${classes.cellMediumDoctor}`]: stylesByDoctor.cellMediumDoctor,
//   [`&.${classes.cellHighDoctor}`]: stylesByDoctor.cellHighDoctor,
//   [`&.${classes.headerCellLowDoctor}`]:
//     stylesByDoctor.headerCellLowDoctor,
//   [`&.${classes.headerCellMediumDoctor}`]:
//     stylesByDoctor.headerCellMediumDoctor,
//   [`&.${classes.headerCellHighDoctor}`]:
//     stylesByDoctor.headerCellHighDoctor,
//   [`& .${classes.icon}`]: {
//     paddingLeft: theme.spacing(1),
//     verticalAlign: "middle",
//   },
// });

const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
  [`&.${classes.flexibleSpace}`]: {
    margin: "0 auto 0 0",
  },
}));

const StyledFormControl = styled(FormControl)(({ theme: { spacing } }) => ({
  [`&.${classes.doctorSelector}`]: {
    minWidth: 140,
    marginLeft: spacing(2),
    "@media (max-width: 500px)": {
      minWidth: 0,
      fontSize: "0.75rem",
      marginLeft: spacing(0.5),
    },
  },
}));

const StyledDoctorSelectorItem = styled("div")(
  ({ theme: { palette, spacing }, color }) => ({
    [`& .${classes.bullet}`]: {
      backgroundColor: color ? color[400] : palette.divider,
      borderRadius: "50%",
      width: spacing(2),
      height: spacing(2),
      marginRight: spacing(2),
      display: "inline-block",
    },
    [`&.${classes.doctorSelectorItem}`]: {
      display: "flex",
      alignItems: "center",
    },
    [`& .${classes.doctorText}`]: {
      "@media (max-width: 500px)": {
        display: "none",
      },
    },
    [`& .${classes.doctorShortText}`]: {
      "@media (min-width: 500px)": {
        display: "none",
      },
    },
  })
);
// const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(
//   groupingStyles
// );
const StyledTooltipContent = styled("div")(
  ({ theme: { spacing, typography, palette }, color }) => ({
    [`&.${classes.content}`]: {
      padding: spacing(3, 1),
      paddingTop: 0,
      backgroundColor: palette.background.paper,
      boxSizing: "border-box",
      width: "400px",
    },
    [`& .${classes.contentContainer}`]: {
      paddingBottom: spacing(1.5),
    },
    [`& .${classes.text}`]: {
      ...typography.body2,
      display: "inline-block",
    },
    [`& .${classes.title}`]: {
      ...typography.h6,
      color: palette.text.secondary,
      fontWeight: typography.fontWeightBold,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    },
    [`& .${classes.icon}`]: {
      verticalAlign: "middle",
    },
    [`& .${classes.contentItemIcon}`]: {
      textAlign: "center",
    },
    [`& .${classes.grayIcon}`]: {
      color: palette.action.active,
    },
    [`& .${classes.colorfulContent}`]: {
      color: color[300],
    },
    [`& .${classes.lens}`]: {
      width: spacing(4.5),
      height: spacing(4.5),
      verticalAlign: "super",
    },
    [`& .${classes.textCenter}`]: {
      textAlign: "center",
    },
    [`& .${classes.dateAndTitle}`]: {
      lineHeight: 1.1,
    },
    [`& .${classes.titleContainer}`]: {
      paddingBottom: spacing(2),
    },
    [`& .${classes.container}`]: {
      paddingBottom: spacing(1.5),
    },
    [`& .${classes.badge}`]: {
      borderRadius: "50px",
      backgroundColor: palette.text.primary,
      padding: "3px 12px",
      margin: "5px",
      color: palette.background.paper,
      fontSize: typography.fontSize,
    },
  })
);

// const StyledDayViewDayScaleCell = styled(DayView.DayScaleCell)(groupingStyles);

// const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(
//   groupingStyles
// );

// const StyledAllDayPanelCell = styled(AllDayPanel.Cell)(groupingStyles);

// const StyledGroupingPanelCell = styled(GroupingPanel.Cell)(groupingStyles);

// const StyledDayViewTimeTableCell = styled(DayView.TimeTableCell)(
//   groupingStyles
// );

// const DayViewTimeTableCell = ({ groupingInfo, ...restProps }) => {
//   const groupId = groupingInfo[0].id;
//   return (
//     <StyledDayViewTimeTableCell
//       className={classNames({
//         [classes.cellLowDoctor]: groupId == 1,
//         [classes.cellMediumDoctor]: groupId == 2,
//         [classes.cellHighDoctor]: groupId == 3,
//       })}
//       groupingInfo={groupingInfo}
//       {...restProps}
//     />
//   );
// };
// const DayViewDayScaleCell = ({ groupingInfo, ...restProps }) => {
//   const groupId = groupingInfo[0].id;
//   return (
//     <StyledDayViewDayScaleCell
//       className={classNames({
//         [classes.headerCellLowDoctor]: groupId == 1,
//         [classes.headerCellMediumDoctor]: groupId == 2,
//         [classes.headerCellHighDoctor]: groupId == 3,
//       })}
//       groupingInfo={groupingInfo}
//       {...restProps}
//     />
//   );
// };
// const WeekViewTimeTableCell = ({ groupingInfo, ...restProps }) => {
//   const groupId = groupingInfo[0].id;
//   return (
//     <StyledWeekViewTimeTableCell
//       className={classNames({
//         [classes.cellLowDoctor]: groupId == 1,
//         [classes.cellMediumDoctor]: groupId == 2,
//         [classes.cellHighDoctor]: groupId == 3,
//       })}
//       groupingInfo={groupingInfo}
//       {...restProps}
//     />
//   );
// };
// const WeekViewDayScaleCell = ({ groupingInfo, ...restProps }) => {
//   const groupId = groupingInfo[0].id;
//   return (
//     <StyledWeekViewDayScaleCell
//       className={classNames({
//         [classes.headerCellLowDoctor]: groupId == 1,
//         [classes.headerCellMediumDoctor]: groupId == 2,
//         [classes.headerCellHighDoctor]: groupId == 3,
//       })}
//       groupingInfo={groupingInfo}
//       {...restProps}
//     />
//   );
// };
// const AllDayCell = ({ groupingInfo, ...restProps }) => {
//   const groupId = groupingInfo[0].id;
//   return (
//     <StyledAllDayPanelCell
//       className={classNames({
//         [classes.cellLowDoctor]: groupId == 1,
//         [classes.cellMediumDoctor]: groupId == 2,
//         [classes.cellHighDoctor]: groupId == 3,
//       })}
//       groupingInfo={groupingInfo}
//       {...restProps}
//     />
//   );
// };
// const GroupingPanelCell = ({ group, ...restProps }) => {
//   const groupId = group.id;
//   const Icon = getIconById(groupId);
//   return (
//     <StyledGroupingPanelCell
//       className={classNames({
//         [classes.headerCellLowDoctor]: groupId == 1,
//         [classes.headerCellMediumDoctor]: groupId == 2,
//         [classes.headerCellHighDoctor]: groupId == 3,
//       })}
//       group={group}
//       {...restProps}
//     >
//       {/* <Icon className={classes.icon} /> */}
//     </StyledGroupingPanelCell>
//   );
// };

const DoctorSelectorItem = ({ color, text: resourceTitle }) => {
  const text = resourceTitle || "All Practitioners";
  const shortText = resourceTitle ? text.substring(0, 1) : "All";

  return (
    <StyledDoctorSelectorItem
      className={classes.doctorSelectorItem}
      color={color}
    >
      <span className={classes.bullet} />
      <span className={classes.doctorText}>{text}</span>
      <span className={classes.doctorShortText}>{shortText}</span>
    </StyledDoctorSelectorItem>
  );
};

const TooltipContent = ({
  appointmentData,
  formatDate,
  appointmentResources,
}) => {
  const resource = appointmentResources[0];
  let icon = <LowPriority className={classes.icon} />;
  if (appointmentData.practitioner == 2) {
    icon = <Event className={classes.icon} />;
  }
  if (appointmentData.practitioner == 3) {
    icon = <PriorityHigh className={classes.icon} />;
  }
  return (
    <StyledTooltipContent
      className={classes.content}
      color={resource ? resource.color : "#555"}
    >
      <Grid
        container
        alignItems="flex-start"
        className={classes.titleContainer}
      >
        <Grid item xs={2} className={classNames(classes.textCenter)}>
          <Lens className={classNames(classes.lens, classes.colorfulContent)} />
        </Grid>
        <Grid item xs={10}>
          <div>
            <Link
              to={`/patient/${appointmentData.patient_details.id}/appointment/${appointmentData.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className={classNames(classes.title, classes.dateAndTitle)}>
                {appointmentData.title}
              </div>
            </Link>

            <div className={classNames(classes.text, classes.dateAndTitle)}>
              {formatDate(appointmentData.startDate, {
                day: "numeric",
                weekday: "long",
              })}
            </div>
          </div>
        </Grid>
      </Grid>

      {appointmentData.appointment_type_list.length > 0 && (
        <Grid
          container
          alignItems="flex-start"
          overflow="scroll"
          className={classes.contentContainer}
        >
          <Grid items xs={2}>
            {appointmentData.appointment_type_list.map((type, i) => (
              <>
                {type.appointment_type != null && (
                  <span className={classes.badge} key={i}>
                    {type.appointment_type.title}
                  </span>
                )}
              </>
            ))}
          </Grid>
        </Grid>
      )}

      {appointmentData.patient_details && (
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid item xs={2} className={classes.textCenter}>
            <Phone className={classes.icon} />
          </Grid>
          <Grid item xs={10}>
            <span className={classNames(classes.text)}>
              {appointmentData.patient_details.phone_number}
            </span>
          </Grid>
        </Grid>
      )}

      <Grid container alignItems="center" className={classes.contentContainer}>
        <Grid item xs={2} className={classes.textCenter}>
          <AccessTime className={classes.icon} />
        </Grid>
        <Grid item xs={10}>
          <div className={classes.text}>
            {`${formatDate(appointmentData.startDate, {
              hour: "numeric",
              minute: "numeric",
            })}
              - ${formatDate(appointmentData.endDate, {
                hour: "numeric",
                minute: "numeric",
              })}`}
          </div>
        </Grid>
      </Grid>
      {resource && (
        <Grid
          container
          alignItems="center"
          key={`${resource.title}_${resource.id}`}
        >
          <Grid
            className={classNames(classes.contentItemIcon, classes.icon)}
            item
            xs={2}
          >
            {icon}
          </Grid>
          <Grid item xs={10}>
            <span className={classNames(classes.text)}>
              {appointmentData.clinic_dict.name}
            </span>
          </Grid>
        </Grid>
      )}

      {appointmentData.notes && (
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid
            className={classNames(classes.contentItemIcon, classes.icon)}
            item
            xs={2}
          >
            <NoteOutlined />
          </Grid>
          <Grid item xs={10}>
            <div className={classes.text}>{appointmentData.notes}</div>
          </Grid>
        </Grid>
      )}

      {appointmentData.status && (
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid
            className={classNames(
              classes.contentItemIcon,
              classes.icon,
              classes.colorfulContent
            )}
            item
            xs={2}
          >
            <Tag />
          </Grid>
          <Grid item xs={10}>
            <div className={classNames(classes.text, classes.colorfulContent)}>
              {appointmentData.status}
            </div>
          </Grid>
        </Grid>
      )}

      {appointmentData.practitioner_details && (
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid
            className={classNames(
              classes.contentItemIcon,
              classes.icon,
              classes.colorfulContent
            )}
            item
            xs={2}
          >
            <LocalHospital />
          </Grid>
          <Grid item xs={10}>
            <div className={classNames(classes.text, classes.colorfulContent)}>
              {appointmentData.practitioner_details.name}
            </div>
          </Grid>
        </Grid>
      )}
    </StyledTooltipContent>
  );
};

export default function Calendar() {
  const [currentDate, setCurrentDate] = React.useState(
    new Date().toDateString()
  );
  const [currentViewName, setCurrentViewName] = React.useState("Week");
  const [data, setData] = React.useState();
  const [priorities, setPriorities] = React.useState();
  const [currentDoctor, setCurrentDoctor] = React.useState(0);
  const [resources, setResources] = React.useState([]);
  const [appointmentId, setAppointmentId] = React.useState(null);

  // const selectedAppoinment = React.useMemo(() => {
  //   if (appointmentId) {
  //     return data.find((appoinment) => appoinment.id == appointmentId);
  //   } else {
  //     return null;
  //   }
  //   // eslint-disable-next-line
  // }, [appointmentId]);

  const {
    appointmentData,
    practitioners,
    getAppointment,
    getPractitioners,
    updateAppointment,
  } = useCalendar();

  React.useEffect(() => {
    flexibleSpace.update();
    getAppointment();
    getPractitioners();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (appointmentData != undefined && practitioners != undefined) {
      setData(formatData(appointmentData));
      setPriorities(practitioners);
      setResources([
        ...resources,
        {
          title: "Praction",
          instances: practitioners,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [appointmentData, practitioners]);

  const DoctorSelector = ({ doctorChange, doctor }) => {
    const currentDoctor =
      doctor > 0
        ? priorities.filter((priority) => priority.id == doctor)[0]
        : {};

    return (
      <StyledFormControl className={classes.doctorSelector} variant="standard">
        <Select
          disableUnderline
          value={doctor}
          onChange={(e) => {
            doctorChange(e.target.value);
          }}
          renderValue={() => (
            <DoctorSelectorItem
              text={currentDoctor.name}
              color={currentDoctor.color}
            />
          )}
        >
          <MenuItem value={0}>
            <DoctorSelectorItem />
          </MenuItem>
          {priorities.map(({ id, color, name }) => (
            <MenuItem value={id} key={id.toString()}>
              <DoctorSelectorItem color={color} text={name} />
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    );
  };

  const FlexibleSpace = ({ doctor, doctorChange, ...restProps }) => (
    <StyledToolbarFlexibleSpace
      {...restProps}
      className={classes.flexibleSpace}
    >
      <DoctorSelector doctor={doctor} doctorChange={doctorChange} />
    </StyledToolbarFlexibleSpace>
  );
  const currentViewNameChange = (viewName) => {
    setCurrentViewName(viewName);
  };

  const currentDateChange = (date) => {
    setCurrentDate(date);
  };

  const doctorChange = (value) => {
    setCurrentDoctor(value);
    setResources([
      ...resources[0],
      priorities.filter((priority) => priority.id == value),
    ]);
  };

  const flexibleSpace = connectProps(FlexibleSpace, () => {
    return {
      doctor: currentDoctor,
      doctorChange: doctorChange,
    };
  });

  const addZeroBefore = (value) => {
    return parseInt(value) < 10 ? "0" + value : value;
  };

  const commitChanges = ({ changed }) => {
    console.log("changed", changed);
    var id = parseInt(Object.keys(changed)[0]);
    // setAppointmentId(id);
    const obj = {
      ...data.find((appoinment) => appoinment.id == id),
    };

    Object.keys(changed[id]).forEach((key) => (obj[key] = changed[id][key]));
    console.log(obj);

    try {
      var time = obj.startDate;
      var duration =
        (parseInt(obj.endDate.getTime()) - parseInt(time.getTime())) / 60000;
      obj.time = `${time.getFullYear()}-${addZeroBefore(
        time.getMonth()
      )}-${addZeroBefore(time.getDay())}T${addZeroBefore(
        time.getHours()
      )}:${addZeroBefore(time.getMinutes())}`;
      obj.duration = `${duration}`;
      delete obj["endDate"];
      delete obj["startDate"];
      let newAppointment = {
        id: id,
        values: obj,
      };
      console.log(newAppointment);
      updateAppointment(newAppointment).then((res) => {
        console.log(res);
        // getAppointment();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper>
      <NavBar active="calender" />
      {data != undefined ? (
        <div className="container-fluid">
          <Scheduler data={filterTasks(data, currentDoctor)}>
            <ViewState
              currentDate={currentDate}
              currentViewName={currentViewName}
              onCurrentViewNameChange={currentViewNameChange}
              onCurrentDateChange={currentDateChange}
            />
            <GroupingState grouping={grouping} />

            <DayView startDayHour={6} endDayHour={19} intervalCount={2} />
            <WeekView
              startDayHour={6}
              endDayHour={19}
              excludedDays={[0, 6]}
              displayName="Week"
              name="Week"
            />
            <MonthView />
            <AllDayPanel />
            <Appointments />
            <Resources data={resources} />
            {/* to edit details in appointment */}
            <EditingState onCommitChanges={commitChanges} />
            {/* these two components are responsible for group components with individual calendars */}
            {/* <IntegratedGrouping /> */}
            {/* <GroupingPanel cellComponent={GroupingPanelCell} /> */}

            <Toolbar flexibleSpaceComponent={flexibleSpace} />
            {/* button to instantly to go the current date */}
            <TodayButton />
            {/* date calendar to navigate among different months and years */}
            <DateNavigator />
            {/* view switcher is dropdown for time view e.g day, week, month */}
            <ViewSwitcher />

            <IntegratedEditing />
            {/* confirming dialog */}
            <ConfirmationDialog />

            {/* popup with appointment detail */}
            <AppointmentTooltip
              contentComponent={TooltipContent}
              showCloseButton
            />
            <AppointmentForm />
          </Scheduler>
        </div>
      ) : (
        <div className="loader">
          <img src="https://i.gifer.com/Yb3A.gif" alt="" />
        </div>
      )}
    </Paper>
  );
}
