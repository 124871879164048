import { React, useState, useEffect } from "react";
import MainAccordion from "../Accordion/accordion";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import moment from "moment";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const ScheduleVisits = ({
  selectedVisit,
  patientId,
  trialId,
  setScheduleBaselineModalShow,
  AllVisitsData,
  trial
}) => {
  const [showRadioButton, setShowRadioButton] = useState(false);
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const token = localStorage.getItem("token");
  const [statusObject, setStatusObject] = useState({
    visitStartDate: selectedVisit?.actual_date,
    visitCompletedDate: selectedVisit?.completed_date,
    patientStartDate: selectedVisit?.patient_start_date,
    patientCompletedDate: selectedVisit?.patient_completed_date,
    visit_status: selectedVisit?.visit_status || "",
    shouldStartNextVisit: "yes",
  });

  useEffect(() => {

    if (!(selectedVisit.visit_number !== 0 && selectedVisit?.visit_status === "Not Opened")) {
      if (selectedVisit?.patient_start_date) {
        setStatusObject((prev) => {
          return {
            ...prev,
            patientStartDate: moment
              .utc(selectedVisit?.patient_start_date)
              .local()
              .format("YYYY-MM-DDTHH:mm"),
          };
        });
      } 
    }

    if (selectedVisit?.patient_completed_date) {
      setStatusObject((prev) => {
        return {
          ...prev,
          patientCompletedDate: moment
            .utc(selectedVisit?.patient_completed_date)
            .local()
            .format("YYYY-MM-DDTHH:mm"),
        };
      });
    }

    if (selectedVisit?.actual_date) {
      setStatusObject((prev) => {
        return {
          ...prev,
          visitStartDate: moment
            .utc(selectedVisit?.actual_date)
            .local()
            .format("YYYY-MM-DDTHH:mm"),
        };
      });
    }

    if (selectedVisit?.completed_date) {
      setStatusObject((prev) => {
        return {
          ...prev,
          visitCompletedDate: moment
            .utc(selectedVisit?.completed_date)
            .local()
            .format("YYYY-MM-DDTHH:mm"),
        };
      });
    }

    if (selectedVisit?.visit_status) {
      setStatusObject((prev) => {
        return {
          ...prev,
          visit_status: selectedVisit?.visit_status,
        };
      });
    }
  }, [selectedVisit]);

  const handleSaveVisitStatus = () => {
    const data = {
      patientId: patientId,
      trialId: trialId,
      visitNumber: selectedVisit?.visit_number,
      status: statusObject.visit_status,
      patientStartDate: moment(
        statusObject.patientStartDate,
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
      patientEndDate: moment(
        statusObject.patientCompletedDate,
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
    };
    if (
      statusObject.visit_status === "Completed" &&
      !statusObject.patientCompletedDate
    ) {
      toast.error("Subject Close Date cannot be Empty");
      return;
    }
 
    if (data.patientStartDate > data.patientEndDate) {
      toast.error("Subject End Date cannot be Past Subject Start Date");
      return;
    }
    if (statusObject.visit_status === "Completed") {
      // If status changed to Completed, show the radio group
      setShowRadioButton(true);
    } else {
      setShowRadioButton(false);
    }
  

    if (data.visitNumber !== -3) {
      if (moment(statusObject.visitStartDate) > data.patientStartDate) {
        if (data.visitNumber !== 0) {
          toast.error("Subject Start Date cannot be Past Visit Start Date");
          return;
        }
      } else if (data.patientStartDate > data.patientEndDate) {
        toast.error("Subject End Date cannot be Past Subject Start Date");
        return;
      } else if (data.patientEndDate > moment(statusObject.visitCompletedDate)) {
        toast.error("Visit End Date cannot be Past Subject End Date");
        return;
      }
    } 

    axios
      .post(`${__API_URL__}/api/photos/change_visit_status/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        toast.success("Visit Status Changed Successfully");
        AllVisitsData()
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };
  function handleClick(event) {
    if (event.target.value === statusObject.shouldStartNextVisit) {
      setStatusObject((prev) => {
        return {
          ...prev,
          shouldStartNextVisit: "",
        };
      });
    } else {
      setStatusObject((prev) => {
        return {
          ...prev,
          shouldStartNextVisit: event.target.value,
        };
      });
    }
  }

  return (
    <MainAccordion
      title="Visit Status"
      isOpen={true}
      data={
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10rem",
              maxWidth: "500px",
              margin: "auto",
            }}
          >
            {(role === "Investigator" || role === "Inv_coordinator" || role === "Sub_investigator") && (
              <>
                <div className="col-md-12 text-end">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm mx-5"
                    onClick={() => handleSaveVisitStatus()}
                  >
                    Update Status
                  </button>
                </div>
              </>
            )}
            {selectedVisit?.visit_number !== -3 && (
              <>
                <TextField
                  id="filled-multiline-flexible"
                  label="Visit Open Date"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: 'darkgrey',
                    },
                  }}
                  type="datetime-local"
                  value={statusObject.visitStartDate}
                />
                <TextField
                  id="filled-multiline-flexible"
                  label="Visit Close Date"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: 'darkgrey',
                    },
                  }}
                  type="datetime-local"
                  value={statusObject.visitCompletedDate}
                />
              </>
            )}
            <>
              <TextField
                id="filled-multiline-flexible"
                label="Subject Start Date"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                type="datetime-local"
                value={
                  statusObject.patientStartDate
                }
                onChange={(event) =>
                  setStatusObject((prev) => {
                    return { ...prev, patientStartDate: event.target.value };
                  })
                }
                disabled={selectedVisit.visit_number !== 0 ? selectedVisit?.has_activity : false}
              />
              <TextField
                id="filled-multiline-flexible"
                label="Subject End Date"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                type="datetime-local"
                value={
                  statusObject.patientCompletedDate
                }
                onChange={(event) =>
                  setStatusObject((prev) => {
                    return {
                      ...prev,
                      patientCompletedDate: event.target.value,
                    };
                  })
                }
                disabled={selectedVisit.visit_number !== 0 ? selectedVisit?.has_activity : false}
              />
            </>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-filled-label">
                Status
              </InputLabel>
              <Select
                value={statusObject.visit_status}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Status"
                onChange={(event) =>
                  setStatusObject((prev) => {
                    return { ...prev, visit_status: event.target.value };
                  })
                }
                disabled={selectedVisit?.has_activity}
              >
                {statusObject.visit_status === "Not Opened" && (
                  <MenuItem value={"Not Opened"}>Not Opened</MenuItem>
                )}
                <MenuItem value={"InComplete"}>InComplete</MenuItem>
                <MenuItem value={"Skipped"}>Skipped</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* DO NOT REMOVE BELOW COMMENT */}
          {!trial.trial_custom_id.includes("RBL") && ["Investigator", "Inv_coordinator", "Sub_investigator"].includes(role) && selectedVisit?.visit_number === -3 && (
            <Grid container>
              <Grid item xs={3}>
                <FormControl
                  sx={{
                    margin: "8px",
                    width: "100%",
                  }}
                >
                  Would you like to schedule the next visit?
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                <div className="">
                  <button
                    onClick={() => setScheduleBaselineModalShow(true)}
                    type="button"
                    className="btn btn-primary btn-sm mx-3 my-2"
                  >
                    Schedule Baseline Visit
                  </button>
                </div>
              </Grid>
            </Grid>
          )}
        </Box>
      }
    />
  );
};
export default ScheduleVisits;
