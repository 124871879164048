import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import NavBar from "../../components/navBar/navBar";
import AddProductModal from "../../components/Modals/AddProductModal";

const YourInventory = () => {
  const { PatientData, Loader ,handleRefresh } = useFetch(`inventory/`);
  const [modalShow, setModalShow] = React.useState(false);
  const [productDataList, setProductDataList] = useState([]);

  const AdddataToList = (obj) => {
    setProductDataList([...productDataList, obj]);
  };

  return (
    <>
      <NavBar active="inventory" />
      <div className="container-fluid">
        <div className="row patient_table inventory">
          <div className="col-md-2">
            <div className="sideBar">
              <h5>Inventory</h5>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Products</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        <Link to="/">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          All Products
                        </Link>
                      </li>
                      <li>
                        <Link to="/inventory/your-inventory">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          Your Inventory
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          Inventory Variations
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          Inventory Reconcillation
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          Stock Transfer
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Orders</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul>
                      <li>
                        <Link to="/">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          Supplier
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          Purchase Orders
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className="col-md-10">
            <div className="tableHeader">
              <div className="row">
                <div className="col-md-12">
                  <h5>Your Inventory</h5>
                </div>
                <div className="col-md-6" style={{ "marginTop": "8px" }}>
                  <input
                    type="search"
                    placeholder="Search by product name"
                    className="form-control product_search"
                  ></input>
                  <button type="submit" className="btn light btn-sm">
                    Filters
                  </button>
                </div>
                <div className="container col-md-6 d-flex justify-content-end">
                  <div className="row">
                  <div className="col-sm">
                   <button type="button" class="btn btn-primary btn-sm"   onClick={handleRefresh}>Refresh</button>
                 </div>
                 <div  className="col-sm">
                  <button type="button" class="btn btn-primary btn-sm"  style={{ "width": "120px" }} onClick={() => setModalShow(true)} >
                    Add Product
                  </button>
                  <AddProductModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    ProductList={AdddataToList}
                  />
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <table>
              {Loader ? (
                <div className="loader">
                  <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                </div>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Generic Name</th>
                      <th>Type </th>
                      <th>Units</th>
                      <th>Current unit in stock</th>
                      <th>Low water mark</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productDataList.map((data,index) => (
                      <tr key={index}>
                        <td>{data.productName}</td>
                        <td>{data.GenericName}</td>
                        <td>{data.productType}</td>
                        <td>{data.productUnits}</td>
                        <td>{data.stockUnits}</td>
                        <td>{data.LowWaterMark}</td>
                        <td>{data.statusVal}</td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourInventory;
