import { useState, useEffect } from "react";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { setLicenseKey } from "survey-core";
import NavBar from "../../components/navBar/navBar";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import { useNavigate } from "react-router-dom";
import SelectTrialType from "./SelectTrialType";


export default function SurveyCreatorWidget() {
  let [creator, setCreator] = useState();
  const [Loader, setLoader] = useState(false);
  const [surveyJson, setSurveyJson] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [fieldModal, setFieldModal] = useState(false);
  const [allModels, setAllModels] = useState([]);
  const [surveyData, setSurveyData] = useState(null);
  const [allTrialType, setAllTrialType] = useState([]);
  const [hasTrialTypeChanged, setHasTrialTypeChanged] = useState(false);
  const token = localStorage.getItem("token");
  const [trialType, setTrialType] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  const initSurvey = () => {
    let options = {
      // showEmbededSurveyTab: false,
      // showTestSurveyTab: false,
      // showJSONEditorTab: false,
      allowModifyPages: false,
      questionTypes: [
        "text",
        "checkbox",
        "radiogroup",
        "dropdown",
        "datetime",
        "persistentcheckbox",
        "boolean",
        "comment",
        "tagbox",
        "matrix",
        "matrixdropdown",
        "matrixdynamic",
        "rating",
        "ranking"
      ],
    };
    creator = new SurveyCreator(options);

    creator.onElementAllowOperations.add(function (_, options) {
      options.allowDragging = false;
      options.allowChangeType = false;
      options.allowChangeInputType = false;
      options.allowChangeInputType = false;
    });

    creator.isAutoSave = false;
    creator.showState = true;
    creator.saveSurveyFunc = async () => {
      const surveyObject = creator.JSON;
      setTrialType(true);
    };

    setCreator(creator);
  };

  useEffect(() => {
    if (creator === undefined) {
      initSurvey()
    } else {
      creator.render("surveyCreatorContainer");
      creator.JSON = surveyJson;
    }
  }, [creator, surveyJson]);

  const saveSurvey = (surveyObject) => {
    const trialTypeIds = currentPage.trial_type.map((obj) => obj.value)
    axios
      .post(`${__API_URL__}/api/ecrf/create_new_ecrf/`, {
        name: surveyObject.title ? surveyObject.title : "",
      surveyJson: surveyObject,
        trial_type_ids: trialTypeIds
      }, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTrialType(false);
        toast.success("Source Document Saved");
        navigate(`/form-creater-v2/?surveyId=${res.data.data.id}`)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  const updateSurvey = (surveyId, surveyObject) => {
    const trialTypeIds = currentPage.trial_type.map((obj) => obj.value)
    axios
      .patch(`${__API_URL__}/api/ecrf/update_new_ecrf/?surveyId=${surveyId}`, {
        name: surveyObject.title ? surveyObject.title : "",
        surveyJson: surveyObject,
        trial_type_ids: trialTypeIds,
      }, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTrialType(false);
        toast.success("Source Document Updated");
      })
      .catch((err) => {
        toast.error("Something Wrong!");
      });
  }

  const getSurvey = () => {
    let surveyId = queryParameters.get("surveyId")
    if (!surveyId) surveyId = ""

    axios
      .get(`${__API_URL__}/api/ecrf/get_new_ecrf/?surveyId=${surveyId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSurveyJson(res.data.data.surveyJson)
        setCurrentPage({trial_type: res.data.data.trial_type})
      })
      .catch((err) => {
        toast.error("Something Wrong!");
      });
  } 

  useEffect(() => {
    getSurvey()
  }, [])

  const GetTrialTypes = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/form_creator_trialtypes/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAllTrialType(res.data);
      })
      .catch((err) => {
        if (err === "Error: Request failed with status code 401") {
        }
      });
  };

  useEffect(() => {
    GetTrialTypes();
  }, []);

  return (
    <div>
      <NavBar active="form_creater" />
      <ToastContainer />
      {queryParameters.get("trial_id") !== undefined &&
        queryParameters.get("trial_id") !== null &&
        queryParameters.get("trial_id") !== "undefined" && (
          <button className="btn btn-primary">
            Finished creating eCRF blocks
          </button>
        )}
      <div
        className="col-md-12 text-end my-3"
        id="surveyCreatorContainer"
        style={{ position: "absolute", height: "85%", width: "100%" }}
      ></div>
      {Loader ? (
        <div className="loader">
          <img src="https://i.gifer.com/Yb3A.gif" alt="" />
        </div>
      ) : (
        <>
        </>
      )}

      {trialType && (
        <SelectTrialType
          trialType={trialType}
          setTrialType={setTrialType}
          allTrialType={allTrialType}
          surveyData={surveyData}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setHasTrialTypeChanged={setHasTrialTypeChanged}
          toast={toast}
          saveSurvey={saveSurvey}
          updateSurvey={updateSurvey}
          surveyId={queryParameters.get("surveyId")}
          creator={creator}
          // setSurveyCreator={setSurveyCreator}
        />
      )}
  
    </div>
  );
}
