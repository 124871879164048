import React, { useEffect, useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Date from "react-datetime";
import Datetime from "react-datetime";
import moment from 'moment';


const Site_Patient_Identification = (props) => {
  const [value, newValue] = React.useState("list")
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  const date_of_intro = props.intro_date;
  const time_of_intro = props.intro_time;
  let disable = false

  const [selectedBlock, setSelectedBlock] = useState({})
  const user = useUser();
  const handleSite = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      site_number: e.target.value,
    });
    props.setHasChanged(true)
  };
  const handleFirstName = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      first_name: e.target.value,
    });
    props.setHasChanged(true)
  };
  const handleLastName = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      last_name: e.target.value,
    });
    props.setHasChanged(true)
  };
  const handleParticpant_Number = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      participant_number: e.target.value,
    });
    props.setHasChanged(true)
  };
  const handleMissed = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_missed_visit: e.target.checked,
    });
    props.setHasChanged(true)
  };
  const handleEarlyTermination = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_early_termination: e.target.checked,
    });
    props.setHasChanged(true)
  };
  const handleIsErythemaComplete = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_assessment_complete: !selectedBlock.is_assessment_complete,
    });
    props.setHasChanged(true)
  };
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedBlock((selectedBlock) => ({
      ...selectedBlock,
      [name]: value,
    }));
    props.setHasChanged(true)
  };
  const handleDate = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      date_of_visit: e.target.value,
    });
    props.setHasChanged(true)
  };

  const GetSelectedBlockData = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_intro_form/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock({
          ...res.data,
          'date_of_visit': res.data?.date_of_visit ? res.data?.date_of_visit : date_of_intro,
          'time_of_visit': (res.data?.time_of_visit ? moment(`${res.data?.time_of_visit}Z`, "HH:mmZ").format("HH:mm") : moment(`${time_of_intro}Z`, "HH:mmZ").format("HH:mm")),

        });
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }

  const AllPatientData = () => {
    const createData = {
      'visit_number': props?.visit_number,
      'patient_id': props?.patient_id,
      ...selectedBlock,
      'date_of_visit': moment(selectedBlock?.date_of_visit)?.format('YYYY-MM-DD'),
      'time_of_visit': moment(selectedBlock?.time_of_visit, "HH:mm").utc().format("HH:mm"),
      'is_assessment_complete': selectedBlock?.is_assessment_complete === undefined ? true : selectedBlock?.is_assessment_complete,
      'sessionId': props.selectedSessionId,
    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_intro_form/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock({
          ...res.data,
          time_of_visit: moment(`${res.data.time_of_visit}Z`, 'HH:mmZ').format("HH:mm")
        });
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        props.patientDetailApi()
        props.globalVisits(false);
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      });
  };

  React.useEffect(() => {
    if ((props?.patient_id && props?.photo_session)) {
      GetSelectedBlockData();
    }
  }, [])
  return <>
    {
      (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined)) ?
        disable = true : ""

    }
    <fieldset disabled={disable}>
      {
        (props.photo_session || props.patient_id == undefined) ?
          <>
            {
              (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                <div className="col-md-12 text-end">
                  <button onClick={() => AllPatientData()} className='btn btn-primary'>Update</button>
                </div>
              )
            }
            <div className='row'>
              <div className='col-md-12'>
                <label> Site #</label>
                <input style={{ marginLeft: "0em" }}
                  class="form-control"
                  type="text"
                  name='site_number'
                  disabled={selectedBlock?.is_setting}
                  id="flexSwitchCheckDefault"
                  onChange={(e) => handleSite(e)}
                  value={selectedBlock?.site_number}
                ></input>
              </div>
              <div className='col-md-6'>
                <label> Participant First Name: </label>
                <input
                  style={{ marginLeft: "0em" }}
                  class="form-control"
                  type="text"
                  name='first_name'
                  id="flexSwitchCheckDefault"
                  onChange={(e) => handleFirstName(e)}
                  // value={props.patient?.first_name && props.patient?.last_name ? `${props.patient.first_name} ${props.patient.last_name}` : ''}
                  value={selectedBlock?.first_name || props.infoData.first_name}
                >
                </input>
              </div>
              <div className='col-md-6'>
                <label> Participant Last Name: </label>
                <input
                  style={{ marginLeft: "0em" }}
                  class="form-control"
                  type="text"
                  name='last_name'
                  id="flexSwitchCheckDefault"
                  onChange={(e) => handleLastName(e)}
                  value={selectedBlock?.last_name || props.infoData.last_name}
                >
                </input>
              </div>
              <div className='col-md-6'>
                <label> Participant #: </label>
                <input
                  style={{ marginLeft: "0em" }}
                  class="form-control"
                  type="text"
                  disabled={selectedBlock?.is_setting}
                  name='participant_number'
                  id="flexSwitchCheckDefault"
                  onChange={(e) => handleParticpant_Number(e)}
                  value={selectedBlock?.participant_number}
                >
                </input>
              </div>
              <div className="col-md-6 calendarIcon">
                <label>Date of Visit:</label>
                <input
                  onChange={handleDate}
                  class='form-control'
                  type="date"
                  value={selectedBlock?.date_of_visit}
                  inputProps={{ placeholder: 'mm/dd/yy 00:00 AM', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined) }}
                />
              </div>
              <div className='col-md-6'>
                <label> Time of Visit: </label>
                <input
                  onChange={(e) => { setSelectedBlock({ ...selectedBlock, time_of_visit: e.target.value }) }}
                  class='form-control'
                  type="time"
                  value={selectedBlock.time_of_visit}
                />
              </div>
              <div>
                {
                  (props.visit_number !== 0 && props.visit_number !== 7) && <>
                    <input
                      className="form-check-input mx-3"
                      type="checkbox"
                      name="is_missed_visit"
                      checked={selectedBlock?.is_missed_visit || false}
                      onChange={(e) => handleMissed(e)}
                    />
                    <label> Missed Visit </label>
                    {selectedBlock.is_missed_visit === true ?
                      <div className='col-md-6'>
                        <label> Specify: </label>
                        <input
                          style={{ marginLeft: "0em" }}
                          class="form-control"
                          type="text"
                          name='missed_visit_reason'
                          id="flexSwitchCheckDefault"
                          onChange={(e) => handleFieldChange(e)}
                          value={selectedBlock.missed_visit_reason}
                        >
                        </input>
                      </div> : ""}
                    {
                      (props.visit_number !== 0 && props.visit_number !== 6) && <>

                        <div className="col-md-12">
                          <input
                            className="form-check-input mx-3"
                            type="checkbox"
                            name="is_early_termination"
                            checked={selectedBlock?.is_early_termination || false}
                            onChange={(e) => handleEarlyTermination(e)}
                          />
                          <label> Early Termination </label>

                          {selectedBlock.is_early_termination === true ?
                            <div className='col-md-6'>
                              <label> Specify: </label>
                              <input
                                style={{ marginLeft: "0em" }}
                                class="form-control"
                                type="text"
                                name='early_termination_reason'
                                id="flexSwitchCheckDefault"
                                onChange={(e) => handleFieldChange(e)}
                                value={selectedBlock.early_termination_reason}
                              >
                              </input>

                            </div> : ""}
                        </div>
                      </>
                    }
                    <tr>
                      <div className="d-flex align-items-center">
                        <div className="col-md-8">
                          <p>Were all participant assessments completed in eCRF/app</p>
                        </div>
                        <div className="col-md-2">
                          <td className="p-0"> Yes
                            <input
                              class="form-check-input"
                              type="radio"
                              name="is_assessment_complete"
                              checked={selectedBlock?.is_assessment_complete == true}
                              id="radio1"
                              value={selectedBlock?.is_assessment_complete == true}
                              onChange={handleIsErythemaComplete}
                            />
                          </td>
                        </div>
                        <div className="col-md-2">
                          <td className="p-0"> No
                            <input
                              class="form-check-input"
                              type="radio"
                              name="is_assessment_complete"
                              checked={selectedBlock?.is_assessment_complete == false}
                              id="radio1"
                              value={selectedBlock?.is_assessment_complete == false}
                              onChange={handleIsErythemaComplete}
                            />
                          </td>
                        </div>
                      </div>
                    </tr>
                    {selectedBlock.is_assessment_complete === false ?
                      <div className='col-md-6'
                      >
                        <label> if No, indicate reason: </label>
                        <input
                          class="form-control"
                          type="text"
                          style={{ borderWidth: '1px' }}
                          name='assessment_reason'
                          id="flexSwitchCheckDefault"
                          value={selectedBlock.assessment_reason}
                          onChange={(e) => handleFieldChange(e)}>
                        </input>
                      </div> : ''}
                  </>
                }
              </div>
            </div>
          </> : 'Visit is not started'
      }
    </fieldset>
  </>
}
export default Site_Patient_Identification;