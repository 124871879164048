
const PARTICIPANT_ASSESSMENTS = () => 
{

    
 return <>      
            <h1>   Will be Right Back  </h1>
    </>
}

export default PARTICIPANT_ASSESSMENTS;