import React,{useEffect } from 'react'
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import TLS_FORM from '../Ecrf/TLS';

const Target_Lesion_Severity_Assessment = (props) => {
  const token = localStorage.getItem("token");
  const [selectedBlock, setSelectedBlock] = React.useState([]);
  const user = useUser();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  const extra_photo = props.extra_photo
  let disable = false

  const getSeverityData = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_severity/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        let customData = []
        res.data.map((obj) => {
            customData.push(
              {
                lesion_one_score: obj?.tls_score?.filter((x) => x.photo_type === "Lesion 1")[0] || "",
                lesion_two_score: obj?.tls_score?.filter((x) => x.photo_type === "Lesion 2")[0] || "",
                lesion_three_score: obj?.tls_score?.filter((x) => x.photo_type === "Lesion 3")[0] || "",
                specify_severity_assessed: obj?.specify_severity_assessed,
                is_severity_assessed: obj?.is_severity_assessed === undefined ? true : obj?.is_severity_assessed,
                first_name: obj?.first_name,
                last_name: obj?.last_name,
                email: obj?.email,
                confirmed_by: obj?.confirmed_by,
                id: obj?.id,
                option: obj?.option,
              }
            )
        })
        setSelectedBlock(customData);

      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }



  const AllVisitsData = (index) => {
    const updatedBlock = selectedBlock[index]
    const createData = {
      'patient_id': props?.patient_id,
      'visit_number': props?.visit_number,
      'sessionId': props.selectedSessionId,
      ...updatedBlock
    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_severity/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        document.addEventListener("DOMContentLoaded", function () {
          var cusMessages = document.getElementById("cusMessages");
          if (cusMessages) {
            setTimeout(function () {
              cusMessages.style.right = "2rem";
            }, 100);
            setTimeout(function () {
              cusMessages.style.right = "-99rem";
            }, 3000);
          }
        });
      });
  };

  useEffect(() => {
    if(props.patient_id != undefined){
      getSeverityData()
    }
    }, []);

  return <>
  {
      (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') || (props.patient_id === undefined) ?
        disable = true : ''
    }
    {
      ((props.photo_session || props.patient_id === undefined)) ? <fieldset disabled={disable}>
        {
          (selectedBlock?.length === 0 || selectedBlock === 'undefined') && (role === 'Investigator' || role === 'Inv_coordinator' || role === 'Super Admin') ?
            <>
              {(props.patient_id && (role === 'Investigator' || role === 'Inv_coordinator' || role === 'Super Admin')) &&
                <div className="col-md-12 text-end">
                  <button onClick={() => AllVisitsData()} className='btn btn-primary'>Create New TLS</button>
                </div>
              }
            </>
            :
            <>
            <TLS_FORM
                    selectedBlock={selectedBlock}
                    setSelectedBlock={setSelectedBlock}
                    extra_photo={extra_photo}
                    setHasChanged={props.setHasChanged}
                    patient_id={props.patient_id}
                    setMessage = {props.setMessage}
                    setTypeOfMessage = {props.setTypeOfMessage}
                    trialId = {props.trialId}
                    setModalVisitChangeShow = {props.setModalVisitChangeShow}
                    visit_number = {props?.visit_number}
                    globalVisits = {props?.globalVisits}
                    selectedSessionId={props?.selectedSessionId}
                  />
            </>
        }
      </fieldset>
        : 'Visit is not started'
    }
  </>
}
export default Target_Lesion_Severity_Assessment;