import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "../../components/navBar/navBar";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import AddStaff from "./AddStaff"
import { toast, ToastContainer } from "react-toastify";
import EditStaff from "./EditStaff";

const StaffList = () => {
  const user = useUser();
  const token = localStorage.getItem("token");
  const [hasSearch, setHasSearch] = useState(false);
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const [pageItemCount, setPageItemCount] = useState(20);
  const [Search, setSearch] = useState({
    email: "",
    head_user_email: "",
    Name: "",
    fName: "",
    lName: "",
    clinic: "",
    fired: "FIRED",
    vip: "VIP",
    do_not_call: "",
    deactive: 0,
    archived: 0,
    itemCount: 20,
    page: 1,
  });
  const resetFilters = () => {
    setSearch({
      itemCount: 20,
      Name: '',
      fName: '',
      lName: '',
      clinic: '',
      email: '',
      head_user_email: '',
      page: 1,
    });
  };
  const [typeOfStaff, setTypeOfStaff] = useState("owner");
  const [paginationurl, setPaginationurl] = useState("");
  const [Loader, setLoader] = useState(false);
  const [PatientData, setPatientData] = useState("");
  const [addStaff, setAddStaff] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [editStaff, setEditStaff] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((Search) => ({
      ...Search,
      [name]: value,
    }));
    setHasSearch(true)
  };
  const PaginationData = () => {
    let coordinator
    if (typeOfStaff === 'Inv_coordinator') {
      coordinator = 'Inv_coordinator'
    } else if (typeOfStaff === 'Cro_coordinator') {
      coordinator = 'Cro_coordinator'
    } else if (typeOfStaff === 'Data Manager') {
      coordinator = 'Data Manager'
    } else if (typeOfStaff === 'Reviewer') {
      coordinator = 'Reviewer'
    } else if (typeOfStaff === 'Sub_investigator') {
      coordinator = 'Sub_investigator'
    }
    let params = `?page=${Search.page}&page_size=${Search.itemCount}&name=${Search.Name}&first_name=${Search.fName}&last_name=${Search.lName}&clinic=${Search.clinic ? (Search.clinic) : ('')}&email=${Search.email}&head_user=${Search.head_user_email}&coordinator=${coordinator}`;
    axios
      .get(`${__API_URL__}${paginationurl}/${params}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPatientData(res.data);
        setHasSearch(false)
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
      });
  };
  useEffect(() => {
    if (hasSearch) {
      setSearch((Search) => ({
        ...Search,
        page: 1,
      }));
    }
  }, [hasSearch])

  const UpdateInstance = (e) => {
    e.preventDefault();
    let url;
    if (typeOfStaff === 'owner') {
      url = 'clinicOwnerGet'
    } else if (typeOfStaff === 'admin') {
      url = 'clinicAdminView'
    } else if (typeOfStaff === 'cro_admin' || typeOfStaff === 'investigator' || typeOfStaff === 'Cro_coordinator' || typeOfStaff === 'Data Manager' || typeOfStaff === 'Inv_coordinator' || typeOfStaff === 'Reviewer' || typeOfStaff === 'Sub_investigator') {
      url = 'edit_crc'
    } else if (typeOfStaff === 'practitioner') {
      url = 'practitioners/practitioner'
    }
    const CreateData = {
      'email': editStaff?.email,
      'first_name': editStaff?.first_name,
      'last_name': editStaff?.last_name,
      'isAdmin': editStaff?.isAdmin,
      'isPractitioner': editStaff?.isPractitioner,
      'clinic_id': editStaff?.clinic?.['value'],
      'name': editStaff?.name
    }
    axios
      .patch(`${__API_URL__}/api/${url}/${editStaff?.id}/`, CreateData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })

      .then(() => {
        toast.success("Staff Updated successfully");
        setEditStaff(false)
        PaginationData(paginationurl)
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
        toast.error(err.response.data.message)
      });
  };

  const handlePageClick = (event) => {
    const no = event?.selected + 1
    setSelectedPage(event.selected);
    let url = "";
    if (typeOfStaff == "owner") {
      url = "/api/allclinicownerslist";
    } else if (typeOfStaff == "admin") {
      url = "/api/allclinicAdminslist";
    } else if (typeOfStaff == "practitioner") {
      url = "/api/practitioners/practitioner_list";
    } else if (typeOfStaff == "investigator") {
      url = "/api/get_investigators/";
    } else if (typeOfStaff == "Inv_coordinator") {
      url = "/api/patient/patient/get_coordinators";
    } else if (typeOfStaff == "cro_admin") {
      url = "/api/get_croList";
    } else if (typeOfStaff == "Cro_coordinator") {
      url = "/api/patient/patient/get_coordinators";
    } else if (typeOfStaff == "Data Manager") {
      url = "/api/patient/patient/get_coordinators";
    } else if (typeOfStaff == "Reviewer") {
      url = "/api/patient/patient/get_coordinators";
    } else if (typeOfStaff == "Sub_investigator") {
      url = "/api/patient/patient/get_coordinators";
    }
    setSearch((prevSearch) => ({
      ...prevSearch,
      page: no,
    }));
  };
  const handleStaffList = (type) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      page: 1,
    }));
    if (type == "owners") {
      setPaginationurl("/api/allclinicownerslist")
      setTypeOfStaff("owner");
    } else if (type == "admins") {
      setPaginationurl("/api/allclinicAdminslist")
      setTypeOfStaff("admin");
    } else if (type == "practitioners") {
      setTypeOfStaff("practitioner");
      setPaginationurl("/api/practitioners/practitioner_list")
    } else if (type == "investigator") {
      setPaginationurl("/api/get_investigators")
      setTypeOfStaff("investigator");
    } else if (type == "Inv_coordinator") {
      setPaginationurl("/api/patient/patient/get_coordinators")
      setTypeOfStaff("Inv_coordinator");
    } else if (type == "cro_admin") {
      setPaginationurl("/api/get_croList")
      setTypeOfStaff("cro_admin");
    } else if (type == "Cro_coordinator") {
      setPaginationurl("/api/patient/patient/get_coordinators")
      setTypeOfStaff("Cro_coordinator");
    } else if (type == "Data Manager") {
      setPaginationurl("/api/patient/patient/get_coordinators")
      setTypeOfStaff("Data Manager");
    } else if (type == "Reviewer") {
      setPaginationurl("/api/patient/patient/get_coordinators")
      setTypeOfStaff("Reviewer");
    } else if (type == "Sub_investigator") {
      setPaginationurl("/api/patient/patient/get_coordinators")
      setTypeOfStaff("Sub_investigator");
    }
  };

  useEffect(() => {
    if (user_data.role == "Clinic Owner") handleStaffList("admins");
    if (user_data.role == "Clinic Admin") handleStaffList("practitioners");
    if (user_data.role == "CRO Admin") handleStaffList("Cro_coordinator");
    if (user_data.role == "CRO Admin") handleStaffList("Data Manager");
    if (user_data.role == "Coordinator") handleStaffList("coordinator");
    if (user_data.role == "Investigator") handleStaffList("Inv_coordinator");
    if (user_data.role == "Super Admin") handleStaffList("owners");
    if (user_data.role == "Investigator") handleStaffList("Sub_investigator");
  }, []);

  useEffect(() => {
    if (paginationurl) {
      PaginationData()
    }
  }, [paginationurl, Search]);

  useEffect(() => {
    handlePageClick({ selected: 0 })
  }, [typeOfStaff]);

  // const handleRefresh = () => {
  //   window.location.reload(true)
  // }
  const handleReset = () => {
    resetFilters();
  }
  return (
    <>
      <NavBar active="staff" setAddStaff={setAddStaff} />
      <ToastContainer />
      {addStaff === true ?
        <>
          <AddStaff setAddStaff={setAddStaff} toast={toast} typeOfStaff={typeOfStaff} PaginationData={PaginationData} paginationurl={paginationurl} setLoader={setLoader} />
        </>
        :
        <>
          <div className="container-fluid">
            <div className="row patient_table patientList">
              <div className="col-md-2">
                <div className="sideBar">
                  <h5>Staff Menu</h5>
                  <ul>
                    {user_data.role == "Super Admin" && (
                      <>
                        <li>
                          <Link
                            to=""
                            onClick={(e) => {
                              handleStaffList("owners");
                            }}
                            className={typeOfStaff == "owner" ? "active" : ""}
                          >
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                              alt=""
                            />
                            Clinic Owners
                          </Link>
                        </li>
                      </>
                    )}
                    {(user_data.role == "Super Admin" ||
                      user_data.role == "Clinic Owner") && (
                        <>
                          <li>
                            <Link
                              to=""
                              onClick={(e) => {
                                handleStaffList("admins");
                              }}
                              className={typeOfStaff == "admin" ? "active" : ""}
                            >
                              <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                                alt=""
                              />
                              Clinic Admins
                            </Link>
                          </li>
                        </>
                      )}
                    {
                      (user_data.role == "Super Admin" ||
                        user_data.role == "Clinic Admin") && <>
                        <li>
                          <Link
                            to=""
                            onClick={(e) => {
                              handleStaffList("practitioners");
                            }}
                            className={typeOfStaff == "practitioner" ? "active" : ""}
                          >
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                              alt=""
                            />
                            Clinic Practitioners
                          </Link>
                        </li>
                      </>
                    }
                    {user_data.role == "Super Admin" && (
                      <li>
                        <Link
                          to=""
                          onClick={(e) => {
                            handleStaffList("cro_admin");
                          }}
                          className={typeOfStaff == "cro_admin" ? "active" : ""}
                        >
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          CRO Admin
                        </Link>
                      </li>
                    )}
                    {(user_data.role === 'CRO Admin' || user_data.role === 'Super Admin') &&
                        <li>
                          <Link
                            to=""
                            onClick={(e) => {
                              handleStaffList("Data Manager");
                            }}
                            className={typeOfStaff == "Data Manager" ? "active" : ""}
                          >
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                              alt=""
                            />
                            Data Manager
                          </Link>
                        </li>
                    }
                    {
                      (user_data.role === 'CRO Admin' || user_data.role === 'Super Admin') &&
                      <li>
                        <Link
                          to=""
                          onClick={(e) => {
                            handleStaffList("Cro_coordinator");
                          }}
                          className={typeOfStaff == "Cro_coordinator" ? "active" : ""}
                        >
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          Cro Coordinator
                        </Link>
                      </li>
                    }
                    {user_data.role === 'Super Admin' || user_data.role === 'CRO Admin' ?
                      <li>
                        <Link
                          to=""
                          onClick={(e) => {
                            handleStaffList("investigator");
                          }}
                          className={typeOfStaff == "investigator" ? "active" : ""}
                        >
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          Clinic Investigators
                        </Link>
                      </li>
                      : ""}

                    {
                      (user_data.role === 'Investigator' || user_data.role === 'Super Admin') && (
                        <li>
                          <Link
                            to=""
                            onClick={(e) => {
                              handleStaffList("Inv_coordinator");
                            }}
                            className={typeOfStaff == "Inv_coordinator" ? "active" : ""}
                          >
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                              alt=""
                            />
                            Investigator Coordinator
                          </Link>
                        </li>
                      )}

                    {user_data.role === 'Super Admin' || user_data.role === 'CRO Admin' ?
                      <li>
                        <Link
                          to=""
                          onClick={(e) => {
                            handleStaffList("Reviewer");
                          }}
                          className={typeOfStaff == "Reviewer" ? "active" : ""}
                        >
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          Reviewer
                        </Link>
                      </li>
                      : ""}
                      {user_data.role == "Super Admin" || user_data.role == "Investigator" ?
                        <li>
                          <Link
                            to=""
                            onClick={(e) => {
                              handleStaffList("Sub_investigator");
                            }}
                            className={typeOfStaff == "Sub_investigator" ? "active" : ""}
                          >
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                              alt=""
                            />
                            Sub Investigator
                          </Link>
                        </li>
                        :""
                    }
                  </ul>
                </div>
              </div>
              <div className="col-md-10">
                <div className="tableHeader">
                  <div className="row">
                    <div className="col-md-3">
                      <h5 className="mt-2">
                        {
                          (user_data.role == "CRO Admin") &&
                            (typeOfStaff == "coordinator") ?
                            "Cro_coordinator"
                            :
                            typeOfStaff == "owner"
                              ? "Clinic Owners"
                              : typeOfStaff == "admin"
                                ? "Clinic Admins"
                                : typeOfStaff == "investigator" ?
                                  "Clinic Investigators" :
                                  typeOfStaff == "cro_admin" ?
                                    "Cro Admin" :
                                    typeOfStaff == "practitioner" ?
                                      "Clinic Practitioners" :
                                      typeOfStaff == "Cro_coordinator" ?
                                        "Cro Coordinator" :
                                        typeOfStaff == "Data Manager" ?
                                        "Data Manager" :
                                          typeOfStaff == "Inv_coordinator" ?
                                            "Invesigator Coordinator" :
                                            typeOfStaff == 'Reviewer' ?
                                              'Reviewer' : 
                                              typeOfStaff == 'Sub_investigator' ?
                                                'Sub Investigator' : ''
                        }
                      </h5>
                    </div>
                    <div className="col-md-9">
                      <div className="d-flex justify-content-end">
                        <button type="button" class="btn btn-danger btn-sm m-1" onClick={handleReset}>Reset Filters</button>
                        <button type="button" className="btn btn-danger btn-sm m-1" onClick={() => { setAddStaff(true) }}>
                          {typeOfStaff == "owner"
                            ? "Add Owner"
                            : typeOfStaff == "admin"
                              ? "Add Admin"
                              : typeOfStaff == "cro_admin"
                                ? "Add CRO Admin"
                                : typeOfStaff == "Inv_coordinator"
                                  ? "Add Inv Coordinator"
                                  : typeOfStaff == "investigator"
                                    ? "Add Investigator"
                                    : typeOfStaff == "Cro_coordinator"
                                      ? "Add Cro Coordinator"
                                      : typeOfStaff == "Data Manager"
                                      ? "Add Data Manager"
                                        : typeOfStaff == "Sub_investigator"
                                          ? "Add Sub Investigator"
                                          : typeOfStaff == "Reviewer"
                                            ? "Add Reviewer"
                                            : "Add Practitioner"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <table style={{ height: "48vh" }}>
                  {Loader ? (
                    <div className="loader">
                      <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                    </div>
                  ) : (
                    <>
                      <thead className="caretOrder">
                        <tr>
                          {typeOfStaff == "practitioner" ? (
                            <th>
                              <input
                                type="search"
                                placeholder="Name"
                                name="Name"
                                value={Search.Name}
                                onChange={(e) => { handleChange(e) }}
                              />
                            </th>
                          ) : null}
                          <th>
                            <input
                              type="search"
                              placeholder="First Name"
                              name="fName"
                              value={Search.fName}
                              onChange={(e) => { handleChange(e) }}
                            />
                          </th>
                          <th>
                            <input
                              type="search"
                              placeholder="Last Name"
                              name="lName"
                              value={Search.lName}
                              onChange={(e) => { handleChange(e) }}
                            />
                          </th>
                          <th>
                            <input
                              type="search"
                              placeholder="Email"
                              name="email"
                              value={Search?.email}
                              onChange={(e) => { handleChange(e) }}
                            />
                          </th>
                          {user_data.role === 'Super Admin' && (typeOfStaff === 'Cro_coordinator' || typeOfStaff === 'Data Manager' || typeOfStaff === 'Inv_coordinator' || typeOfStaff === 'Sub_investigator') ?
                          
                            <th>
                              <input
                                type="search"
                                placeholder="Head User Email"
                                name="head_user_email"
                                value={Search?.head_user_email}
                                onChange={(e) => { handleChange(e) }}
                              />
                            </th>
                            : ''}
                          {typeOfStaff == "owner" && (
                            <>
                              <th>Clinics</th>
                            </>
                          )}
                          {typeOfStaff == "admin" ? (
                            <>
                              <th>
                                <input
                                  type="search"
                                  placeholder="Clinic"
                                  name="clinic"
                                  value={Search.clinic}
                                  onChange={(e) => { handleChange(e) }}
                                />
                              </th>
                            </>
                          ) : (
                            null
                          )}
                          {typeOfStaff == "practitioner" ? (
                            <>
                              <th>
                                <input
                                  type="search"
                                  placeholder="Clinic"
                                  name="clinic"
                                  value={Search.clinic}
                                  onChange={handleChange}
                                />
                              </th>
                            </>
                          ) : (
                            null
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {PatientData?.results?.map((data, index) => (
                          <tr key={index} onClick={(e) => {
                            setEditStaff({
                              ...data,
                              'clinic': typeOfStaff === "practitioner"
                                ? { 'value': (data?.practitioner_clinic?.[0]?.id), 'label': (data?.practitioner_clinic?.[0]?.name) }
                                : { 'value': (data?.clinics?.[0]?.id), 'label': (data?.clinics?.[0]?.name) }
                            });
                          }}>

                            {typeOfStaff == "practitioner" ? (
                              <td>

                                {data.name}
                              </td>
                            ) : null}
                            <td>

                              {data.first_name}
                            </td>
                            <td>

                              {data.last_name}
                            </td>
                            <td>

                              {(typeOfStaff == "owner" || typeOfStaff == "investigator" || typeOfStaff == "Inv_coordinator")
                                ? data?.email
                                : data?.email}

                            </td>
                            {user_data.role === 'Super Admin' && (typeOfStaff === 'Cro_coordinator' || typeOfStaff === 'Data Manager' || typeOfStaff === 'Inv_coordinator' || typeOfStaff === 'Sub_investigator') ?
                              <td>
                                {data?.head_user_email}
                              </td>
                              : ''}

                            {typeOfStaff == "admin" ? (
                              <td>
                                {data.clinics?.map((clinic) => {
                                  return <>
                                    {clinic.name},&nbsp;
                                  </>
                                })}

                              </td>

                            ) : (
                              null
                            )}

                            {typeOfStaff == "owner" && (
                              <>
                                <td>{data.clinic?.join(", ")}</td>
                              </>
                            )}
                            {typeOfStaff === "practitioner" && data?.practitioner_clinic?.[0]?.name && (
                              <td>{data.practitioner_clinic[0].name}</td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                </table>
                <div className="tableHeader">
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        Showing {PatientData ? PatientData.results?.length : 0} out
                        of {PatientData ? PatientData.count : 0}
                      </p>
                    </div>
                    <div className="reactPagination col-md-6">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={Math.ceil(PatientData?.count / pageItemCount)}
                        previousLabel="previous"
                        forcePage={selectedPage}
                      />
                    </div>
                  </div>
                </div>

                {/*  Modal for Edit Staff  */}
                {editStaff &&
                  <EditStaff
                    setEditStaff={setEditStaff}
                    editStaff={editStaff}
                    UpdateInstance={UpdateInstance}
                    typeOfStaff={typeOfStaff}
                  />
                }

              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default StaffList;
