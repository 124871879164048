import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
const EcrfPopUpMessage = (props) => {
    return (
        <>
            <Modal size="md" centered show onHide={() => props.setPopMessage(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Are You Sure
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <p className="text-center">You Want to Delete this Source Document (eCRF) Blocks?</p>
                    <p className="text-center text-danger">Warning: This action will also delete the Patient Filled (eCRF) Forms as well.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { props.setPopMessage(false) }}>
                        No
                    </Button>
                    <Button variant="primary" onClick={() => props.confirmDelete(props.id)}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default EcrfPopUpMessage;