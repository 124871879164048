import {  GridToolbarColumnsButton, GridToolbarExport, GridToolbarDensitySelector} from '@mui/x-data-grid-pro';
import React from 'react'

const CustomToolbar = ({reportName}) => {
    return (
        <div>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport
                csvOptions={{
                fileName: reportName,
                delimiter: ';',
                utf8WithBom: true,
                }}
      />
        </div>
      );
}
export default CustomToolbar