import React, { useState } from "react";
import NavBar from "../../components/navBar/navBar";
import Breadcrumbs from "../../components/breadcrumbs";
import PatientMenu from "./PatientMenu";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router";


const CreateReward = () => {
  const { _id_ } = useParams();
  const { Createreward } = useFetch();
  const [Reward, setReward] = useState({
    id:_id_,
    type: "",
    points: "",
    reason: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReward((Reward) => ({
      ...Reward,
      [name]: value,
    }));
  };


  const CreateInstance = (e) => {
    e.preventDefault();
    Createreward(Reward);
  };

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs B="Reward - Create" />
        <div className="patient_table">
          <PatientMenu active="reward" />
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <h5>Create Reward</h5>
              </div>
            </div>
          </div>
          <form className="createReward" onSubmit={CreateInstance}>
            <div className="row">
              <div className="col-md-4">
                <label>Points</label>
                <input
                  type="number"
                  name="points"
                  value={Reward.points}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
                <label>Reason</label>
                <input
                  type="text"
                  name="reason"
                  value={Reward.reason}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
                <label>Type</label>
                <select
                  type="date"
                  name="type"
                  required
                  value={Reward.type}
                  onChange={handleChange}
                  className="form-control"
                >
                <option value="">Select</option>
                <option>Rewarded</option>
                <option>Redeemed</option>
                </select>
                <button class="btn btn-primary" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateReward;
