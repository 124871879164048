import React from 'react'
import TrialDrugIdRange from '../Trials/TrialDrugIdRange'
import MainAccordion from '../Accordion/accordion'

const DrugIdRangeAccordion = ({trialDetails, hasChanged, setHasChanged, setTrialDetails, handleBasicInfoUpdate, setDrugRangeChanged, DrugRangeChanged}) => {

  return (
    <MainAccordion
    title="Drug Id Range"
    data={
        <TrialDrugIdRange 
        trialDetails={trialDetails}
        setTrialDetails={setTrialDetails}
        setHasChanged={setHasChanged}
        hasChanged={hasChanged}
        handleBasicInfoUpdate={handleBasicInfoUpdate}
        DrugRangeChanged={DrugRangeChanged}
        setDrugRangeChanged={setDrugRangeChanged}
        />}
    />
  )
}
export default DrugIdRangeAccordion