import React, { useState, useEffect } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import moment from 'moment';

const Informed_Consent = (props) => {
const [selectedBlock,setSelectedBlock] = useState({})
const token = localStorage.getItem("token");
const user = useUser();
const user_data = JSON.parse(localStorage.getItem("user_data"));
const role = user_data.role
const setMessage = props.setMessage
const setTypeOfMessage = props.setTypeOfMessage
const [consentTime, setConsentTime] = useState(false);
const [researchTime, setResearchTime] = useState(false);

let disable=false
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedBlock((selectedBlock) => ({
    ...selectedBlock,
    [name]: value,
    }));
    props.setHasChanged(true)
  };
  const handleChange = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      consent_signed:!selectedBlock?.consent_signed,
  });
  props.setHasChanged(true)
  };
  const handleChangeDocument = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      document_provided_participant:!selectedBlock?.document_provided_participant,
  });
  props.setHasChanged(true)
  };
  const handleConsentPhotos = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      consent_for_photos:!selectedBlock?.consent_for_photos,
  });
  };
  const handlePhotosPublication = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      photos_for_publication:!selectedBlock?.photos_for_publication,
  });
  };
  const handlePhotosMarketing = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      photos_for_marketing:!selectedBlock?.photos_for_marketing,
  });
  };
  const timeConstraints = {
    minutes:{step:15}
  }
  const handleWithout_Application = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      without_application:!selectedBlock?.without_application,
    });
    props.setHasChanged(true)
  };
  const handleIs_Application = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_application:!selectedBlock?.is_application,

    });
    props.setHasChanged(true)
  };
  const handleConsentDate = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      consent_date: e.target.value,
    });
  props.setHasChanged(true)
  };
  const handleProtocolDate = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      protocol_date: e.target.value,
    });
  props.setHasChanged(true)
  };
  const handleConsentSignedDate = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      consent_signed_date: e.target.value,
    });
  props.setHasChanged(true)
  };
  const handleResearchSignedDate = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      research_signed_date: e.target.value,
    });
  props.setHasChanged(true)
  };
  const handleVersionChange = (e) => {
    const { name, value } = e.target;
    setSelectedBlock((selectedBlock) => ({
      ...selectedBlock,
      [name]: value,
    }));
    props.setHasChanged(true)
  };

  let consent_signed_time = ''
  if ( consentTime === true && selectedBlock?.consent_signed_time){
    consent_signed_time = selectedBlock?.consent_signed_time
    ? moment(selectedBlock?.consent_signed_time, "HH:mm").utc().format("HH:mm") : ''
  }else if(selectedBlock?.consent_signed_time){
    consent_signed_time = selectedBlock?.consent_signed_time
  }

  let research_signed_time = ''
  if ( researchTime === true && selectedBlock?.research_signed_time){
    research_signed_time = selectedBlock?.research_signed_time
    ? moment(selectedBlock?.research_signed_time, "HH:mm").utc().format("HH:mm") : ''
  }else if(selectedBlock?.research_signed_time){
    research_signed_time = selectedBlock?.research_signed_time
  }

  const GetSelectedBlockData = () => {
    axios
    .get(`${__API_URL__}/api/ecrf/get_inform_consent/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
    headers: {
    Authorization: `Token ${token}`,
    },
    })
    .then((res) => {
      setSelectedBlock(res.data);
    }).catch((err) => {
    if (err == 'Error: Request failed with status code 401') {
    }
    });
    }

const InformedConsentData = () => {
    const createData = {
            'patient_id': props?.patient_id,
            'visit_number':props?.visit_number,
            'sessionId': props.selectedSessionId,
            ...selectedBlock,
            'consent_signed': selectedBlock?.consent_signed === null ? true : selectedBlock?.consent_signed,
            'document_provided_participant': selectedBlock?.document_provided_participant === null ? true : selectedBlock?.document_provided_participant,
            'consent_for_photos': selectedBlock?.consent_for_photos === null ? true : selectedBlock?.consent_for_photos,
            'photos_for_publication': selectedBlock?.photos_for_publication === null ? false : selectedBlock?.photos_for_publication,
            'photos_for_marketing': selectedBlock?.photos_for_marketing === null ? false : selectedBlock?.photos_for_marketing,
            'without_application':selectedBlock?.without_application === null ? true : selectedBlock?.without_application,
            'is_application':selectedBlock?.is_application === null ? true : selectedBlock?.is_application,
            'consent_signed_time': consent_signed_time,
            'research_signed_time': research_signed_time,
        }
        axios
          .patch(`${__API_URL__}/api/ecrf/update_inform_consent/${props.trialId}/`,createData, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((res) => {
            setConsentTime(false)
            setResearchTime(false)
            setSelectedBlock(res.data);
            props.globalVisits(false);
            props.setModalVisitChangeShow(false)
            props.setHasChanged(false)
            setMessage("Block updated successfully");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        }).catch((err) => {
            if(err == 'Error: Request failed with status code 401'){
            user.actions.logout()
            }
            setMessage("Something Went Wrong");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        });
      };

      React.useEffect(() => {
        if((props?.patient_id && props?.photo_session)){
          GetSelectedBlockData();
        }
      }, [])

        return <>   
                    {
                      ((role==='CRO Admin'  || role==='Cro_coordinator' || role === 'Data Manager') ||  (props.patient_id === undefined)) ?
                        disable=true:''
                    } 
                    <fieldset disabled={disable}>
                    {
                      (props.photo_session || props.patient_id == undefined ) ?
                      <>
                          {
                              (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                              <div className="col-md-12 text-end">
                                  <button onClick={() => InformedConsentData()} className='btn btn-primary'>Update</button>
                              </div>
                              )
                          }
                         <div className='col-md-10'>
                          <div className="row">
                              <div className="col-md-9">
                              <label> Informed consent obtained and HIPAA signed?</label>
                                        <th scope="row">
                                        </th>
                              </div>
                            <div className="col-md-1">                                
                            <p><small>Yes
                                <input
                                        className="form-check"
                                        type="radio"
                                        name="consent_signed"
                                        checked = {selectedBlock?.consent_signed == true}
                                        id="radio1"
                                        value={selectedBlock?.consent_signed == true}
                                        onChange={handleChange}
                                    />
                                </small>
                                </p>
                                </div>
                                <div className="col-md-1">       
                                <p><small> No 
                                    <input
                                          className="form-check"
                                          type="radio"
                                          name="consent_signed"
                                          checked = {selectedBlock?.consent_signed == false}
                                          id="radio1"
                                          value={selectedBlock?.consent_signed == false}
                                          onChange={handleChange}
                                    />
                              </small>
                              </p>
                              </div>
          
                        { selectedBlock.consent_signed === true ?
                        <>
                                <div className='col-md-6 calendarIcon'>
                                <label> Date of consent form used:(must be current, IRB approved)</label>
                                <input     
                                            onChange={handleConsentDate}
                                            class='form-control'
                                            type="date"
                                            timeFormat={false}
                                            value={selectedBlock?.consent_date}
                                            inputProps={{placeholder:'mm/dd/yy', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined) }}
                                            />
                                </div>
                                <div className='col-md-6'>
                                <label>Version # of consent form used:(must be current, IRB approved) </label>
                                <input
                                  style={{ marginLeft: "0em" }}
                                  class="form-control"
                                  type="string"
                                  name='consent_version'
                                  id="flexSwitchCheckDefault"
                                  onChange={(e) => handleVersionChange(e)}
                                  value={selectedBlock?.consent_version}
                                >
                                </input>
                              </div>
                                <div className="col-md-6 calendarIcon">
                                  <label> Date of protocol used:(must be current, IRB approved)</label>
                                      <input     
                                      onChange={handleProtocolDate}
                                      class='form-control'
                                      type="date"
                                      timeFormat={false}
                                      value={selectedBlock?.protocol_date}
                                      inputProps={{placeholder:'mm/dd/yy', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined) }}
                                      />
                                </div>
                                <div className='col-md-6'>
                                <label>Version # of protocol used:(must be current, IRB approved)</label>
                                <input
                                  style={{ marginLeft: "0em" }}
                                  class="form-control"
                                  type="string"
                                  name='protocol_version'
                                  id="flexSwitchCheckDefault"
                                  onChange={(e) => handleVersionChange(e)}
                                  value={selectedBlock?.protocol_version}
                                >
                                </input>
                                </div>
                                <div>
                                  <label> <p>
                                               Please note that the participant, the person conducting the informed consent discussion and the Investigator must
                                              sign and date the consent form on the same day and the California Experimental Research Subject's Bill of Rights.
                                              The signature date should be the same date as the Baseline Visit and obtained prior to the collection of any protected
                                              health information as well as any study-related procedures.

                                        </p></label>
                                </div>
                                  <div className="col-md-6 calendarIcon">
                                        <label>  Date informed consent/HIPAA signed: </label>
                                          <input     
                                            onChange={handleConsentSignedDate}
                                            class='form-control'
                                            type="date"
                                            timeFormat={false}
                                            value={selectedBlock?.consent_signed_date}
                                            inputProps={{placeholder:'mm/dd/yy', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined) }}
                                            />
                                  </div>
                                  <div className='col-md-6'>
                                        <label>  Time informed consent/HIPAA signed: </label>
                                            <input
                                              onChange={(e) => {
                                                setSelectedBlock({...selectedBlock, consent_signed_time: e.target.value});
                                                setConsentTime(true);
                                              }}
                                              class='form-control'
                                              type="time"
                                              value={selectedBlock.consent_signed_time} 
                                              inputProps={{disabled: (role==='CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true:false || (props.patient_id === undefined)}}
                                            />
                                  </div>
                                  <div className="col-md-6 calendarIcon">
                                    <label>  Date California Experimental Research Subjects Bill of Rights signed :</label>
                                          <input     
                                            onChange={handleResearchSignedDate}
                                            class='form-control'
                                            type="date"
                                            timeFormat={false}
                                            value={selectedBlock?.research_signed_date}
                                            inputProps={{placeholder:'mm/dd/yy', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined) }}
                                            />
                                  </div>
                                  <div className="col-md-6">          
                                  <label>  Time California Experimental Research Subjects Bill of Rights signed :</label>
                                      <input
                                              onChange={(e) => {
                                              setSelectedBlock({...selectedBlock, research_signed_time: e.target.value});
                                              setResearchTime(true)
                                            }}
                                              class='form-control'
                                              type="time"
                                              value={selectedBlock.research_signed_time} 
                                              inputProps={{disabled: (role==='CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true:false || (props.patient_id === undefined)}}
                                            />
                                  </div>
                                  <label> 
                                  <p>
                                       Please ensure all signatories have signed and dated the consent.
                                  </p>
                                  </label>
                                  <div>
                                  <label> 
                                  <p>
                                       All consented participants must be entered onto the Participant Screening/Enrolment Log and Photography/Videography Release Log (see Site Binder).
                                  </p>
                                  </label>
                                  </div>
                                  <div className="col-md-9">
                                      <label> Was a signed and dated copy of the informed consent document given to the participant?</label>  
                                      </div>
                                        <div className="col-md-1">                                
                                        <p><small> Yes
                                                <input
                                                    className="form-check"
                                                    type="radio"
                                                    name="document_provided_participant"
                                                    checked = {selectedBlock?.document_provided_participant == true}
                                                    id="radio1"
                                                    value={selectedBlock?.document_provided_participant == true}
                                                    onChange={handleChangeDocument}
                                              />
                                            </small>
                                            </p>
                                        </div> 
                                        <div className="col-md-1">                                
                                        <p><small> No
                                              <input
                                                  className="form-check"
                                                  type="radio"
                                                  name="document_provided_participant"
                                                  checked = {selectedBlock?.document_provided_participant == false}
                                                  id="radio1"
                                                  value={selectedBlock?.document_provided_participant == false}
                                                  onChange={handleChangeDocument}
                                              />
                                          </small>                                              
                                          </p>                                              
                                          </div>
                                      <div className="col-md-10">
                                        { selectedBlock.document_provided_participant === false ?
                                        <>
                                              <label>Provide Reason:</label>
                                              <textarea 
                                                    className="form-control col-md-10"
                                                    style={{marginLeft: "0em"}} 
                                                    type="text" 
                                                    name='reason' 
                                                    id="flexSwitchCheckDefault" 
                                                    onChange={(e) => handleFieldChange(e)}
                                                    value={selectedBlock.reason}
                                              >
                                              </textarea>
                                              </>
                                                :''}
                                        </div>
                        </>:''}
                          <div className="col-md-9">
                            <label> Can photos be used for publication</label>
                            </div>
                                <div className="col-md-1">                                
                                  <p><small> Yes
                                    <input
                                        className="form-check"
                                        type="radio"
                                        name="photos_for_publication"
                                        checked = {selectedBlock?.photos_for_publication == true}
                                        id="radio1"
                                        value={selectedBlock?.photos_for_publication == true}
                                        onChange={handlePhotosPublication}
                                    />
                                </small>
                                </p>
                                </div>
                                <div className="col-md-1">          
                                <p><small> No
                                    <input
                                          className="form-check"
                                          type="radio"
                                          name="photos_for_publication"
                                          checked = {selectedBlock?.photos_for_publication == false}
                                          id="radio1"
                                          value={selectedBlock?.photos_for_publication == false}
                                          onChange={handlePhotosPublication}
                                    />
                              </small>
                              </p>
                    </div>
                <div className="col-md-9">
                    <label> Can photos be used for marketing</label>
                        </div>
                          <div className="col-md-1"> 
                          <p><small> Yes
                                    <input
                                        className="form-check"
                                        type="radio"
                                        name="photos_for_marketing"
                                        checked = {selectedBlock?.photos_for_marketing == true}
                                        id="radio1"
                                        value={selectedBlock?.photos_for_marketing == true}
                                        onChange={handlePhotosMarketing}
                                    />
                                </small>
                                </p>
                                </div>
                                <div className="col-md-1"> 
                                <p><small> No
                                    <input
                                          className="form-check"
                                          type="radio"
                                          name="photos_for_marketing"
                                          checked = {selectedBlock?.photos_for_marketing == false}
                                          id="radio1"
                                          value={selectedBlock?.photos_for_marketing == false}
                                          onChange={handlePhotosMarketing}
                                    />
                              </small>
                              </p>
                              </div>
                <div className="col-md-9">
                         <label> Consent for photos to be taken for the study</label>
                         </div>
                                <div className="col-md-1"> 
                                <p><small> Yes
                                    <input
                                        className="form-check"
                                        type="radio"
                                        name="consent_for_photos"
                                        checked = {selectedBlock?.consent_for_photos == true}
                                        id="radio1"
                                        value={selectedBlock?.consent_for_photos == true}
                                        onChange={handleConsentPhotos}
                                    />
                                    </small>
                                </p>
                                </div>
                                <div className="col-md-1"> 
                                <p><small> No
                                    <input
                                          className="form-check"
                                          type="radio"
                                          name="consent_for_photos"
                                          checked = {selectedBlock?.consent_for_photos == false}
                                          id="radio1"
                                          value={selectedBlock?.consent_for_photos == false}
                                          onChange={handleConsentPhotos}
                                    />
                              </small>
                              </p>
                        </div>
                        <div className="col-md-9">
                        <label> I permit the photography of my whole face without the application of “eye bars” (i.e., black bar covering eyes) </label>
                         </div>
                                <div className="col-md-1"> 
                                <p><small> Yes
                                    <input
                                        className="form-check"
                                        type="radio"
                                        name="without_application"
                                        checked = {selectedBlock?.without_application == true}
                                        id="radio1"
                                        value={selectedBlock?.without_application == true}
                                        onChange={handleWithout_Application}
                                    />
                                    </small>
                                </p>
                                </div>
                                <div className="col-md-1"> 
                                <p><small> No
                                    <input
                                          className="form-check"
                                          type="radio"
                                          name="without_application"
                                          checked = {selectedBlock?.without_application == false}
                                          id="radio1"
                                          value={selectedBlock?.without_application == false}
                                          onChange={handleWithout_Application}
                                    />
                              </small>
                              </p>
                        </div>
                        <div className="col-md-9">
                        <label> I permit the photography of my whole face only with the application of “eye bars”</label>
                         </div>
                                <div className="col-md-1"> 
                                <p><small> Yes
                                    <input
                                        className="form-check"
                                        type="radio"
                                        name="is_application"
                                        checked = {selectedBlock?.is_application == true}
                                        id="radio1"
                                        value={selectedBlock?.is_application == true}
                                        onChange={handleIs_Application}
                                    />
                                    </small>
                                </p>
                                </div>
                                <div className="col-md-1"> 
                                <p><small> No
                                    <input
                                          className="form-check"
                                          type="radio"
                                          name="is_application"
                                          checked = {selectedBlock?.is_application == false}
                                          id="radio1"
                                          value={selectedBlock?.is_application == false}
                                          onChange={handleIs_Application}
                                    />
                              </small>
                              </p>
                        </div>
                    </div>
              </div>
      </> :'Visit is not started' 
            }
        </fieldset>
      </>
    }    
export default Informed_Consent;