import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import MyMapComponent from "../../components/GoogleMap";
import { __API_URL__ }from "../../SERVER_URL";
import axios from "axios";
import useUser from '../../hooks/user';
import MessageBox from "../../components/Alert/MessageBox";
import Swal from "sweetalert2";
import Geocode from "react-geocode";
import AddNotification from "../../components/Modals/AddNotification";
import useFetch from "../../hooks/useFetch";
import "../../../src/App.css"
import ShowNotificationModal from "../../components/Modals/ShowNotificationModal";

const Clinic = () => {

  Geocode.setApiKey("AIzaSyBykNn93LjLI2MEv2PGOnSfpD_11zi1q30");
  Geocode.enableDebug();
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const user = useUser();
  const { Loader,loaderSettingFalse,loaderSettingTrue} = useFetch();

  const [initialOwnersList, setInitialOwnersList] = useState([])
  const [clinicList, setClinicList] = useState([])
  const [ownersList, setOwnersList] = useState([])
  const [adminsList, setAdminsList] = useState([])
  const [AddEmail,setAddEmail]=useState([])
  const [cusMessage, setCusMessage] = useState("")
  const [cusMessageType, setCusMessageType] = useState("cusMessages")
  const [addClinicCheck, setAddClinicCheck] = useState(false)
  const [clinicDetails, setClinicDetails] = useState({
    name: '',
    phone: '',
    address: '',
    lat: 0,
    lng: 0,
    owner_details: {},
    owner: user_data.role == 'Clinic Owner'? user_data.id: user_data.role == 'Clinic Admin' ?  user_data.owner_id : '', 
    clinic_emails: [],
    rewards: false
  })

  const changeClinicDetails = (latt,lngg,Address) => {
      setClinicDetails((clinicDetails)=>({
        ...clinicDetails,
        lat:latt,
        lng:lngg,
        address:Address
      }))
    }

  const AllClinics = () => {
    // setLoader(true);
    axios
      .get(`${__API_URL__}/api/clinic/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setClinicList(res.data);
        // setLoader(false);
        loaderSettingFalse()
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
      });
  };

  const AllOwners = () => {
    // setLoader(true);
    axios
      .get(`${__API_URL__}/api/owners_list/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setInitialOwnersList(res.data);
        // setLoader(false);
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
      });
  };

    const AllAdmins = (clincId) => {
      // setLoader(true);
      axios
        .get(`${__API_URL__}/api/clinicAdminslist/${clincId}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          // let clinic = clinicList.filter((x) => x.id == clincId)
          setAdminsList(res.data)
          // res.data.map((admin) => {
          //   if (clinic[0].clinic_email !== admin.user.email) {
          //     setAdminsList((prev) => {
          //         return [...prev, {value: admin.user.email, label: admin.user.email}]
          //     })
          //   }
          // })
          // setLoader(false);
        }).catch((err) => {
          if(err == 'Error: Request failed with status code 401'){
            user.actions.logout()
            }
        });
    };

  const UpdateClinic = async (id) => {
    // setLoader(true);
    await axios
      .patch(`${__API_URL__}/api/clinic/${id}/`, clinicDetails, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setCusMessage('Clinic Updated Successfully')
        setCusMessageType('success')
        setShowDetails(0)
        setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 100);
        setTimeout(() => {document.getElementById('cusMessages').style.right = '-99rem';}, 3000);
        setTimeout(() => window.location.reload(), 500)
        
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          } else {
            setCusMessage('Clinic Updation Failed')
            setCusMessageType('error')
            setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 100);
            setTimeout(() => {document.getElementById('cusMessages').style.right = '-99rem';}, 3000);
          }
      });
  };

  useEffect(() => {
    AllClinics()
    AllOwners()
  }, [])

  const [showDetails, setShowDetails] = useState(0)

  const clinicDetailHandler = async (e) => {
      if (showDetails == e.target.id) {
         setShowDetails(0)
      } else {
        let clinic = clinicList.filter((x) => x.id == e.target.id)
        setClinicDetails({...clinic[0], lat:clinic[0].location?.latitude, lng:clinic[0].location?.longitude, clinic_emails:clinic[0].clinic_emails ?  clinic[0].clinic_emails.split(','):''})
        clinic[0].owner_details &&
        setOwnersList(initialOwnersList.filter((owner) => owner.id != clinic[0].owner_details.id))
        setShowDetails(e.target.id)
        clinic[0].clinic_email ? setAdminsList([{value: clinic[0].clinic_email, label: clinic[0].clinic_email}]) : setAdminsList([])
        AllAdmins(e.target.id)
      }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClinicDetails((clinicDetails) => ({
    ...clinicDetails,
    [name]: value,
    }));
  };

  const handleCustomDropDown = (getNewOptionData) => {
      const {value} = getNewOptionData
      setClinicDetails((clinicDetails) => ({
        ...clinicDetails,
        clinic_email: value,
        }));
  }

  const handleUpdate = (id) => {
   UpdateClinic(id)
  }

  const handleAddClinic = () => {
     setAddClinicCheck(true)
     setClinicDetails({
      name: '',
      phone: '',
      address: '',
      owner_details: {},
      owner: user_data.role == 'Clinic Owner'? user_data.id: user_data.role == 'Clinic Admin' ?  user_data.owner_id : ''
     })
     setOwnersList(initialOwnersList)
  }

  const AddClinic = async () => {
    // setLoader(true);
    axios
      .post(`${__API_URL__}/api/addclinic/`, clinicDetails, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setCusMessage('Clinic Added Successfully')
        setCusMessageType('success')
        setShowDetails(0)
        setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 100);
        setTimeout(() => {document.getElementById('cusMessages').style.right = '-99rem';}, 3000);
        setTimeout(() => window.location.reload(), 1000)
        
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          } else {
            setCusMessage('Clinic Addition Failed')
            setCusMessageType('error')
            setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 100);
            setTimeout(() => {document.getElementById('cusMessages').style.right = '-99rem';}, 3000);
          }
      });
  };

  // const isEmail = input => {
  //   const re =
  //   /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
  //   if (input.match(re)) return true
  //   return false
  // }

  const handleRefresh = () =>{
    setClinicList([])
    loaderSettingTrue()
    AllClinics()
  }
  const handleAddEmail = (e) => {
      setAddEmail(e.target.value)
}

  const  handleAddEmailChange = (id) => {
        const re =
      /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
        if(AddEmail.match(re)){
          if(clinicDetails.clinic_emails == null){
            setClinicDetails({...clinicDetails,clinic_emails:[AddEmail]})
            setAddEmail("")
          }
          else {
            setClinicDetails({...clinicDetails,clinic_emails:[...clinicDetails.clinic_emails,AddEmail]})
            setAddEmail("")
          }
        }
          else{
            Swal.fire("Error","Please provide Correct Email Address")
          }
  }

  const handleCrossEmail = (id) => {
      let existingArray = clinicDetails.clinic_emails
      let newArray = existingArray.filter((item, index) => index != id)
      setClinicDetails({...clinicDetails, clinic_emails: newArray})
  }   

  return (
    <>
      { Loader ? (
        <div className="loader">
          <img src="https://i.gifer.com/Yb3A.gif" alt="" />
        </div>
     ) : (

     <>
      <MessageBox msg={cusMessage} type={cusMessageType} />
      <NavBar active="clinic" />
      <div className="Clinic">
        <div className="container-fluid">
        {showDetails == 0 ? addClinicCheck ? null : <button type="button" className="btn btn-primary small" style={{float: 'right', marginLeft:'10px'}} onClick={handleAddClinic}>
                        Add Clinic </button> : null }
         <button type="button" className="btn btn-primary small" style={{float: 'right'}} onClick={handleRefresh}>Refresh </button> 
          
          <div className="row ">
            {addClinicCheck ? '' : <div className="col-md-3">
                <div className="clinicCard borderless table-wrapper-scroll-y my-custom-scrollbar2">
                  {clinicList.length > 0 ? clinicList.map((clinic) => (
                    <div className="row align-items-center" key={clinic.id}>
                      <div className="col ml--2" key={clinic.id}>
                        <h6>{clinic.name}</h6>
                        <small>{clinic.address}</small>
                      </div>
                      <div className="col-auto">
                        <button id={clinic.id} type="button" className="btn btn-primary small" onClick={(e) => clinicDetailHandler(e)}>
                          {showDetails == clinic.id ? 'UnSelect' : 'Select' }
                        </button>
                      </div>
                    </div>
                  )) : 'No Clinics'}
                </div>
              </div>
              }
            {showDetails != 0 || addClinicCheck ? 
              <> 
                <div className="col-md-1">
                  {addClinicCheck && <button type="button" className="btn btn-primary small" onClick={(e) => window.location.reload()}>
                    Back
                  </button>}
                  
                </div> 
                <div className="col-md-8">
                  <div className="clinicForm">
                    <form>
                      <div className="tableHeader">
                        <div className="row">
                          <div className="col-md-6">
                            <h5>{ addClinicCheck ? 'Add Information' : 'Edit Information' }</h5>
                          </div>
                          <div className="col-md-6 text-end">
                            <button type="button" className="btn btn-primary m-2" onClick={() => addClinicCheck ? AddClinic() : handleUpdate(showDetails)}>
                              { addClinicCheck ? 'Submit' : 'Update' }
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Clinic Name</label>
                          <input
                            type="text"
                            value={clinicDetails.name}
                            name="name"
                            onChange={handleChange}
                            className="form-control"
                          ></input>
                        </div>
                        <div className="col-md-6">
                          <label>Clinic Phone</label>
                          <input
                            type="tel"
                            value={clinicDetails.phone}
                            name="phone"
                            onChange={handleChange}
                            className="form-control"
                          ></input>
                        </div>
                        {/* <div className="col-md-6">
                          <label>Clinic Open Time</label>
                          <input
                            type="time"
                            value={clinicDetails.clinic_open_time}
                            className="form-control"
                          ></input>
                        </div>
                        <div className="col-md-6">
                          <label>Clinic Close Time</label>
                          <input
                            type="time"
                            value={clinicDetails.clinic_close_time}
                            className="form-control"
                          ></input>
                        </div> */}
                        
                        {user_data.role == 'Super Admin' && <>
                          <div className="col-md-12">
                            <label>Clinic Owner</label>
                            <select className="form-control"
                              value={clinicDetails.owner_details? clinicDetails.owner_details.id: ''}
                              onChange={handleChange}
                              name="owner">
                                {!addClinicCheck && Object.keys(clinicDetails.owner_details).length != 0 ? <><option selected value={clinicDetails.owner_details.id}>
                                  {clinicDetails.owner_details.full_name}
                                </option> </> : <option value='' disabled selected>Select Owner</option>}
                                {ownersList.map((owner) => {
                                  return <>
                                  <option value={owner.id} key={owner.id}>
                                    {owner.full_name}
                                  </option>
                                </>
                                })}
                            </select>
                        </div>
                        </> }
                        { (!addClinicCheck) && <>
                            <div className="col-md-6">
                              <label>Add Email
                              </label>
                              <input type='email' 
                              className="form-control"
                              value={ AddEmail}
                              onChange={handleAddEmail}
                               />
                            </div>
                            <div className="col-md-6">
                              <button type="button" className="btn btn-primary" style={{marginTop:'30px'}} onClick={()=>handleAddEmailChange(showDetails)} >Add </button>
                            </div>
                              <div className="col-md-12">
                                <div className="d-flex flex-row justify-content-start flex-wrap" >
                                { clinicDetails.clinic_emails ?
                                <>
                                 {clinicDetails.clinic_emails.map((item, index) =>(
                                      <div className=" d-flex flex-row border rounded-pill px-2  mx-2" style={{gap:'12px'}} >
                                           <div>
                                              <p className="mt-2" style={{color:'black'}}><small>{item}</small></p>
                                          </div>
                                          <div>
                                                <svg onClick={(e) => handleCrossEmail(index)} style={{marginTop:'12px'}} type="button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                          </div>
                                      </div>
                                  ))}
                                  </>
                                  :
                                  null
                                }
                                </div>
                              </div>
                          </> }
                          {" "}
                          {" "}

                        <div className="col-md-12">
                          <label>Add Rewards For Patients</label>
                          <input
                            type="checkbox"
                            value={clinicDetails.rewards}
                            checked={clinicDetails.rewards}
                            name="rewards"
                            onChange={() => setClinicDetails((clinicDetails) => ({
                              ...clinicDetails, rewards: !clinicDetails.rewards
                            }))}
                            style={{marginLeft: '1em'}}
                            // className="form-control"
                          ></input>
                        </div>
                      </div>
                    </form>
                    <div className="row">
                    <div className="col-md-12">
                          <label>Clinic Address</label>
                          <div
                           style={{ height: "300px", width: "100%", marginBottom: '10px', borderRadius:"8px"}}
                          >
                            <MyMapComponent
                              // setClinicDetails={setClinicDetails}
                              changeClinicDetails={changeClinicDetails}
                              clinicDetails={clinicDetails}
                              isMarkerShown
                              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBykNn93LjLI2MEv2PGOnSfpD_11zi1q30&v=3.exp&libraries=places"
                              loadingElement={<div style={{ height: `100%` }} />}
                              containerElement={<div style={{ height: `250px` }} />}
                              mapElement={<div style={{ height: `100%` }} />}
                            />                            
                          </div>
                                        
                      </div>
                    </div>
                    <div className="col-md-12">
                          <label>Clinic Address</label>
                          <input 
                            readOnly={true}
                            type="text"
                            value={clinicDetails.address}
                            name="address"
                            required='required'
                            onChange={handleChange}
                            className="form-control AdressInput"
                          ></input>
                        </div>
                  </div>
                  {!addClinicCheck && <NotificationSettings  clinicData={clinicList} clinicDetails={clinicDetails} role={user_data.role} user={user} token={token} AllClinics={AllClinics} loader={Loader} loaderTrue={loaderSettingTrue} loaderFalse={loaderSettingFalse} handleRefresh = {handleRefresh}/>}
                  </div>
              </> : ''
            }
            

              {/* 
              <div className="clinicForm">
                <div className="tableHeader">
                  <div className="row">
                    <div className="col-md-6">
                      <h5>Patient Notification Settings</h5>
                    </div>
                    <div className="col-md-6 text-end">
                      <button type="submit" class="btn btn-primary m-2">
                        Add Notification
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <p>No Notifications Available</p>
                </div>
              </div>

              <div className="clinicForm">
                <div className="tableHeader">
                  <div className="row">
                    <div className="col-md-6">
                      <h5>Documents</h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <p>No Documents Available</p>
                </div>
              </div>
              */}
          </div>
        </div>
      </div>
    </>
    )
  }
 </>
  );
            
};

const NotificationSettings = (props) => {
  const PopupOpen = () =>{
    document.getElementById("messageSend").style.display = "block";
  }
  const [selectedFile, setSelectedFile] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem,setSelectedItem]=useState()
  const [fileIsSelected,setFileIsSelected] = useState(false)

  const handleFileChange = (e) => {
      setSelectedFile(e.target.files[0])
      setFileIsSelected(true)
  }

  const handleFileSubmission = (e) => {
    if(fileIsSelected){
      e.preventDefault()
      const formData = new FormData();
      formData.append('document', selectedFile);    
      formData.append('clinic', props.clinicDetails.id);    
      if (props.role == 'Super Admin') formData.append('file_type', 1);
      else formData.append('file_type', 2);
      axios
      .post(`${__API_URL__}/api/clinic/file_upload/`, formData, {
        headers: {
          Authorization: `Token ${props.token}`,
        },
        
      })
      .then((res) => {
        props.loaderTrue()
        props.AllClinics()
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          props.user.actions.logout()
          }
      });
  }
  else
  Swal.fire("Error","Please choose a file")
  }

   const handleDeleteFile = (id) => {
    Swal.fire({
      title: 'Are you sure ?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('File Deleted', '', 'success')
        DeleteFile(id)
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  async function DeleteFile(id){
    try {
      await axios
        .delete(
          `${__API_URL__}/api/clinic/delete_file/${id}/`,
          {
            headers: {
              Authorization: `Token ${props.token}`,
            }
          }
        )
      props.loaderTrue()
      props.handleRefresh()
    } 
    catch (err) {
      if(err == 'Error: Request failed with status code 401'){
        props.user.actions.logout()
        }
    }
  }

  const handleDeleteNotification = () => {
    Swal.fire({
      title: 'Are you sure ?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Notification Deleted', '', 'success')
        DeleteNotification()
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  async function DeleteNotification(){
    try {
        await axios
          .delete(
            `${__API_URL__}/api/clinic/delete_notification/${selectedItem.id}/`,
            {
              headers: {
                Authorization : `Token ${props.token}`
              }  
            }
          )
          setModalShow(false)
          props.loaderTrue()
          props.AllClinics()

    } catch (error) {
      if(error == 'Error :Request failed with status code 401'){
        props.user.logout()
      }
    }
  }


const selectedClinic= props.clinicData.find (item => item.id == props.clinicDetails.id)

const handleAddNotiModal = (item) => {
  setSelectedItem(item)
  setModalShow(true)
}
const convertFirstLetterToCapital = (str) => {
  const newStr= str.charAt(0).toUpperCase()+ str.slice(1)
  return newStr
}

  return (
    <>
      <div className="clinicForm patient_table">
        <div className="tableHeader">
          <div className="row">
            <div className="col-md-6">
              <h5>Patient Notification Settings</h5>
            </div>
            <div className="col-md-6 text-end">
              <button type="button" className="btn btn-primary btn-sm m-2" onClick={PopupOpen}>
                Add Notification
              </button>
            </div>
          </div>
          
        </div>
        <div className="borderless table-wrapper-scroll-y my-custom-scrollbar" >
            <table className="table table-bordered table-striped mb-0">
              { 
                selectedClinic.appointment_reminders.length ?
                  <>
                      <div className="row">
                          <div>
                            <h6>Appointment Reminders ({selectedClinic.appointment_reminders.length}) </h6>
                          </div>
                        </div>
                          <thead>
                            <tr>
                              <th>App</th>
                              <th>SMS</th>
                              <th>Email</th>
                              <th>Days before</th>
                              <th>Days After</th>
                              <th>Title</th>
                            </tr>
                          </thead>
                          <tbody>
                            { selectedClinic.appointment_reminders &&
                              selectedClinic.appointment_reminders.map((item,index)=>(
                                  <>
                            
                              <tr key={index} onClick={() => handleAddNotiModal(item)} >
                              <td>{convertFirstLetterToCapital(`${item.IsApp }`)}</td>
                              <td>{convertFirstLetterToCapital(`${item.IsSMS}`) }</td>
                              <td>{convertFirstLetterToCapital(`${item.IsEmail}`)}</td>
                              <td>{item.days_before}</td>  
                              <td>{item.days_after}</td>  
                              <td>{item.custom_title}</td>
                            </tr>
                            
                                </>
                              )
                              )
                            }
                          </tbody>
                    </>
                  :
                    <>
                      <div className="row">
                        <div className="col-md-12" >
                          <h6>Appointment Reminders</h6>
                            <p className="container" style={{maxWidth:"40%"}} > No Appointment Reminder Found</p>
                          </div>
                        </div>
                    </>
              }

                <br></br>

              { selectedClinic.photo_reminders.length  ?
                  <>
                      <div className="row" >
                        <div className="col-md-12" >
                          <h6>Photo Reminders ({selectedClinic.photo_reminders.length}) </h6>
                        </div>
                      </div>
                      <thead>
                        <tr>
                          <th>App</th>
                          <th>SMS</th>
                          <th>Email</th>
                          <th>Days before</th>
                          <th>Days After</th>
                          <th>Title</th>
                        </tr>
                      </thead>
                      <tbody>
                        { selectedClinic.photo_reminders &&
                          selectedClinic.photo_reminders.map((item,index)=>(
                              <>
                          <tr key={index} onClick={() => handleAddNotiModal(item)} >
                          <td>{convertFirstLetterToCapital(`${item.IsApp }`)}</td>
                          <td>{convertFirstLetterToCapital(`${item.IsSMS}`)}</td>
                          <td>{convertFirstLetterToCapital(`${item.IsEmail}`)}</td>
                          <td>{item.days_before}</td>  
                          <td>{item.days_after}</td>  
                          <td>{item.custom_title}</td>
                        </tr>
                            </>
                          )
                          )
                        }
                      </tbody>
                    </>
                  :
                    <>
                      <div className="row">
                        <div className="col-md-12" >
                          <h6>Photo Reminders</h6>
                          <p className="container" style={{maxWidth:"40%"}} > No Photo Reminder Found</p>
                        </div>
                      </div>
                    </>

              }
              <br></br>

              { selectedClinic.general_reminders.length ?
                    <>
                        <div className="row">
                          <div className="col-md-12" >
                            <h6>General Reminders ({selectedClinic.general_reminders.length}) </h6>
                          </div>
                        </div>
                          <thead>
                            <tr>
                              <th>App</th>
                              <th>SMS</th>
                              <th>Email</th>
                              <th>Days before</th>
                              <th>Days After</th>
                              <th>Title</th>
                            </tr>
                          </thead>
                          <tbody>
                            { selectedClinic.general_reminders &&
                              selectedClinic.general_reminders.map((item,index)=>(
                                  <>
                              <tr key={index} onClick={() => handleAddNotiModal(item)}>
                              <td>{convertFirstLetterToCapital(`${item.IsApp }`)}</td>
                              <td>{convertFirstLetterToCapital(`${item.IsSMS}`)}</td>
                              <td>{convertFirstLetterToCapital(`${item.IsEmail}`)}</td>
                              <td>{item.days_before}</td>  
                              <td>{item.days_after}</td>  
                              <td>{item.custom_title}</td>
                            </tr> 
                                </>
                              )
                              )
                            }
                          </tbody>
                    </>
                    :
                    <>
                      <div className="row">
                        <div className="col-md-12" >
                          <h6>General Reminders</h6>
                          <p className="container" style={{maxWidth:"40%"}} > No General Reminder Found</p>
                        </div>
                      </div>
                    </>
                
                }
            </table>
        </div>
      </div>
      <div className="card">
        <div className=" card-header">
          <div className="row">
            <div className="col-md-12">
              <h5>Documents</h5>
          </div>
         </div>
        </div>
        <div className="card-body">
          <div className="col-md-12">
            <div className="card">
               <form>
                 <input type="file" name="file" onChange={(e) => handleFileChange(e)} required />
                 <button className="btn btn-primary btn-sm m-2" onClick={handleFileSubmission}>Upload</button>
               </form>
             </div>
          </div>
        <div className="row documentShow">
          <div className="col-md-6">
            <div className="clinicForm card">
              <div className="tableHeader">
                <h6>From Appiell</h6>
                </div>
                  <div className="">
                    {
                      selectedClinic.files_by_super.length ?
                      selectedClinic.files_by_super.map((item,index)=>
                      <div className="row">
                        <div className="col-md-10"  key={item.id} >
                          <a href={item.document} download ><p>{item.document}</p></a>
                        </div>
                        <br></br>
                          <div className="col-md-2">
                            <svg type="button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg" viewBox="0 0 16 16" onClick={()=>handleDeleteFile(item.id)}>
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                            </svg>
                          </div>
                      </div>
                      )
                      :
                      <span>No Documents Found</span>
                    }
                  </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="clinicForm">
              <div className="tableHeader">
                <h6>From Clinic</h6>
              </div>
              <span>No document Available</span>
            </div>
          </div>
        </div>
        </div>
      </div>
      <AddNotification token={props.token} id={props.clinicDetails.id} AllClinics={props.AllClinics} />
      {
        modalShow 
         ?
        <ShowNotificationModal show={modalShow} onHide={() => setModalShow(false)} selectedItem={selectedItem} handleDelete={handleDeleteNotification} /> 
        :
        null
      }
    </>
  );
};

export default Clinic;
