import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import { useParams } from "react-router";
import { toast } from "react-toastify";


const roles = ["Inv_coordinator", "Investigator", "Sub_investigator"];

const roleDisplayNames = {
  Inv_coordinator: "CRC",
  Investigator: "Investigator",
  "Sub_investigator": "Sub-Investigator",
};

const GenerateQueryPopUp = ({
  showRolesModal,
  setShowRolesModal,
  answerJsonId,
  QueryLogData,
}) => {
  const [selectedRoles, setSelectedRoles] = React.useState([
    "Inv_coordinator"
  ]);
  const { _id_ } = useParams();
  const token = localStorage.getItem("token");

  const userRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(typeof value === "string" ? value.split(",") : value);
  };

  const handleGenerateQuery = () => {
    if (selectedRoles.length === 0) {
      toast.error("Select Role for Generating a Query");
      return;
    }
    const data = {
      user_roles: selectedRoles,
      answerjson_id: answerJsonId,
    };
    axios
      .post(
        `${__API_URL__}/api/ecrf/generate_query/?patient_id=${_id_}`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setShowRolesModal(false);
        toast.success(response.data.message);
        QueryLogData();
      })
      .catch(() => {
        toast.error();
      });
  };

  return (
    <>
      <Modal
        size="md"
        centered
        show={showRolesModal}
        onHide={() => setShowRolesModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Generate a Query
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <InputLabel>
            {selectedRoles.length > 1 ? "Select Roles" : "Select Role"}
          </InputLabel>
          <Select
            className="form-control"
            multiple
            value={selectedRoles}
            onChange={userRoleChange}
            variant="outlined"
            renderValue={(selected) =>
              selected.map((name) => roleDisplayNames[name]).join(", ")
            }
          >
            {roles.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={selectedRoles.indexOf(name) > -1} />
                <ListItemText primary={roleDisplayNames[name]} />
              </MenuItem>
            ))}
          </Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleGenerateQuery}>
            Generate
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GenerateQueryPopUp;
