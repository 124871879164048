import React, { useState, useEffect } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Table from "react-bootstrap/Table";

const Global_Severity_Score = (props) => {
  const [consentFormOption, setConsentFormOption] = React.useState("list");
  const [value, newValue] = React.useState("list");
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const setMessage = props.setMessage;
  const setTypeOfMessage = props.setTypeOfMessage;
  let disable = false;
  const [selectedBlock, setSelectedBlock] = useState({});
  const user = useUser();
  const GRADE_CHOICES = [
    {
      Score: 0,
      Description:
        "0: Clear : Normal, clear skin with no evidence of acne vulgaris",
      value: "Clear",
    },
    {
      Score: 1,
      Description:
        "1: Almost Clear: Rare non-inflammatory lesions present, with rare non-inflamed papules (papules must be resolving and may be hyperpigmented, though not pink-red",
      value: "Almost Clear",
    },
    {
      Score: 2,
      Description:
        "2: Mild: Some non-inflammatory lesions are present, with few inflammatory lesions papules/pustules only; no nodulocystic lesions)",
      value: "Mild",
    },
    {
      Score: 3,
      Description:
        "3: Moderate: Non-inflammatory lesions predominate, with multiple inflammatory lesions evident: several to many comedones and papules/pustules, and there may or may not be one nodulocystic lesion",
      value: "Moderate",
    },
    {
      Score: 4,
      Description:
        "4: Severe: Inflammatory lesions are more apparent, many comedones and papules/pustules, there may or may not be up to 2 nodulocystic lesions",
      value: "Severe",
    },
  ];
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedBlock((selectedBlock) => ({
      ...selectedBlock,
      [name]: value,
    }));
    props.setHasChanged(true);
  };

  const handleChange = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_score_assessed: !selectedBlock.is_score_assessed,
    });
    props.setHasChanged(true);
  };

  const GetSelectedBlockData = () => {
    axios
      .get(
        `${__API_URL__}/api/ecrf/get_globalseverity/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedBlock(res.data);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
        }
      });
  };

  const AllVisitsData = () => {
    const createData = {
      patient_id: props?.patient_id,
      visit_number: props?.visit_number,
      grade: selectedBlock?.grade,
      is_score_assessed: selectedBlock?.is_score_assessed === undefined ? true : selectedBlock?.is_score_assessed,
      spcecify_score_access: selectedBlock?.spcecify_score_access,      
      sessionId: props.selectedSessionId,
    };
    axios
      .patch(
        `${__API_URL__}/api/ecrf/update_globalseverity/${props.trialId}/`,
        createData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        props.setModalVisitChangeShow(false);
        props.setHasChanged(false);
        setSelectedBlock(res.data);
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
          user.actions.logout();
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      });
  };

  React.useEffect(() => {
    if (props?.patient_id && props?.photo_session) {
      GetSelectedBlockData();
    }
  }, []);

  return (
    <>
      {role === "CRO Admin" ||
      role === "Cro_coordinator" ||
      role === "Data Manager" ||
      props.patient_id === undefined
        ? (disable = true)
        : ""}
      <fieldset disabled={disable}>
        {props.photo_session || props.patient_id == undefined ? (
          <>
            {(props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
              <>
                <div className="col-md-12 text-end">
                  <button
                    onClick={() => AllVisitsData()}
                    className="btn btn-primary"
                  >
                    Update
                  </button>
                </div>
              </>
            )}
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-9">
                  <p> Was the global severity score assessed at this visit?</p>
                </div>
                <div className="col-md-1">
                  <p>
                    <small>
                      {" "}
                      Yes
                      <input
                        className="form-check-input mx-1"
                        type="radio"
                        name="is_score_assessed"
                        checked={selectedBlock?.is_score_assessed == true}
                        id="radio1"
                        value={selectedBlock?.is_score_assessed == true}
                        onChange={handleChange}
                      />
                    </small>
                  </p>
                </div>
                <div className="col-md-1">
                  <p>
                    <small>
                      {" "}
                      No
                      <input
                        className="form-check-input mx-1"
                        type="radio"
                        name="is_score_assessed"
                        checked={selectedBlock?.is_score_assessed == false}
                        id="radio1"
                        value={selectedBlock?.is_score_assessed == false}
                        onChange={handleChange}
                      />
                    </small>
                  </p>
                </div>
                {selectedBlock.is_score_assessed === false ? (
                  <div className="col-md-6">
                    <label> if No, specify: </label>
                    <input
                      class="form-control"
                      type="text"
                      style={{ borderWidth: "1px" }}
                      name="spcecify_score_access"
                      id="flexSwitchCheckDefault"
                      value={selectedBlock.spcecify_score_access}
                      onChange={(e) => handleFieldChange(e)}
                    ></input>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-7">
                  <label>
                     Circle the score that represents the participants acne
                    global severity the best.*
                  </label>
                  <select
                    className="form-control"
                    name="grade"
                    style={{ borderWidth: "1px" }}
                    value={selectedBlock.grade}
                    onChange={(e) => handleFieldChange(e)}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {GRADE_CHOICES.map((grade) => {
                      return (
                        <>
                          <option value={grade.value}>
                            {grade.Description}{" "}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        ) : (
          "Visit is not started"
        )}
      </fieldset>
    </>
  );
};
export default Global_Severity_Score;
