import React from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "../../Pages/Patient/PatientMenu";
import Breadcrumbs from "../../components/breadcrumbs";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Modal from "react-bootstrap/Modal";
import Datetime from "react-datetime";
import moment from 'moment';
import { toast, ToastContainer } from "react-toastify";

const ManuallyMarkVisit = () => {
  const { _id_ } = useParams();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const [Loader, setLoader] = useState(false);
  const role = user_data.role;
  const [visitData, setVisitData] = useState([]);
  const [infoData, setInfoData] = useState({});
  const [trialFilter, setTrialFilter] = useState("");
  const token = localStorage.getItem("token");
  const [open, setOpen] = React.useState(false);
  const [allowClick, setAllowClick] = useState(false);
  const [statusChangePayload, setStatusChangePayload] = useState({
    patientStartDate: moment(),
    patientEndDate: moment(),
    status: '',
    visitNumber: 0,
    trialId: 0
  })
  const navigate = useNavigate();

  const SinglePatient = () => {
    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      });
  };

  const getVisitData = () => {
    setLoader(true);
    axios
      .get(
        `${__API_URL__}/api/patient/patient/all/get_visits_data/?patient_id=${_id_}&wrinkles=${true}&trial_name=${trialFilter}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setVisitData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
        }
      });
  };
  useEffect(() => {
    SinglePatient();
  }, []);

  useEffect(() => {
    getVisitData();
  }, [trialFilter]);

  const routeTo = (id) => {
    navigate("/patient/" + _id_ + "/status/" + id);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleSaveVisitStatus = () => {
    axios
        .post(`${__API_URL__}/api/photos/change_visit_status/`, {
            ...statusChangePayload,
            patientId: _id_
        }, {
        headers: {
            Authorization: `Token ${token}`,
        },
        })
        .then((res) => {
            handleClose(false)
            toast.success("Visit Status Changed Successfully");
            getVisitData()
        })
        .catch((err) => {
            toast.error(err.response.data.error);
        });
  }
  
  return (
    <>
      <ToastContainer />
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs
          B="Visit Status"
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
        />
        <div className="patient_table patient_photos">
          <PatientMenu active="status" data={infoData} />
          <div className="tableHeader">
            <br />
          </div>
          {Loader ? (
            <div className="loader">
              <img src="https://i.gifer.com/Yb3A.gif" alt="" />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  {(role === "Investigator" ||
                    role === 'Sub_investigator' ||
                    role === "Inv_coordinator" ||
                    role === "Super Admin") && (
                    <>
                      <th>Trial Name</th>
                      <th>Visit Type</th>
                      <th>Visit Name</th>
                      <th>Visit Open Date</th>
                      <th>Visit Close Date</th>
                      <th>Patient Start Date</th>
                      <th>Patient End Date</th>
                      <th>Active Status</th>
                      {
                        user_data.role !== "Super Admin" && <>
                          <th>Change Visit Status</th>
                        </>
                      }
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {visitData?.map((data) => (
                  <>
                    {data?.visit_session?.map((visit) => {
                      if (![-1].includes(visit.visit_number) ) {
                        return (
                          <tr
                            key={visit?.id}
                            onClick={allowClick ? (e) => routeTo(visit?.id) : null}
                          >
                            <td>
                              {visit?.trial_area}
                            </td> 
                            <td>{visit?.visit_type ? visit?.visit_type : "Regular"}</td>
                            <td>
                            {
                              visit.visit_type === "Unscheduled Event" 
                              ? visit.user_selected_name || "-" 
                              : visit.visit_type === "Adverse Event" 
                                ? visit.user_selected_name || "-" 
                                : visit.visit_name 
                                  ? visit.visit_name 
                                  : visit.visit_type 
                                    ? visit.visit_type 
                                    : `Visit ${visit.visit_number}`
                            }
                            </td>
                            <td>
                              {visit.actual_date ? (
                                <>
                                  {new Date(visit.actual_date + "Z").toDateString()}
                                  ,&nbsp;
                                  {new Date(
                                    visit.actual_date + "Z"
                                  ).toLocaleTimeString()}
                                </>
                              ) : (
                                "-"
                              )}
                            </td>
    
                            <td>
                              {visit.visit_close_date ? (
                                <>
                                  {new Date(
                                    visit.visit_close_date + "Z"
                                  ).toDateString()}
                                  ,&nbsp;
                                  {new Date(
                                    visit.visit_close_date + "Z"
                                  ).toLocaleTimeString()}
                                </>
                              ) : (
                                "-"
                              )}
                            </td>
    
                            {visit?.start_date && visit?.visit_status !== 'Not Opened' && !visit?.is_skipped ? (
                              <td
                                style={{
                                  textAlignLast: "left",
                                  textAlign: "center",
                                }}
                              >
                                {new Date(visit?.start_date + "Z").toDateString()}
                                ,&nbsp;
                                {new Date(
                                  visit?.start_date + "Z"
                                ).toLocaleTimeString()}
                              </td>
                            ) : (
                              <td
                                style={{
                                  textAlignLast: "center",
                                  textAlign: "center",
                                }}
                              >
                                -
                              </td>
                            )}
                            {visit?.is_skipped ? (
                              <td
                                style={{
                                  textAlignLast: "center",
                                  textAlign: "center",
                                }}
                              >
                                -
                              </td>
                            ) : visit?.completed_date && visit?.visit_status !=='Not Opened' && !visit?.is_skipped ? (
                              <td
                                style={{
                                  textAlignLast: "left",
                                  textAlign: "center",
                                }}
                              >
                                {new Date(
                                  visit?.completed_date + "Z"
                                ).toDateString()}
                                ,&nbsp;
                                {new Date(
                                  visit?.completed_date + "Z"
                                ).toLocaleTimeString()}
                              </td>
                            ) : (
                              <td
                                style={{
                                  textAlignLast: "center",
                                  textAlign: "center",
                                }}
                              >
                                -
                              </td>
                            )}
                            {
                              <td
                                style={{
                                  textAlignLast: "center",
                                  textAlign: "center",
                                }}
                              >
                                {visit?.visit_status}
                              </td>
                            }
                            {
                              user_data.role !== "Super Admin" && <>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-primary m-2"
                                    onClick={() => {
                                      handleOpen(true);
                                      if (visit?.visit_status === "Completed") {
                                        setStatusChangePayload((prev) => {return {...prev, patientStartDate: moment.utc(visit?.start_date).local(), patientEndDate: moment.utc(visit?.completed_date).local(), status: visit?.visit_status, visitNumber: visit?.visit_number, trialId: data?.trial_id}})
                                      } else {
                                        setStatusChangePayload((prev) => {return {...prev, patientStartDate: visit?.start_date ? moment.utc(visit?.start_date).local() : moment.utc(), patientEndDate: visit?.completed_date ? moment.utc(visit?.completed_date).local() : moment(), status: visit?.visit_status, visitNumber: visit?.visit_number, trialId: data?.trial_id}})
                                      }
                                    }  
                                    }
                                  >
                                    Update
                                  </button>
                                </td>
                              </>
                            }
                          </tr>
                        )
                      }
                    } 
                    )}
                  </>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {open && (
        <Modal
          show
          onHide={handleClose}
          centered
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Status</label>
            <select className="form-control" disabled={["Completed", "Skipped"].includes(statusChangePayload.status)} value={statusChangePayload.status} onChange={(e) => {setStatusChangePayload((prev) => {return {...prev, status: e.target.value}})}}>
              <option value="InComplete">InComplete</option>
              <option value="Skipped">Skipped</option>
              <option value="Completed">Completed</option>
            </select>
            {
             ['Completed', 'InComplete'].includes(statusChangePayload.status) && <>
                <div className="col-md-12 calendarIcon">
                    <label>Patient Start Date</label>
                    <i className="fa fa-calendar-o" aria-hidden="true"></i>
                    <Datetime
                        onChange={(e) => {setStatusChangePayload((prev) => {return {...prev, patientStartDate: e}})}}
                        value={statusChangePayload.patientStartDate}
                        inputProps={{ placeholder: "dd/mm/yy 00:00 AM" }}
                    />
                </div>
                {
                  statusChangePayload.status === "Completed" && 
                    <div className="col-md-12 calendarIcon">
                        <label>Patient Complete Date</label>
                        <i className="fa fa-calendar-o" aria-hidden="true"></i>
                        <Datetime
                            onChange={(e) => {setStatusChangePayload((prev) => {return {...prev, patientEndDate: e}})}}
                            value={statusChangePayload.patientEndDate}
                            inputProps={{ placeholder: "dd/mm/yy 00:00 AM" }}
                        />
                    </div>
                  }
                  </>
                }
                <div className="col-md-12" style={{ textAlign: "end" }}>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm m-2"
                    onClick={() => {handleSaveVisitStatus()}}
                    >
                    Save
                    </button>  
                </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ManuallyMarkVisit;
