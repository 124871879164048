import NavBar from "../../components/navBar/navBar";
import PatientMenu from "../Patient/PatientMenu";
import Breadcrumbs from "../../components/breadcrumbs";
import { Card } from "@mui/material";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

const VideoDetail = () => {
  const { _id_, caseId } = useParams();
  const token = localStorage.getItem("token");
  const [video, setVideo] = useState([]);
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const [infoData, setInfoData] = useState({})
  const role = user_data.role
  const [Loader, setLoader] = useState(true);

  const getSingleVideoList = () => {
    setLoader(true);
    axios
      .get(`${__API_URL__}/api/photos/single_photo_session/${caseId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setLoader(false);
        setVideo(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }

  const SinglePatient = () => {
    axios
    .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
        headers: {
        Authorization: `Token ${token}`,
        },
        
    })
    .then((res) => {
        setInfoData(res.data)
    });
}

  useEffect(() => {
    getSingleVideoList();
    SinglePatient();
  }, [])

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <div className="patient_table patient_photos">
          <Breadcrumbs B='Videos'
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
          ></Breadcrumbs>
          <PatientMenu active="videos" data={infoData}/>
          {Loader ? (
              <div className="loader">
                <img src="https://i.gifer.com/Yb3A.gif" alt="" />
              </div>
            ) : (
              <>
            <Card className="col-md-12" >
            <div className="container">
              <h4 className="my-5"></h4> 
                 <div className="App"> 
                {
                  video.video ?
                <video
                    style={{ maxWidth: "50%", width: "300px", margin: "0 auto" }}
                    playsInline
                    controls
                    alt="All the devices"
                    src={__API_URL__ + video?.video}
        />
        :"No video is attached with this session!"
                }
        </div>
            </div>
            </Card>
            </>)}
        </div>
      </div>
    </>
  )
}
export default VideoDetail;

