import { useState, React, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";

const DrugMin = ({ setMiniDrug, trial, setMessage, setTypeOfMessage }) => {
  const token = localStorage.getItem("token");
  const [drugValue, setDrugValue] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDrugValue((newDrug) => ({
      ...newDrug,
      [name]: value,
    }));
  };

  const GetDrugValue = () => {
    axios
      .get(`${__API_URL__}/api/photos/get_drug_value/${trial}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setDrugValue(res.data);
      });
  };

  const updateDrugValue = (e) => {
    e.preventDefault();
    axios
      .patch(
        `${__API_URL__}/api/photos/update_drug_value/${trial}/`,
        { ...drugValue, min_value: drugValue.minimum_value },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setDrugValue(res.data);
        setMiniDrug(false)
        setMessage("Drug value updated successfully!");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setTypeOfMessage("error");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 5000);
      });
  };

  useEffect(() => {
    GetDrugValue();
  }, []);

  return (
    <div>
      <Modal
        size="md"
        show
        onHide={() => {
          setMiniDrug(false);
        }}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Minimum Drug Value
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <form
            onSubmit={(e) => {
              updateDrugValue(e);
            }}
          >
            <div className="form-group">
              <label htmlFor="question">Minimum Value</label>
              <input
                type="number"
                className="form-control"
                id="name"
                name="minimum_value"
                min="0"
                value={drugValue?.minimum_value}
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Enter value"
                required
              />
            </div>

            <div className="container MX-5 d-flex justify-content-end">
              <div className="row">
                <div className="col-sm">
                  <button
                    onClick={() => {
                      setMiniDrug(false);
                    }}
                    className="btn btn-secondary my-2"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-sm">
                  <button type="submit" className="btn btn-primary my-2">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DrugMin;
