import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ShowNotificationModal = (props) => {

  const closeModal = () => {
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Notifications
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form className="form-outline">
            <label>Notification Type</label>
            <input
              type="text"
              className="form-control"
              defaultValue={props.selectedItem.notification_type.type}
              disabled
            ></input>

            <label>Custom Title</label>
            <input
              type="text"
              className="form-control"
              defaultValue={props.selectedItem.custom_title}
              disabled
            ></input>

            <label className="form-label" for="textAreaExample">
              Custom Data
            </label>
            <textarea
              className="form-control"
              defaultValue={props.selectedItem.custom_data}
              disabled
            ></textarea>

            <label>{props.selectedItem.notification_type.type == "APPOINTMENT_REMINDER" ? 'Days-Before' : 'Days-After' }</label>
            <input 
                 type="number" 
                 className="form-control" 
                 disabled 
                 defaultValue={props.selectedItem.notification_type.type == "APPOINTMENT_REMINDER" ? props.selectedItem.days_before : props.selectedItem.days_after} 
            />
            <br></br>

            <label>Notification</label>
            <div style={{ display: "block", "margin-bottom": "14px" }}>
              <input
                type="checkbox"
                name="typeApp"
                checked={props.selectedItem.IsApp == true}
                disabled
              />
              <label style={{ "padding-right": "22px" }}>&nbsp; In App</label>
              <input
                type="checkbox"
                name="typeSms"
                checked={props.selectedItem.IsSMS == true}
                disabled
              />
              <label style={{ "padding-right": "22px" }}>&nbsp; SMS</label>
              <input
                type="checkbox"
                name="typeEmail"
                checked={props.selectedItem.IsEmail == true}
                disabled
              />
              <label style={{ "padding-right": "22px" }}>&nbsp; Email</label>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.handleDelete}>Delete</Button>
        <Button onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowNotificationModal;
