import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";

const PhotoGraphic_App = (props) => {
  const [selectedBlock, setSelectedBlock] = useState({})
  const token = localStorage.getItem("token");
  const user = useUser();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  let disable = false
  const handleFieldChange = (e) => {
    const { name, value, checked, type } = e.target;
    setSelectedBlock((prevBlock) => ({
      ...prevBlock,
      [name]: type === 'checkbox' ? (checked ? value : '') : (type === 'radio' ? (value === 'true') : value),
    }));
    props.setHasChanged(true)
  };
  const Update_PhotoGraphic = () => {
    const createData = {
      'patient_id': props?.patient_id,
      'visit_number': props?.visit_number,
      'sessionId': props.selectedSessionId,
      ...selectedBlock
    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_photographic_app/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      });
  };
  const get_PhotoGraphic = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_photographic_app/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }
  React.useEffect(() => {
    if ((props?.patient_id && props?.photo_session)) {
      get_PhotoGraphic();
    }
  }, [])
  React.useEffect(() => {
    if (role === 'CRO Admin') {
      setSelectedBlock(prevState => ({
        ...prevState,
        is_download: true,
        is_participant_trained: true,
        is_lesion_performed: true,
      }));
    }
  }, [role]);
  return <>
    {
      ((role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') || (props.patient_id === undefined)) ?
        disable = true : ''
    }
    <fieldset disabled={disable}>
      {
        (props.photo_session || props.patient_id == undefined) ?
          <>
            {
              (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                <div className="col-md-12 text-end">
                  <button onClick={() => Update_PhotoGraphic()} className='btn btn-primary'>Update</button>
                </div>
              )
            }
            <div className='col-md-10'>
              <div className="row">
                <div className="col-md-9">
                  <label> Were instructions to download and use the photographic app dispensed to the
                    participant at this visit?</label>
                </div>
                <div className="col-md-1">
                  <p><small> Yes
                    <input
                      className="form-check"
                      type="radio"
                      name="is_download"
                      checked={selectedBlock?.is_download === true || (!selectedBlock && selectedBlock?.is_download === undefined)}
                      id="is_download"
                      value={true}
                      key='is_download'
                      onChange={handleFieldChange}
                    />
                  </small>
                  </p>
                </div>
                <div className="col-md-1">
                  <p><small> No
                    <input
                      className="form-check"
                      type="radio"
                      name="is_download"
                      checked={selectedBlock?.is_download === false || (!selectedBlock && selectedBlock?.is_download === undefined)}
                      id="is_download"
                      value={false}
                      onChange={handleFieldChange}
                      key='is_download'
                    />
                  </small>
                  </p>
                </div>
                {selectedBlock.is_download ?
                  <div className='col-md-8'>
                    <label> Reason:</label>
                    <input
                      class="form-control"
                      style={{ marginLeft: "0em", borderWidth: '1px' }}
                      type="text"
                      name='not_download_reason'
                      id="flexSwitchCheckDefault"
                      value={selectedBlock?.not_download_reason}
                      onChange={(e) => handleFieldChange(e)}
                    >
                    </input>
                  </div> : ''
                }
                <div className="col-md-9">
                  <label> Was the participant trained on the use of the photographic app on site at this visit?</label>
                </div>
                <div className="col-md-1">
                  <p><small> Yes
                    <input
                      className="form-check"
                      type="radio"
                      name="is_participant_trained"
                      checked={selectedBlock?.is_participant_trained === true || (!selectedBlock && selectedBlock?.is_participant_trained === undefined)}
                      id="is_participant_trained"
                      value={true}
                      onChange={handleFieldChange}
                    />
                  </small>
                  </p>
                </div>
                <div className="col-md-1">
                  <p><small> No
                    <input
                      className="form-check"
                      type="radio"
                      name="is_participant_trained"
                      checked={selectedBlock?.is_participant_trained === false || (!selectedBlock && selectedBlock?.is_participant_trained === undefined)}
                      id="is_participant_trained"
                      value={false}
                      onChange={handleFieldChange}
                    />
                  </small>
                  </p>
                </div>
                {selectedBlock.is_participant_trained ?
                  <div className='col-md-8'>
                    <label> Reason:</label>
                    <input
                      class="form-control"
                      style={{ marginLeft: "0em", borderWidth: '1px' }}
                      type="text"
                      name='not_trained_reason'
                      id="flexSwitchCheckDefault"
                      value={selectedBlock?.not_trained_reason}
                      onChange={(e) => handleFieldChange(e)}>
                    </input>
                  </div> : ''
                }
                <div className="col-md-9">
                  <label>Was the photography performed lesion at this visit?</label>
                </div>
                <div className="col-md-1">
                  <p><small> Yes
                    <input
                      className="form-check"
                      type="radio"
                      name="is_lesion_performed"
                      checked={selectedBlock?.is_lesion_performed === true || (!selectedBlock && selectedBlock?.is_lesion_performed === undefined)}
                      id="is_lesion_performed"
                      value={true}
                      onChange={handleFieldChange}
                    />
                  </small>
                  </p>
                </div>
                <div className="col-md-1">
                  <p><small> No
                    <input
                      className="form-check"
                      type="radio"
                      name="is_lesion_performed"
                      checked={selectedBlock?.is_lesion_performed === false || (!selectedBlock && selectedBlock?.is_lesion_performed === undefined)}
                      id="is_lesion_performed"
                      value={false}
                      onChange={handleFieldChange}
                    />
                  </small>
                  </p>
                </div>
                {selectedBlock.is_lesion_performed ?
                  <div className='col-md-8'>
                    <label> Reason:</label>
                    <input
                      class="form-control"
                      style={{ marginLeft: "0em", borderWidth: '1px' }}
                      type="text"
                      name='not_performed_reason'
                      id="flexSwitchCheckDefault"
                      value={selectedBlock?.not_performed_reason}
                      onChange={(e) => handleFieldChange(e)}>
                    </input>
                  </div> : ''
                }
              </div>
            </div>
          </> : 'Visit is not started'
      }
    </fieldset>
  </>
}
export default PhotoGraphic_App;