import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const RetakePhotoPopUp = ({handleRetakePhoto,setModalRetake}) => {
return (
    <>
{
     <>
        <Modal size="md" show onHide={() => setModalRetake(false)}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Unsaved Changes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <p className="text-center">“Are you sure?”, You want to delete this photo.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(e) => {setModalRetake(false)}}>Cancel</Button>
                <Button variant="primary" onClick={() => handleRetakePhoto()}>Ok</Button>
            </Modal.Footer>
        </Modal>
    </>
}
</>
    )
}

export default RetakePhotoPopUp;