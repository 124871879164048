import { React, useState, useEffect } from "react";
import AddChoice from "./AddChoice";
import { toast, ToastContainer } from "react-toastify";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import Select from "react-select";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Modal from "react-bootstrap/Modal";

const AddQuestion = ({
  AllQuestionsDropdown,
  setNewQuestion,
  newQuestion,
  setEditQuestion,
  editQuestion,
  questionIndex,
  handleChoicesFromSurvey
}) => {
  const token = localStorage.getItem("token");
  const [question, setQuestion] = useState({
    choice: [],
  });
  const [newChoice, setNewChoice] = useState(false);
  const [editChoice, setEditChoice] = useState("");
  const [photoType, setPhotoType] = useState([]);
  const [allChoice, setAllChoice] = useState([]);
  const [choiceCount, setChoiceCount] = useState([1]);
  const [choiceList, setChoiceList] = useState([]);
  const [changeChoice, setChangeChoice] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disableOrderNumber, setDisableOrderNumber] = useState(true);
  const [choiceIndex, setChoiceIndex] = useState(-1);

  const handleChoices = (e, index) => {
    const { label, value } = e;
    setDisableOrderNumber(false);
    const choiceArray = question?.choice ? [...question.choice] : [];

    if (index >= 0 && index < choiceArray.length) {
      choiceArray[index] = { label: label, value: value };
      if (!choiceArray[index].order) choiceArray[index]["order"] = index + 1
    } else {
      choiceArray.push({ label: label, value: value, order_number: index + 1 });
    }
    setQuestion({
      ...question,
      choice: choiceArray,
    });

    setChangeChoice(true);
    setDisabled(false);
  };

  const handleOrderNumber = (e, index) => {
    const ChoiceArray = question?.choice ? [...question.choice] : [];

    if (index >= 0 && index < ChoiceArray.length) {
      ChoiceArray[index] = {
        ...ChoiceArray[index],
        order_number: e.target.value,
      };
    } else {
      ChoiceArray.push({ order_number: e.target.value });
    }
    setQuestion({
      ...question,
      choice: ChoiceArray,
    });
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      setQuestion({
        ...question,
        type: value === "Multi-choice" ? "Choice" : value,
        multi_choice: value === "Multi-choice",
      });
    } else {
      setQuestion({
        ...question,
        [name]: value,
      });
    }
  };

  const handleEdit = (value, index) => {
    setEditChoice(value);
    setNewChoice(true);
  };

  const handleNewChoice = (index) => {
    setNewChoice(true);
    setChoiceIndex(index)
  };

  const handleAddChoice = () => {
    const nextValue = choiceCount[choiceCount.length - 1] + 1;
    const updatedChoiceCount = [...choiceCount, nextValue];
    setChoiceCount(updatedChoiceCount);
    setDisabled(true);
    setDisableOrderNumber(true);
  };

  const handleRemoveChoice = (index) => {
    if (choiceCount.length === 1) {
      toast.error("At least one choice is necessary.");
    } else {
      const updatedChoiceCount = choiceCount.slice();
      updatedChoiceCount.splice(index, 1);

      setChoiceCount(updatedChoiceCount);

      setQuestion((prevQuestion) => {
        const updatedChoice = prevQuestion.choice.slice();
        updatedChoice.splice(index, 1);

        return {
          ...prevQuestion,
          choice: updatedChoice,
        };
      });
    }
  };

  const AllPhotoType = () => {
    axios
      .get(`${__API_URL__}/api/photos/visit-photo-types/web/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPhotoType(res.data);
      })
      .catch((err) => {
        console.log("Something Went Wrong");
      });
  };

  const AllChoices = (e) => {
    axios
      .post(
        `${__API_URL__}/api/survey/get_choices`,
        { choices: question?.choice },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setAllChoice(res.data);
        setChangeChoice(false);
      })
      .catch((err) => {
        console.log("Something Went Wrong");
      });
  };

  const CreateQuestion = (e) => {
    e.preventDefault();
    if (editQuestion?.value) {
      axios
        .patch(
          `${__API_URL__}/api/survey/edit_question/${editQuestion?.value}/`,
          { ...question, choices: choiceList },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success("Question Updated");
          AllQuestionsDropdown();
          setChangeChoice(false);
          setNewQuestion(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something Went Wrong");
        });
    } else {
      axios
        .post(
          `${__API_URL__}/api/survey/create_question`,
          { ...question, choices: choiceList },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success("Question Added");
          AllQuestionsDropdown();
          setNewQuestion(false);
          handleChoicesFromSurvey({label: res.data.question, value: res.data.id}, questionIndex)
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something Went Wrong");
        });
    }
  };

  const getSingleQuestion = () => {
    axios
      .get(
        `${__API_URL__}/api/survey/get_single_question/${editQuestion?.value}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setQuestion(res.data);
      });
  };

  useEffect(() => {
    const choiceKeys = Object.keys(question).filter((key) =>
      key.startsWith("Choice")
    );
    const choiceList = choiceKeys.map((key) => ({ [key]: question[key] }));
    setChoiceList(choiceList);
  }, [question]);

  useEffect(() => {
    AllPhotoType();
  }, []);

  useEffect(() => {
    AllChoices();
  }, [changeChoice]);

  useEffect(() => {
    if (editQuestion?.value) {
      getSingleQuestion();
      setDisabled(false);
      setDisableOrderNumber(false);
    }
  }, [editQuestion]);

  useEffect(() => {
    if (
      question.choice &&
      Array.isArray(question.choice) &&
      editQuestion?.value
    ) {
      const newQuestionCount = Array.from(
        { length: question.choice.length },
        (_, index) => index + 1
      );

      setChoiceCount(newQuestionCount);
    }
  }, [question]);

  return (
    <div>
      <Modal
        size="lg"
        show={newQuestion}
        onHide={() => {
          setNewQuestion(false);
          setEditQuestion({});
        }}
        centered
        backdrop="static" 
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {editQuestion?.value ? "Edit Question" : "Add Question"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <form
            onSubmit={(e) => {
              CreateQuestion(e);
            }}
          >
            <div className="form-group">
              <label htmlFor="question">Question</label>
              <input
                type="text"
                className="form-control"
                id="question"
                name="question"
                value={question?.question}
                onChange={(e) => {
                  handleFieldChange(e);
                }}
                placeholder="Enter question"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="photo_type">Photo Type</label>
              <select
                className="form-control"
                id="photo_type"
                name="photo_type"
                value={question?.photo_type}
                onChange={(e) => {
                  handleFieldChange(e);
                }}
              >
                <option value="">Select</option>
                {photoType?.map((photo) => {
                  return (
                    <option key={photo.id} value={photo.id}>
                      {photo.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <select
                className="form-control"
                id="type"
                name="type"
                value={question.multi_choice ? "Multi-choice" : question.type}
                onChange={(e) => handleFieldChange(e)}
              >
                <option value="Choice">Choice</option>
                <option value="Rating">Rating</option>
                <option value="Multi-choice">Multi-choice</option>
              </select>
            </div>
            <div className="row">
              <label className="form-check-label">Choice</label>
              {choiceCount?.map((choice, index) => (
                <>
                  <div className="col-md-7 my-2" key={index}>
                    <Select
                      value={question?.choice[index]}
                      className="basic-multi-select"
                      options={allChoice}
                      name={`choice ${index}`}
                      onChange={(e) => {
                        handleChoices(e, index);
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-md-2 mt-2">
                    <input
                      type="number"
                      min="0"
                      className="form-control w-100"
                      id="exampleCheck1"
                      name="order_number"
                      value={question?.choice[index]?.order_number}
                      disabled={disableOrderNumber}
                      required
                      onChange={(e) => {
                        handleOrderNumber(e, index);
                      }}
                    />
                  </div>
                  <div className="col-md-1 mt-2" style={{ width: "30px" }}>
                    <a
                      type="button"
                      onClick={() =>
                        handleEdit(question?.choice?.[index], index)
                      }
                    >
                      <EditTwoToneIcon />
                    </a>
                  </div>
                    <div className="col-md-1 mt-2" style={{ width: "30px" }}>
                      <h4>
                        <a type="button" onClick={() => handleNewChoice(index)}>
                          +
                        </a>
                      </h4>
                    </div>
                  <div className="col-md-1 mt-2" style={{ width: "30px" }}>
                    <a type="button" onClick={() => handleRemoveChoice(index)}>
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>
                </>
              ))}
            </div>
            <div className="col-md-6">
              <a
                type="button"
                onClick={() => handleAddChoice()}
                className={`btn btn-sm btn-primary ${
                  disabled ? " disabled" : ""
                }`}
              >
                <span className="mx-1">Add row</span>
              </a>
            </div>

            <div className="container MX-5 d-flex justify-content-end">
              <div className="row">
                <div className="col-sm">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {newChoice && (
        <AddChoice
          setNewChoice={setNewChoice}
          toast={toast}
          AllChoices={AllChoices}
          setEditChoice={setEditChoice}
          editChoice={editChoice}
          choiceIndex={choiceIndex}
          handleChoices={handleChoices}
        />
      )}
    </div>
  );
};
export default AddQuestion;
