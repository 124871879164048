import React, { useEffect } from 'react'

const TrialDrugIdRange = ({trialDetails, setTrialDetails, setHasChanged, hasChanged, handleBasicInfoUpdate, setDrugRangeChanged, DrugRangeChanged}) => {
    const drug_assignment_setting = trialDetails?.drug_assignment_setting
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role;
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setTrialDetails((prevDetails) => ({
          ...prevDetails,
          drug_assignment_setting: {
            ...prevDetails.drug_assignment_setting,
            [name]: value,
            'id_change':true
          },
        }));
        setDrugRangeChanged(true);
      };
      
  return (

    <div class="row mx-5 mb-3">
      <div className='col-md-12'>
        <div className="row">
          <div class="w-100"></div>
            <div className="col">
              <div className='row'>

              <div className="tableHeader">
              <div className="row d-flex align-items-end">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-end d-flex justify-content-end">
                  <>
                   {
                  (role !== "Investigator" && role !== "Inv_coordinator" && role !== 'Reviewer') && <>
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={() => handleBasicInfoUpdate()}
                  disabled={!DrugRangeChanged}
                >
                  Save
                </button>
                   </>
                  }
                </>

                </div>
              </div>
            </div>

                  <div class="col-md-6">
                      <label for="lower_value" class="form-label ">Lower Value</label>
                      <input
                        type="number"
                        border
                        class="form-control"
                        id="lower_value"
                        name='lower_range'
                        min="1"
                        value={drug_assignment_setting?.lower_range}
                        onChange={handleChange}
                        placeholder="Lower Value" />
                  </div>


                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="upper_value" class="form-label">Upper Value</label>
                    <input
                      type="number"
                      class="form-control"
                      id="upper_value"
                      name='upper_range'
                      min="1"
                      value={drug_assignment_setting?.upper_range}
                      onChange={handleChange}
                      placeholder="Upper Value"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

  )
}

export default TrialDrugIdRange
