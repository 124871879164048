import * as React from "react";
import useUser from "../hooks/user";
import LoginHeader from "../components/loginHeader";
import LoginFooter from "../components/loginFooter";
import { useParams } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';

const ResetPassword = () => {
  const { uid, token } = useParams();
  const [Pass, setPass] = React.useState("");
  const [NewPass, setNewPass] = React.useState("");
  const [error, setError] = React.useState("");

  const user = useUser();

  const PasswordValidation = (password) => {
      if (! /[0-9]/.test(password)) {
        return "Password should contain atleast one numeric number"
      } else if (! /[A-Z]/.test(password)) {
        return "Password should contain atleast one uppercase character"
      } else if (! /[a-z]/.test(password)) {
        return "Password should contain atleast one lowercase character"
      } else if (! /[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        return "Password should contain atleast one special character"
      } else if (password.length < 8) {
        return "Password should contain atleast eight characters"
      }

      return "OK"
  }

  const handleSubmit =async (e) => {
    
    e.preventDefault();
    if (NewPass == Pass) {
      const resp = PasswordValidation(NewPass)
      if (resp !== "OK") {
        setError(resp)
      } else {
        setError("");
        let newPass = {
          new_password1: Pass,
          new_password2: NewPass,
          uid,
          token
        };
        user.actions.reset_password(newPass);
      }
    } else {
      setError("The passwords you entered do not match");
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);

  return (
   <div style={{backgroundColor:"#fcf6f6", height:"100vh"}}>
     <LoginHeader />
   <div className="loginBox">
     <h1>Reset Password</h1>
     <form onSubmit={handleSubmit}>
      <Input type={showPassword ? "text" : "password"} placeholder="Password" onChange={(e) => {setPass(e.target.value);}} value={Pass}
        fullWidth={true} disableUnderline={true}
        endAdornment={
          <InputAdornment position="end">
            <div style={{cursor: 'pointer'}}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          </InputAdornment>
        } 
        />
      <Input type={showPassword1 ? "text" : "password"} placeholder="Confirm Password" onChange={(e) => {setNewPass(e.target.value);}} value={NewPass}
        fullWidth={true} disableUnderline={true}
        endAdornment={
          <InputAdornment position="end">
            <div style={{cursor: 'pointer'}}
              onClick={() => setShowPassword1(!showPassword1)}
            >
              {showPassword1 ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          </InputAdornment>
        } 
        />
       <span style={{color:"red",textAlign:"left",display:"block"}}>{error}</span>
       <button type="submit">Change</button>
     </form>
   </div>
   <LoginFooter />
   </div>

    );
};

export default ResetPassword;
