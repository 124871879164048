import React, { useEffect, useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";


const Reminders = (props) => 
{
const token = localStorage.getItem("token");
const user = useUser();
const user_data = JSON.parse(localStorage.getItem("user_data"));
const role = user_data.role
const setMessage = props.setMessage
const setTypeOfMessage = props.setTypeOfMessage
const setSelectedVisit = props.setSelectedVisit
let diasble=false
const [selectedBlock,setSelectedBlock] = useState({})

const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedBlock((selectedBlock) => ({
    ...selectedBlock,
    [name]: value,
    }));
    props.setHasChanged(true)
  };

  const GetSelectedBlockData = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_reminder/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }

  const AllVisitsData = () => {
      const createData = {
              'visit_number':props?.visit_number,
              'patient_id': props?.patient_id,
              'description':selectedBlock?.description,
              'sessionId': props.selectedSessionId,
          }
          axios
            .patch(`${__API_URL__}/api/ecrf/update_reminder/${props.trialId}/`,createData, {
              headers: {
                Authorization: `Token ${token}`,
              },
            })
            .then((res) => {
              setSelectedBlock(res.data);
              props.setModalVisitChangeShow(false)
              props.setHasChanged(false)
              setMessage("Block updated successfully");
              setTypeOfMessage("success");
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "2rem";
              }, 100);
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "-99rem";
              }, 3000);
          }).catch((err) => {
              if(err == 'Error: Request failed with status code 401'){
              user.actions.logout()
              }
              setMessage("Something Went Wrong");
              setTypeOfMessage("success");
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "2rem";
              }, 100);
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "-99rem";
              }, 3000);
          });
        };

        React.useEffect(() => {
          if((props?.patient_id && props?.photo_session)){
            GetSelectedBlockData();
          }
        }, [])

                    return <>
                    {
                      (role ==='CRO Admin' || role==='Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined)) ?
                       diasble=true:''
                    }
                    <fieldset disabled={diasble}>
                    {
                         (props.photo_session || props.patient_id == undefined ) ?
                         <>
                                       {
                                        (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                              <div className="col-md-12 text-end" >
                                                <button onClick={() => AllVisitsData()} className='btn btn-primary'>Update</button>
                                              </div>
                                        )
                                     }

                                    <div className='row'>           
                                            <td>  Review participant assessment worksheet to ensure correct completion; </td>
                                            <td>  Complete the ADVERSE EVENTS WORKSHEET (if applicable): </td>
                                            <td>  Document ADDITIONAL COMMENTS (if any) below. Use additional page(s) if needed.</td>
                                    <div className="col-md-12"> 
                                        <label></label>
                                        <textarea class = 'form-control' name='description' rows={10} type='text' value={selectedBlock.description} onChange={handleFieldChange}></textarea>
                                    </div>
                                    </div>
                                  </>:'Visit is not started'
                                  }
                                  </fieldset>
                                      </>
                                  }

export default Reminders;