import React, { useEffect, useState } from 'react';
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "./PatientMenu";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs";
import MessageBox from "../../components/Alert/MessageBox";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from '../../hooks/user';

const PatientAppointments = () => {
  const { _id_} = useParams();
  const { PatientData, Loader, handleRefresh } = useFetch(`appointments/appointments/patient/?PatientId=${_id_}`);
  const token = localStorage.getItem("token");

  const user = useUser();
  
  const hrefHIt = (e)=>{
    const href = document.getElementById(e);
    href.click();
    }

  const [appointmentIds, setAppointmentIds] = useState([])
  const [infoData, setInfoData] = useState({})

  const handleAppointmentIds = (e, data) => {
      if (e.target.checked) {
        setAppointmentIds([...appointmentIds, data.id]);
      } else {
        let newArray = appointmentIds.filter(
          (ele) => ele != data.id
        );
        setAppointmentIds(newArray);
      }
  }

  const handleDeleteAppointments = () => {
      axios
      .delete(
        `${__API_URL__}/api/appointments/delete_appointments/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          data: {
            list_of_ids: appointmentIds
          }
        }
      ).then((res) => {
          window.location.reload()
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
      });
  }
        const patientDetailApi = () => {
          axios
          .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
              headers: {
              Authorization: `Token ${token}`,
              },
          })
          .then((res) => {
              setInfoData(res.data)
          });
      }

      useEffect(() => {
        patientDetailApi()
    }, [])

  return (
    <>
      <MessageBox msg="Patient Appointment Updated!" />
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs B="Appointments"
         F={infoData.first_name}
         L={infoData.last_name}
         E={infoData.email}
         P={infoData.phone}
         participant_id={infoData?.participant_id}
         age={infoData?.age}
         birth_date={infoData?.birth_date}
         />
        <div className="patient_table">
          <PatientMenu active="appointments" data={infoData}/>
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <h5>Patient Appointments</h5>
              </div>
              <div className=" container col-md-6 d-flex justify-content-end">
                <div className="row">
                  <div  className="col-sm" >
                    {
                      appointmentIds.length > 0 && <>
                          <button onClick={() => handleDeleteAppointments()} type="button" class="btn btn-primary m-2">
                            Delete Selected Appointments
                          </button>
                      </>
                    }
                    <button type="button" style={{marginRight: '1em'}} className="btn btn-primary btn-sm" onClick={handleRefresh}>Refresh</button>
                  
                    <Link to={"/patient/"+_id_+"/appointment/create"}>
                      <button type="button" className="btn btn-primary btn-sm">
                        Create
                      </button>
                    </Link>
                  {/* </div> */}
                  {/* <div  className="col-sm" > */}
                    
                  </div>
              </div>
              </div>
              <div>

              </div>
            </div>
          </div>
          <table>
            {Loader ? (
              <div className="loader">
                <img src="https://i.gifer.com/Yb3A.gif" alt="" />
              </div>
            ) : (
              <>
                <thead>
                  <tr>
                    <th></th>
                    <th>Clinic</th>
                    <th>Appointment Time</th>
                    <th>Duration</th>
                    <th>Status</th>
                    <th>Notes</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {PatientData.length ? (
                    <>
                  {PatientData.map((data)=>(
                    <tr key={data.id}>
                    <td onClick={(e) => handleAppointmentIds(e, data)}><input type="checkbox" /></td>
                    <td onClick={(e)=>{hrefHIt(data.id)}}>
                      <Link to={"/patient/"+_id_+"/appointment/"+data.id} id={data.id}>
                      {data.clinic_dict.name}
                      </Link>
                    </td>
                    <td onClick={(e)=>{hrefHIt(data.id)}}>
                      {new Date(data.time+'Z').toDateString()},&nbsp;
                      {new Date(data.time+'Z').toLocaleTimeString()}
                    </td>
                    <td onClick={(e)=>{hrefHIt(data.id)}}>{data.duration} Mins</td>
                    <td onClick={(e)=>{hrefHIt(data.id)}}>{data.status}</td>
                    <td onClick={(e)=>{hrefHIt(data.id)}}>{data.notes}</td>
                    <td onClick={(e)=>{hrefHIt(data.id)}}>
                      {new Date(data.created_at+'Z').toDateString()},&nbsp;
                      {new Date(data.created_at+'Z').toLocaleTimeString()}
                    </td>
                  </tr>
                  ))}
                  </>
                  ):(
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  )}

                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default PatientAppointments;
