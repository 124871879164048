import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import { useState, useEffect } from "react";

const PatientMenu = ({ active, data }) => {
  const { _id_ } = useParams();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const token = localStorage.getItem("token");

  return (
    <div className="PatientMenu" style={{ position: "sticky", top: "60px", backgroundColor: "#f1f1f1", padding: "10px", zIndex: "99" }}>
      <ul>
        {role !== "Reviewer" &&
          role !== "CRO Admin" &&
          role !== "Cro_coordinator" &&
          role !== "Data Manager" && (
            <>
              {role !== "Investigator" && role !== "Inv_coordinator" && role !== "Sub_investigator" && (
                <>
                  <li className={active == "info" && "pactive"}>
                    <Link to={"/patient/" + _id_ + "/info"}>Personal Info</Link>
                  </li>
                </>
              )}
              <li className={active == "appointments" && "pactive"}>
                <Link to={"/patient/" + _id_ + "/appointment"}>
                  Appointments
                </Link>
              </li>
              <li className={active == "messages" && "pactive"}>
                <Link to={"/patient/" + _id_ + "/messages"}>Messages</Link>
              </li>
            </>
          )}
        <li className={active == "photos" && "pactive"}>
          <Link to={"/patient/" + _id_ + "/photos"}>APP Photos</Link>
        </li>
        {role !== "Reviewer" && role !== "Clinic Admin" && (
          <>
            <li className={active == "ecrf" && "pactive"}>
              <Link to={"/patient/" + _id_ + "/ecrf"}>eCRF</Link>
            </li>
            <li className={active == "changeLogs" && "pactive"}>
              <Link to={"/patient/" + _id_ + "/ecrf_change_logs"}>
                eCRF Change Logs
              </Link>
            </li>
            {
              (role === "Super Admin" || role === "CRO Admin" || role === "Cro_coordinator" || role ==="Investigator" || role === "Inv_coordinator" || role === "Sub_investigator" || role ==="Data Manager" ) && 
              <li className={active == "query" && "pactive"}>
                <Link to={"/patient/" + _id_ + "/query"}>Query/eSignature/Freeze</Link>
              </li>
            }
            <li className={active == "QueryChangeLogs" && "pactive"}>
              <Link to={"/patient/" + _id_ + "/query_change_logs"}>
                Query Change Logs
              </Link>
            </li>
            <li className={active == "poem" && "pactive"}>
              <Link to={"/patient/" + _id_ + "/poem"}>POEM/ePRO/eDiary</Link>
            </li>
            <li className={active == "videos" && "pactive"}>
              <Link to={"/patient/" + _id_ + "/videos"}>APP Videos</Link>
            </li>
            <li className={active == "econsent" && "pactive"}>
              <Link to={"/patient/" + _id_ + "/e_consent"}>eConsent</Link>
            </li>
          </>
        )}
        {role !== "Reviewer" &&
          role !== "CRO Admin" &&
          role !== "Cro_coordinator" &&
          role !== "Data Manager" && (
            <>
              <li className={active == "activity" && "pactive"}>
                <Link to={"/patient/" + _id_ + "/activity"}>Activity</Link>
              </li>
              {role !== "Inv_coordinator" &&
                role !== "Investigator" &&
                role !== "Sub_investigator" &&
                data?.is_rewards === true && (
                  <>
                    <li className={active == "reward" && "pactive"}>
                      <Link to={"/patient/" + _id_ + "/reward"}>Reward</Link>
                    </li>
                  </>
                )}
            </>
          )}
        {role !== "Reviewer" &&
          role !== "CRO Admin" &&
          role !== "Cro_coordinator" &&
          role !== "Clinic Admin" && (
            <>
              <li className={active == "notifications" && "pactive"}>
                <Link to={"/patient/" + _id_ + "/notification_list"}>
                  Auto Notifications Log
                </Link>
              </li>
            </>
          )}
        {(user_data.role === "Clinic Owner" ||
          user_data.role === "Super Admin" ||
          user_data.role === "Investigator" ||
          user_data.role === 'Sub_investigator' ||
          user_data.role === "Inv_coordinator" ||
          user_data.role === "CRO Admin" ||
          user_data.role === "Cro_coordinator" ||
          user_data.role === "Data Manager" ||
          user_data.role === "Clinic Admin") && (
            <>
              <li className={active == "documents" && "pactive"}>
                <Link to={"/patient/" + _id_ + "/documents"}>
                  Additional Documents
                </Link>
              </li>
            </>
          )}

        {(user_data.role === "Investigator" ||
          user_data.role === 'Sub_investigator' ||
          user_data.role === "Inv_coordinator" || 
          user_data.role === "Super Admin") && (
            <>
              <li className={active == "status" && "pactive"}>
                <Link to={"/patient/" + _id_ + "/visit_status"}>
                  Visit Status
                </Link>
              </li>
            </>
          )}

        {(user_data.role === "Investigator" ||
          user_data.role === 'Sub_investigator' ||
          user_data.role === 'CRO Admin' ||
          user_data.role === 'Cro_coordinator' ||
          user_data.role === 'Data Manager' ||
          user_data.role === "Inv_coordinator") && (
            <>
              <li className={active == "externalMedia" && "pactive"}>
                <Link to={"/patient/" + _id_ + "/sessions_external_media"}>
                  External Media
                </Link>
              </li>
            </>
          )}
      </ul>
    </div>
  );
};

export default PatientMenu;
