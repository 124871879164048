import React, { useEffect, useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";



const Target_Lesion_Identification = (props) => 
{
const token = localStorage.getItem("token");
const user = useUser();
const user_data = JSON.parse(localStorage.getItem("user_data"));
const role = user_data.role
const setMessage = props.setMessage
const setTypeOfMessage = props.setTypeOfMessage
let disable=false
const [selectedBlock, setSelectedBlock] = useState({})

const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedBlock((selectedBlock) => ({
    ...selectedBlock,
    [name]: value,
    }));
    props.setHasChanged(true)
  };

const handleChange=(e) =>{

        setSelectedBlock({
                ...selectedBlock,
                one_facial_inflammatory_lesion: !selectedBlock.one_facial_inflammatory_lesion,
                }); 
                props.setHasChanged(true)
};

const GetSelectedBlockData = () => {
  axios
    .get(`${__API_URL__}/api/ecrf/get_identification/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      setSelectedBlock(res.data);
    }).catch((err) => {
      if (err == 'Error: Request failed with status code 401') {
      }
    });
}

  const AllVisitsData = () => {
      const createData = {
              'visit_number':props?.visit_number,
              'patient_id': props?.patient_id,
              ...selectedBlock,
              'number_of_lesions': selectedBlock?.number_of_lesions === null ? 0 : selectedBlock?.number_of_lesions,
              'one_facial_inflammatory_lesion': selectedBlock?.one_facial_inflammatory_lesion === undefined ? true : selectedBlock?.one_facial_inflammatory_lesion,
              'sessionId': props.selectedSessionId,
          }
          axios
            .patch(`${__API_URL__}/api/ecrf/update_identification/${props.trialId}/`,createData, {
              headers: {
                Authorization: `Token ${token}`,
              },
            })
            .then((res) => {
              setSelectedBlock(res.data);
              props.setModalVisitChangeShow(false)
              props.setHasChanged(false)
              setMessage("Block updated successfully");
              setTypeOfMessage("success");
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "2rem";
              }, 100);
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "-99rem";
              }, 3000);
          }).catch((err) => {
              if(err == 'Error: Request failed with status code 401'){
              user.actions.logout()
              }
              setMessage("Something Went Wrong");
              setTypeOfMessage("success");
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "2rem";
              }, 100);
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "-99rem";
              }, 3000);
          });
        };

        React.useEffect(() => {
          if((props?.patient_id && props?.photo_session)){
            GetSelectedBlockData();
          }
        }, [])
        return <>
         {
                      (role ==='CRO Admin' || role==='Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined)) ?
                      disable=true:''
                    }
                    <fieldset disabled={disable}>
                    {
                         (props.photo_session || props.patient_id == undefined ) ?
                         <>
                                       {
                                        (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                        
                                        <div className="col-md-12 text-end">
                                              <button onClick={() => AllVisitsData()} className='btn btn-primary'>Update</button>
                                         </div>
                                        )
                                     }
                            <div className='col-md-12'>
                              <div className="row">
                              <div className="col-md-9">
                              <p> Does the participant have at least one facial inflammatory lesion that the principal investigator deems        amendable to intralesional injection of triamcinolone ?</p>
                                        <th scope="row">
                                        </th>
                              </div>
                              <div className="col-md-1">
                              <p> Yes
                                    <input
                                        className="form-check-input mx-1"
                                        type="radio"
                                        name="one_facial_inflammatory_lesion"
                                        checked = {selectedBlock?.one_facial_inflammatory_lesion == true}
                                        id="radio1"
                                        value={selectedBlock?.one_facial_inflammatory_lesion == true}
                                        onChange={handleChange}
                                    />
                                    </p>
                              </div>
                              <div className="col-md-1">
                             <p> No
                                    <input
                                    className="form-check-input mx-1"
                                    type="radio"
                                    name="one_facial_inflammatory_lesion"
                                    checked = {selectedBlock?.one_facial_inflammatory_lesion == false}
                                    id="radio1"
                                    value={selectedBlock?.one_facial_inflammatory_lesion == false}
                                    onChange={handleChange}
                                    />
                                    </p>
                              </div>
                        { selectedBlock.one_facial_inflammatory_lesion === true ?

                                <td >  If yes, how many facial inflammatory lesions does the participant have: 
                                                <th scope="row">
                                                        <div className='col-md-6'>
                                                                <input
                                                                        className='form-control'
                                                                        name='number_of_lesions'
                                                                        type='number'
                                                                        value={selectedBlock.number_of_lesions}
                                                                        onChange={handleFieldChange}
                                                                />
                                                        </div>
                                                </th>
                                        </td>
                                        :''}
                              </div>
                                </div>
                                
    </>:'Visit is not started'
    }
    </fieldset>
    </>
}
export default Target_Lesion_Identification;