import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import Select from "react-select";

const EditStaff = ({ setEditStaff, editStaff, UpdateInstance, typeOfStaff }) => {
  const [haschanged, setHasChanged] = useState(false);
  const [clinicList, setClinicList] = useState([]);
  const token = localStorage.getItem("token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditStaff((editStaff) => ({
      ...editStaff,
      [name]: value,
    }));
    setHasChanged(true)
  };

  const onClinicChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
        case 'remove-value':
                newValue = clinicList.filter((v) => v.value != actionMeta.removedValue.value);
                setEditStaff((editStaff) => {
                    return {
                        ...editStaff,
                        clinic: newValue
                    }
                });
                setHasChanged(true)
            break;
        case 'pop-value':
            break;
        case 'clear':
            newValue = clinicList.filter((v) => v.isFixed);
            setEditStaff((editStaff) => {
                return {
                    ...editStaff,
                    clinic: newValue
                }
            });
            setHasChanged(true)
            break;
        case 'select-option':
          setEditStaff((editStaff) => {
                return {
                    ...editStaff,
                    clinic: newValue
                }
            });
            setHasChanged(true)
            break;
    }
}

  const ListOfClinics = () => {
    axios
      .get(`${__API_URL__}/api/clinic/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        const modifiedClinicList = res.data.map((clinic) => ({
          label: clinic?.name, 
          value: clinic.id, 
        }));
        setClinicList(modifiedClinicList);
      });
  };

  useEffect(()=>{
    if (typeOfStaff === 'practitioner' || typeOfStaff === 'admin'){
      ListOfClinics()
    }
  }, [])

  return (
    <div>
      <Modal size="md" show onHide={() => setEditStaff(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Staff
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <form onSubmit={(e)=>{UpdateInstance(e)}}>
            <>
              {typeOfStaff === 'practitioner'?
                  <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    aria-describedby="emailHelp"
                    value={editStaff?.name}
                    onChange={handleChange}
                    placeholder="name"
                  />
                </div>
              :
              <>
              <div class="form-group">
                <label for="first_name">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="first_name"
                  name="first_name"
                  aria-describedby="emailHelp"
                  value={editStaff?.first_name}
                  onChange={handleChange}
                  placeholder="first name"
                />
              </div>
              <div class="form-group">
                <label for="last_name">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="last_name"
                  name="last_name"
                  value={editStaff?.last_name}
                  onChange={handleChange}
                  placeholder="last name"
                />
              </div>
              </>
            }

              {(typeOfStaff === 'admin' || typeOfStaff === 'practitioner') &&(
                  <div class="form-group mb-5">
                    <label>Clinic</label>
                    <Select
                        value={editStaff?.clinic}
                        className="basic-multi-select"
                        options={clinicList}
                        name="clinic"
                        onChange={onClinicChange}
                        required={true}
                      />
                  </div>
              )}

              { (typeOfStaff==='owner' || typeOfStaff==='admin' || typeOfStaff==='practitioner') &&
              <>
              <label style={{ "margin-right": "1em" }}>Roles:</label>
              <div className="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="isAdmin"
                  value={editStaff?.isAdmin}
                  checked={editStaff?.isAdmin}
                  disabled={editStaff?.isAdmin}
                 
                  id="flexCheckDefault1"
                  onChange={(e) => {
                    setEditStaff({ ...editStaff, isAdmin: !editStaff.isAdmin });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault1">
                  Clinic Admin
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="isPractitioner"
                  value={editStaff?.isPractitioner}
                  checked={editStaff?.isPractitioner}
                  disabled={editStaff?.isPractitioner}

                  id="flexCheckDefault2"
                  onChange={(e) => {
                    setEditStaff({
                      ...editStaff,
                      isPractitioner: !editStaff.isPractitioner,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault2">
                  Clinic Practitioner
                </label>
              </div>
              </>
              }
            
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setEditStaff(false)}>
            Cancel
          </button>
            <button type="submit" className="btn btn-primary">
              Update
            </button>
        </Modal.Footer>

            </>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditStaff;
