import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Table from 'react-bootstrap/Table';

const Fitzpatrick_Skin_Type = (props) => {
  const [selectedBlock, setSelectedBlock] = useState({})
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const user = useUser();
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  let disable = false

  const CHARACTERISTICS_CHOICES = [
    { value: 'Always burns, never tans', label: 'Always burns, never tans' },
    { value: 'Usually burns, tans with difficulty', label: 'Usually burns, tans with difficulty' },
    { value: 'Sometimes mild burns, gradually tans', label: 'Sometimes mild burns, gradually tans' },
    { value: 'Rarely burns, tans with ease', label: 'Rarely burns, tans with ease' },
    { value: 'Very rarely burns, tans easily', label: 'Very rarely burns, tans easily' },
    { value: 'Never burns, tans very easily', label: 'Never burns, tans very easily' },
  ]

  const handleFieldChange = (e) => {
    const { name, value, checked, type } = e.target;
    setSelectedBlock((prevBlock) => ({
      ...prevBlock,
      [name]: type === 'checkbox' ? (checked ? value : '') : value,
    }));
    props.setHasChanged(true)
  };
  const Fitz_Patrick_Data = () => {
    const createData = {
      'patient_id': props?.patient_id,
      'visit_number': props?.visit_number,
      'sessionId': props.selectedSessionId,
      ...selectedBlock
    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_fitz_patrick/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      });
  };
  const get_fitz_Patrick_Data = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_fitz_patrick/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }
  React.useEffect(() => {
    if((props?.patient_id && props?.photo_session)){
      get_fitz_Patrick_Data();
    }
  }, [])
  if (role === 'CRO Admin' && !selectedBlock?.is_disease) {
    setSelectedBlock(prevSelectedBlock => ({
      ...prevSelectedBlock,
      is_disease: true,
    }));
  }
  return <>
    {
      (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props?.patient_id === undefined)) ?
        disable = true : ""
    }
    <fieldset disabled={disable}>
      {
        (props?.photo_session || props?.patient_id == undefined) ?
          <>
            {
              (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                <div className="col-md-12 text-end">
                  <button onClick={() => Fitz_Patrick_Data()} className='btn btn-primary my-3'>Update</button>
                </div>
             )
            }
               <Table id='patient_table' className="border_table table-sm border">
                <tr>
                <thead>
                    <th className='border'>Skin Type</th>
                    <th className='border'>Skin Color</th>
                    <th className='border'>CHARACTERISTICS</th>
                    <th className='border'>Select One</th>
                </thead>
                <tbody>
                    <td>
                      <div className='col-md-12'>
                        <label>1</label>
                      </div>
                      <div className='col-md-12'>
                        <label>2</label>
                      </div>
                      <div className='col-md-12'>
                        <label>3</label>
                      </div>
                      <div className='col-md-12'>
                        <label>4</label>
                      </div>
                      <div className='col-md-12'>
                        <label>5</label>
                      </div>
                      <div className='col-md-12'>
                        <label>6</label>
                      </div>
                    </td>
                    <td>
                      <div className='col-md-10 p-0'>
                        <label>White, very fair, red, or blond hair; blue eyes; freckles</label>
                      </div>
                      <div className='col-md-10 p-0'>
                        <label>White, fair, red, or blond hair; blue, hazel or green eyes</label>
                      </div>
                      <div className='col-md-10 p-0'>
                        <label>Cream white; fair with any eye or hair color (common)</label>
                      </div>
                      <div className='col-md-10 p-0'>
                        <label>Brown; typical Mediterranean Caucasian skin</label>
                      </div>
                      <div className='col-md-10 p-0'>
                        <label>Dark Brown; mid-eastern skin types</label>
                      </div>
                      <div className='col-md-10 p-0'>
                        <label>Black</label>
                      </div>
                    </td>
                    <td>
                      <div className='col-md-10 p-0'>
                        <label>Always burns, never tans</label>
                      </div>
                      <div className='col-md-10 p-0'>
                        <label>Usually burns, tans with difficulty</label>
                      </div>
                      <div className='col-md-10 p-0'>
                        <label>Sometimes mild burns, gradually tans</label>
                      </div>
                      <div className='col-md-10 p-0'>
                        <label>Rarely burns, tans with ease</label>
                      </div>
                      <div className='col-md-10 p-0'>
                        <label>Very rarely burns, tans easily</label>
                      </div>
                      <div className='col-md-10 p-0'>
                        <label>Never burns, tans very easily</label>
                      </div>
                    </td>
                    <td>
                      {CHARACTERISTICS_CHOICES?.map(event => (
                        <div className='col-md-10 mt-2 mx-3' key={event?.value}>
                          <input
                            className="form-check-input mx-2 mt-1"
                            type="checkbox"
                            name='name'
                            checked={selectedBlock?.name === event?.value}
                            value={event?.value}
                            onChange={(e) => handleFieldChange(e)}
                          />
                        </div>
                      ))}
                    </td>
                </tbody>
                </tr>
              </Table>
          </> : 'Visit is not started'
      }
    </fieldset>
  </>
}

export default Fitzpatrick_Skin_Type;
