import * as React from "react";
import { useState, useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
import CommentIcon from "@mui/icons-material/Comment";
import { TextField, Box, Paper, FormControl } from "@mui/material";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";

export default function ChatForm(props) {
  const [message, setMessage] = useState("");
  const token = localStorage.getItem("token");
  const { _id_ } = useParams();
  const user_data = JSON.parse(localStorage.getItem("user_data"));

  const inital_roles = [
    "Inv_coordinator",
    "Investigator",
    "Sub_investigator",
    "CRO Admin",
    "Cro_coordinator",
    "Data Manager",
  ];
  const roles = inital_roles.filter((role) => role !== user_data.role);

  const roleDisplayNames = {
    Inv_coordinator: "CRC",
    Investigator: "Investigator",
    "Sub_investigator": "Sub-Investigator",
    "CRO Admin": "CRO Admin",
    Cro_coordinator: "Cro Coordinator",
    "Data Manager": "Data Manager",
  };

  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    if (props.lastReceivedMessage?.sender?.role?.title) {
      setSelectedRoles([props.lastReceivedMessage?.sender?.role?.title]);
    }
  }, [props.lastReceivedMessage]);

  const userRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(typeof value === "string" ? value.split(",") : value);
  };

  const handleMessageQuery = (closed) => {
    if (!closed && (selectedRoles.length === 0 || message.length === 0)) {
      toast.error(
        "Write a message or Select Role for Generating a Query Message"
      );
      return;
    }
    const data = {
      query_id: props.queryId,
      text: message,
      user_roles: selectedRoles,
      closed: closed,
    };
    axios
      .post(
        `${__API_URL__}/api/ecrf/generate_messages/?patient_id=${_id_}`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        props.getQueryMessages();
        props.GetQueryData();
        setMessage("");
        setSelectedRoles([]);
      })
      .catch(() => {
        toast.error();
      });
  };

  return (
    <Box
      component="div"
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <Paper
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: "background.paper",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Write a message"
          fullWidth
          name="message"
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{
            mr: 1,
            backgroundColor: "background.default",
            minWidth: "200px",
          }}
          disabled={props.isConversationClosed}
        />
        <Box sx={{ mr: 1 }}>
          <InputLabel id="multiple-role-label">Select Role</InputLabel>
          <Select
            labelId="multiple-role-label"
            className="form-control"
            multiple
            value={selectedRoles}
            onChange={userRoleChange}
            variant="outlined"
            renderValue={(selected) =>
              selected.map((name) => roleDisplayNames[name]).join(", ")
            }
          >
            {roles.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={selectedRoles.indexOf(name) > -1} />
                <ListItemText primary={roleDisplayNames[name]} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          className="btn btn-primary btn-sm m-1"
          variant="primary"
          endIcon={<SendIcon />}
          onClick={() => handleMessageQuery(false)}
          disabled={props.isConversationClosed}
        >
          Send
        </Button>
        <Button
          className="btn btn-primary btn-sm m-1"
          variant="primary"
          onClick={() => handleMessageQuery(true)}
          disabled={
            props.isConversationClosed || props.fromUserId !== user_data.id
          }
        >
          Close Query
        </Button>
      </Paper>
    </Box>
  );
}
