import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { __API_URL__ } from "../../SERVER_URL";

const ReportDropdown = (props) => {
  const trialDetails = props.trialDetails
  const setTrialDetails = props.setTrialDetails
  const blocksData = props.blocksData
  const visitData = props.visitData
  const AllTrialData = props.AllTrialData
  const allInvestigators = props.allInvestigators
  const AllCroData = props.AllCroData
  const AllSubjectEmail = props.AllSubjectEmail
  const AllPhotoData = props.AllPhotoData
  const siteIdData = props.siteIdData
  const participantIdData = props.participantIdData
  const AllSubjectId = props.AllSubjectId
  const user_data = props.user_data
  const getDownloadDataView = props.getDownloadDataView
  const DownloadEcrfReportExtra = props.DownloadEcrfReportExtra
  const DownloadEcrfSpecialReport = props.DownloadEcrfSpecialReport
  const DownloadVisitReport = props.DownloadVisitReport
  const IsLoadingButton = props.IsLoadingButton
  const isLoadingDownloadReport = props.isLoadingDownloadReport
  const IsLoadingDownloadButton = props.IsLoadingDownloadButton
  const IsDisabledDownload = props.IsDisabledDownload
  const REPORT_TYPE = props.REPORT_TYPE
 
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: '185px',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '185px',
    }),
  };
  const VISIT_STATUS = [
    { value: 'COMPLETED', label: 'COMPLETED' },
    { value: 'INCOMPLETE', label: 'INCOMPLETE' },
    { value: 'NOT STARTED', label: 'NOT STARTED' },
    { value: 'SKIPPED', label: 'SKIPPED' },
  ]
  const ACTIVITY_TYPE = [
    { value: 'POEM', label: 'POEM' },
    { value: 'Consent', label: 'Consent' },
    { value: 'Drug Video Proof', label: 'Drug Video Proof' },
  ]
  const options = [...ACTIVITY_TYPE, ...AllPhotoData];
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true);
  const [isDownloadButtonDisabledVisit, setIsDownloadButtonDisabledVisit] = useState(true);
  const [reportMultiOption, setReportMultiOption] = useState(true);

  const handleApplyFilters = () => {
    getDownloadDataView()
    setIsDownloadButtonDisabled(false);
    setIsDownloadButtonDisabledVisit(false);
  };
  const onChange = (field, newValue, actionMeta) => {
    let updatedValue;
    switch (actionMeta.action) {
      case 'remove-value':
        updatedValue = trialDetails[field].filter((v) => v.value !== actionMeta.removedValue.value);
        break;
      case 'pop-value':
        break;
      case 'clear':
        updatedValue = trialDetails[field].filter((v) => v.isFixed);
        break;
      case 'select-option':
        if (field === 'trials') {
          const latestChangedObject = newValue.find((option) => !trialDetails[field]?.some((v) => v.value === option.value));
          updatedValue = latestChangedObject ? [latestChangedObject] : [];
        } else {
          updatedValue = newValue;
        }
        break;
      default:
        return;
    }
    setTrialDetails((trial) => ({
      ...trial,
      [field]: updatedValue,
    }));
  };
  useEffect(() => {
    setTrialDetails((prev) => ({
      ...prev,
      visit_status: VISIT_STATUS,
    }))
  }, [])

  useEffect(() => {
      trialDetails.participant_number = [];

    if (trialDetails?.reportType?.value === "filled_block") {
      setReportMultiOption(false)
    } else {
      setReportMultiOption(true)
    }
  }, [trialDetails.reportType])

  const investigatorSelect = (
    <div className="col-md-6">
      <label>Investigator</label>
      <Select
        value={trialDetails?.investigators}
        isMulti
        name="investigators"
        className="basic-multi-select"
        options={allInvestigators}
        onChange={(newValue, actionMeta) => onChange("investigators", newValue, actionMeta)}
        styles={customSelectStyles}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
      />
    </div>
  );
  const croSelect = (
    <div className="col-md-6">
      <label>CRO</label>
      <Select
        value={trialDetails?.cro}
        isMulti
        name="cro"
        className="basic-multi-select"
        options={AllCroData}
        onChange={(newValue, actionMeta) => onChange("cro", newValue, actionMeta)}
        styles={customSelectStyles}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
      />
    </div>
  );
  const trialSelect = (
    <div className="col-md-6">
      <label>Trial*</label>
      <Select
        value={trialDetails?.trials}
        isMulti
        name="trials"
        className="basic-multi-select"
        options={AllTrialData}
        onChange={(newValue, actionMeta) => onChange("trials", newValue, actionMeta)}
        styles={customSelectStyles}
      />
    </div>
  );

  const reportDownloadTypeSelect = (
    <div className="col-md-6">
      <label>Select Report Type</label>
      <Select
        value={trialDetails?.reportType}
        name="reportType"
        className="basic-multi-select"
        options={REPORT_TYPE}
        onChange={(newValue, actionMeta) =>
          onChange("reportType", newValue, actionMeta)
        }
        styles={customSelectStyles}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
      />
    </div>
  );

  const visitStatusSelect = (
    <div className="col-md-6">
      <label>Visit Status</label>
      <Select
        value={trialDetails?.visit_status}
        isMulti
        name="visit_status"
        className="basic-multi-select"
        options={VISIT_STATUS}
        onChange={(newValue, actionMeta) => onChange("visit_status", newValue, actionMeta)}
        styles={customSelectStyles}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
      />
    </div>
  );
  const activityTypeSelect = (
    <div className="col-md-6">
      <label>Activity Type</label>
      <Select
        value={trialDetails?.activity_type}
        isMulti
        name="activity_type"
        className="basic-multi-select"
        options={options}
        onChange={(newValue, actionMeta) => onChange("activity_type", newValue, actionMeta)}
        styles={customSelectStyles}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
      />
    </div>
  );
  const photoTypeSelect = (
    <div className="col-md-6">
      <label>Photo Type</label>
      <Select
        value={trialDetails?.photo_type}
        isMulti
        name="photo_type"
        className="basic-multi-select"
        options={AllPhotoData}
        onChange={(newValue, actionMeta) => onChange("photo_type", newValue, actionMeta)}
        styles={customSelectStyles}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
      />
    </div>
  );
  const siteIdSelect = (
    <div className="col-md-6">
      <label>Site Id</label>
      <Select
        value={trialDetails?.site_number}
        isMulti
        name="site_number"
        className="basic-multi-select"
        options={siteIdData}
        onChange={(newValue, actionMeta) => onChange("site_number", newValue, actionMeta)}
        styles={customSelectStyles}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
      />
    </div>
  );
  const participantIdSelect = (
    <div className="col-md-6">
      <label>
        Participant Id
      </label>
      <Select
        value={trialDetails?.participant_number}
        isMulti={reportMultiOption}
        name="participant_number"
        className="basic-multi-select"
        options={participantIdData}
        onChange={(newValue, actionMeta) =>
          onChange("participant_number", newValue, actionMeta)
        }
        styles={customSelectStyles}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
      />
    </div>
  );

  const blockNameSelect = (
    <div className="col-md-6">
      <label>Block Name</label>
      <Select
        value={trialDetails?.blocks}
        isMulti
        name="blocks"
        className="basic-multi-select"
        options={blocksData}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
        getOptionLabel={(option) => `${option.label}`}
        getOptionValue={(option) => option.value}
        onChange={(newValue, actionMeta) => onChange("blocks", newValue, actionMeta)}
        styles={customSelectStyles}
      />
    </div>
  );
  const visitSelect = (
    <div className="col-md-6">
      <label>Visit</label>
      <Select
        value={trialDetails?.visit}
        isMulti
        name="visit"
        className="basic-multi-select"
        options={visitData}
        onChange={(newValue, actionMeta) => onChange("visit", newValue, actionMeta)}
        styles={customSelectStyles}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
      />
    </div>
  );
  const participantIdSuperAdminSelect = (
    <div className="col-md-6">
      <label>Internal Id</label>
      <Select
        value={trialDetails?.subject_id_super}
        isMulti
        name="subject_id_super"
        className="basic-multi-select"
        options={AllSubjectId}
        onChange={(newValue, actionMeta) => onChange("subject_id_super", newValue, actionMeta)}
        styles={customSelectStyles}
        isDisabled={!trialDetails.trials || trialDetails.trials.length === 0}
      />
    </div>
  );
  
  return (
    <div class="card form-control" style={{ width: '100%', height: '80%' }}>
      <div className="row">
        <div className="col-md-6">
          <label>Filters: </label>
        </div>
      </div>
      <div className="row">
        <>
          {trialSelect}
          {trialDetails.selected_report === "SCORES" && ( <> {reportDownloadTypeSelect}  </>)}
          {trialDetails.selected_report === "ECRF" && (
            <>
              {reportDownloadTypeSelect}       
              {blockNameSelect}
              {visitSelect}
              {siteIdSelect}
              {participantIdSelect}   
              {
                user_data.role === "Super Admin" ? participantIdSuperAdminSelect : ''
              }
            </>
          )}

          {trialDetails.selected_report !== "ECRF" && trialDetails.selected_report !== "PHOTO REPORT" && trialDetails.selected_report !== "VIDEO REPORT" && trialDetails.selected_report !== "VISIT REPORT"  && trialDetails.selected_report !== "ADVERSE EVENT REPORT" && trialDetails.selected_report !== "UNSCHEDULED EVENT REPORT"&&(
            <> 
              {investigatorSelect}
              {visitSelect}
              {croSelect}
              {visitStatusSelect}
              {participantIdSelect}
              {siteIdSelect}
            </>
          )}
          {trialDetails.selected_report === "PHOTO REPORT" && (
            <>
              {visitSelect}
              {photoTypeSelect}
              {siteIdSelect}
              {participantIdSelect}
            </>
          )}
          {trialDetails.selected_report === "VIDEO REPORT" && (
            <>
              {visitSelect}
              {siteIdSelect}
              {participantIdSelect}
            </>
          )}
          {trialDetails.selected_report === "VISIT REPORT" && (
            <>
            {visitSelect}
            </>
          )}
          {trialDetails.selected_report === "ADVERSE EVENT REPORT" && (
            <>
            {reportDownloadTypeSelect}
            {blockNameSelect}
            {visitSelect}
            {participantIdSelect}
            </>
          )}
          {trialDetails.selected_report === "UNSCHEDULED EVENT REPORT" && (
            <>
            {reportDownloadTypeSelect}
            {blockNameSelect}
            {visitSelect}
            {participantIdSelect}
            </>
          )}
        </>
        <div className="col-md-6">
          <button
            type="button"
            className={`btn btn-primary btn-sm m-4${IsLoadingButton ? " btn-loading" : ""}`}
            disabled={IsLoadingButton}
            onClick={handleApplyFilters}
          >
            {IsLoadingButton ? <><i class="fa fa-spinner fa-spin"></i> Loading</> : "Apply Filters"}
          </button>
          {trialDetails.selected_report === "VISIT REPORT" ? (
          <button
            type="button"
            className={`btn btn-primary btn-sm m-4${IsLoadingDownloadButton ? " btn-loading" : ""}`}
            disabled={isDownloadButtonDisabledVisit ? true : IsDisabledDownload ? true : false}
            onClick={DownloadVisitReport}
          >
            {IsLoadingDownloadButton ? <><i class="fa fa-spinner fa-spin"></i> Loading</> : "Download Visit Report"}
          </button>
          ) : (
            ""
          )}
          {trialDetails.selected_report === "ECRF" ? (
            <button
              type="button"
              className={`btn btn-primary btn-sm  m-4${isLoadingDownloadReport ? " btn-loading" : ""}`}
              target="_blank"
              onClick={DownloadEcrfReportExtra}
              disabled={isLoadingDownloadReport ? true : IsDisabledDownload ? true : false}
            >
              {isLoadingDownloadReport ? <><i class="fa fa-spinner fa-spin"></i> Loading</> : "Download Report"}
            </button>
          ) : (
            ""
          )}
          {trialDetails.selected_report === "ADVERSE EVENT REPORT" ? (
            <button
              type="button"
              className={`btn btn-primary btn-sm  m-4${IsLoadingDownloadButton ? " btn-loading" : ""}`}
              target="_blank"
              onClick={DownloadEcrfSpecialReport}
              disabled={isDownloadButtonDisabled ? true : IsDisabledDownload ? true : false}
            >
              {IsLoadingDownloadButton ? <><i class="fa fa-spinner fa-spin"></i> Loading</> : "Download Adverse Event Report"}
            </button>
          ) : (
            ""
          )}
          {trialDetails.selected_report === "UNSCHEDULED EVENT REPORT" ? (
            <button
              type="button"
              className={`btn btn-primary btn-sm  m-4${IsLoadingDownloadButton ? " btn-loading" : ""}`}
              target="_blank"
              onClick={DownloadEcrfSpecialReport}
              disabled={isDownloadButtonDisabled ? true : IsDisabledDownload ? true : false}
            >
              {IsLoadingDownloadButton ? <><i class="fa fa-spinner fa-spin"></i> Loading</> : "Download Unschedueled Visit Report"}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportDropdown