import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Button from 'react-bootstrap/Button';

const Exclusion_Criteria_Stem_Cell = (props) => {
    const [selectedBlock, setSelectedBlock] = useState({});
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role
    const token = localStorage.getItem("token");
    const user = useUser();
    const setMessage = props.setMessage
    const setTypeOfMessage = props.setTypeOfMessage
    let disable = false
    const handleFieldChange = (e) => {
        const { name, value, checked, type } = e.target;
        setSelectedBlock((prevBlock) => ({
          ...prevBlock,
          [name]: type === 'checkbox' ? (checked ? value : '') : (type === 'radio' ? (value === 'true') : value),
        }));
        props.setHasChanged(true)
        };
    const selectAll = () => {
        setSelectedBlock({
            is_understand_protocol: true,
            is_unable: true,
            is_previous_biological: true,
            is_topical_treatment: true,
        });
        props.setHasChanged(true)
        };
    const removeAll = () => {
        setSelectedBlock({
            is_understand_protocol: false,
            is_unable: false,
            is_previous_biological: false,
            is_topical_treatment: false,
        });
        props.setHasChanged(true)
        };

    const All_Exclusion_Data = () => {
        const createData = {
            'patient_id': props?.patient_id,
            'visit_number': props?.visit_number,
            'sessionId': props.selectedSessionId,
            ...selectedBlock
        }
        axios
            .patch(`${__API_URL__}/api/ecrf/update_exclusion_stemcell/${props.trialId}/`, createData, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                props.setModalVisitChangeShow(false)
                props.setHasChanged(false)
                setSelectedBlock(res.data);
                setMessage("Block updated successfully");
                setTypeOfMessage("success");
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                    user.actions.logout()
                }
                setMessage("Something Went Wrong");
                setTypeOfMessage("success");
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            });
    };
    const get_Exclusion_Criteria_Data = () => {
        axios
            .get(`${__API_URL__}/api/ecrf/get_exclusion_stemcell/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                setSelectedBlock(res.data);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                }
            });
    }
    React.useEffect(() => {
        if((props?.patient_id && props?.photo_session)){
            get_Exclusion_Criteria_Data();
        }
    }, [])
    return <>
        {
            (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props?.patient_id === undefined)) ?
                disable = true : ""
        }
        <fieldset disabled={disable}>
            {
                (props.photo_session || props.patient_id == undefined) ?
                    <>
                        {
                            (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                <div className="col-md-12 text-end">
                                    <button onClick={() => All_Exclusion_Data()} className='btn btn-primary my-3'>Update</button>
                                </div>
                            )
                        }

                <div className="row d-flex align-items-end">
                <div className="col-md-8">
                  <p>EXCLUSION CRITERIA STEM CELL</p>
                </div>
                <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={selectAll}
                >
                  Select all Yes's
                </button>
                  &nbsp;
                </div>
                <div className="col-md-2">
                  <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={removeAll}
                  >Select all No's</button>
                        &nbsp;
                </div>
              </div>

                        <div>
                            <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                                <div className="col-md-8 mt-2" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> 1. Unable to understand the protocol and/or ICF and/or provide valid
                                    permission for procedure.</div>
                                <div className="col-md-4  mx-3">
                                <td>
                                    <input
                                            type="radio"
                                            className="form-check-input  mx-2"
                                            name="is_understand_protocol"
                                            id="is_understand_protocol_yes"
                                            checked={selectedBlock?.is_understand_protocol === true}
                                            value={true}
                                            onChange={handleFieldChange}
                                            key="is_understand_protocol_yes"
                                        />
                                        <label>Yes</label>
                                    </td>
                                    <td>
                                    <input
                                        class="form-check-input  mx-2"
                                        type="radio"
                                        name="is_understand_protocol"
                                        checked={selectedBlock?.is_understand_protocol === false}
                                        id="is_understand_protocol_no"
                                        value={false}
                                        onChange={handleFieldChange}
                                        key="is_understand_protocol_no"
                                        />
                                        <label>No</label>
                                    </td>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                                <div className="col-md-8 mt-2" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> 2. Unable or unwilling to comply with any of the study requirements.
                                </div>
                                <div className="col-md-4 mx-3">
                                <td>
                                    <input
                                            type="radio"
                                            className="form-check-input  mx-2"
                                            name="is_unable"
                                            id="is_unable_yes"
                                            checked={selectedBlock?.is_unable === true}
                                            value={true}
                                            onChange={handleFieldChange}
                                            key="is_unable_yes"
                                        />
                                         <label>Yes</label>
                                    </td>
                                    <td>
                                    <input
                                        class="form-check-input  mx-2"
                                        type="radio"
                                        name="is_unable"
                                        checked={selectedBlock?.is_unable === false}
                                        id="is_unable_no"
                                        value={false}
                                        onChange={handleFieldChange}
                                        key="is_unable_no"
                                        />
                                        <label>No</label>
                                    </td>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                                <div className="col-md-8 mt-2" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> 3. No previous biological or immunosuppressive therapies. </div>
                                <div className="col-md-4  mx-3">
                                <td>
                                    <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name="is_previous_biological"
                                            id="is_previous_biological_yes"
                                            checked={selectedBlock?.is_previous_biological === true}
                                            value={true}
                                            onChange={handleFieldChange}
                                            key="is_previous_biological_yes"
                                        />
                                         <label>Yes</label>
                                    </td>
                                    <td>
                                    <input
                                        class="form-check-input  mx-2"
                                        type="radio"
                                        name="is_previous_biological"
                                        checked={selectedBlock?.is_previous_biological === false}
                                        id="is_previous_biological_no"
                                        value={false}
                                        onChange={handleFieldChange}
                                        key="is_previous_biological_no"
                                        />
                                        <label>No</label>
                                    </td>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                                <div className="col-md-8 mt-2" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>4. No topical treatment of biopsied lesion in previous 2 weeks.</div>
                                <div className="col-md-4 mx-3">
                                <td>
                                    <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name="is_topical_treatment"
                                            id="is_topical_treatment_yes"
                                            checked={selectedBlock?.is_topical_treatment === true}
                                            value={true}
                                            onChange={handleFieldChange}
                                            key="is_topical_treatment_yes"
                                        />
                                         <label>Yes</label>
                                    </td>
                                    <td>
                                    <input
                                        class="form-check-input mx-2"
                                        type="radio"
                                        name="is_topical_treatment"
                                        checked={selectedBlock?.is_topical_treatment === false}
                                        id="is_topical_treatment_no"
                                        value={false}
                                        onChange={handleFieldChange}
                                        key="is_topical_treatment_no"
                                        />
                                        <label>No</label>
                                    </td>
                                </div>
                            </div>
                        </div>
                    </> : 'Visit is not started'
                }
        </fieldset>
    </>
}

export default Exclusion_Criteria_Stem_Cell