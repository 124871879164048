import React, { useEffect } from 'react'

const TrialDrugKitAssignmentId = ({trialDetails, setTrialDetails, setHasChanged, hasChanged, handleBasicInfoUpdate, setDrugChanged, DrugChanged}) => {
    const drug_assignment_setting = trialDetails?.drug_assignment_setting
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTrialDetails((prevDetails) => ({
          ...prevDetails,
          drug_assignment_setting: {
            ...prevDetails.drug_assignment_setting,
            [name]: value,
            'id_change':true
          },
        }));
        setDrugChanged(true);
      };
      
      const handleDrugSettingType = (e) => {
        setTrialDetails({
          ...trialDetails,
          automatic_drug_type: !trialDetails.automatic_drug_type,
          drug_assignment_setting: {
            ...trialDetails.drug_assignment_setting,
            'id_change':true,
          },
        });
        setDrugChanged(true)
      };
      useEffect(() => {
        if (trialDetails?.automatic_drug_type === false) {
          setTrialDetails((prevTrialDetails) => ({
            ...prevTrialDetails,
            drug_assignment_setting: {
              ...prevTrialDetails.drug_assignment_setting,
              drug_id_type: 'Manual',
            },
          }));
        }
      }, [trialDetails?.automatic_drug_type]);

  return (

    <div class="row mx-5 mb-3">
      <div className="tableHeader">
              <div className="row d-flex align-items-end">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-end d-flex justify-content-end">
                
                  <>
                   {
                  (role !== "Investigator" && role !== "Inv_coordinator" && role !== 'Reviewer') && <>
                <button
                  type="button"
                  className="btn btn-primary mx-2  mt-2"
                  onClick={() => handleBasicInfoUpdate()}
                  disabled={!DrugChanged}
                >
                  Save
                </button>
                   </>
                  }
                </>
                </div>
              </div>
            </div>
          <div class="col-md-6">
            <label for="automatic_drug_type" class="form-label">Automatic</label>
            <input
              type="radio"
              name='automatic_drug_type'
              style={{ textAlign: 'right', width: '25%'}}
              id="automatic_drug_type"
              onChange={handleDrugSettingType}
              checked={trialDetails?.automatic_drug_type === true}
              value={true}
            />
          </div>

          <div class="col-md-6">
            <label for="automatic_drug_type" class="form-label">Manual</label>
            <input
              type="radio"
              name='automatic_drug_type'
              style={{ textAlign: 'right', width: '20%'}}
              id="automatic_drug_type"
              onChange={handleDrugSettingType}
            checked={trialDetails?.automatic_drug_type === false}
              value={true}
            />
          </div>

      <div className='col-md-12'>
        <div className="row">

          {(trialDetails?.automatic_drug_type) &&

            <div class="col-md-6">
                <label for="drug_id_type" class="form-label">Sequential</label>
                <input
                  type="radio"
                  name='drug_id_type'
                  style={{ textAlign: 'right', width: '24%'}}
                  id="drug_id_type"
                  onChange={handleChange}
                  checked={drug_assignment_setting?.drug_id_type === 'Sequence'}
                  value='Sequence'
                />
            </div>
          }

              {(trialDetails?.automatic_drug_type) &&

                <div class="col-md-6 mb-4">
                    <label for="drug_id_type" class="form-label">Randomize</label>
                    <input
                      type="radio"
                      name='drug_id_type'
                      style={{ textAlign: 'right', width: '11%'}}
                      id="drug_id_type"
                      onChange={handleChange}
                      checked={drug_assignment_setting?.drug_id_type === 'Randomize'}
                      value='Randomize' />
                </div>
              }
            
        </div>

      </div>
    </div>

  )
}

export default TrialDrugKitAssignmentId
