import { Box, Paper, Typography } from "@mui/material";

export default function Message({ message }) {
  const user_data = JSON.parse(localStorage.getItem("user_data"));

  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        justifyContent:
          message?.sender?.email === user_data.email
            ? "flex-end"
            : "flex-start",
        mb: 2,
      }}
    >
      <Box>
        <Paper
          sx={{
            maxWidth: "100%",
            px: 2,
            py: 1,
            borderRadius: 2,
            boxShadow: 3,
            bgcolor:
              message?.sender?.email === user_data.email
                ? "primary.main"
                : "background.paper",
            color:
              message?.sender?.email === user_data.email
                ? "primary.contrastText"
                : "text.primary",
          }}
        >
          <Typography variant="body1">{message.text}</Typography>
        </Paper>
        {message?.sender?.email !== user_data.email && (
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ display: "block", mt: 0.5 }}
          >
            Sent by:{" "}
            {`[${message?.sender?.email} , ${message?.sender?.role.title}]`}
          </Typography>
        )}
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ display: "block", mt: 0.5 }}
        >
          {new Date(message.created_at + "Z").toDateString()}
          ,&nbsp;
          {new Date(message.created_at + "Z").toLocaleTimeString()}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ display: "block", mt: 0.5 }}
        >
          Recipient/s: [
          {message?.recipient?.map((r) => ` {${r.email}, ${r.role.title}} `)}]
        </Typography>
      </Box>
    </Box>
  );
}
