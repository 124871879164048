import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";

const Skin_Biopsy_Stem_Cell = (props) => {
    const [selectedBlock, setSelectedBlock] = useState({})
    const [showAddButton, setShowAddButton] = useState(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [disableButton, setDisableButton] = useState(false)
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role
    const token = localStorage.getItem("token");
    const user = useUser();
    const [photoType, setPhotoType] = useState()
    const [checkCreate, setCheckCreate] =useState(false)
    const setMessage = props.setMessage
    const setTypeOfMessage = props.setTypeOfMessage
    let disable = false
    const BIOPSY_CHOICES = [
        { value: 'Standard punch (3 mm diameter) biopsy', label: 'Standard punch (3 mm diameter) biopsy' },
        { value: 'Standard punch (4 mm diameter) biopsy', label: 'Standard punch (4 mm diameter) biopsy' },
        { value: 'Double punch biopsy', label: 'Double punch biopsy' },
        { value: 'Shave biopsy', label: 'Shave biopsy' },
    ]
    const BIOPSY_SUB_TYPE_CHOICES = [
        { value: 'lesional', label: 'lesional' },
        { value: 'perilesional', label: 'perilesional' },
    ]
    const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItem = { ...selectedBlock.skinbiopsy_data[index], [name]: value };
    const updatedLogData = [...selectedBlock.skinbiopsy_data];
    updatedLogData[index] = updatedItem;
    setSelectedBlock({ ...
        selectedBlock, skinbiopsy_data: updatedLogData });
            props.setHasChanged(true)
    };
    const handleChange = (e, index) => {
        const { id, value } = e.target;
        const updatedItem = { ...selectedBlock.skinbiopsy_data[index], [id]: value === 'true' };
        const updatedLogData = [...selectedBlock.skinbiopsy_data];
        updatedLogData[index] = updatedItem;
        setSelectedBlock({ ...selectedBlock, skinbiopsy_data: updatedLogData });
        props.setHasChanged(true);
      };
    const CreateSkinBiopsy = (id) => {
        let skinbiopsy_data = '';
        if (selectedBlock && selectedBlock.skinbiopsy_data && selectedBlock.skinbiopsy_data[id]) {
          skinbiopsy_data = selectedBlock.skinbiopsy_data[id];
        } else {
          skinbiopsy_data = { 'id': null };
          setCheckCreate(true);
        }
        const createData = {
            'skinbiopsy_data' : skinbiopsy_data,
            'patient_id': props?.patient_id,
            'visit_number': props?.visit_number,
            'sessionId': props.selectedSessionId,
        }
        axios
            .patch(`${__API_URL__}/api/ecrf/update_skin_biopsy_stemcell/${props.trialId}/`, createData, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                let customMessage = ""
                if (checkCreate === true)
                {
                    customMessage = "New Block Added!"
                }
                else{
                    customMessage = "Block updated successfully"
                }
                setCheckCreate(false)
                props.setModalVisitChangeShow(false)
                props.setHasChanged(false)
                setSelectedBlock(res.data);
                setMessage(customMessage);
                setTypeOfMessage("success");
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                    user.actions.logout()
                }
                setMessage("Something Went Wrong");
                setTypeOfMessage("success");
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            });
    };
    const get_skin_biopsy = () => {
        axios
            .get(`${__API_URL__}/api/ecrf/get_skin_biopsy_stemcell/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                setSelectedBlock(res.data);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                }
            });
    }
    const get_photo_types = () => {
        axios
            .get(`${__API_URL__}/api/ecrf/get_photo_types/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                setPhotoType(res.data);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                }
            });
    }
    React.useEffect(() => {
        if ((props?.patient_id && props?.photo_session)) {
            get_photo_types();
            get_skin_biopsy();
        }
    }, [])
    if (role === 'CRO Admin' && !selectedBlock?.is_suture_removed) {
        setSelectedBlock(prevSelectedBlock => ({
          ...prevSelectedBlock,
          is_suture_removed: true,
        }));
      }
      let biopsyValues = [] 
      if (role === 'CRO Admin') {
         biopsyValues = ['Standard punch (3 mm diameter) biopsy', 'Standard punch (4 mm diameter) biopsy' ,  'Double punch biopsy', 'Shave biopsy']
        } 
    return <>
        {
            (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props?.patient_id === undefined)) ?
                disable = true : ""
        }
        <fieldset disabled={disable}>
        {
            role === "CRO Admin" ?
           <>
            {
                (props.patient_id != undefined && role === 'Investigator' || role === 'CRO Admin' || role === 'Inv_coordinator' || role === 'Cro_coordinator' || role === 'Data Manager' || role === 'Super Admin') && <>
                    <div className="col-md-12 text-end border mt-4">
                    <button onClick={() => CreateSkinBiopsy()} 
                    setShowTooltip={false}
                    className='btn btn-primary p-1 mx-3 my-1 px-2'><i className="fa fa-save fa-lg text-white"></i> Save</button>
                    </div>
                </>
            }
                        <div className="col-md-4 mb-3">
                        <label> Photo Types: </label>
                        <select
                            className="form-control"
                            name="photo_type"
                            style={{ borderWidth: "1px" }}
                        >
                            <option disabled={true} selected>Select</option>
                            {photoType && photoType?.map((type) => (
                                <option value={type.id} key={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                            </div>
                            <div className='col-md-10'>
                            {BIOPSY_CHOICES.map(event => (
                                                <div className='col-md-10 p-0' >
                                                        <input 
                                                        className="form-check-input mx-1" 
                                                        style={{marginLeft: "1em"}}
                                                        type="checkbox" 
                                                        name='biopsy' 
                                                        /> 
                                                        <label>{event.label}</label>
                                        <div className='col-md-10' style={{ marginLeft: "2em" }}>
                                            {BIOPSY_SUB_TYPE_CHOICES.map(event => (
                                                <div className='col-md-10 p-0'>
                                                        <input 
                                                        className="form-check-input mx-1" 
                                                        style={{marginLeft: "1em"}}
                                                        type="checkbox" 
                                                        name='biopsy_sub_type' 
                                                        /> 
                                                        <label>{event.label}</label>
                                                </div>
                                            ))}
                                        </div>
                                </div>
                            ))}
                        </div>
                        <div className='col-md-12'>
                            <label> Location of Biopsy:</label>
                            <input style={{ marginLeft: "0em" }}
                                class="form-control"
                                type="text"
                                name='location_biopsy'
                            ></input>
                        </div>
                         <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>Was sedation used for biopsy?</div>
                            <div className="col-md-4">
                                <td>
                                <label>
                                    <input
                                    type="radio"
                                    className="form-check-input mx-2"
                                    id="is_sedation_used"
                                    key="is_sedation_used_yes"
                                    />
                                    Yes
                                </label>
                                </td>
                                <td>
                                <label>
                                    <input
                                    type="radio"
                                    className="form-check-input mx-2"
                                    id="is_sedation_used"
                                    key="is_sedation_used_no"
                                    />
                                    No
                                </label>
                                </td>
                            </div>
                            </div>
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> Were sutures used at the biopsy location?</div>
                            <div className="col-md-4">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            id="is_suture_used"
                                            key="is_suture_used_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            id="is_suture_used"
                                            key="is_suture_used_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> Is suture removal needed?</div>
                            <div className="col-md-4">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            id="is_suture_removed"
                                            key="is_suture_removed_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            id="is_suture_removed"
                                            key="is_suture_removed_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>
                        {
                                <div className="col-md-6 calendarIcon">
                                    <label>Visit Date:</label>
                                    <input
                                        className="form-control"                                                        
                                        type="date"
                                        name="visit_date"
                                        inputProps={{
                                            placeholder: 'mm/dd/yy 00:00 AM',
                                            disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined)
                                        }}
                                    />
                                </div>
                        }
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> Biopsy instructions reviewed? </div>
                            <div className="col-md-4">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            id="is_instruction_reviewed"
                                            key="is_instruction_reviewed_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            id="is_instruction_reviewed"
                                            key="is_instruction_reviewed_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>
                                Written instructions given?
                            </div>
                            <div className="col-md-4">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            id="is_instruction_given"
                                            key="is_instruction_given_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            id="is_instruction_given"
                                            key="is_instruction_given_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>
        </>
         :
         <>   
        {(selectedBlock?.skinbiopsy_data) && selectedBlock?.skinbiopsy_data?.map((item, index) => {
            return <>
            {
                (props.patient_id != undefined && role === 'Investigator' || role === 'CRO Admin' || role === 'Inv_coordinator' || role === 'Cro_coordinator' || role === 'Data Manager' || role === 'Super Admin') && <>
                    <div className="col-md-12 text-end border mt-4">
                    <button onClick={() => CreateSkinBiopsy(index)} 
                    setShowTooltip={false}
                    className='btn btn-primary p-1 mx-3 my-1 px-2'><i className="fa fa-save fa-lg text-white"></i> Save</button>
                    </div>
                </>
            }
            {
                (props?.photo_session || props?.patient_id == undefined) ?
                    <>
                        <div className="col-md-4 mb-3">
                        <label> Photo Types: </label>
                        <select
                            className="form-control"
                            name="photo_type"
                            style={{ borderWidth: "1px" }}
                            value={item?.photo_type}
                            onChange={(e) => handleFieldChange(e, index)}
                        >
                            <option disabled={true} selected>Select</option>
                            {photoType && photoType?.map((type) => (
                                <option value={type.id} key={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                            </div>
                            <div className='col-md-10'>
                            {BIOPSY_CHOICES.map(event => (
                                                <div className='col-md-10 p-0' key={event.value}>
                                                        <input 
                                                        className="form-check-input mx-1" 
                                                        style={{marginLeft: "1em"}}
                                                        type="checkbox" 
                                                        name='biopsy' 
                                                        checked={item.biopsy === event.value}
                                                        value={event?.value} 
                                                        onChange={(e) => handleFieldChange(e, index)}
                                                        /> 
                                                        <label>{event.label}</label>
                                        {((item?.biopsy === event?.value) || (biopsyValues?.includes(event?.value))) && (
                                        <div className='col-md-10' style={{ marginLeft: "2em" }}>
                                            {BIOPSY_SUB_TYPE_CHOICES.map(event => (
                                                <div className='col-md-10 p-0' key={event.value}>
                                                        <input 
                                                        className="form-check-input mx-1" 
                                                        style={{marginLeft: "1em"}}
                                                        type="checkbox" 
                                                        name='biopsy_sub_type' 
                                                        checked={item.biopsy_sub_type === event.value}
                                                        value={event?.value} 
                                                        onChange={(e) => handleFieldChange(e, index)}
                                                        /> 
                                                        <label>{event.label}</label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className='col-md-12'>
                            <label> Location of Biopsy:</label>
                            <input style={{ marginLeft: "0em" }}
                                class="form-control"
                                type="text"
                                name='location_biopsy'
                                onChange={(e) => handleFieldChange(e, index)}
                                value={item?.location_biopsy}
                            ></input>
                        </div>
                         <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>Was sedation used for biopsy?</div>
                            <div className="col-md-4">
                                <td>
                                <label>
                                    <input
                                    type="radio"
                                    className="form-check-input mx-2"
                                    name={"is_sedation_used"+`${index}`}
                                    id="is_sedation_used"
                                    checked={item?.is_sedation_used === true}
                                    value={true}
                                    onChange={(e) => handleChange(e, index)}
                                    key="is_sedation_used_yes"
                                    />
                                    Yes
                                </label>
                                </td>
                                <td>
                                <label>
                                    <input
                                    type="radio"
                                    className="form-check-input mx-2"
                                    name={"is_sedation_used"+`${index}`}

                                    id="is_sedation_used"
                                    checked={item?.is_sedation_used === false}
                                    value={false}
                                    onChange={(e) => handleChange(e, index)}
                                    key="is_sedation_used_no"
                                    />
                                    No
                                </label>
                                </td>
                            </div>
                            </div>
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> Were sutures used at the biopsy location?</div>
                            <div className="col-md-4">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name={"is_suture_used"+`${index}`}
                                            id="is_suture_used"
                                            checked={item?.is_suture_used === true }
                                            value={true}
                                            onChange={(e) => handleChange(e, index)}
                                            key="is_suture_used_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name={"is_suture_used"+`${index}`}
                                            id="is_suture_used"
                                            checked={item?.is_suture_used === false }
                                            value={false}
                                            onChange={(e) => handleChange(e, index)}
                                            key="is_suture_used_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> Is suture removal needed?</div>
                            <div className="col-md-4">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name={"is_suture_removed"+`${index}`}
                                            id="is_suture_removed"
                                            checked={item?.is_suture_removed === true }
                                            value={true}
                                            onChange={(e) => handleChange(e, index)}
                                            key="is_suture_removed_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name={"is_suture_removed"+`${index}`}
                                            id="is_suture_removed"
                                            checked={item?.is_suture_removed === false }
                                            value={false}
                                            onChange={(e) => handleChange(e, index)}
                                            key="is_suture_removed_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>
                        {
                            (item?.is_suture_removed) === true ?
                                <div className="col-md-6 calendarIcon">
                                    <label>Visit Date:</label>
                                    <input
                                        className="form-control"                                                        
                                        onChange={(e) => handleFieldChange(e, index)} 
                                        type="date"
                                        name="visit_date"
                                        value={item?.visit_date}
                                        inputProps={{
                                            placeholder: 'mm/dd/yy 00:00 AM',
                                            disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined)
                                        }}
                                    />
                                </div>
                                : ""
                        }
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> Biopsy instructions reviewed? </div>
                            <div className="col-md-4">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name={"is_instruction_reviewed"+`${index}`}
                                            id="is_instruction_reviewed"
                                            checked={item?.is_instruction_reviewed === true }
                                            value={true}
                                            onChange={(e) => handleChange(e, index)}
                                            key="is_instruction_reviewed_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name={"is_instruction_reviewed"+`${index}`}
                                            id="is_instruction_reviewed"
                                            checked={item?.is_instruction_reviewed === false }
                                            value={false}
                                            onChange={(e) => handleChange(e, index)}
                                            key="is_instruction_reviewed_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>
                                Written instructions given?
                            </div>
                            <div className="col-md-4">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name={"is_instruction_given" +`${index}`}
                                            id="is_instruction_given"
                                            checked={item?.is_instruction_given === true }
                                            value={true}
                                            onChange={(e) => handleChange(e, index)}
                                            key="is_instruction_given_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name={"is_instruction_given" +`${index}`}
                                            id="is_instruction_given"
                                            checked={item?.is_instruction_given === false }
                                            value={false}
                                            onChange={(e) => handleChange(e, index)}
                                            key="is_instruction_given_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>
                   </> 
                    :
                     'Visit is not started'
            }
            </>
        })
    }
    </>}
    <div className="col-md-12 d-flex justify-content-end my-3">
                                       
                                       <button
                                               type="button"
                                               disabled={disableButton}
                                               className="btn btn-primary float-end disabled-cursor"
                                               onClick={() => {
                                                CreateSkinBiopsy();
                                               }}
                                               title={disableButton? 'Please save the information first': 'Click to add Skin Biopsy Block'}
                                       >
                                               Add +
                                       </button>
                       </div>    
    </fieldset>
                       </>
}
export default Skin_Biopsy_Stem_Cell