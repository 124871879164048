import axios from "axios";
import React, { useState } from "react";
import { __API_URL__ } from "../../SERVER_URL"

// import "../../App.css"

const AddNotification = ({ token, id, AllClinics }) => {
  const [noti, setnoti] = useState("")
  const [title, setTitle] = useState("")
  const [customdata, setcustomdata] = useState("")
  const [clinicId, setclinicId] = useState("")
  const [is_email, setis_email] = useState(false)
  const [is_app, setis_app] = useState(false)
  const [is_sms, setis_sms] = useState(false)
  const [days, setDays] = useState([])
  const [controlDays, setControlDays] = useState(false)
  const [value, setvalue] = useState([])

  const handleDaysChange = (e) => {
    setDays(e.target.value)
  }

  const handleAddDays = () => {
    setControlDays(true)
    setvalue(data => [...data, Number(days)])
    setDays([])
  }

  const handleDaysButton = (id) => {
    const newValue = value.filter((item, index) => index != id)
    setvalue(newValue)
  }

  const handleAppChange = () => {
    setis_app(!is_app)
  }

  const handleSmsChange = () => {
    setis_sms(!is_sms)
  }

  const handleEmailChange = () => {
    setis_email(!is_email)
  }

  const handleCustomdataChange = (e) => {
    setcustomdata(e.target.value)

  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const handleNoti = (e) => {
    setnoti(e.target.value)
  }

  function resetstate() {
    setTitle("")
    setcustomdata("")
    setis_email(false)
    setis_app(false)
    setis_sms(false)
    setvalue([])
    setDays([])
  }

  const CreateInstance = (e) => {
    e.preventDefault();
    const obj = {
      is_email: is_email,
      is_app: is_app,
      is_sms: is_sms,
      custom_title: title,
      custom_data: customdata,
      notification_type: noti,
      days: value,
      clinic_id: id,
    }
    saveClinicNotification(obj)
    resetstate()
    PopupClose()
  };

  async function saveClinicNotification(data) {
    try {
      await axios.post(`${__API_URL__}/api/clinic/saveClinicNotification/`, data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
      AllClinics()
    } catch (error) {
      console.error(error);
    }
  }
  const PopupClose = () => {
    document.getElementById("messageSend").style.display = "none";
    resetstate()
  }

  let optionsArray = [
    'GENERAL_MESSAGE',
    'PHOTO_REMIND',
    // 'NEWS',
    // 'APPOINTMENT_SCHEDULED',
    'APPOINTMENT_REMINDER',
    // 'SURVEY_REMINDER',
    // 'Other'
  ]

  return (
    <div id="messageSend">
      <form class="form-outline" onSubmit={CreateInstance}>
        <h5>Add Notifications</h5>
        <label>Notification Type</label>
        <select id="language" onChange={handleNoti} className="form-control">
          <option value='' >Select Type</option>
          {optionsArray.map((item, index) =>
            <option value={item} key={index} >{item}</option>
          )}
        </select>
        {noti !== 'APPOINTMENT_REMINDER' && <>
          <label>Custom Title</label>
          <input type="text"
            onChange={handleTitleChange}
            name="cusTitle"
            value={title}
            className="form-control"
          />
          <label class="form-label" for="textAreaExample">Custom Data</label>
          <textarea
            class="form-control" id="textAreaExample" rows="3"
            onChange={handleCustomdataChange}
            name="cusData"
            className="form-control"
            value={customdata}>
          </textarea>
        </>
        }
        <label>Days</label>
        <div className="row">
          <div className="col-8">
            <input type="number"
              onChange={handleDaysChange}
              value={days}
              name="date"
              className="form-control"
            />
          </div>
          <div className="col-4">
            <span className="btn btn-danger" onClick={handleAddDays} > Add Days </span>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap">
          {controlDays ? value.map((item, index) => (
            <>
              <div className="p-2">
                <span>{item}</span>
              </div>
              <div className="p-2 border">
                <button onClick={() => handleDaysButton(index)} type="button" className="btn btn-primary btn-sm px-1 py-0"> x </button>
              </div>
            </>
          ))
            :
            null
          }
        </div>
        <br></br>
        <label>Notification</label>
        <div style={{ 'display': 'block', 'margin-bottom': '14px' }}>
          <input type="checkbox" name="typeApp" checked={is_app} onChange={handleAppChange} /><label style={{ 'padding-right': '22px' }}>&nbsp; In App</label>
          <input type="checkbox" name="typeSms" checked={is_sms} onChange={handleSmsChange} /><label style={{ 'padding-right': '22px' }}>&nbsp; SMS</label>
          <input type="checkbox" name="typeEmail" checked={is_email} onChange={handleEmailChange} /><label style={{ 'padding-right': '22px' }}>&nbsp; Email</label>
        </div>
        <span className="btn btn-danger" onClick={PopupClose}>Close</span> &nbsp;
        <button type="submit" className="btn btn-primary">
          Send
        </button>
      </form>
    </div>
  )
}

export default AddNotification;