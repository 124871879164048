import React from "react";
// import logo from "../assets/img/whiteLogo.svg"
import logo from "../assets/img/smartTrialWhite.svg"
import zigzag from "../assets/img/zigzag.png"
import dotted from "../assets/img/dotted-img.png"



const LoginFooter = () =>{
    return(
        <>
        <div className="loginFooter">
            <div className="bgImg">
                <img src={zigzag} alt=""/>
                <img src={dotted} alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <img src={logo} alt="" />
                    </div>
                    <div className="col-md-3">
                        <ul>
                            <li>PRIVACY POLICY</li>
                            <li>TERMS & CONDITIONS</li>
                            <li>Contact Appiell: support@appiell.ai</li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <ul>
                            <li>APPIELL INC</li>
                            <li>SAN FRANCISCO. IRVINE. PLANO</li>
                        </ul>
                    </div>
                    <div className="col-md-3 footerIcon">
                        <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                        <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                        <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default LoginFooter;