import React, { useEffect, useState } from "react";
import AddTrialNotification from "../../components/Modals/AddTrialNotification";
import ShowNotificationModal from "../../components/Modals/ShowNotificationModal";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import MessagePopUp from "../../components/PopUp/MessagePopUp";
import moment from 'moment';
import ReactPaginate from "react-paginate";

const TrialNotification = (props,id, AllClinics) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const [isOpen, setIsOpen] = useState(false);
  const [trialNotification, setTrialNotification] = useState([]);
  const [adverseNotification, setAdverseNotification] = useState([]);
  const [notificationToDeleteId, setNotificationToDeleteId] = useState();
  const [popMessage, setPopMessage] = useState(false);
  const [isTrialNotification, setIsTrialNotification] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState("Trial");
  const [loader, setLoader] = useState(false);
  const convertFirstLetterToCapital = (str) => {
    const newStr = str.charAt(0).toUpperCase() + str.slice(1);
    return newStr;
  };
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleDeleteNotification = () => {
    Swal.fire({
      title: 'Are you sure ?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Notification Deleted', '', 'success')
        DeleteNotification()
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    });
  }

  async function DeleteNotification() {
    try {
      await axios.delete(
        `${__API_URL__}/api/appointments/delete_trial_notification/${selectedItem.id}/`,
        {
          headers: {
            Authorization: `Token ${props.token}`
          }
        }
      );

      setModalShow(false)
      props.loaderTrue()
      props.AllClinics()
    } catch (error) {
      if (error === 'Error: Request failed with status code 401') {
        props.user.logout()
      }
    }
  }
  const PopupOpen = () => {
    setIsOpen(true);
    document.getElementById("messageSend").style.display = "block";
  }

  const NotificationDelete = (id) => {
    setNotificationToDeleteId(id);
    setPopMessage(true);
    setIsTrialNotification(true);
  };

  const getTrialsNotification = () => {
    setLoader(true)
    axios
      .get(`${__API_URL__}/api/appointments/get_trial_notifications/${props.trialId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTrialNotification(res.data)
        setLoader(false)
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
        }
      });
  };

  const getAdverseNotification = () => {
    setLoader(true)
    axios
      .get(`${__API_URL__}/api/appointments/get_adverse_notifications/${props.trialId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAdverseNotification(res.data)
        setLoader(false)
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
        }
      });
  };

  const confirmDelete = () => {
    if (notificationToDeleteId) {
      axios
        .delete(`${__API_URL__}/api/appointments/delete_trial_notification/${notificationToDeleteId}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then(() => {
          toast.success("Notification Deleted Successfully");
          getTrialsNotification()
          getAdverseNotification()
          setNotificationToDeleteId(0)
        })
        .catch(() => {
          toast.error("Something Went Wrong");
        })
        .finally(() => {
          setPopMessage(false);
        });
    }
  }

  useEffect(() => {
    if (selectedNotification === "Trial") {
      getTrialsNotification("")
    } else if (selectedNotification === "Adverse") {
      getAdverseNotification()
    }
    setCurrentPage(0); 
  }, [selectedNotification]);

  const renderTrialNotifications = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const notificationsToDisplay = trialNotification.slice(
      startIndex,
      endIndex
    );

    return (
      <tbody>
        {notificationsToDisplay?.map((notification) => (
          <tr key={notification.id}>
            <td>{convertFirstLetterToCapital(`${notification.IsApp}`)}</td>
            <td>{convertFirstLetterToCapital(`${notification.IsSMS}`)}</td>
            <td>{convertFirstLetterToCapital(`${notification.IsEmail}`)}</td>
            <td>{notification.hours_before}</td>
            <td>{notification.custom_title}</td>
            <td>{notification.custom_data}</td>
            {(role === "CRO Admin" || role === "Cro_coordinator" || role === 'Data Manager' || role === "Super Admin") && (
              <td style={{ whiteSpace: 'pre-wrap' }}>
                <a type='button' onClick={() => NotificationDelete(notification?.id)}>
                  <i className="fa fa-trash"></i>
                </a>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    );
  };

  const renderAdverseNotifications = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const notificationsToDisplay = adverseNotification.slice(
      startIndex,
      endIndex
    );
    return (
      <tbody>
        {notificationsToDisplay?.map((adverse) => (
          <tr key={adverse.id}>
            <td>{convertFirstLetterToCapital(`${adverse.IsApp}`)}</td>
            <td>{convertFirstLetterToCapital(`${adverse.IsSMS}`)}</td>
            <td>{convertFirstLetterToCapital(`${adverse.IsEmail}`)}</td>
            <td>{adverse.custom_title}</td>
            <td>{adverse.custom_data}</td>
            <td>{adverse.start_date? moment(`${adverse.start_date}Z`).format("YYYY-MM-DD"):'-'}</td>
            <td>{adverse.end_date?moment(`${adverse.end_date}Z`).format("YYYY-MM-DD"):'-'}</td>
            <td>{adverse.start_date_offset}</td>
            <td>{adverse.end_date_offset}</td>
            <td>{adverse.frequency}</td>
            {(role === "CRO Admin" || role === "Cro_coordinator" || role === "Data Manager" || role === "Super Admin") && (
              <td style={{ whiteSpace: 'pre-wrap' }}>
                <a type='button' onClick={() => NotificationDelete(adverse?.id)}>
                  <i className="fa fa-trash"></i>
                </a>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className="row" key="" style={{ padding: '1em' }}>
      <div className="clinicForm patient_table">
        <div className="tableHeader">
          <div className="row">
            <div className="col-md-6">
            </div>
            {role === 'Reviewer' ? '' :
              <div className="col-md-6 text-end">
                {
                  (role === "CRO Admin" || role === "Cro_coordinator" || role === "Data Manager" || role === "Super Admin") &&
                  <>
                    <button type="button" className="btn btn-primary btn-sm mx-5"
                      onClick={PopupOpen}
                    >
                      Add Notification
                    </button>
                  </>
                }
              </div>
            }
          </div>
          <AddTrialNotification token={token} trialId={props.trialId} getTrialsNotification={getTrialsNotification} getAdverseNotification={getAdverseNotification} setMessage={props.setMessage} setTypeOfMessage={props.setTypeOfMessage} />
          {
            modalShow ?
              <ShowNotificationModal show={modalShow} onHide={() => setModalShow(false)} selectedItem={selectedItem} handleDelete={handleDeleteNotification} />
              :
              null
          }
        </div>
        <ToastContainer />
        <div className="row border" key="" style={{ padding: '1em' }}>
          <div className="borderless table-wrapper-scroll-y my-custom-scrollbar">
            <div className="patient_table patient_photos">
              <div className="tableHeader">
                <div className="row">
                  <div className="col-md-6">
                    <button type="button" className={selectedNotification === 'Trial' ? "btn btn-primary btn-sm" : "btn"} onClick={() => setSelectedNotification('Trial')}>
                      Trial Notification
                    </button>
                    <button type="button" className={selectedNotification === 'Adverse' ? "btn btn-primary btn-sm" : "btn"} onClick={() => setSelectedNotification('Adverse')}>
                      Adverse Notification
                    </button>
                  </div>
                </div>
                <table>
                  {loader ? (
                    <div className="loader">
                      <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                    </div>
                  ) : (
                    <>
                      <thead className="caretOrder">
                        <tr>
                          {/* Trial Notification headers */}
                          {selectedNotification === 'Trial' && (
                            <>
                              <th>IN APP</th>
                              <th>SMS</th>
                              <th>EMAIL</th>
                              <th>Hours Before</th>
                              <th>Custom Title</th>
                              <th>Custom Data</th>
                              {(role === "CRO Admin" || role === "Cro_coordinator" || role === "Data Manager" || role === "Super Admin") && <th>Delete</th>}
                            </>
                          )}
                          {/* Adverse Notification headers */}
                          {selectedNotification === 'Adverse' && (
                            <>
                              <th>IN APP</th>
                              <th>SMS</th>
                              <th>EMAIL</th>
                              <th>Custom Title</th>
                              <th>Custom Data</th>
                              <th>Start Date</th>
                              <th>End Date </th>
                              <th>Start Date Offset</th>
                              <th>End Date Offset</th>
                              <th>Frequency</th>
                              {(role === "CRO Admin" || role === "Cro_coordinator" || role === "Data Manager" || role === "Super Admin") && <th>Delete</th>}
                            </>
                          )}
                        </tr>
                      </thead>
                      {selectedNotification === "Trial"
                        ? renderTrialNotifications()
                        : renderAdverseNotifications()}
                    </>
                  )}
                </table>
                <div className="tableHeader">
                  <div className="row">
                    <div className="col-md-6">
                    <p>
                      Showing {selectedNotification === "Trial" ? (
                        trialNotification.length > 0
                          ? currentPage * itemsPerPage + 1
                          : 0
                      ) : selectedNotification === "Adverse" ? (
                        adverseNotification.length > 0
                          ? currentPage * itemsPerPage + 1
                          : 0
                      ) : 0}  out of {selectedNotification === "Trial" ? trialNotification.length : selectedNotification === "Adverse" ? adverseNotification.length : 0}
                    </p>
                    </div>
                    <div className="reactPagination col-md-6">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next"
                        pageRangeDisplayed={5}
                        pageCount={Math.ceil(
                          (selectedNotification === "Trial"
                            ? trialNotification?.length
                            : adverseNotification?.length) / itemsPerPage
                        )}
                        previousLabel="previous"
                        onPageChange={handlePageChange}
                        forcePage={currentPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {popMessage &&
              <MessagePopUp
                setPopMessage={setPopMessage}
                confirmDelete={confirmDelete}
                isTrialNotification={isTrialNotification}
              ></MessagePopUp>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrialNotification;
