import {useState, React, useEffect} from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import Select from 'react-select';


const SelectTrialType = ({setTrialType, trialType, allTrialType, setCurrentPage, currentPage, saveSurvey, updateSurvey, surveyId, creator}) => {
  const token = localStorage.getItem('token');

  useEffect(()=>{
    console.log(currentPage)
  }, [])

  const onTrialTypeChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
        case 'remove-value':
            if (actionMeta.removedValue.isFixed) {
                return newValue;
            } else {
                newValue = currentPage.trial_type.filter((v) => v.value !== actionMeta.removedValue.value);
                setCurrentPage((prev) => ({
                    ...prev,
                    trial_type: newValue
                }));
            }
            break;
        case 'pop-value':
            break;
        case 'clear':
            newValue = currentPage.trial_type.filter((v) => v.isFixed);
            setCurrentPage((prev) => ({
                ...prev,
                trial_type: newValue
            }));
            break;
        case 'select-option':
            setCurrentPage((prev) => {
                return {
                    ...prev,
                    trial_type: newValue
                };
            });
            break;
    }
};


  return (
    <div>
       <Modal size="md" show onHide={() => {setTrialType(false)}} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Select Trial Type
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
        <form
            onSubmit={(e) => {
              e.preventDefault()
              if (!surveyId) {
                saveSurvey(creator.JSON)
              } else {
                updateSurvey(surveyId, creator.JSON)
              }
            }}
          >
            <div className="col-md-12 mb-5">
                  <label>Trial Types</label>
                  <Select
                    value={currentPage?.trial_type}
                    isMulti
                    className="basic-multi-select"
                    options={allTrialType}
                    onChange={onTrialTypeChange}
                  />
                </div>
              <button className='btn btn-primary d-flex justify-content-end' type='submit'>Save</button>
          </form>

        </Modal.Body>
    </Modal>
    </div>
  )
}

export default SelectTrialType
