import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from "react-router";
import { Box } from '@mui/material';




const Breadcrumbs = ({B,F,L,E,P, participant_id, age, birth_date}) => {
    const { _id_ } = useParams()

    var items = [F, L, E, P, participant_id, age, birth_date];
    localStorage.setItem('myKey', JSON.stringify(items));
    
    try {
        items = JSON.parse(localStorage.getItem('myKey'));
      } catch (error) {
        console.error('Error parsing JSON data:', error);
      }


    let user_data = ''
    try {
        user_data = JSON.parse(localStorage.getItem("user_data"));
      } catch (error) {
        console.error('Error parsing JSON data:', error);
      }

      let role
      try {
        role = user_data.role
      } catch (error) {
        console.error('Error', error);

      }
            
  return (
    <div className='uiBreadcrumbs'style={{position:"sticky", top:"0", backgroundColor:"#f1f1f1", padding:"10px", zIndex:"99"}}>
    <ul>
        <li><Link to="/">Home</Link><svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon"><path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg></li>
        <li><Link to="/patient/">{user_data.role === 'CRO Admin' || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator' || user_data.role === 'Inv_coordinator' || role === 'Cro_coordinator' || user_data.role ==="Data Manager" || user_data.role ==="Super Admin" ? "Subject" : "Patient"}</Link>
       {B &&
        <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon"><path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
        }
        </li>
        <li style={{'color':'#EE295D'}}>
            {B == 'Photos' ? (
                <Link to={"/patient/"+_id_+"/photos"}>{B}</Link>
            ):(
                <>
                {B}
                </>
            )}
        </li> 
        <Box>Logged in as: <strong>{user_data.role == 'Inv_coordinator' ? 'CRC' : user_data.role == 'Cro_coordinator' ? 'Sub_CRO': user_data.role == 'Sub_investigator' ? 'Sub_INV': user_data.role}</strong> [<strong>{user_data.email}</strong>]</Box>
    </ul>
        {items && B &&
        <div className='PInfo'>
           { items[4] &&
            <span><i className="fa fa-id-card" aria-hidden="true"></i> &nbsp;{items[4]}</span>
            }
        { (role !== 'Reviewer' && role !== 'CRO Admin' && role !=="Cro_coordinator" && user_data.role !=="Data Manager") && <>
            <span><i className="fa fa-user-o" aria-hidden="true"></i> &nbsp;{items[0]}&nbsp;{items[1]}</span>
            <span><i className="fa fa-user-md" aria-hidden="true"></i> &nbsp;{items[5]}</span>
            <span><i class="fa fa-calendar-check-o" aria-hidden="true"></i> &nbsp;{items[6]}</span>
            <span><i className="fa fa-envelope-o" aria-hidden="true"></i> &nbsp;{items[2]}</span>
            <span><i className="fa fa-volume-control-phone" aria-hidden="true"></i> &nbsp;{items[3]}</span>
            </>}
        </div>
        }
    </div>
  )
}

export default Breadcrumbs