import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "../../components/navBar/navBar";


const PatientArchive = () => {
//   const [Page, setPage] = useState(2);
//   const { PatientData, LoadData, PaginationData, Loader } =
//     useFetch("allpatient");

//   const handlePageClick = (event) => {
//     console.log(event.selected)
//     PaginationData(event.selected+1);
//   };

  return (
    <>
      <NavBar active="" />
      <div class="container-fluid">
        <div className="row patient_table">
          <div className="col-md-2">
            <div className="sideBar">
              <h5>Patient Menu</h5>
              <ul>
                <li>
                  <Link to="/patient">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                      alt=""
                    />
                    Patients
                  </Link>
                </li>
                <li>
                  <Link to="/patient/archive/" className="active">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                      alt=""
                    />
                    Patients Archived
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-10">
            <div className="tableHeader">
              <div className="row">
                <div className="col-md-6">
                  <h5>Patients</h5>
                </div>
                <div className="col-md-6 text-end">
                  <button type="button" class="btn btn-danger">
                    Unarchive
                  </button>
                </div>
              </div>
            </div>
            <table>
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>
                        <input type="search" placeholder="Email" />
                      </th>
                      <th>
                        <input type="search" placeholder="Messages" />
                      </th>
                      <th>
                        <input type="search" placeholder="First Name" />
                      </th>
                      <th>
                        <input type="search" placeholder="Last Name" />
                      </th>
                      <th>
                        <input type="search" placeholder="Birth Year" />
                      </th>
                      <th>
                        <input type="search" placeholder="Practitioner" />
                      </th>
                      <th>
                        <input type="search" placeholder="Clinic" />
                      </th>
                      <th>
                        <input type="search" placeholder="Appoinment" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({length : 6}).map((index)=>(
                      <tr key={index}>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                            susanmackiesmith@gmail.com
                        </td>
                        <td></td>
                        <td>Susan</td>
                        <td>Smith</td>
                        <td>1990-07-28</td>
                        <td></td>
                        <td>appiell.ai </td>
                        <td> </td>
                      </tr>
                
                    ))}
                        </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientArchive;
