import { useState } from 'react';
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
export default function MainAccordion({title, data, isOpen}) {
  return (
      <div>
        <Accordion defaultExpanded={isOpen}>
        <AccordionSummary    
         expandIcon={<ExpandMoreIcon/>}
         aria-controls="panel2a-content"
         id="panel2a-header"
        >
        <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {data}
          </Typography>
        </AccordionDetails>
      </Accordion>
        <Collapse in={isOpen}>
          <div style={{ marginTop: '10px' }}>
          </div>
        </Collapse>
        <Accordion>
        <></>      
        </Accordion>
    </div>
  );
}
