import React, { useState, useEffect } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Table from 'react-bootstrap/Table';


const Photographic_App_After_Injection = (props) => {

const [value,newValue]=React.useState("list")
const token = localStorage.getItem("token");
const user_data = JSON.parse(localStorage.getItem("user_data"));
const role = user_data.role
const setMessage = props.setMessage
const setTypeOfMessage = props.setTypeOfMessage
let disable=false
const [selectedBlock, setSelectedBlock] = useState({})
const user = useUser();

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setSelectedBlock((selectedBlock) => ({
        ...selectedBlock,
        [name]: value,
        }));
        props.setHasChanged(true)
      };
    
    const handleChange=(e) =>{
    
            setSelectedBlock({
                    ...selectedBlock,
                    is_after_injection: !selectedBlock.is_after_injection,
                    }); 
                    props.setHasChanged(true)
    };
  
    const GetSelectedBlockData = () => {
      axios
        .get(`${__API_URL__}/api/ecrf/get_photographic_app_after/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setSelectedBlock(res.data);
        }).catch((err) => {
          if (err == 'Error: Request failed with status code 401') {
          }
        });
    }

    const AllVisitsData = () => {
        const createData = {
          'patient_id': props?.patient_id,
          'visit_number':props?.visit_number,
          'is_after_injection': selectedBlock?.is_after_injection === undefined ? true :selectedBlock?.is_after_injection,
          'after_injection_reason':selectedBlock?.after_injection_reason,
          'sessionId': props.selectedSessionId,
    
        }
        axios
          .patch(`${__API_URL__}/api/ecrf/update_photographic_app_after/${props.trialId}/`, createData, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((res) => {
            setSelectedBlock(res.data);
            props.setModalVisitChangeShow(false)
            props.setHasChanged(false)
            setMessage("Block updated successfully");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        }).catch((err) => {
            if(err == 'Error: Request failed with status code 401'){
            user.actions.logout()
            }
            setMessage("Something Went Wrong");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        });
      };
      React.useEffect(() => {
        if((props?.patient_id && props?.photo_session)){
          GetSelectedBlockData();
        }
      }, [])
                    return <>
                    {

                        role==='CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined) ?
                            disable=true:''
                    }
                    <fieldset disabled={disable}>
                    {
                         (props.photo_session || props.patient_id == undefined ) ?
                         <>
                                       {
                                        (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                            <div className="col-md-12 text-end">
                                                <button onClick={() => AllVisitsData()} className='btn btn-primary'>Update</button>
                                            </div>
                                        )
                                     }
                    <div className='col-md-12'>
                        <div className="row">
                              <div className="col-md-9">
                              <p> Was the photography performed by the participant after injection for each target lesion at this visit?  </p>
                              </div>
                              <div className="col-md-1">
                              <p><small> Yes
                                    <input
                                        className="form-check-input mx-1"
                                        type="radio"
                                        name="is_after_injection"
                                        checked = {selectedBlock?.is_after_injection == true}
                                        id="radio1"
                                        value={selectedBlock?.is_after_injection == true}
                                        onChange={handleChange}
                                    />
                                </small>
                                </p>
                                </div>
                            <div className="col-md-1">
                              <p><small> No
                                    <input
                                    className="form-check-input mx-1"
                                    type="radio"
                                    name="is_after_injection"
                                    checked = {selectedBlock?.is_after_injection == false}
                                    id="radio1"
                                    value={selectedBlock?.is_after_injection == false}
                                    onChange={handleChange}
                                    />
                                </small>
                                </p>
                        </div>
                { selectedBlock.is_after_injection === false ?
                    <div className='col-md-6'
                         style={{marginLeft: "1em"}} 
                    >
                        <label> If No, indicate reason:  </label>
                            <input 
                                    class="form-control" 
                                    type="text" 
                                    style={{ borderWidth:'1px'}}
                                    name='after_injection_reason' 
                                    id="flexSwitchCheckDefault"
                                    value={selectedBlock.after_injection_reason}
                                    onChange={(e) => handleFieldChange(e)}>
                            </input>
                    </div>:''}


                            </div>
                            </div>
    </>:'Visit is not started'
}
</fieldset>

    </>
}
export default Photographic_App_After_Injection;