import React, { useState, useEffect } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Button from 'react-bootstrap/Button';
const Inclusion_Criteria = (props) => {
  const [selectedBlock, setSelectedBlock] = React.useState({})
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const user = useUser();
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  let disable = false
  const handleIrbApprovedIcf = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      irb_approved_icf: !selectedBlock.irb_approved_icf,
    });
    props.setHasChanged(true)
  };
  const handleIsCompletedAllRequired = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_complete_all_required: !selectedBlock.is_complete_all_required,
    });
    props.setHasChanged(true)
  };
  const handleIsDiagnosedFacialAcneVulgaris = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_diagnosed_facial_acne_vulgaris: !selectedBlock.is_diagnosed_facial_acne_vulgaris,
    });
    props.setHasChanged(true)
  };
  const handleOwnerSmartPhone = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_owner_smartphone: !selectedBlock.is_owner_smartphone,
    });
    props.setHasChanged(true)
  };
  const handleIsGoodHealth = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_good_health: !selectedBlock.is_good_health,
    });
    props.setHasChanged(true)
  };
  const handleIsInflammatoryLesion = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_inflammatory_lesion: !selectedBlock.is_inflammatory_lesion,
    });
    props.setHasChanged(true)
  };
  const handleIsNegativeUpt = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_negative_upt: !selectedBlock.is_negative_upt,
    });
    props.setHasChanged(true)
  };
  const selectAll = () => {
    setSelectedBlock({
        irb_approved_icf: true,
        is_complete_all_required: true,
        is_diagnosed_facial_acne_vulgaris: true,
        is_owner_smartphone: true,
        is_good_health: true,
        is_inflammatory_lesion: true,
        is_negative_upt: true,
    });
    props.setHasChanged(true)
    };
  const removeAll = () => {
    setSelectedBlock({
        irb_approved_icf: false,
        is_complete_all_required: false,
        is_diagnosed_facial_acne_vulgaris: false,
        is_owner_smartphone: false,
        is_good_health: false,
        is_inflammatory_lesion: false,
        is_negative_upt: false,
    });
    props.setHasChanged(true)
    };
  
    const GetSelectedBlockData = () => {
      axios
      .get(`${__API_URL__}/api/ecrf/get_inclusion/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
      headers: {
      Authorization: `Token ${token}`,
      },
      })
      .then((res) => {
        setSelectedBlock(res.data);
      }).catch((err) => {
      if (err == 'Error: Request failed with status code 401') {
      }
      });
      }

  const AllInclusionData = () => {
    const createData = {
      'patient_id': props?.patient_id,
      'visit_number': props?.visit_number,
      'irb_approved_icf': selectedBlock?.irb_approved_icf === undefined ? true : selectedBlock?.irb_approved_icf,
      'is_complete_all_required': selectedBlock?.is_complete_all_required === undefined ? true : selectedBlock?.is_complete_all_required,
      'is_diagnosed_facial_acne_vulgaris': selectedBlock?.is_diagnosed_facial_acne_vulgaris === undefined ? true : selectedBlock?.is_diagnosed_facial_acne_vulgaris,
      'is_owner_smartphone': selectedBlock?.is_owner_smartphone === undefined ? true : selectedBlock?.is_owner_smartphone,
      'is_good_health': selectedBlock?.is_good_health === undefined ? true : selectedBlock?.is_good_health,
      'is_inflammatory_lesion': selectedBlock?.is_inflammatory_lesion === undefined ? true : selectedBlock?.is_inflammatory_lesion,
      'is_negative_upt': selectedBlock?.is_negative_upt === undefined ? true : selectedBlock?.is_negative_upt,
      'sessionId': props.selectedSessionId,
    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_inclusion/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        props.globalVisits(false);
        setSelectedBlock(res.data);
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      });
  };

  React.useEffect(() => {
    if((props?.patient_id && props?.photo_session)){
      GetSelectedBlockData();
    }
  }, [])
  return <>
    {
      (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined)) ?
        disable = true : ""
    }
    <fieldset disabled={disable}>
      {
        (props.photo_session || props.patient_id == undefined) ?
          <>
            {
              (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                <div className="col-md-12 text-end">
                  <button onClick={() => AllInclusionData()} className='btn btn-primary'>Update</button>
                </div>
              )
            }
              <thread> INCLUSION CRITERIA</thread>

              <div className="row d-flex align-items-end">
                <div className="col-md-8">
                  <p>Check Yes or No for each criterion</p>
                </div>
                <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={selectAll}
                >
                  Select all Yes's
                </button>
                  &nbsp;
                </div>
                <div className="col-md-2">
                  <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={removeAll}
                  >Select all No's</button>
                        &nbsp;
                </div>
              </div>

              <div>
                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                  <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>1. Outpatient, male or female of any race, 18 years of age or older.
                    Female subjects of childbearing potential must have a negative UPT at Baseline.
                  </div>
                  <div className="col-md-4 mx-3">
                    <td>
                      <input
                        class="form-check-input mx-2"
                        type="radio"
                        name="irb_approved_icf"
                        checked={selectedBlock?.irb_approved_icf === true}
                        id="radio1"
                        value={selectedBlock?.irb_approved_icf === true}
                        onChange={(e) => handleIrbApprovedIcf(e)}
                      />
                      <label>Yes</label>
                    </td>
                    <td>
                      <input
                        class="form-check-input mx-2"
                        type="radio"
                        name="irb_approved_icf"
                        checked={selectedBlock?.irb_approved_icf === false}
                        id="radio1"
                        value={selectedBlock?.irb_approved_icf === false}
                        onChange={(e) => handleIrbApprovedIcf(e)}
                      />
                      <label>No</label>

                    </td>
                  </div>
                </div>
                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                <div className="col-md-8 col-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>2. Diagnosed with facial acne vulgaris.
                  </div>
                  <div className="col-md-4  mx-3">
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_complete_all_required"
                      checked={selectedBlock?.is_complete_all_required === true}
                      id="radio2"
                      value={selectedBlock?.is_complete_all_required === true}
                      onChange={handleIsCompletedAllRequired}
                    />
                      <label>Yes</label>

                  </td>
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_complete_all_required"
                      checked={selectedBlock?.is_complete_all_required === false}
                      id="radio2"
                      value={selectedBlock?.is_complete_all_required === false}
                      onChange={handleIsCompletedAllRequired}
                    />
                     <label>No</label>
                  </td>
                  </div>
                </div>
                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                <div className="col-md-8 col-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>
                    <div style={{ borderWidth: '0px' }}>
                      <p style={{ whiteSpace: 'normal' }}>3. At least one (1) identifiable inflammatory lesion that, in the opinion of the investigator, is clinically indicated for
                        standard-of-care intralesional injection(s) of triamcinolone and for which the research participant has already
                        agreed to have standard-of-care intralesional injection(s) of triamcinolone.</p></div>
                  </div>
                  <div className="col-md-4  mx-3">
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_diagnosed_facial_acne_vulgaris"
                      checked={selectedBlock?.is_diagnosed_facial_acne_vulgaris === true}
                      id="radio3"
                      value={selectedBlock?.is_diagnosed_facial_acne_vulgaris === true}
                      onChange={handleIsDiagnosedFacialAcneVulgaris}
                    />
                      <label>Yes</label>

                  </td>
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_diagnosed_facial_acne_vulgaris"
                      checked={selectedBlock?.is_diagnosed_facial_acne_vulgaris === false}
                      id="radio3"
                      value={selectedBlock?.is_diagnosed_facial_acne_vulgaris === false}
                      onChange={handleIsDiagnosedFacialAcneVulgaris}
                    />
                     <label>No</label>
                  </td>
                  </div>
                </div>
                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                <div className="col-md-8 col-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>
                    <div style={{ borderWidth: '0px' }}>
                      <p>4.	Owner of smartphone with capacity for front-facing photography and app download from Apple App Store or Google Play.</p></div>
                  </div>
                  <div className="col-md-4  mx-3">
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_owner_smartphone"
                      checked={selectedBlock?.is_owner_smartphone === true}
                      id="radio3"
                      value={selectedBlock?.is_owner_smartphone === true}
                      onChange={handleOwnerSmartPhone}
                    />
                      <label>Yes</label>

                  </td>
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_owner_smartphone"
                      checked={selectedBlock?.is_owner_smartphone === false}
                      id="radio3"
                      value={selectedBlock?.is_owner_smartphone === false}
                      onChange={handleOwnerSmartPhone}
                    />
                     <label>No</label>
                  </td>
                  </div>
                  </div>
                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                <div className="col-md-8 col-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>5. Able to follow study instructions and likely to complete all required visits.</div>
                <div className="col-md-4  mx-3">
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_good_health"
                      checked={selectedBlock?.is_good_health === true}
                      id="radio4"
                      value={selectedBlock?.is_good_health === true}
                      onChange={handleIsGoodHealth}
                    />
                      <label>Yes</label>
                  </td>
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_good_health"
                      checked={selectedBlock?.is_good_health === false}
                      id="radio4"
                      value={selectedBlock?.is_good_health === false}
                      onChange={handleIsGoodHealth}
                    />
                     <label>No</label>
                  </td>
                </div>
                </div>
                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                <div className="col-md-8 col-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>6. In good general health as determined by medical history at the time of screening (Investigator discretion).</div>
                <div className="col-md-4  mx-3">
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_inflammatory_lesion"
                      checked={selectedBlock?.is_inflammatory_lesion === true}
                      id="radio5"
                      value={selectedBlock?.is_inflammatory_lesion === true}
                      onChange={handleIsInflammatoryLesion}
                    />
                      <label>Yes</label>
                  </td>
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_inflammatory_lesion"
                      checked={selectedBlock?.is_inflammatory_lesion === false}
                      id="radio5"
                      value={selectedBlock?.is_inflammatory_lesion === false}
                      onChange={handleIsInflammatoryLesion}
                    />
                     <label>No</label>
                  </td>
                </div>
                </div>
                <div className="d-flex justify-content-between" style={{width: '100%'}}>
                  <div className="col-md-8 col-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>7. Sign the IRB-approved ICF (including HIPAA authorization) prior to any study-related procedures being performed. </div>
                  <div className="col-md-4  mx-3">
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_negative_upt"
                      checked={selectedBlock?.is_negative_upt === true}
                      id="radio6"
                      value={selectedBlock?.is_negative_upt === true}
                      onChange={handleIsNegativeUpt}
                    />
                      <label>Yes</label>
                  </td>
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_negative_upt"
                      checked={selectedBlock?.is_negative_upt === false}
                      id="radio6"
                      value={selectedBlock?.is_negative_upt === false}
                      onChange={handleIsNegativeUpt}
                    />
                     <label>No</label>
                  </td>
                </div>
                </div>
              </div>
          </> : 'Visit is not started'
      }
    </fieldset>
  </>
}

export default Inclusion_Criteria;
