import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "../../components/navBar/navBar";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import EcrfPopUpMessage from "../../components/PopUp/EcrfPopUpMessage";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import ReactPaginate from 'react-paginate';

const SurveyListWidget = () => {
    const [items, setItems] = useState([]);
    const token = localStorage.getItem("token");
    const [popMessage, setPopMessage] = useState(false);
    const navigate = useNavigate();
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [pageItemCount, setPageItemCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [loader, setLoader] = useState(false);

    const DeleteDocument = (id) => {
        setDeleteItemId(id);
        setPopMessage(true);
    };
    const confirmDelete = (id) => {
        if (id) {
            axios
                .delete(`${__API_URL__}/api/ecrf/delete_new_ecrf/${id}/`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                })
                .then(() => {
                    toast.success("File Deleted Successfully");
                    setItems(items.filter(item => item.id !== id));
                }).catch(() => {
                    toast.error("Something Went Wrong");
                }).finally(() => {
                    setPopMessage(false);
                });
        }
    };

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage);
    };
    useEffect(() => {
        setLoader(true)
        axios
            .get(`${__API_URL__}/api/ecrf/get_new_ecrf/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                setItems(res.data.data);
                setLoader(false)
            })
            .catch((err) => {
                toast.error("Something Wrong!");
            });
    }, []);
    const filteredItems = items.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const startIndex = (currentPage - 1) * pageItemCount;
    const endIndex = startIndex + pageItemCount;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);

    return (
        <div>
            <NavBar active="form_creater" />
            <ToastContainer />
            <div className="container-fluid">
                <div className="row patient_table patientList">
                    <div className="col-md-12">
                        <div className="tableHeader px-0">
                            <div className="d-flex">
                                <h5>Source Document (eCRF) Blocks</h5>
                                <div style={{ display: "flex", marginTop: "8px", marginLeft: "auto" }}>
                                    <div className="me-2">
                                        {
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => navigate("/form-creater-v2/")}
                                                >
                                                    Create eCRF Block
                                                </button>
                                            </>
                                        }
                                    </div>
                                    <select
                                        className="form-control mb-0 ml-2"
                                        value={pageItemCount}
                                        onChange={(e) => setPageItemCount(parseInt(e.target.value))}
                                        style={{ width: "180px", height: "32px" }}
                                    >
                                        {[10, 25, 50, 100].map((item) => (
                                            <option key={item} value={item}>
                                                Show {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div>
                            <table style={{ height: "60vh" }}>
                                {
                                    loader ? (
                                        <div className="loader">
                                            <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                                        </div>
                                    ) : (
                                        <>
                                            <thead>
                                                <tr>
                                                    <th>Internal Block ID</th>
                                                    <th style={{ "padding-top": "24px" }}>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search by Block Name"
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />
                                                    </th>
                                                    <th>Creator</th>
                                                    <th>Created At</th>
                                                    <th>Delete</th>
                                                    <th>Edit Block</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedItems.length > 0 ? (
                                                    paginatedItems.map(item => (
                                                        <tr key={item.id}>
                                                            <td>{item.id}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.user_email}</td>
                                                            <td>{item.created_at}</td>
                                                            <td>
                                                                <a type='button' onClick={() => DeleteDocument(item?.id)}>
                                                                    <i className="fa fa-trash mx-3"></i>
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a
                                                                    type="button"
                                                                    onClick={() => navigate(`/form-creater-v2/?surveyId=${item.id}`)}
                                                                >
                                                                    <EditTwoToneIcon />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6" style={{textAlign: 'center'}}>There is No Source Document (eCRF) Blocks.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </>
                                    )
                                }
                            </table>
                            {popMessage && <EcrfPopUpMessage setPopMessage={setPopMessage} confirmDelete={confirmDelete} id={deleteItemId} />}
                            <div className="reactPagination col-md-7">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next"
                                    onPageChange={(selected) => handlePageChange(selected.selected + 1)}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    pageCount={Math.ceil(filteredItems.length / pageItemCount)}
                                    previousLabel="previous"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SurveyListWidget;