import React from 'react'
import TrialDrugKitAssignmentId from '../Trials/TrialDrugKitAssignmentId'
import MainAccordion from '../Accordion/accordion'

const DrugAssignmentAccordion = ({trialDetails, hasChanged, setHasChanged, setTrialDetails, handleBasicInfoUpdate, setDrugChanged, DrugChanged}) => {

  return (
    <MainAccordion
    title="Drug Assignment for Participant"
    data={
        <TrialDrugKitAssignmentId 
        trialDetails={trialDetails}
        setTrialDetails={setTrialDetails}
        setHasChanged={setHasChanged}
        hasChanged={hasChanged}
        handleBasicInfoUpdate={handleBasicInfoUpdate}
        setDrugChanged={setDrugChanged}
        DrugChanged={DrugChanged}
        />}
    />
  )
}
export default DrugAssignmentAccordion