import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Table from 'react-bootstrap/Table';

const Child_Bearing_Potential = (props) => {
  const [selectedBlock, setSelectedBlock] = useState({})
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const user = useUser();
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  let disable = false

 const METHOD_CHOICES = [
    { value: 'N/A, subject is male', label :'N/A, subject is male'},
    { value: 'Abstinence', label :'Abstinence'},
    { value: 'Oral Contraception', label :'Oral Contraception'},
    { value: 'Contraceptive Patch', label :'Contraceptive Patch'},
    { value: 'Condom and Spermicide', label :'Condom and Spermicide'},
    { value: 'IUD', label :'IUD'},
    { value: 'Vaginal Contraceptive', label :'Vaginal Contraceptive'},
    { value: 'Contraceptive Implant or Injection', label :'Contraceptive Implant or Injection'},
    { value: 'Surgical Sterilization', label :'Surgical Sterilization'},
    { value: 'One or More Years Postmenopausal', label :'One or More Years Postmenopausal'},
    { value: 'Same Sex Partner, or Partner who has had Vasectomy', label :'Same Sex Partner, or Partner who has had Vasectomy'},
    { value: 'Other', label :'Other'},
]
const TEST_CHOICES = [
{ value: 'Yes', label: 'Yes' },
{ value: 'No', label: 'No' },
{ value: 'N/A because subject is male', label: 'N/A because subject is male' },
]
const RESULT_CHOICES = [
    { value: 'Negative', label: 'Negative' },
    { value: 'Positive*', label: 'Positive*' },
    { value: 'Indeterminate*', label: 'Indeterminate*' },
]
const handleFieldChange = (e) => {
    const { name, value, checked, type } = e.target;
    setSelectedBlock((prevBlock) => ({
      ...prevBlock,
      [name]: type === 'checkbox' ? (checked ? value : '') : (type === 'radio' ? (value === 'true') : value),
    }));
    props.setHasChanged(true)
    };
    const handleDate = (e, fieldName) => {
        setSelectedBlock({
          ...selectedBlock,
          [fieldName]: e.target.value,
        });
        props.setHasChanged(true);
      };
      


  const All_Child_Bearing = () => {
    const createData = {
      'patient_id': props?.patient_id,
      'visit_number': props?.visit_number,
      'sessionId': props.selectedSessionId,
      ...selectedBlock
    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_childbearing/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      });
  };
  const get_Child_Bearing_Data = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_childbearing/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }
  React.useEffect(() => {
    if((props?.patient_id && props?.photo_session)){
        get_Child_Bearing_Data();
    }
  }, [])
  if (role === 'CRO Admin' && !selectedBlock?.primary_method) {
    setSelectedBlock(prevSelectedBlock => ({
      ...prevSelectedBlock,
      primary_method: "Other",
    }));
  }
return <>
{
  (role === 'CRO Admin') || (role === 'Cro_coordinator') || (role === 'Data Manager') || (props.patient_id === undefined) ?
    disable = true : ""
}
<fieldset disabled={disable}>
  {
    (props.photo_session || props.patient_id === undefined) ?
      <div className='row'>
        {
          (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
            <div className="col-md-12 text-end">
              <button onClick={() => All_Child_Bearing()} className='btn btn-primary'>Update</button>
            </div>
          )
        }
        <div className="col-md-12">
        <div className='col-md-10'>
        <div className="row">
            <div className="col-md-6">
                <label>Primary method of birth control:</label>
            {METHOD_CHOICES?.slice(0, METHOD_CHOICES.length / 2).map(event => (
                <div className='col-md-10 p-0' key={event?.value}>
                <input
                    className="form-check-input mx-1"
                    style={{ marginLeft: "1em" }}
                    type="checkbox"
                    name='primary_method'
                    checked={selectedBlock?.primary_method === event?.value}
                    value={event?.value}
                    onChange={(e) => handleFieldChange(e)}
                />
                <label>{event?.label}</label>
                </div>
            ))}
            </div>
            <div className="col-md-6">
            {METHOD_CHOICES?.slice(METHOD_CHOICES.length / 2).map(event => (
                <div className='col-md-10 p-0' key={event?.value}>
                <input
                    className="form-check-input mx-1"
                    style={{ marginLeft: "1em" }}
                    type="checkbox"
                    name='primary_method'
                    checked={selectedBlock?.primary_method === event?.value}
                    value={event?.value}
                    onChange={(e) => handleFieldChange(e)}
                />
                <label>{event?.label}</label>
                </div>
            ))}
             {selectedBlock?.primary_method === "Other" ?
            <div className='col-md-12'>
              <label> Specify:  </label>
              <textarea
                style={{ width: '250px', height: '50px' }}
                className="form-control"
                type="text"
                name="other_method"
                id="flexSwitchCheckDefault"
                value={selectedBlock?.other_method}
                onChange={(e) => handleFieldChange(e)}
              ></textarea>
            </div>
            : ''}
            </div>
        </div>
        </div>
        <div className='col-md-10'>
            <div className="row">
            <div className="col-md-6">
            <label><p>Pregnancy Test Performed: </p></label>
            {TEST_CHOICES?.map(event => (
              <div className='col-md-10 p-0' key={event?.value}>
                <input
                  className="form-check-input mx-1"
                  style={{ marginLeft: "1em" }}
                  type="checkbox"
                  name='test_performed'
                  checked={selectedBlock?.test_performed === event?.value}
                  value={event?.value}
                  onChange={(e) => handleFieldChange(e)}
                />
                <label>{event?.label}</label>
              </div>
            ))}
          </div>
            <div className="col-md-6 calendarIcon">
            <div className='col-md-4'>
              <label> Date of Test:  </label>
                <input
                    onChange={(e) => handleDate(e, 'test_date')}
                    class='form-control'
                    type="date"
                    value={selectedBlock?.test_date}
                    inputProps={{ placeholder: 'mm/dd/yy 00:00 AM', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || (role === 'Data Manager')) ? true : false || (props?.patient_id === undefined) }}
                />
            </div>
            <div className="col-md-6">
                  <label><p>Check if same as visit date:</p></label>
                  <input
                    style={{ marginLeft: "1em" }}
                    type="checkbox"
                    class="form-check-input"
                    name="is_same_visit_date"
                    id="is_same_visit_date"
                    checked={selectedBlock?.is_same_visit_date}
                    onChange={(e) => handleFieldChange(e)}
                  >
                  </input>
                </div>
            <div className='col-md-12'>
              <label> Lot#  </label>
              <input
                style={{ width: '250px', height: '50px' }}
                className="form-control"
                type="text"
                name="lot_number"
                id="flexSwitchCheckDefault"
                value={selectedBlock?.lot_number}
                onChange={(e) => handleFieldChange(e)}
              ></input>
            </div>
            <div className="col-md-6 calendarIcon">
            <div className='col-md-8'>
              <label>Expiration date:</label>
                <input
                    onChange={(e) => handleDate(e, 'expiration_date')}
                    class='form-control'
                    type="date"
                    value={selectedBlock?.expiration_date}
                    inputProps={{ placeholder: 'mm/dd/yy 00:00 AM', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || (role === 'Data Manager')) ? true : false || (props?.patient_id === undefined) }}
                />
            </div>
            </div>
            <div className='col-md-10'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}>Test Results:</div>
                    <div className='col-md-10 p-0' style={{ display: 'flex' }}>
                        {RESULT_CHOICES?.map(event => (
                            <div key={event?.value} style={{ marginRight: '1em' }}>
                                <input
                                    className="form-check-input mx-1"
                                    type="checkbox"
                                    name='test_result'
                                    checked={selectedBlock?.test_result === event?.value}
                                    value={event?.value}
                                    onChange={(e) => handleFieldChange(e)}
                                />
                                <label>{event?.label}</label>
                            </div>
                        ))}
                </div>
            </div>
            <br />
            <label>* If positive or indeterminate, Do NOT enroll subject. Do not
                    proceed with any further study related assessments. </label>
        </div>
        </div>
        </div>
        </div>
        </div>
      </div>
      : 'Visit is not started'
  }
</fieldset>
</>
}
export default Child_Bearing_Potential;
