import React, { useEffect, useState } from 'react';
import * as SurveyCreator from 'survey-creator';
import 'survey-creator/survey-creator.css';
import NavBar from '../../components/navBar/navBar';
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import { toast, ToastContainer } from "react-toastify";
import SelectTrialType from './SelectTrialType';
import FieldModal from './FieldModal';
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom'
import { SurveyPDF } from "survey-pdf";

const FormCreater = () => {

  const [surveyCreator, setSurveyCreator] = useState(null);
  const [currentPage, setCurrentPage] = useState({});
  const [allModels, setAllModels] = useState([]);
  const [surveyData, setSurveyData] = useState(null);
  const [hasTrialTypeChanged, setHasTrialTypeChanged] = useState(false);
  const [trialType, setTrialType] = useState(false);
  const [fieldModal, setFieldModal] = useState(false);

  const [allTrialType, setAllTrialType] = useState([]);
  const [Loader, setLoader] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (currentPage?.id) {
  //     setSurveyData((prevSurveyData) => ({
  //       ...prevSurveyData,
  //       [currentPage.id]: currentPage,
  //     }));
  //   }
  // }, [currentPage]);

  const initSurveyCreator = async (dataSet) => {
    try {
      const creatorOptions = {
        showEmbededSurveyTab: false,
        haveCommercialLicense: true,
        showTestSurveyTab: false,
        showJSONEditorTab: false,
        questionTypes: ['text', 'checkbox', 'radiogroup', 'dropdown', 'datetime', 'persistentcheckbox'],
      };

      const creator = new SurveyCreator.SurveyCreator(null, creatorOptions);
      creator.saveSurveyFunc = async () => {
        const surveyObject = creator.JSON;
        await sendSurveyDataToBackend(surveyObject, creator, dataSet);
      };

      creator.toolbarItems.push({
        id: 'pdf',
        title: 'Field View',
        action: () => {
          setFieldModal(true);
        },
      });

      setSurveyCreator(creator);
      creator.onSelectedElementChanged.add((sender, options) => {
        const selectedElement = options.newSelectedElement;

        if (selectedElement && selectedElement.isPage) {
          const currentPageIndex = creator.survey.pages.indexOf(selectedElement);
          const currentpageData = dataSet?.pages[currentPageIndex] || null;
          setCurrentPage(currentpageData)
        } else if (currentPage) {
          setCurrentPage(currentPage)
        }
        else {
          setCurrentPage(dataSet?.pages[0])
        }
      });

      if (creator.onPageAdded && creator.onPageAdded.add) {
        creator.onPageAdded.add((sender, options) => {
          options.page.title = 'Block name';
          options.page.name = 'Block name';
          options.page.description = 'Order number';

          const page = options.page;
          const allElements = page.elements;

        });
      } else {
        console.log('onPageAdded or add method not available.');
      }
    } catch (error) {
      console.error('Error initializing survey creator:', error);
    }
  };

  useEffect(() => {
    const initSurvey = async () => {
      await initSurveyCreator();
    };
    initSurvey();
    handleTestTabClick()
    GetTrialTypes()
  }, []);

  useEffect(() => {
    if (surveyCreator) {
      surveyCreator.render('surveyCreatorContainer');
      surveyCreator.JSON = surveyData;
    }
  }, [surveyCreator]);

  const sendSurveyDataToBackend = async (surveyObject, creator, dataSet) => {

    const selectedPage = creator?.survey?.currentPage;
    const pageIndex = creator?.survey?.pages.indexOf(selectedPage);
    const firstPage = surveyObject?.pages[pageIndex] || null;
    const stateData = dataSet?.pages[pageIndex] || null;

    const blockNames = surveyObject.pages.map((page) => page.title.trim());
    const uniqueBlockNames = new Set(blockNames);

    if (blockNames.length !== uniqueBlockNames.size) {
      toast.error('Source Document with the given name in the system, try other name.');
      return;
    }

    if (!firstPage?.title || !firstPage?.title.trim() || firstPage?.title === 'Block name') {
      toast.error('Block Name is missing');
      return;
    }

    if (!firstPage?.description || !firstPage?.description.trim() || firstPage?.description === 'Order number') {
      toast.error('Block Order is missing');
      return;
    }

    const missingElementTitles =
      firstPage?.elements && firstPage?.elements.some((element) => !element.title || !element.title.trim());

    if (missingElementTitles) {
      toast.error('Question title is missing');
      return;
    }

    const missingChoiceTexts =
      firstPage.elements &&
      firstPage.elements
        .filter((element) => element.choices)
        .some((element) => element.choices.some((choice) => !choice.text || !choice.text.trim()));

    if (missingChoiceTexts) {
      toast.error('Choice text is missing');
      return;
    }

    if (firstPage) {
      const updatedStateData = { ...firstPage };

      if (stateData?.id) {
        updatedStateData.id = stateData.id;
      }

      if (updatedStateData?.elements && stateData?.elements) {
        updatedStateData.elements.forEach((element, index) => {
          const stateElement = stateData.elements[index];

          if (stateElement?.id) {
            updatedStateData.elements[index].id = stateElement.id;
          }

          if (element.choices && stateElement?.choices) {
            element.choices.forEach((choice, choiceIndex) => {
              const stateChoice = stateElement.choices[choiceIndex];

              if (stateChoice?.id) {
                updatedStateData.elements[index].choices[choiceIndex].id = stateChoice.id;
              }
            });
          }
        });
      }

      axios
        .post(`${__API_URL__}/api/ecrf/create_form/`, updatedStateData, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setCurrentPage(res.data);
          setTrialType(true);
        })
        .catch((err) => {
          toast.error('Something Wrong!');
        });
    }
  };

  const handleTestTabClick = () => {
    setLoader(true)
    axios
      .get(`${__API_URL__}/api/ecrf/get_form_models/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAllModels(res.data);
        setSurveyData(res.data)
        setLoader(false)
        initSurveyCreator(res.data)

      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
        }
      });

  };

  const GetTrialTypes = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/form_creator_trialtypes/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAllTrialType(res.data)
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
        }
      });

  };
  const savePdf = function () {
    if (surveyData && surveyData.pages) {
      surveyData.pages.sort((a, b) => {
        const orderA = parseInt(a.description, 10);
        const orderB = parseInt(b.description, 10);
        return orderA - orderB;
      });

      const pdfDocOptions = {
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        haveCommercialLicense: true,
      };

      const surveyPdf = new SurveyPDF({ pages: surveyData.pages }, pdfDocOptions);
      const customFilename = 'Source Document.pdf';
      surveyPdf.save(customFilename);
    } else {
      console.error('Survey data or pages are missing.');
    }
  };
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)

  const routeBackTo = () => {
    navigate(`/clinic_trials/${queryParameters.get('trial_id')}`)
  }
  return (
    <div>
      <NavBar active='form_creater' />
      <ToastContainer />
      <div className="col-md-12 text-end my-3">
        {
          (queryParameters.get('trial_id') !== undefined && queryParameters.get('trial_id') !== null && queryParameters.get('trial_id') !== 'undefined') &&
          <button
            onClick={() => routeBackTo()}
            className='btn btn-primary'
          >
            Finished creating eCRF blocks
          </button>
        }
        <button
          onClick={() => savePdf()}
          className='btn btn-primary btn-sm mx-5'
        >
          Export to PDF
        </button>
      </div>
      {
        Loader ? (
          <div className="loader">
            <img src="https://i.gifer.com/Yb3A.gif" alt="" />
          </div>
        ) : (
          <div id='surveyCreatorContainer' />
        )}

      {trialType && (
        <SelectTrialType
          trialType={trialType}
          setTrialType={setTrialType}
          allTrialType={allTrialType}
          surveyData={surveyData}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setHasTrialTypeChanged={setHasTrialTypeChanged}
          toast={toast}
        // setSurveyCreator={setSurveyCreator}
        />
      )}
      {fieldModal && (
        <FieldModal
          fieldModal={fieldModal}
          setFieldModal={setFieldModal}
          toast={toast}
        />
      )}
    </div>
  );
};

export default FormCreater;
