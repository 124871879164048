import {useState, React, useEffect} from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import Select from 'react-select';


const FieldModal = ({setFieldModal, fieldModal, toast}) => {
  const token = localStorage.getItem('token');
  const [baseBlocks, setBaseBlocks] = useState([]);
  const [targetBlocks, setTargetBlocks] = useState([]);
  const [choices, setChoices] = useState([]);
  const [fieldDisable, setFieldDisable] = useState(true);
  const [selectedBlocks, setSelectedBlocks] = useState({
    block1: {},
    block2: {},
    field1: {},
    field2: [],
    value:'',
  });

  const [fieldData, setFieldData] = useState({
    block1_fields:[],
    block2_fields:[],
  });


  const GetBlocks = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/blocks_dropdown/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setBaseBlocks(res.data)
        setTargetBlocks(res.data)
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
        }
      });
  
  };

  const GetFieldsDropDown = () => {
    const block1Id = selectedBlocks?.block1?.value ?? '';
    const block2Id = selectedBlocks?.block2?.value ?? '';
    
    axios
      .get(`${__API_URL__}/api/ecrf/fields_dropdown/?block1=${block1Id}&block2=${block2Id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setFieldData({
            block1_fields: res.data.block1_fields,
            block2_fields: res.data.block2_fields,
          });
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
        }
      });
  
  };

  const GetChoiceDropDown = () => {
    const field1Id = selectedBlocks?.field1?.value ?? '';
    
    axios
      .get(`${__API_URL__}/api/ecrf/choice_dropdown/?field1=${field1Id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setChoices(res.data)
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
        }
      });
  
  };

  useEffect(()=>{
    GetBlocks()
  }, [])

  useEffect(() => {
    if (selectedBlocks.block1 !== undefined || selectedBlocks.block2 !== undefined) {
        if (
            (selectedBlocks.block1 && Object.keys(selectedBlocks.block1).length > 0) ||
            (selectedBlocks.block2 && Object.keys(selectedBlocks.block2).length > 0)
        ) {
            GetFieldsDropDown();
            setFieldDisable(false);
        }
    }
}, [selectedBlocks]);

useEffect(()=>{
    if (selectedBlocks?.field1){
        GetChoiceDropDown()
    }
  }, [selectedBlocks?.field1])

  const UpdateConditionalFields = (e) => {
    e.preventDefault();
  
    if (!selectedBlocks.block1) {
      toast.error('Source Block is required.');
      return;
    }
  
    if (!selectedBlocks.block2) {
      toast.error('Targeted Block is required.');
      return;
    }
  
    if (!selectedBlocks.field1) {
      toast.error('Source Block Field is required.');
      return;
    }
  
    if (!selectedBlocks.field2.length) {
      toast.error('Targeted Block Fields is required.');
      return;
    }
  
    if (!selectedBlocks.value) {
      toast.error('Value is required.');
      return;
    }
  
    axios
      .post(`${__API_URL__}/api/ecrf/create_conditional_field/`, selectedBlocks, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        toast.success('Fields Updated Successfully!');
        setFieldModal(false);
      })
      .catch((err) => {
        toast.error('Something went wrong!');
      });
  };

  const onBlock1Change = (newValue, actionMeta) => {
        setSelectedBlocks((prev) => {
            return {
                ...prev,
                block1: newValue
            };
        });
};

  const onBlock2Change = (newValue, actionMeta) => {
        setSelectedBlocks((prev) => {
            return {
                ...prev,
                block2: newValue
            };
        });
};

  const onField1Change = (newValue, actionMeta) => {
        setSelectedBlocks((prev) => {
            return {
                ...prev,
                field1: newValue
            };
        });
};

const handleFieldChange = (e) => {
    const {name, value} = e.target
    setSelectedBlocks({
      ...selectedBlocks,
      [name]: value,
    });
}

const onField2Change = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
        if (actionMeta.removedValue.isFixed) {
            return newValue;
        } else {
            setSelectedBlocks((prev) => ({
                ...prev,
                field2: (prev.field2 || []).filter((v) => v.value !== actionMeta.removedValue.value),
            }));
        }
        break;
        case 'pop-value':
            break;
            case 'clear':
              newValue = Array.isArray(selectedBlocks?.block2)
                  ? selectedBlocks.block2.filter((v) => v.isFixed)
                  : [];
              setSelectedBlocks((prev) => ({
                  ...prev,
                  field2: newValue
              }));
              break;
        case 'select-option':
            setSelectedBlocks((prev) => {
                return {
                    ...prev,
                    field2: newValue
                };
            });
            break;
    }
};


  return (
<div>
  <Modal size="lg" show onHide={() => { setFieldModal(false) }} centered backdrop="static" keyboard={false}>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Field View
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="show-grid">
      <form
        onSubmit={(e) => {
            UpdateConditionalFields(e);
        }}
      >
        <div className="row">
          <div className="col-md-6 mb-5">
            <label>Source Block</label>
            <Select
              value={selectedBlocks?.block1}
              className="basic-multi-select"
              options={baseBlocks}
              onChange={onBlock1Change}
              required={true}
            />
          </div>
          <div className="col-md-6 mb-5">
            <label>Targeted Block</label>
            <Select
              value={selectedBlocks?.block2}
              className="basic-multi-select"
              options={targetBlocks}
              onChange={onBlock2Change}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <label>Source Block Field</label>
            <Select
              value={selectedBlocks?.field1}
              className="basic-multi-select"
              options={fieldData?.block1_fields}
              isDisabled={fieldDisable}
              onChange={onField1Change}
            />
          </div>
          <div className="col-md-6 mb-4">
            <label>Targeted Block Fields</label>
            <Select
        
              value={selectedBlocks?.field2}
              isMulti
              className="basic-multi-select"
              options={fieldData?.block2_fields}
              isDisabled={fieldDisable}
              onChange={onField2Change}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <label>Source Block Selected Field</label>
            <input
              value={selectedBlocks?.field1?.label}
              className="basic-multi-select"
              disabled={true}
            />
          </div>
          <div className="col-md-6 mb-4">
            <label>Source Block Selected Value</label>
            <select
             name='value'
              className="basic-multi-select"
              onChange={(e)=>{handleFieldChange(e)}}
            >
            <option value="">select</option>
            {choices.map((choice, index) => (
            <option key={index} value={choice.label}>
                {choice.label}
            </option>
            ))}
            
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button className='btn btn-primary float-end' type='submit'>Save</button>
          </div>
        </div>
      </form>
    </Modal.Body>
  </Modal>
</div>

  )
}

export default FieldModal
