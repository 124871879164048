import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from "react-toastify";
import Select from 'react-select';
import TrialCrc from "../PopUp/TrialCrc";

const ManualInvestigatorSIteId = ({ trialDetails, setTrialDetails, setChangeSetting, setInfoSettingChanged, investigatorChanged, setHasChanged, setInvestigatorChanged, siteIdManual, setChangeTrialSetting, allInvestigators, onInvestigatorChange, handleBasicInfoUpdate, AllInvestigatorsData, setAllInvestigators, isDrug}) => {
  const investigators = trialDetails?.investigators
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const [loader, setLoader] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [showModal, setShowModal] = useState('');
  const [errorMsg, seterrorMsg] = useState('');
  const [drugErrorMsg, setDrugerrorMsg] = useState('');
  const [focusedInputIndex, setFocusedInputIndex] = useState(null);
  const [drugfocusedInput, setDrugfocusedInput] = useState(null);
  const [maxPatientFocusedInput, setMaxPatientFocusedInput] = useState(null);

  const settingToUpdate = trialDetails?.site_id_setting;

  const handleSiteIdChange = (e, index) => {
    const newSiteNumber = e.target.value;
    setFocusedInputIndex(index);

    setTrialDetails((prevState) => {
      const updatedInvestigators = [...prevState?.investigators];

      const isSiteNumberExists = updatedInvestigators.some(
        (investigator, i) => i !== index && investigator.site_number === newSiteNumber
      );
  
      if (isSiteNumberExists) {
        seterrorMsg('This Site already exists!') 
        setInvestigatorChanged(false)
      }else{
        seterrorMsg('')
        setInvestigatorChanged(true)
      }


      updatedInvestigators[index] = {
        ...updatedInvestigators[index],
        site_number: newSiteNumber,
      };
      return { ...prevState, investigators: updatedInvestigators };
    });

  };

  const handleMinDrugChange = (e, index) => {
    const minDrugValue = e.target.value;
    setDrugfocusedInput(index);

    setTrialDetails((prevState) => {
      const updatedInvestigators = [...prevState?.investigators];
      setInvestigatorChanged(true)
      setDrugerrorMsg('');
      updatedInvestigators[index] = {
        ...updatedInvestigators[index],
        minimum_drug: minDrugValue,
      };
      return { ...prevState, investigators: updatedInvestigators };
    });

  };

  const handleSettingTypeChange = (event) => {
    const { name, value } = event.target;
    setTrialDetails((prevDetails) => ({
      ...prevDetails,
      site_id_setting: {
        ...prevDetails.site_id_setting,
        [name]: value,
        'id_change':true,
      },
    }));
    setInfoSettingChanged(true);
    setInvestigatorChanged(true)
  };

  const handleAddSuccess = () => {
    AllInvestigatorsData(); 
  };

  const handleBooleanFields = (e) => {
    setTrialDetails({
      ...trialDetails,
      manual_investigator_id: !trialDetails.manual_investigator_id,
      site_id_setting: {
        ...trialDetails.site_id_setting,
        'id_change': true,
      },
    });
    setInvestigatorChanged(true)
  };

  useEffect(() => {
    setChangeTrialSetting(trialDetails?.site_id_setting);
  }, [trialDetails?.site_id_setting])


  useEffect(() => {
    if (trialDetails?.manual_investigator_id === true) {
      setIsDisable(false);
      setTrialDetails((prevTrialDetails) => ({
        ...prevTrialDetails,
        site_id_setting: {
          ...prevTrialDetails.site_id_setting,
          setting_type: "Manual",
        },
      }));
    } else {
      setIsDisable(true);
      setTrialDetails((prevTrialDetails) => ({
        ...prevTrialDetails,
        site_id_setting: {
          ...prevTrialDetails.site_id_setting,
          setting_type: "Sequence",
        },
      }));
    }
  }, [trialDetails?.manual_investigator_id]);

  const handleSave = () => {
    const isEmpty = trialDetails?.investigators.some((inv) => !inv?.site_number && !isDisable);
    const drugEmptyField = trialDetails?.investigators.some((inv) => !inv?.minimum_drug);
    const maxPatientField = trialDetails?.investigators.some((inv) => !inv?.max_patient);
    if (isEmpty) { 
      const firstEmptyIndex = trialDetails?.investigators.findIndex((inv, index) => {
        const isEmpty = !inv?.site_number && !isDisable;
        return isEmpty;
      });
      setFocusedInputIndex(firstEmptyIndex);
      seterrorMsg('Please fill this field');
    } else if (drugEmptyField && isDrug){
      const firstEmptyIndex = trialDetails?.investigators.findIndex((inv, index) => {
        const isEmpty = !inv?.minimum_drug;
        return isEmpty;
      });
      setDrugfocusedInput(firstEmptyIndex);
      setDrugerrorMsg('Please fill this field');
    } else if (maxPatientField) {
      const firstEmptyIndex = trialDetails?.investigators.findIndex((inv, index) => {
        const isEmpty = !inv?.max_patient;
        return isEmpty;
      });
      setMaxPatientFocusedInput(firstEmptyIndex);
      toast.error('Please fill the Max Patients field');
    } else {
      handleBasicInfoUpdate();
    }
  };

  const handleMaxPatientChange = (e, index) => {
    const newMaxPatientValue = e.target.value;
    setMaxPatientFocusedInput(index)

    setTrialDetails((prevState) => {
      const updatedInvestigators = [...prevState?.investigators];
  
      updatedInvestigators[index] = {
        ...updatedInvestigators[index],
        max_patient: newMaxPatientValue,
      };
  
      return { ...prevState, investigators: updatedInvestigators };
    });

    setInvestigatorChanged(true)
  };
  

  return (
    <>
      <ToastContainer />
      <div class="row mx-5">
      {trialDetails?.is_setting &&
      <>
        <div class="col-md-12">
        <div className="tableHeader">
              <div className="row d-flex align-items-end">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-end d-flex justify-content-end">

                  <>
                   {
                  (role !== "Investigator" && role !== "Inv_coordinator" && role !== 'Reviewer') && <>
                <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={() => handleSave()}
                  disabled={!investigatorChanged}
                >
                  Save
                </button>
                   </>
                  }
                </>

                </div>
              </div>
            </div>
        </div>

        <div class="col-md-6 my-3">
                    <label for="manual_investigator_id" class="form-label">
                      Automatic
                    </label>
                    <input
                      type="radio"
                      name="manual_investigator_id"
                      style={{ textAlign: "right", width: "25%" }}
                      id="manual_investigator_id"
                      onChange={(e)=>{handleBooleanFields(e)}}
                      checked={trialDetails?.manual_investigator_id === false}
                      value={false}
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="manual_investigator_id" class="form-label">
                      Manual
                    </label>
                    <input
                      type="radio"
                      name="manual_investigator_id"
                      style={{ textAlign: "right", width: "20%" }}
                      id="manual_investigator_id"
                      onChange={(e)=>{handleBooleanFields(e)}}
                      checked={trialDetails?.manual_investigator_id === true}
                      value={true}
                    />
                  </div>

            <div class="col-md-6">
                    <div class="mb-3">
                      <label for="lower_value" class="form-label">Lower Value</label>
                      <input
                        type="number"
                        class="form-control"
                        id="lower_value"
                        name='lower_range'
                        min="1"
                        value={settingToUpdate?.lower_range}
                        onChange={handleSettingTypeChange}
                        placeholder="Lower Value" />
                    </div>
                  </div>

                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="upper_value" class="form-label">Upper Value</label>
                    <input
                      type="number"
                      class="form-control"
                      id="upper_value"
                      name='upper_range'
                      min="1"
                      value={settingToUpdate?.upper_range}
                      onChange={handleSettingTypeChange}
                      placeholder="Upper Value"
                      required
                    />
                  </div>
                </div>
                </>
      }

        <div class="col-md-12">
          <div class="row">
            {
              (role !== "Investigator" && role !== "Inv_coordinator" && role !== 'Reviewer') &&
              (trialDetails.investigators && trialDetails.investigators.length > 0) && (
              <>
                <div className="col-md-6 mb-5">
                  <label>Investigators</label>
                  <Select
                    value={trialDetails.investigators}
                    isMulti
                    className="basic-multi-select"
                    options={allInvestigators} 
                    onChange={onInvestigatorChange}
                  />
                </div>
              </>
              )
            }           
        </div>

      {trialDetails?.is_setting &&
            <>
              {investigators?.map((inv, index) => {
                return (
                  <div class="row">

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="upper_value" class="form-label">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          id="upper_value"
                          name='upper_range'
                          placeholder="Upper Value"
                          value={inv?.label}
                          disabled={true}
                          required
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="site_id" class="form-label">Site Id</label>
                        <input
                          type="number"
                          class="form-control"
                          id="site_id"
                          name='site_id'
                          min='1'
                          value={inv?.site_number}
                          onChange={(e) => handleSiteIdChange(e, index)}
                          disabled={isDisable}
                          required
                        />
                        <span style={{ fontSize: '12px' }} className={`text-danger text-sm ${focusedInputIndex === index ? 'd-block' : 'd-none'}`}>
                              {errorMsg}
                          </span>
                      </div>
                    </div>

                  {isDrug &&
                    <div class="col-md-6 mb-3">
                      <div class="mb-3">
                        <label for="minimum_value" class="form-label">Minimum drug</label>
                        <input
                          type="number"
                          class="form-control"
                          id="minimum_value"
                          name='minimum_value'
                          min='1'
                          value={inv?.minimum_drug}
                          onChange={(e) => handleMinDrugChange(e, index)}
                          required
                        />
                        <span style={{ fontSize: '12px' }} className={`text-danger text-sm ${drugfocusedInput === index ? 'd-block' : 'd-none'}`}>
                              {drugErrorMsg}
                          </span>
                      </div>
                    </div>
              }
                    <div class="col-md-6 mb-3">
                      <div class="mb-3">
                        <label for="max_patient" class="form-label">Max Patients</label>
                        <input
                          type="number"
                          class="form-control"
                          id="max_patient"
                          name="max_patient"
                          min="1"
                          value={inv?.max_patient}
                          onChange={(e) => handleMaxPatientChange(e, index)}
                          required
                        />
                      </div>
                    </div>
              <hr />

                  </div>
                );
              })}
            </>
          }
          {showModal === true ?
                    <TrialCrc
                    setShowModal = {setShowModal}
                    toast = {toast}
                    type="investigator"
                    onAddSuccess={handleAddSuccess} 
                    setTrialDetails={setTrialDetails}
                    setAllInvestigators={setAllInvestigators}
                    allInvestigators={allInvestigators}
                    onInvestigatorChange={onInvestigatorChange}
                    trialDetails={trialDetails}
                    setInvestigatorChanged={setInvestigatorChanged}
                    />
                    :""}
                    <div className="col-sm mx-1 mb-3" >
                      <button type="button" className="btn btn-sm mt-3 btn-primary" onClick={()=> setShowModal(true)}>
                          Invite New Investigator
                      </button>
                  </div>
              </div>
          </div>
    </>
  )
}

export default ManualInvestigatorSIteId
