import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "./PatientMenu";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import Breadcrumbs from "../../components/breadcrumbs";
import MessageBox from "../../components/Alert/MessageBox";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from '../../hooks/user';
import Avatar from "../../assets/img/avatar.png";

import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const ExternalPhotoSessionView = () => {
  const { _id_ } = useParams();
  const user = useUser();
  const trial_name_filter = localStorage.getItem("trial_name_filter");
  const trial_status_filter = localStorage.getItem("trial_status_filter");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const token = localStorage.getItem("token");
  const role = user_data.role
  const [scoreUser, setScoreUser] = useState();
  const [infoData, setInfoData] = useState({})
  const [PatientData, setPatientData] = useState("");
  const [Loader, setLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filterData, setFilterData] = useState({
      photo_types: '',
      area: '',
      trial_area: '',
      trails: '',
  })

  const GetGalleryPhotos = () => {
    axios
      .get(`${__API_URL__}/api/patient/patient/photo_session_gallery/?PatientId=${_id_}&photo_type=${filterData?.photo_types}&photo_area=${filterData?.area}&selected_user=${filterData?.selected_user}&trial_area=${filterData?.trial_area}&trialCroFilter=${filterData?.trial_area}&trialName=${trial_name_filter}&trialStatus=${trial_status_filter}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPatientData(res.data);
      });
  };

  const [trailsList, setTrialsList] = useState([]);
  const GetScoredUser = () => {
      axios
      .get(`${__API_URL__}/api/ecrf/get_score_user/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setScoreUser(res.data)
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
      });
  }
  const SinglePatient = () => {
    axios
    .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
        headers: {
        Authorization: `Token ${token}`,
        },
        
    })
    .then((res) => {
        setInfoData(res.data)
    });
}

  useEffect(()=>{
    GetGalleryPhotos()
  },[filterData])

  useEffect(() => {
    getTrialsList()
    GetScoredUser()
    SinglePatient()
  }, [])
  const [toastrMessage, setToastrMessage] = useState('Evaluation Updated!')
  const [typeOfMessage, setTypeOfMessage] = useState('')

  
  const getTrialsList = () => {
    axios
      .get(`${__API_URL__}/api/photos/get_patient_trials/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTrialsList(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }

    const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const handleRemovePhoto = (indexToRemove) => {
    setUploadedFiles(uploadedFiles.filter((file, index) => index !== indexToRemove));
  };
  return (
    <>
    <MessageBox msg={toastrMessage} type={typeOfMessage}/>
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs 
        B="Photos - Gallery View" 
        F={infoData?.first_name}
        L={infoData?.last_name}
        E={infoData?.email}
        P={infoData?.phone}
        participant_id={infoData?.participant_id}
        age={infoData?.age}
        birth_date={infoData?.birth_date}
      />
        <div className="patient_table patient_photos">
          <PatientMenu active="external" data={infoData}/>
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <Link to={"/patient/" + _id_ + "/external_photo"}>
                  <button type="button" class="btn">
                    Session View
                  </button>
                </Link>
                <Link to={"/patient/" + _id_ + "/external"}>
                  <button type="button" class="btn btn-primary">
                    Gallery View
                  </button>
                </Link>
              </div>
            </div>
            <div className="row">
            <div className="container-fluid" style={{marginTop:"30px"}}>
                <label htmlFor="upload-photo">
                   <input
                     style={{ display: 'none' }}
                     id="upload-photo"
                     name="upload-photo"
                     type="file"
                     onChange={handleFileUpload}
                     multiple 
                   />
                   <Fab
                     color="secondary"
                     size="small"
                     component="span"
                     aria-label="add"
                     variant="extended"
                   >
                     <AddIcon /> Upload photo
                   </Fab>
                 </label>
                 <br />
                 <br />
                 


            </div>
            </div>
            </div>
            {Loader ? (
                <div className="loader">
                <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                </div>
            ) : (
                <>
                {PatientData?.data?.map((row, index) => (
                    <div className="row caseSection" key={row.id}>
                    {row.photo.length !== 0 && <>
                    <p>
                        <span style={{ "padding-right": "40px" }}>
                        {new Date(row.created_at + "Z").toDateString()},&nbsp;
                        {new Date(row.created_at + "Z").toLocaleTimeString()}&nbsp;&nbsp;
                        {row.is_trial && 
                        <>
                        Visit Number: {row.visit_number}
                        </>
                        }     
                        </span>
                    </p>
                        </>}
                    {row.photo.map((photo, i) => (
                        <div className="col-md-3" key={photo.id} style={{width:"70%"}}>
                        {/* {photo.image ?
                            <img
                            src={__API_URL__+ photo.image}
                            alt=""
                            /> :
                            <img
                            src={Avatar}
                            alt=""
                            />
                            } */}
                            <div className="row">
                            {uploadedFiles.map((file, index) => (
                                <div key={index} className="col-md-4">
                                    <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
                                    <div className="caseBox">
                                    <label>Captured At - {new Date().toLocaleDateString()}, {new Date().toLocaleTimeString()}</label>
                                    </div>
                                </div>
                                ))}
                            </div>
                        {/* <div className="caseBox">
                            <h6>{photo.photo_type.name}</h6>
                            <label>
                            Captured At -{" "}
                            {new Date(photo.captured_at + "Z").toDateString()}
                            ,&nbsp;
                            {new Date(
                                photo.captured_at + "Z"
                            ).toLocaleTimeString()}
                            </label>
                        </div> */}
                        </div>
                    ))}
                    </div>
                ))}
                </>
            )}
            </div>
      </div>
    </>
  );
};

export default ExternalPhotoSessionView;