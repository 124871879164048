import React, {useState, useEffect} from "react";
import { Formik, Field, Form } from "formik";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import { ApiManager } from "../../servicemanager/apimanager";
import { toast, ToastContainer } from "react-toastify";

const Exit_Form = (props) => {
    const [selectedBlock, setSelectedBlock] = useState({date_of_completion: '', status: ''})
    const token = localStorage.getItem("token");
    const TOKEN = localStorage.getItem("token");  
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role
    const [showField, setShowField] = useState(false);

    let disable = false
    const STATUS_CHOICES = [
        { value: 'Completed Study', label: 'Completed Study' },
        { value: 'Adverse Event', label: 'Adverse Event' },
        { value: 'Lost to Follow-Up', label: 'Lost to Follow-Up' },
        { value: 'Withdrew Consent', label: 'Withdrew Consent' },
        { value :'Protocol Violation', label: 'Protocol Violation'},
        { value :'Death', label: 'Death'},
        { value :'Study Terminated by Sponsor',  label: 'Study Terminated by Sponsor'},
        { value :'Investigator Decision', label: 'Investigator Decision'},
        { value :'Pregnancy', label: 'Pregnancy'},
        { value :'Other', label: 'Other'},
    ]

    const handleFieldChange = (e) => {
        const { name, value, checked, type } = e.target;
        setSelectedBlock((prevBlock) => ({
          ...prevBlock,
          [name]: type === 'checkbox' ? (checked ? value : '') : (type === 'radio' ? (value === 'true') : value),
        }));
        props.setHasChanged(true)
        };

    const ExitFormData = () => {
        if (selectedBlock.date_of_completion?.length > 0 &&  selectedBlock.status?.length > 0) {
            const ExitData = {
                'visit_number': props?.visit_number,
                'patient_id': props?.patient_id,
                'sessionId': props.selectedSessionId,
                ...selectedBlock,
              }
            axios
            .patch(`${__API_URL__}/api/ecrf/update_exit_form/${props.trialId}/`, ExitData, {
                headers: {
                  Authorization: `Token ${token}`,
                },
              })
            .then((res) => {
                setSelectedBlock(res.data);
                props.setModalVisitChangeShow(false)
                props.setHasChanged(false)
                toast.success("Block Updated Successfully");
            })
            .catch((err) => {
                toast.error("Something Went Wrong");
            });
        } else {
            if (!selectedBlock.date_of_completion) {
                toast.error("Please Select Date of Completion or Discontinuation!");
            }
            else if (!selectedBlock.status) {
                toast.error("Please Select Status at Exit from Study");
            }
        }
    };

    const getExitForm = async () => {
        try {
        const response = await ApiManager.get(`${__API_URL__}/api/ecrf/get_exit_form/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, TOKEN);
        setSelectedBlock(response.data)
        } catch (error) {
        console?.error(error);
        return []
        }
    };

    useEffect(() => {
        if((props?.patient_id && props?.photo_session)){
            getExitForm();
        }
        if (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') {
            setShowField(true)
        } 
      }, [])
    return (
    <>
    <ToastContainer />
    {
        (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props?.patient_id === undefined)) ?
            disable = true : ""
    }
    <fieldset disabled={disable}>
    {
        (props.photo_session || props.patient_id == undefined) ?
            <>
    <Formik>
      {({ isSubmitting }) => (
        <Form>
        {(props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
        <div className="col-md-12 text-end">
            <button 
                type="submit" 
                disabled={isSubmitting} 
                className='btn btn-primary'
                onClick={() => ExitFormData()}
                > Update
            </button>
        </div>
        )}
        <div className="row">
            <div className='col-md-4'>
                <label> Participant Initials: </label>
                <input
                    style={{ marginLeft: "0em" }}
                    className="form-control"
                    type="text"
                    name='participant_initials'
                    id="flexSwitchCheckDefault"
                    value={`${selectedBlock?.first_name || ''} ${selectedBlock?.last_name || ''}`}
                    disabled
                />
            </div>
            <div className='col-md-4'>
                <label> Participant #: </label>
                <input
                    style={{ marginLeft: "0em" }}
                    className="form-control"
                    type="text"
                    name='participant_number'
                    id="flexSwitchCheckDefault"
                    value={selectedBlock?.participant_number}
                    disabled
                />
            </div>
            <div className='col-md-4'>
                <label> Site #: </label>
                <input
                    style={{ marginLeft: "0em" }}
                    className="form-control"
                    type="text"
                    name='site_number'
                    id="flexSwitchCheckDefault"
                    value={selectedBlock?.site_number}
                    disabled
                />
            </div>
        </div>
        <div className="col-md-3">
        <div className="form-group">
            <label htmlFor="date_of_completion">Date of Completion or Discontinuation</label>
            <Field
              type="date"
              id="date_of_completion"
              name="date_of_completion"
              className="form-control"
              value={selectedBlock.date_of_completion}
              onChange={(e) => handleFieldChange(e)}
            />
          </div>
          </div>
        <label htmlFor="">Status at Exit from Study:</label>
        <div className="col-md-12 mx-5">
        {STATUS_CHOICES?.map(event => (
        <div key={event?.value} style={{ marginRight: '1em' }}>
            <label>
                <input
                    className="form-check-input mx-1"
                    type="checkbox"
                    name="status"
                    checked={selectedBlock?.status === event?.value}
                    value={event?.value}
                    onChange={(e) => handleFieldChange(e)}
                />
                {event?.label}
            </label>
            {(event?.label === 'Adverse Event' && (selectedBlock?.status === 'Adverse Event' || showField)) && (
                <div className="col-md-3 form-group mx-5">
                    <label htmlFor="specify_adverse_event">Specify associated AE #:</label>
                    <textarea
                        type="text"
                        id="specify_adverse_event"
                        name="specify_adverse_event"
                        className="form-control"
                        value={selectedBlock?.specify_adverse_event}
                        onChange={(e) => handleFieldChange(e)}
                    />
                </div>
            )}
            {(event?.label === 'Lost to Follow-Up' && (selectedBlock?.status === 'Lost to Follow-Up' || showField)) && (
                <>
                <div className="col-md-3 form-group mx-5">
                        <label htmlFor="specify_contact_participant">Specify:</label>
                        <textarea
                        type="text"
                        id="specify_contact_participant"
                        name="specify_contact_participant"
                        className="form-control"
                        value={selectedBlock?.specify_contact_participant}
                        onChange={(e) => handleFieldChange(e)}
                        />
                    </div>
                <div className="col-md-3 form-group mx-5">
                <label htmlFor="date_of_last_contact">Date of last contact:</label>
                    <Field
                        type="date"
                        id="date_of_last_contact"
                        name="date_of_last_contact"
                        className="form-control"
                        value={selectedBlock?.date_of_last_contact}
                        onChange={(e) => handleFieldChange(e)}
                    />
                </div>
                </>
            )}
            {(event?.label === 'Withdrew Consent' && (selectedBlock?.status === 'Withdrew Consent' || showField)) && (
                <div className="col-md-3 form-group mx-5">
                <label htmlFor="specify_withdrew_consent">Specify, if any reason given:</label>
                <textarea
                    type="text"
                    id="specify_withdrew_consent"
                    name="specify_withdrew_consent"
                    className="form-control"
                    value={selectedBlock?.specify_withdrew_consent}
                    onChange={(e) => handleFieldChange(e)}
                    />
            </div>
            )}
            {(event?.label === 'Protocol Violation' && (selectedBlock?.status === 'Protocol Violation' || showField)) && (
                <div className="col-md-3 form-group mx-5">
                <label htmlFor="specify_protocol_violation">Specify:</label>
                <textarea
                    type="text"
                    id="specify_protocol_violation"
                    name="specify_protocol_violation"
                    className="form-control"
                    value={selectedBlock?.specify_protocol_violation}
                    onChange={(e) => handleFieldChange(e)}
                    />
                </div>
            )}
            {(event?.label === 'Death' && (selectedBlock?.status === 'Death' || showField)) && (
                <div className="col-md-3 form-group mx-5">
                <label htmlFor="date_of_death">Date of death:</label>
                <Field
                    type="date"
                    id="date_of_death"
                    name="date_of_death"
                    className="form-control"
                    value={selectedBlock?.date_of_death}
                    onChange={(e) => handleFieldChange(e)}
                    />
            </div>
            )}
            {(event?.label === 'Investigator Decision' && (selectedBlock?.status === 'Investigator Decision' || showField)) && (
                <div className="col-md-3 form-group mx-5">
                <label htmlFor="specify_investigator_decision">Specify:</label>
                <textarea
                    type="text"
                    id="specify_investigator_decision"
                    name="specify_investigator_decision"
                    className="form-control"
                    value={selectedBlock?.specify_investigator_decision}
                    onChange={(e) => handleFieldChange(e)}
                />
                </div>
            )}
            {(event?.label === 'Other' && (selectedBlock?.status === 'Other' || showField)) && (
                <div className="col-md-3 form-group mx-5">
                    <label htmlFor="specify_other">Specify:</label>
                    <textarea
                    type="text"
                    id="specify_other"
                    name="specify_other"
                    className="form-control"
                    value={selectedBlock?.specify_other}
                    onChange={(e) => handleFieldChange(e)}
                    />
                </div>
            )}
            </div>
        ))}
        </div>
        </Form>
      )}
    </Formik>
    </> : 'Visit is not started'
            }
    </fieldset>
    </>
  );
};

export default Exit_Form;
