import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Button from 'react-bootstrap/Button';

const Inclusion_Criteria_Stem_Cell = (props) => {
  const [selectedBlock, setSelectedBlock] = useState({})
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const user = useUser();
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  let disable = false
  const DISEASE_CHOICES = [
    { value: 'Plaque psoriasis', label: 'Plaque psoriasis' },
    { value: 'Atopic dermatitis', label: 'Atopic dermatitis' },
    { value: 'Dermatitis induced by cancer therapy (chemotherapy or radiation)', label: 'Dermatitis induced by cancer therapy (chemotherapy or radiation)' },
    { value: 'Vitiligo', label: 'Vitiligo' },
    { value: 'Alopecia areata', label: 'Alopecia areata' },
    { value: 'Hidradenitis suppurativa', label: 'Hidradenitis suppurativa' },
    { value: 'Lichen planus', label: 'Lichen planus' },
    { value: 'Bullous pemphigoid', label: 'Bullous pemphigoid' },
    { value: 'Chronic spontaneous urticaria', label: 'Chronic spontaneous urticaria' },
    { value: 'Actinic keratosis', label: 'Actinic keratosis' },
    { value: 'Seborrheic keratosis', label: 'Seborrheic keratosis' },
  ]

  const handleFieldChange = (e) => {
    const { name, value, checked, type } = e.target;
    setSelectedBlock((prevBlock) => ({
      ...prevBlock,
      [name]: type === 'checkbox' ? (checked ? value : '') : (type === 'radio' ? (value === 'true') : value),
    }));
    props.setHasChanged(true)
  };
  const selectAll = () => {
    setSelectedBlock({
        is_eighteen_older: true,
        is_disease: true,
        is_irb_approved: true,
    });
    props.setHasChanged(true)
    };
  const removeAll = () => {
    setSelectedBlock({
        is_eighteen_older: false,
        is_disease: false,
        is_irb_approved: false,
    });
    props.setHasChanged(true)
    };
  const Inclusion_Criteria_Data = () => {
    const createData = {
      'patient_id': props?.patient_id,
      'visit_number': props?.visit_number,
      'sessionId': props.selectedSessionId,
      ...selectedBlock
    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_inclusion_stemcell/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        props.globalVisits(false);
        setSelectedBlock(res.data);
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      });
  };
  const get_Inclusion_Criteria_Data = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_inclusion_stemcell/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }
  React.useEffect(() => {
    if((props?.patient_id && props?.photo_session)){
      get_Inclusion_Criteria_Data();
    }
  }, [])
  if (role === 'CRO Admin' && !selectedBlock?.is_disease) {
    setSelectedBlock(prevSelectedBlock => ({
      ...prevSelectedBlock,
      is_disease: true,
    }));
  }
  return <>
    {
      (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props?.patient_id === undefined)) ?
        disable = true : ""
    }
    <fieldset disabled={disable}>
      {
        (props?.photo_session || props?.patient_id == undefined) ?
          <>
            {
              (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                <div className="col-md-12 text-end">
                  <button onClick={() => Inclusion_Criteria_Data()} className='btn btn-primary my-3'>Update</button>
                </div>
              )
            }

          <div className="row d-flex align-items-end">
                <div className="col-md-8">
                  <p>INCLUSION CRITERIA STEM CELL</p>
                </div>
                <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={selectAll}
                >
                  Select all Yes's
                </button>
                  &nbsp;
                </div>
                <div className="col-md-2">
                  <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={removeAll}
                  >Select all No's</button>
                        &nbsp;
                </div>
              </div>
     
            <div>
              <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                <div className="col-md-8 mt-2" style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>1. Male or female of any race, 18 years of age or older
                </div>
                <div className="col-md-4 mx-3">
                  <td>
                    <input
                      type="radio"
                      className="form-check-input  mx-2"
                      name="is_eighteen_older"
                      id="is_eighteen_older_yes"
                      checked={selectedBlock?.is_eighteen_older === true}
                      value={true}
                      onChange={handleFieldChange}
                      key="is_eighteen_older_yes"
                    />
                    <label>Yes</label>
                  </td>
                  <td>
                    <input
                      class="form-check-input  mx-2"
                      type="radio"
                      name="is_eighteen_older"
                      checked={selectedBlock?.is_eighteen_older === false}
                      id="is_eighteen_older_no"
                      value={false}
                      onChange={handleFieldChange}
                      key="is_eighteen_older_no"
                    />
                    <label>No</label>
                  </td>
                </div>
              </div>
              <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                <div className="col-md-8  mt-2" style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>
                  2. Has one of the following diseases:
                </div>
                <div className="col-md-4  mx-3">
                  <td>
                    <input
                      type="radio"
                      className="form-check-input  mx-2"
                      name="is_disease"
                      id="is_disease_yes"
                      checked={selectedBlock.is_disease === true}
                      value={true}
                      onChange={handleFieldChange}
                      key="is_disease_yes"
                    />
                    <label>Yes</label>
                  </td>
                  <td>
                    <input
                      class="form-check-input  mx-2"
                      type="radio"
                      name="is_disease"
                      checked={selectedBlock?.is_disease === false}
                      id="is_disease_no"
                      value={false}
                      onChange={handleFieldChange}
                      key="is_disease_no"
                    />
                    <label>No</label>
                  </td>
                </div>
              </div>
              {selectedBlock && selectedBlock?.is_disease === true && (
                <div className="col-md-12">
                  <div className="col-md-8 mt-2" style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>
                    {DISEASE_CHOICES?.map(event => (
                      <div className='col-md-10 p-0' key={event?.value}>
                        <input
                          className="form-check-input mx-1"
                          style={{ marginLeft: "1em" }}
                          type="checkbox"
                          name='disease_name'
                          checked={selectedBlock?.disease_name === event?.value}
                          value={event?.value}
                          onChange={(e) => handleFieldChange(e)}
                        />
                        <label>{event?.label}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <br></br>
              <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                <div className="col-md-8  mt-2" style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>3. Sign the IRB-approved Informed Consent Form (ICF) prior to any study-related procedures being performed.</div>
                <div className="col-md-4  mx-3">
                  <td>
                    <input
                      type="radio"
                      className="form-check-input mx-2"
                      name="is_irb_approved"
                      id="is_irb_approved_yes"
                      checked={selectedBlock?.is_irb_approved === true}
                      value={true}
                      onChange={handleFieldChange}
                      key="is_irb_approved_yes"
                    />
                    <label>Yes</label>
                  </td>
                  <td>
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="is_irb_approved"
                      checked={selectedBlock?.is_irb_approved === false}
                      id="is_irb_approved_no"
                      value={false}
                      onChange={handleFieldChange}
                      key="is_irb_approved_no"
                    />
                    <label>No</label>
                  </td>
                </div>
              </div>
            </div>
          </> : 'Visit is not started'
      }
    </fieldset>
  </>
}

export default Inclusion_Criteria_Stem_Cell;
