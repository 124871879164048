import React from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "./PatientMenu";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs";
import useFetch from "../../hooks/useFetch";
import { useNavigate, useParams } from "react-router";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import { useEffect, useState } from "react";

const PatientPhotos = () => {
  const { _id_ } = useParams();
  const trial_name_filter = localStorage.getItem("trial_name_filter");
  const trial_status_filter = localStorage.getItem("trial_status_filter");
  const pass_number = localStorage.getItem("pass_number");
  // const {PatientData, Loader, PaginationData } = useFetch(`patient/patient/all/photos/?patient_id=${_id_}&trial_name=${trial_name_filter}&trial_status=${trial_status_filter}`);
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const [Loader, setLoader] = useState(false);
  const role = user_data.role;
  const [trailsList, setTrialsList] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [infoData, setInfoData] = useState({});
  const [trialFilter, setTrialFilter] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const getTrialsList = () => {
    axios
      .get(`${__API_URL__}/api/photos/get_patient_trials/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTrialsList(res.data);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
        }
      });
  };
  const SinglePatient = () => {
    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      });
  };

  const getVisitData = () => {
    setLoader(true);
    axios
      .get(
        `${__API_URL__}/api/patient/patient/all/get_visits_data/?patient_id=${_id_}&wrinkles=${true}&trial_name=${trialFilter}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setVisitData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
        }
      });
  };

  useEffect(() => {
    getTrialsList();
    SinglePatient();
  }, []);

  useEffect(() => {
    getVisitData();
  }, [trialFilter]);

  const routeTo = (id) => {
    navigate("/patient/" + _id_ + "/photos/" + id);
  };
  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs
          B="Photos - Session View"
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
        />
        <div className="patient_table patient_photos">
          <PatientMenu active="photos" data={infoData} />
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <Link to={"/patient/" + _id_ + "/photos"}>
                  <button type="button" class="btn btn-primary">
                    Session View
                  </button>
                </Link>
                <Link to={"/patient/" + _id_ + "/gallery"}>
                  <button type="button" class="btn">
                    Gallery View
                  </button>
                </Link>
              </div>
            </div>
            {/* {(role === 'CRO Admin' || role === 'Investigator' || role === 'Cro_coordinator' || role === 'Data Manager' || role === 'Inv_coordinator') && <>
              <div className="row">
                <div className="col-md-3">
                  <strong>Filters Applied:</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                    <strong>Trial Name: {trial_name_filter === '' ? 'All' : trial_name_filter}</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Trial Status: {trial_status_filter === '' ? 'All' : trial_status_filter}</strong>
                  </div>
                  <div className="col-md-3">
                    <strong>Pass Number: {pass_number}</strong>
                  </div>
              </div>
            </>} */}

            {(role === "CRO Admin" ||
              role === "Investigator" ||
              role === 'Sub_investigator' ||
              role === "Cro_coordinator" ||
              role === 'Data Manager' ||
              role === "Inv_coordinator" ||
              role === "Super Admin" ||
              role === "Reviewer") && (
              <div className="row">
                <div className="col-lg-12">
                  <label>Trials</label>
                  <select
                    className="form-control photoFilter"
                    //  onChange={(e) => PaginationData(`/api/patient/patient/all/photos/?patient_id=${_id_}&trial_name=${trial_name_filter}&trial_status=${trial_status_filter}&trial_id=${e.target.value}`)}
                    onChange={(e) => setTrialFilter(e.target.value)}
                  >
                    <option value="">All Trial Areas</option>
                    <option value={0}>Appiell_Wrinkles</option>
                    {trailsList.map((area) => {
                      return (
                        <>
                          <option value={area.id}>{area.name}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
          </div>
          {Loader ? (
            <div className="loader">
              <img src="https://i.gifer.com/Yb3A.gif" alt="" />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    {role === "Investigator" ||
                    role === 'Sub_investigator' ||
                    role === "CRO Admin" ||
                    role === "Cro_coordinator" ||
                    role === 'Data Manager' ||
                    role === "Inv_coordinator" ||
                    role === "Super Admin"
                      ? "Trial Name"
                      : "Session Area"}
                  </th>
                  <th>Visit Type</th>

                  {(role === "Investigator" ||
                    role === 'Sub_investigator' ||
                    role === "CRO Admin" ||
                    role === "Cro_coordinator" ||
                    role === 'Data Manager' ||
                    role === "Inv_coordinator" ||
                    role === "Super Admin") && (
                    <>
                      <th>Visit Name</th>
                    </>
                  )}
                  {(role === "Investigator" ||
                    role === 'Sub_investigator' ||
                    role === 'Sub_investigator' ||
                    role === "CRO Admin" ||
                    role === "Cro_coordinator" ||
                    role === 'Data Manager' ||
                    role === "Inv_coordinator" ||
                    role === "Super Admin") && (
                    <>
                      <th>Visit Open Date</th>
                      <th>Visit Close Date</th>
                      <th>{user_data.role === 'CRO Admin' || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator' || user_data.role === 'Inv_coordinator' || role === 'Cro_coordinator' || role === 'Data Manager' ? "Subject Start Date" : "Patient Start Date"}</th>
                      <th>{user_data.role === 'CRO Admin' || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator' || user_data.role === 'Inv_coordinator' || role === 'Cro_coordinator' || role === 'Data Manager' ? "Subject Completed Date" : "Patient Completed Date"}</th>
                      <th>Is Skipped</th>
                      <th>Has Activity?</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {visitData?.map((data) => (
                  <>
                    {data?.visit_session?.map((visit) => 
                      {
                        return visit?.visit_type !== 'Unscheduled Event' &&
                      <tr
                        key={visit?.id}
                        onClick={visit?.id ? (e) => routeTo(visit?.id) : null}
                      >
                        <td>
                          {visit?.trial_area
                            ? visit?.trial_area
                            : visit?.area?.map((wrinkle) => {
                                return <>{wrinkle?.name},&nbsp;</>;
                              })}
                        </td>
                        <td>{visit?.visit_type ? visit?.visit_type : "Regular"}</td>
                        {(role === "Investigator" ||
                          role === 'Sub_investigator' ||
                          role === "CRO Admin" ||
                          role === "Cro_coordinator" ||
                          role === 'Data Manager' ||
                          role === "Inv_coordinator" ||
                          role === "Super Admin") && (
                          <>
                            <td>
                            {visit.visit_name 
                            ? visit.visit_name 
                            : visit.visit_type 
                              ? visit.visit_type 
                              : `Visit ${visit.visit_number}`}
                            </td>
                          </>
                        )}
                        <td>
                          {visit.actual_date ? (
                            <>
                              {new Date(visit.actual_date + "Z").toDateString()}
                              ,&nbsp;
                              {new Date(
                                visit.actual_date + "Z"
                              ).toLocaleTimeString()}
                            </>
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {visit.visit_close_date ? (
                            <>
                              {new Date(
                                visit.visit_close_date + "Z"
                              ).toDateString()}
                              ,&nbsp;
                              {new Date(
                                visit.visit_close_date + "Z"
                              ).toLocaleTimeString()}
                            </>
                          ) : (
                            "-"
                          )}
                        </td>

                        {!visit?.is_skipped && visit?.start_date ? (
                          <td
                            style={{
                              textAlignLast: "left",
                              textAlign: "center",
                            }}
                          >
                            {new Date(visit?.start_date + "Z").toDateString()}
                            ,&nbsp;
                            {new Date(
                              visit?.start_date + "Z"
                            ).toLocaleTimeString()}
                          </td>
                        ) : (
                          <td
                            style={{
                              textAlignLast: "center",
                              textAlign: "center",
                            }}
                          >
                            -
                          </td>
                        )}
                        {visit?.is_skipped ? (
                          <td
                            style={{
                              textAlignLast: "center",
                              textAlign: "center",
                            }}
                          >
                            -
                          </td>
                        ) : visit?.completed_date ? (
                          <td
                            style={{
                              textAlignLast: "left",
                              textAlign: "center",
                            }}
                          >
                            {new Date(
                              visit?.completed_date + "Z"
                            ).toDateString()}
                            ,&nbsp;
                            {new Date(
                              visit?.completed_date + "Z"
                            ).toLocaleTimeString()}
                          </td>
                        ) : (
                          <td
                            style={{
                              textAlignLast: "center",
                              textAlign: "center",
                            }}
                          >
                            -
                          </td>
                        )}
                        <td>
                          {visit?.is_skipped
                            ? "True"
                            : visit?.is_skipped === false
                            ? "False"
                            : "-"}
                        </td>
                        <td>{visit?.has_activity ? "True" : "False"}</td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-primary btn-sm m-2"
                          >
                            View Case
                          </button>
                        </td>
                      </tr>
                    })}
                  </>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientPhotos;
