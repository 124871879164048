import { React, useState, useEffect } from "react";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import useUser from "../../hooks/user";
import Select from "react-select";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import AddQuestion from "./AddQuestion";
import { useNavigate } from "react-router-dom";

const CreateSurvey = ({
  setAddPoem,
  GetQuestions,
  questionCount,
  setQuestionCount,
  activeTab,
  setActiveTab,
  toast,
  GetSurveys,
  surveyId,
  setSurveyId,
  survey,
  setSurvey,
  settabChanged,
  setAddNewSurvey,
  setLoader
}) => {
  const user = useUser();
  const token = localStorage.getItem("token");
  const [allQuestion, setAllQuestion] = useState([]);
  const [questionChange, setQuestionChange] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [newQuestion, setNewQuestion] = useState(false);
  const [editQuestion, setEditQuestion] = useState("");
  const [disableOrderNumber, setDisableOrderNumber] = useState(true);
  const [questionIndex, setQuestionIndex] = useState(-1);
  const navigate = useNavigate();

  const handleChoices = (e, index) => {
    const { label, value } = e;
    setDisableOrderNumber(false);
    const questionArray = survey?.question ? [...survey.question] : [];

    if (index >= 0 && index < questionArray.length) {
      questionArray[index] = { label: label, value: value };
      if (!questionArray[index].order) questionArray[index]["order"] = index + 1
    } else {
      questionArray.push({ label: label, value: value, order_number: index + 1 });
    }
    setSurvey({
      ...survey,
      question: questionArray,
    });

    setQuestionChange(true);
    setDisabled(false);
  };
  const handleOrderNumber = (e, index) => {
    const questionArray = survey?.question ? [...survey.question] : [];

    if (index >= 0 && index < questionArray.length) {
      questionArray[index] = {
        ...questionArray[index],
        order_number: e.target.value,
      };
    } else {
      questionArray.push({ order_number: e.target.value });
    }
    setSurvey({
      ...survey,
      question: questionArray,
    });
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSurvey({
      ...survey,
      [name]: value,
    });
  };
  const handleAvailable = (e) => {
    const { name, value } = e.target;
    setSurvey({
      ...survey,
      available: !survey.available,
    });
  };
  const handleAddQuestion = () => {
    const nextValue = questionCount[questionCount.length - 1] + 1;
    const updatedquestionCount = [...questionCount, nextValue];
    setQuestionCount(updatedquestionCount);
    setDisabled(true);
    setDisableOrderNumber(true);
  };

  const handleRemoveQuestion = (index) => {
    if (questionCount.length === 1) {
      toast.error("At least one question is necessary.");
    } else {
      const updatedQuestionCount = questionCount.slice();
      updatedQuestionCount.splice(index, 1);

      setQuestionCount(updatedQuestionCount);

      setSurvey((prevSurvey) => {
        const updatedQuestions = prevSurvey.question.slice();
        updatedQuestions.splice(index, 1);

        return {
          ...prevSurvey,
          question: updatedQuestions,
        };
      });
    }
  };

  const getSingleSurvey = () => {
    axios
      .get(`${__API_URL__}/api/survey/get_single_survey/${surveyId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSurvey(res.data);
      });
  };

  const AllQuestionsDropdown = (e) => {
    axios
      .post(
        `${__API_URL__}/api/survey/dropdown_question`,
        { questions: survey?.question },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setAllQuestion(res.data);
        setQuestionChange(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });
  };

  const CreateSurvey = (e) => {
    e.preventDefault();
    if (surveyId) {
      axios
        .patch(
          `${__API_URL__}/api/survey/edit_survey/${surveyId}/`,
          {
            ...survey,
            available: survey?.available ? survey.available : false,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success("Survey updated successfully!");
          GetSurveys();
          setActiveTab("viewCase");
          setSurveyId(0);
          settabChanged(false);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    } else {
      axios
        .post(
          `${__API_URL__}/api/survey/create_survey/`,
          {
            ...survey,
            available: survey?.available ? survey.available : false,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success("Survey Added successfully!");
          GetSurveys();
          setActiveTab("viewCase");
          setLoader(false)
          setSurveyId(0);
          if (setAddNewSurvey){
            setAddNewSurvey(false)
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    }
  };

  const handleEdit = (value, index) => {
    setEditQuestion(value);
    setNewQuestion(true);
  };
  const handleNewQuestion = (index) => {
    setNewQuestion(true);
    setQuestionIndex(index)
  };

  useEffect(() => {
    if (survey.question && Array.isArray(survey.question) && surveyId) {
      const newQuestionCount = Array.from(
        { length: survey.question.length },
        (_, index) => index + 1
      );
      setQuestionCount(newQuestionCount);
    }
  }, [survey]);

  useEffect(() => {
    AllQuestionsDropdown();
  }, [survey?.question]);

  useEffect(() => {
    if (surveyId) {
      getSingleSurvey();
      setDisabled(false);
      setDisableOrderNumber(false);
    }
  }, []);

  const handleCancel = () => {
    settabChanged(false);
    setSurveyId(0);
    if (setAddNewSurvey){
      setAddNewSurvey(false)
    }
    navigate(setActiveTab("viewCase")); 
  };

  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-md-9 border mx-auto">
          <form
            onSubmit={(e) => {
              CreateSurvey(e);
            }}
          >
            <div className="form-group">
              <label htmlFor="question">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={survey?.name}
                onChange={(e) => {
                  handleFieldChange(e);
                }}
                placeholder="Enter Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <select
                className="form-control"
                id="type"
                name="type"
                value={survey?.type}
                onChange={(e) => {
                  handleFieldChange(e);
                }}
                required
              >
                <option value="">Select</option>
                <option value="Wrinkles">Wrinkles</option>
                <option value="POEM">Poem</option>
              </select>
            </div>

            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                checked={survey.available == true ? true : false}
                name="available"
                onChange={(e) => {
                  handleAvailable(e);
                }}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Available
              </label>
            </div>

            <div className="row">
              <label className="form-check-label" htmlFor="exampleCheck1">
                Question
              </label>
              {questionCount?.map((choice, index) => (
                <>
                  <div className="col-md-7 my-2" key={index}>
                    <Select
                      value={survey?.question[index]}
                      className="basic-multi-select"
                      options={allQuestion}
                      name={`question ${index}`}
                      onChange={(e) => {
                        handleChoices(e, index);
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-md-2 mt-2">
                    <input
                      type="number"
                      min="1"
                      className="form-control w-100"
                      id="exampleCheck1"
                      name="order_number"
                      value={survey?.question[index]?.order_number}
                      disabled={disableOrderNumber}
                      required
                      onChange={(e) => {
                        handleOrderNumber(e, index);
                      }}
                    />
                  </div>
                  <div className="col-md-1 mt-2" style={{ width: "30px" }}>
                    <a
                      type="button"
                      onClick={() => handleEdit(survey?.question[index], index)}
                    >
                      <EditTwoToneIcon />
                    </a>
                  </div>
                    <div className="col-md-1 mt-2" style={{ width: "30px" }}>
                      <h4>
                        <a type="button" onClick={() => handleNewQuestion(index)}>
                          +
                        </a>
                      </h4>
                    </div>
                  <div className="col-md-1 mt-2" style={{ width: "30px" }}>
                    <a
                      type="button"
                      onClick={() => handleRemoveQuestion(index)}
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>
                </>
              ))}
            </div>
            <div className="col-md-6">
              <a
                type="button"
                className={`btn btn-primary ${disabled ? " disabled" : ""}`}
                onClick={() => handleAddQuestion()}
              >
                <span className="mx-1">Add row</span>
              </a>
            </div>

            <div className="container MX-5 d-flex justify-content-end">
              <div className="row">
                <div className="col-sm">
                  <button type="submit" className="btn btn-primary my-2">
                    Save
                  </button>
                </div>
                <div className="col-sm">
                  <button onClick={() => handleCancel()} className="btn btn-primary my-2">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {newQuestion && (
        <AddQuestion
          editQuestion={editQuestion}
          setEditQuestion={setEditQuestion}
          newQuestion={newQuestion}
          setNewQuestion={setNewQuestion}
          AllQuestionsDropdown={AllQuestionsDropdown}
          questionIndex={questionIndex}
          handleChoicesFromSurvey={handleChoices}
        />
      )}
    </div>
  );
};
export default CreateSurvey;
