import React, { useEffect } from "react";

const MessageBox = ({ msg, type }) => {

  let faClass = "fa fa-check-circle"
  if (type == "success") {
      faClass = "fa fa-check-circle"
  } else if (type == "warning") {
      faClass = "fa fa-exclamation-circle"
  } else if (type == "error") {
      faClass = "fa fa-times-circle"
  }

  return (
    <div id="cusMessages">
      <i className={faClass} aria-hidden="true" ></i> {msg}
    </div>
  )
};

export default MessageBox;
