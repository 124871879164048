import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import { toast, ToastContainer } from "react-toastify";

import AddNewModal from "../Modals/AddNewTrial";
import { useParams } from "react-router";

const Medical_surgical = (props) => {
  const [medicalHistory, setMedicalHistory] = useState({});
  const token = localStorage.getItem("token");
  const user = useUser();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  let disable = false;
  const [showAddButton, setShowAddButton] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showError, setShowError] = useState(false);

  const { _id_ } = useParams();
  const [modalTrialTypeShow, setModalTrialTypeShow] = useState(false);
  const [trialDetails, setTrialDetails] = useState({});
  const [allTitles, setAllTitles] = useState([]);
  const [title, setTitle] = useState('');

  const handleDisorder = (event, index) => {
    const { name, value, type, checked } = event.target;
    const updatedMedicalData = [...medicalHistory.medical_data];
    const updatedMedical = { ...updatedMedicalData[index].disorder_name };
    updatedMedical[name] = value;
    updatedMedicalData[index].disorder_name = updatedMedical;
    setMedicalHistory((prevState) => ({
      ...prevState,
      medical_data: updatedMedicalData,
    }));
    props.setHasChanged(true);
  };

  const handleMedical = (event, index) => {
    const { name, value, type, checked } = event.target;
    const updatedMedicalData = [...medicalHistory.medical_data];
    const updatedMedical = { ...updatedMedicalData[index].medical };
    if (type === "checkbox") {
      updatedMedical[name] = checked;
    } else {
      updatedMedical[name] = value;
    }
    updatedMedicalData[index].medical = updatedMedical;
    setMedicalHistory((prevState) => ({
      ...prevState,
      medical_data: updatedMedicalData,
    }));
    props.setHasChanged(true);
  };

  const handleOngoingEvent = (e, index) => {
    const updatedItem = {
      ...medicalHistory.medical_data[index].medical,
      ongoing: !medicalHistory.medical_data[index].medical.ongoing,
    };
    const updatedLogData = [...medicalHistory.medical_data];
    const updatedMedicalData = { ...updatedLogData[index] };
    updatedMedicalData.medical = updatedItem;
    updatedLogData[index] = updatedMedicalData;
    setMedicalHistory((prevState) => ({
      ...prevState,
      medical_data: updatedLogData,
    }));
    props.setHasChanged(true);
  };

  const handleMedicationEvent = (e, index) => {
    const updatedItem = {
      ...medicalHistory.medical_data[index].medical,
      medication: !medicalHistory.medical_data[index].medical.medication,
    };
    const updatedLogData = [...medicalHistory.medical_data];
    const updatedMedicalData = { ...updatedLogData[index] };
    updatedMedicalData.medical = updatedItem;
    updatedLogData[index] = updatedMedicalData;
    setMedicalHistory((prevState) => ({
      ...prevState,
      medical_data: updatedLogData,
    }));
    props.setHasChanged(true);
  };
  const handleMedicalEvent = (e) => {
    setMedicalHistory({
      ...medicalHistory,
      is_medical_condition: !medicalHistory?.is_medical_condition,
    });
  };
  const handleDelete = (id) => {
    props.setHasChanged(false);
    setDisableButton(false);
    const data = medicalHistory.medical_data.filter(
      (item, index) => index !== id
    );
    setMedicalHistory({
      ...medicalHistory,
      medical_data: data,
    });
  };
  const getMedicalTitle = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_medical_title/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAllTitles(res.data);
      });
  };

  const CreateMedicalHistory = () => {
    setDisableButton(true);
    props.setHasChanged(true);
    setShowAddButton(true);
    setShowTooltip(true);
    setMedicalHistory({
      ...medicalHistory,
      medical_data: [
        ...medicalHistory.medical_data,
        {
          id: null,
          title: "",
          medical: {
            id: null,
          },
        },
      ],
    });
  };
  const CreateMedicalSurgical = () => {
    const sendData = {
      patient_id: props.patient_id,
      visit_number: props.visit_number,
      trial: props.trialId,
      is_medical_condition: medicalHistory?.is_medical_condition,
      sessionId: props.selectedSessionId,
    };
    axios
      .post(`${__API_URL__}/api/ecrf/create_medical_data/`, sendData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setMedicalHistory(res.data);
        if (medicalHistory?.is_medical_condition) {
          props.globalVisits();
        }
        getMedicalSurgicalHistory();
      })
      .catch((err) => {});
  };
  const MedicalSurgical = (id, item_id) => {
    const sendData = {
      medical_data_id: item_id ? item_id : "None",
      medical_id: medicalHistory?.medical_data[id]?.medical?.id,
      medical_data: medicalHistory.medical_data[id],
      patient_id: props.patient_id,
      visit_number: props.visit_number,
      is_medical_condition: medicalHistory?.is_medical_condition,
      sessionId: props.selectedSessionId,
    };
    axios
      .patch(
        `${__API_URL__}/api/ecrf/update_medical_history/${props.trialId}/`,
        sendData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setShowError(false);
        getMedicalSurgicalHistory();
        setDisableButton(false);
        setMedicalHistory(res.data);
        props.setHasChanged(false);
        props.globalVisits(false);
        toast.success("Block updated successfully");
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
          user.actions.logout();
        }
        toast.error(err.response.data.message);
      });
  };
  const getMedicalSurgicalHistory = () => {
    axios
      .get(
        `${__API_URL__}/api/ecrf/get_medical_history/${props.trialId}/?visit_number=${props.visit_number}&patient_id=${props.patient_id}&sessionId=${props?.selectedSessionId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setMedicalHistory(res.data);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
        }
      });
  };
  React.useEffect(() => {
    if (props?.patient_id && props?.photo_session) {
      CreateMedicalSurgical();
    }
  }, [medicalHistory.is_medical_condition]);

  React.useEffect(() => {
    if (props?.patient_id && props?.photo_session) {
      getMedicalSurgicalHistory();
      getMedicalTitle();
    }
  }, []);

  const DeleteMedicalBlockData = (id) => {
    axios
      .delete(`${__API_URL__}/api/ecrf/delete_medical_data/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        getMedicalSurgicalHistory();
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
          user.actions.logout();
        }
      });
  };

  const addMedicalHistoryTitleApi = () => {
        setModalTrialTypeShow(false)
        const Createdata = {
           'title': title,
        };
        axios
        .post(`${__API_URL__}/api/ecrf/create_medical_history_title/`, Createdata, {
            headers: {
            Authorization: `Token ${token}`,
            },
        })
        .then((res) => {
            res = res.data
            getMedicalTitle()
            toast.success("Disorder Name Added!");
        }).catch((err) => {
            toast.error("Something Went Wrong!");
        });
    }

  return (
    <>
      <ToastContainer />

      {role === "CRO Admin" ||
      role === "Cro_coordinator" ||
      role === 'Data Manager' ||
      props.patient_id === undefined
        ? (disable = true)
        : ""}
      <fieldset disabled={disable}>
        {props.photo_session || props.patient_id === undefined ? (
          <div className="row">
            <tr>
              <td>
                {" "}
                • Does the participant have any clinically significant medical
                conditions?
              </td>
              <th>
                <td>
                  {" "}
                  <label for="radioYes"> Yes</label>
                  <input
                    className="form-check-input mx-1"
                    type="radio"
                    name="is_medical_condition"
                    checked={medicalHistory?.is_medical_condition == true}
                    value={medicalHistory?.is_medical_condition == true}
                    id="radioYes"
                    onChange={handleMedicalEvent}
                  />
                </td>
              </th>
              <th>
                <td>
                  {" "}
                  <label for="radioNo"> No</label>
                  <input
                    className="form-check-input mx-1"
                    type="radio"
                    name="is_medical_condition"
                    checked={medicalHistory?.is_medical_condition == false}
                    id="radioNo"
                    value={medicalHistory?.is_medical_condition == false}
                    onChange={handleMedicalEvent}
                  />
                </td>
              </th>
            </tr>
            {medicalHistory?.is_medical_condition === true ? (
              <>
                <Table
                  id="patient_table"
                  className="table table-responsive ecrf-medication-table"
                >
                  <thead>
                    <tr>
                      <th className="border">Disease/Disorder/Condition</th>
                      <th className="border">Start Date</th>
                      <th className="border">Stop Date</th>
                      <th className="border">Ongoing </th>
                      <th className="border">Medication</th>
                    </tr>
                  </thead>
                  {Array.isArray(medicalHistory.medical_data) &&
                    medicalHistory.medical_data.map((item, index) => {
                      return (
                        <>
                          <tbody className="dis-medication-s">
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <div className="text-end">
                                  {item.id ? (
                                    <>
                                      <span
                                        onClick={() =>
                                          DeleteMedicalBlockData(item.id)
                                        }
                                      >
                                        <i className="fa fa-trash fa-lg text-danger"></i>
                                      </span>
                                    </>
                                  ) : (
                                    <span onClick={() => handleDelete(index)}>
                                      <i className="fa fa-trash fa-lg text-danger"></i>
                                    </span>
                                  )}
                                  <button
                                    onClick={() =>
                                      MedicalSurgical(index, item.id)
                                    }
                                    setShowTooltip={false}
                                    className="btn btn-primary p-1 mx-3 my-1 px-2"
                                  >
                                    <i className="fa fa-save fa-lg text-white"></i>{" "}
                                    Save
                                  </button>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="col-md-10 p-0">
                                  <label>Disorder Names:</label>
                                </div>
                                <div>
                                  <select
                                    style={{ width: "370px", height: "35px" }}
                                    className="form-control"
                                    value={item.disorder_name?.id}
                                    // options={allTitles}
                                    onChange={(e) => handleDisorder(e, index)}
                                    name="id"
                                  >
                                    <option value="">Select</option>

                                    {allTitles?.map((title) => {
                                      return (
                                        <>
                                          <option
                                            value={title.id}
                                            key={title.id}
                                          >
                                            {title.title}
                                          </option>
                                        </>
                                      );
                                    })}
                                  </select>

                                  {
                                    <div className="row">
                                      <div className="col-md-5">
                                        <button
                                          class="btn btn-primary"
                                          onClick={() =>
                                            setModalTrialTypeShow(true)
                                          }
                                        >
                                          Add New
                                        </button>
                                      </div>
                                      {modalTrialTypeShow && (
                                        <AddNewModal
                                          show={modalTrialTypeShow}
                                          onHide={() => setModalTrialTypeShow(false)}
                                          formSubmissionApi={addMedicalHistoryTitleApi}
                                          modalShow={setModalTrialTypeShow}
                                          title={title}
                                          setTitle={setTitle}
                                        />
                                      )}
                                    </div>
                                  }
                                </div>
                                <br></br>
                                <div className="col-md-10">
                                  <label> Specify: </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="specify"
                                    id="flexSwitchCheckDefault"
                                    value={item.medical?.specify}
                                    onChange={(e) => handleMedical(e, index)}
                                  ></input>
                                </div>
                              </td>
                              <td>
                                <div className="col-md-10 calendarIcon">
                                  <input
                                    class="form-control"
                                    onChange={(e) => handleMedical(e, index)}
                                    type="date"
                                    name="start_date"
                                    value={item.medical?.start_date}
                                    inputProps={{
                                      placeholder: "mm/dd/yy 00:00 AM",
                                      disabled:
                                        role === "CRO Admin" ||
                                        role === "Cro_coordinator" ||
                                        role === 'Data Manager'
                                          ? true
                                          : false ||
                                            props.patient_id === undefined,
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="col-md-10">
                                  <input
                                    onChange={(e) => handleMedical(e, index)}
                                    class="form-control"
                                    type="date"
                                    name="stop_date"
                                    value={item.medical?.stop_date}
                                    inputProps={{
                                      placeholder: "mm/dd/yy 00:00 AM",
                                      disabled:
                                        role === "CRO Admin" ||
                                        role === "Cro_coordinator" || 
                                        role === 'Data Manager' 
                                          ? true
                                          : false ||
                                            props.patient_id === undefined,
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="col-md-4">
                                  <input
                                    className="form-check-input mx-1"
                                    style={{ marginLeft: "1em" }}
                                    type="checkbox"
                                    name="ongoing"
                                    checked={item.medical?.ongoing === true}
                                    value={item.medical?.ongoing === true}
                                    onChange={(e) =>
                                      handleOngoingEvent(e, index)
                                    }
                                  />
                                  <label>Yes*</label>
                                </div>
                                <div className="col-md-2 p-0">
                                  <input
                                    className="form-check-input mx-1"
                                    style={{ marginLeft: "1em" }}
                                    type="checkbox"
                                    name="ongoing"
                                    checked={item.medical?.ongoing === false}
                                    value={item.medical?.ongoing === false}
                                    onChange={(e) =>
                                      handleOngoingEvent(e, index)
                                    }
                                  />
                                  <label> No</label>
                                </div>
                              </td>
                              <td>
                                <div className="col-md-2 p-0">
                                  <input
                                    className="form-check-input mx-1"
                                    style={{ marginLeft: "1em" }}
                                    type="checkbox"
                                    name="medication"
                                    checked={item.medical?.medication === true}
                                    value={item.medical?.medication === true}
                                    onChange={(e) =>
                                      handleMedicationEvent(e, index)
                                    }
                                  />
                                  <label>Yes*</label>
                                </div>
                                <div className="col-md-2 p-0">
                                  <input
                                    className="form-check-input mx-1"
                                    style={{ marginLeft: "1em" }}
                                    type="checkbox"
                                    name="medication"
                                    checked={item.medical?.medication === false}
                                    value={item.medical?.medication === false}
                                    onChange={(e) =>
                                      handleMedicationEvent(e, index)
                                    }
                                  />
                                  <label> No</label>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <div>
                            <br />
                          </div>
                        </>
                      );
                    })}
                  <div className="col-md-12 d-flex justify-content-end my-2">
                    <button
                      type="button"
                      disabled={disableButton}
                      className="btn btn-primary float-end disabled-cursor"
                      onClick={() => {
                        CreateMedicalHistory();
                      }}
                      title={
                        disableButton
                          ? "Please save the information first"
                          : "Click to add medical history"
                      }
                    >
                      Add +
                    </button>
                  </div>
                </Table>
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          "Visit is not started"
        )}
      </fieldset>
    </>
  );
};

export default Medical_surgical;
