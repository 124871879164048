import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Button from 'react-bootstrap/Button';

const Sutures_Removed_Stem_Cell = (props) => {
    const token = localStorage.getItem("token");
    const user = useUser();
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role
    const setMessage = props.setMessage
    const setTypeOfMessage = props.setTypeOfMessage
    let diasble = false
    const [selectedBlock, setSelectedBlock] = useState({})

    const handleFieldChange = (e) => {
        const { name, value, checked, type } = e.target;
        setSelectedBlock((prevBlock) => ({
            ...prevBlock,
            [name]: type === 'checkbox' ? (checked ? value : '') : (type === 'radio' ? (value === 'true') : value),
        }));
        props.setHasChanged(true)
    };
    const selectAll = () => {
        setSelectedBlock({
            is_sutures_not_needed: true,
            is_sutures_removed: true,
            is_steristrips_applied: true,
            is_healing_well: true,
        });
        props.setHasChanged(true)
        };
      const removeAll = () => {
        setSelectedBlock({
            is_sutures_not_needed: false,
            is_sutures_removed: false,
            is_steristrips_applied: false,
            is_healing_well: false,
        });
        props.setHasChanged(true)
        };

    const CreateSuture = () => {
        const createData = {
            'visit_number': props?.visit_number,
            'patient_id': props?.patient_id,
            'sessionId': props.selectedSessionId,
            ...selectedBlock

        }
        axios
            .patch(`${__API_URL__}/api/ecrf/update_suture_stemcell/${props.trialId}/`, createData, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                setSelectedBlock(res.data);
                props.globalVisits(false);
                props.setModalVisitChangeShow(false)
                props.setHasChanged(false)
                setMessage("Block updated successfully");
                setTypeOfMessage("success");
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                    user.actions.logout()
                }
                setMessage("Something Went Wrong");
                setTypeOfMessage("success");
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                    document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            });
    };


    const get_suture_data = () => {
        axios
            .get(`${__API_URL__}/api/ecrf/get_suture_stemcell/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                setSelectedBlock(res.data);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                }
            });
    }
    React.useEffect(() => {
        if ((props?.patient_id && props?.photo_session)) {
            get_suture_data();
        }
    }, [])
    return <>
        {
            (role ==='CRO Admin' || role==='Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined)) ?
                diasble = true : ''
        }
        <fieldset disabled={diasble}>
            {
                (props?.photo_session || props?.patient_id == undefined) ?
                    <>
                        {
                            (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                <div className="col-md-12 text-end" >
                                    <button onClick={() => CreateSuture()} className='btn btn-primary'>Update</button>
                                </div>
                            )
                        }
            <br/>
            <div className="row d-flex align-items-end">
                <div className="col-md-8">
                </div>
                <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={selectAll}
                >
                  Select all Yes's
                </button>
                  &nbsp;
                </div>
                <div className="col-md-2">
                  <button
                  type="button"
                  className="btn btn-primary btn-sm mt-2"
                  onClick={removeAll}
                  >Select all No's</button>
                        &nbsp;
                </div>
              </div>

                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8 mt-2" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> Suture Not Needed.</div>
                            <div className="col-md-4 mx-3">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name="is_sutures_not_needed"
                                            id="is_sutures_not_needed_yes"
                                            checked={selectedBlock?.is_sutures_not_needed === true}
                                            value={true}
                                            onChange={handleFieldChange}
                                            key="is_sutures_not_needed_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name="is_sutures_not_needed"
                                            id="is_sutures_not_needed_no"
                                            checked={selectedBlock?.is_sutures_not_needed === false}
                                            value={false}
                                            onChange={handleFieldChange}
                                            key="is_sutures_not_needed_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> Suture removed.</div>
                            <div className="col-md-4  mx-3">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name="is_sutures_removed"
                                            id="is_sutures_removed_yes"
                                            checked={selectedBlock?.is_sutures_removed === true}
                                            value={true}
                                            onChange={handleFieldChange}
                                            key="is_sutures_removed_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name="is_sutures_removed"
                                            id="is_sutures_removed_no"
                                            checked={selectedBlock?.is_sutures_removed === false}
                                            value={false}
                                            onChange={handleFieldChange}
                                            key="is_sutures_removed_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> Steristrips applied.</div>
                            <div className="col-md-4  mx-3">
                                <td>
                                    <label>
                                        <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name="is_steristrips_applied"
                                            id="is_steristrips_applied_yes"
                                            checked={selectedBlock?.is_steristrips_applied === true}
                                            value={true}
                                            onChange={handleFieldChange}
                                            key="is_steristrips_applied_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                    <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name="is_steristrips_applied"
                                            id="is_steristrips_applied_no"
                                            checked={selectedBlock?.is_steristrips_applied === false}
                                            value={false}
                                            onChange={handleFieldChange}
                                            key="is_steristrips_applied_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div className="col-md-8" style={{ fontSize: '14px', whiteSpace: 'prewrap' }}> Healing well. </div>
                            <div className="col-md-4 mx-3">
                                <td>
                                    <label>
                                    <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name="is_healing_well"
                                            id="is_healing_well_yes"
                                            checked={selectedBlock?.is_healing_well === true}
                                            value={true}
                                            onChange={handleFieldChange}
                                            key="is_healing_well_yes"
                                        />
                                        Yes
                                    </label>
                                </td>
                                <td>
                                    <label>
                                    <input
                                            type="radio"
                                            className="form-check-input mx-2"
                                            name="is_healing_well"
                                            id="is_healing_well_no"
                                            checked={selectedBlock?.is_healing_well === false}
                                            value={false}
                                            onChange={handleFieldChange}
                                            key="is_healing_well_no"
                                        />
                                        No
                                    </label>
                                </td>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label></label>
                            <textarea class='form-control' name='description' rows={10} type='text' value={selectedBlock?.description} onChange={handleFieldChange}></textarea>
                        </div>
                    </> : 'Visit is not started'
            }
        </fieldset>
    </>
}

export default Sutures_Removed_Stem_Cell;