import NavBar from "../../components/navBar/navBar";
import PatientMenu from "../../Pages/Patient/PatientMenu";
import Breadcrumbs from "../../components/breadcrumbs";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MainAccordion from "../Accordion/accordion";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { ToastContainer } from "react-toastify";
import EcrfBlocks from "../Trials/EcrfBlocks";
import UpdateStatusPopUp from "../PopUp/UpdateStatusPopUpPopUp";
import GenerateQueryPopUp from "../PopUp/GenerateQueryPopUp";
import ChatRoom from "./ChatRoom";
import QueryTablePopUp from "../PopUp/QueryTablePopUp";
import QueryTableContainer from "./QueryTableContainer";

const QueryTable = () => {
  const { _id_ } = useParams();
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const [Loader, setLoader] = useState(true);
  const [infoData, setInfoData] = useState({});
  const [queryLogData, setQueryLogData] = useState([]);
  const [visitNames, setVisitNames] = useState([]);
  const [trialId, setTrialId] = useState(0);
  const [checked, setChecked] = useState(Array(0).fill(false));
  const [showModal, setShowModal] = useState(false);
  const [inputType, setInputType] = useState(undefined);
  const [showRolesModal, setShowRolesModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [showQueryTableModal, setShowQueryTableModal] = useState(false);
  const [blocksSelected, setBlocksSelected] = useState([]);
  const [hyperLinkObject, setHyperLinkObject] = useState({});
  const [generateQueryObject, setGenerateQueryObject] = useState({});
  const [generateQueryMessageObject, setGenerateQueryMessageObject] = useState(
    {}
  );
  const [showEcrfBlock, setShowEcrfBlock] = useState(false);
  const [searchObject, setSearchObject] = useState({
    visit: "All",
    block_name: "",
    inv_certified: "All",
    cro_block_status: "All",
    dm_block_status: "All",
    block_status: "All",
  });
  const [timeoutId, setTimeoutId] = useState(null);
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);

  const convertFirstLetterToCapital = (str) => {
    const newStr = str.charAt(0).toUpperCase() + str.slice(1);
    return newStr;
  };

  const handleParentChange = (event) => {
    const filteredQueryLog = queryLogData.filter(
      (item) => item.status !== "Not Started"
    );
    setChecked(Array(queryLogData.length).fill(event.target.checked));
    if (event.target.checked) {
      setBlocksSelected((prev) => filteredQueryLog.map((i) => i.id));
    } else {
      setBlocksSelected([]);
    }
  };

  const handleChildChange = (event, index, answerJsonId) => {
    const newChecked = [...checked];
    newChecked[index] = event.target.checked;
    setChecked(newChecked);
    setBlocksSelected((prev) => {
      const filtered = prev.filter((i) => i !== answerJsonId);
      if (event.target.checked) {
        return [...filtered, answerJsonId];
      } else {
        return filtered;
      }
    });
  };

  useEffect(() => {
    if (queryLogData.length > 0) {
      setChecked(Array(queryLogData.length).fill(false));

      if (params.get("queryId")) {
        const filterdItem = queryLogData.filter(
          (data) => data.query_status?.id === parseInt(params.get("queryId"))
        );
        if (filterdItem.length > 0) {
          const item = filterdItem[0];
          setGenerateQueryMessageObject({
            queryId: item.query_status?.id,
            queryStatus: item.query_status?.status,
            fromUserId: item.query_status?.from_user,
          });
          setShowChatModal(true);
        }
      }
    }
    setBlocksSelected([]);
  }, [queryLogData]);

  const [allChecked, setAllChecked] = useState(false);
  const [someChecked, setSomeChecked] = useState(false);

  useEffect(() => {
    setAllChecked(checked.every((value) => value === true));
    setSomeChecked(checked.some((value) => value === true));
  }, [checked]);

  const SinglePatient = () => {
    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      });
  };

  const trialDetailApi = () => {
    axios
      .get(`${__API_URL__}/api/photos/get_trial_for_patient/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        res = res.data;
        setTrialId(res.trial.id);
      });
  };
  
  const QueryLogData = (searchString) => {
    axios
      .get(
        `${__API_URL__}/api/ecrf/get_query_logs/?trial=${trialId}&patient=${_id_}&${searchString}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setQueryLogData(res.data);
      })
      .catch((err) => {});
  };

  const GetVisitNames = () => {
    axios
      .get(`${__API_URL__}/api/photos/get_visits_details/${trialId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setVisitNames(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      passSearchObjectToAPI()
    }, 1000);

    setTimeoutId(newTimeoutId);
  }, [searchObject]);

  const passSearchObjectToAPI = () => {
    let searchString = "";
    for (const key in searchObject) {
      if (searchObject.hasOwnProperty(key)) {
        searchString += `${key}=${searchObject[key]}&`;
      }
    }
    if (trialId !== 0) {
      QueryLogData(searchString);
    }
  }

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  const handleHyperLink = (item) => {
    setHyperLinkObject({
      visit_number: item.visit_number,
      selectedBlock: item.block_name,
      visit: item.visit,
      patient_id: _id_,
      photo_session: true,
      isOpen: item.status !== "Not Started",
    });
  };

  const handleGenerateQuery = (item) => {
    setGenerateQueryObject({
      answerJsonId: item.id,
    });
  };

  const handleGenerateQueryMessage = (item) => {
    setGenerateQueryMessageObject({
      queryId: item.query_status?.id,
      queryStatus: item.query_status?.status,
      fromUserId: item.query_status?.from_user,
    });
  };

  useEffect(() => {
    if (hyperLinkObject.selectedBlock) {
      setShowEcrfBlock(true);
    } else {
      setShowEcrfBlock(false);
    }
  }, [hyperLinkObject]);

  useEffect(() => {
    setLoader(true);
    trialDetailApi();
    SinglePatient();
    setLoader(false);
  }, []);

  useEffect(() => {
    if (trialId !== 0) {
      GetVisitNames();
      QueryLogData("");
    }
  }, [trialId]);

  return (
    <>
      <ToastContainer />
      <NavBar active="patient" />
      <div className="container-fluid">
        <div className="patient_table patient_photos">
          <Breadcrumbs
            B="Query"
            F={infoData?.first_name}
            L={infoData?.last_name}
            E={infoData?.email}
            P={infoData?.phone}
            participant_id={infoData?.participant_id}
            age={infoData?.age}
            birth_date={infoData?.birth_date}
          ></Breadcrumbs>

          <PatientMenu active="query" data={infoData} />
          <div className="tableHeader">
            <>
              <MainAccordion
                title="Query Table"
                isOpen={true}
                data={
                  <>
                    <div className="col-md-12 text-end">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm mx-5"
                        onClick={() => setShowQueryTableModal(true)}
                      >
                        Full Screen View
                      </button>
                    </div>
                    <QueryTablePopUp
                      showQueryTableModal={showQueryTableModal}
                      setShowQueryTableModal={setShowQueryTableModal}
                      setShowModal={setShowModal}
                    >
                      <QueryTableContainer
                        Loader={Loader}
                        allChecked={allChecked}
                        someChecked={someChecked}
                        handleParentChange={handleParentChange}
                        searchObject={searchObject}
                        queryLogData={queryLogData}
                        checked={checked}
                        handleChildChange={handleChildChange}
                        convertFirstLetterToCapital={
                          convertFirstLetterToCapital
                        }
                        handleHyperLink={handleHyperLink}
                        handleGenerateQueryMessage={handleGenerateQueryMessage}
                        setShowChatModal={setShowChatModal}
                        handleGenerateQuery={handleGenerateQuery}
                        setShowRolesModal={setShowRolesModal}
                        QuestionMarkRoundedIcon={QuestionMarkRoundedIcon}
                        visitNames={visitNames}
                        setSearchObject={setSearchObject}
                        setShowModal={setShowModal}
                        role={role}
                        setInputType={setInputType}
                        showQueryTableModal={showQueryTableModal}
                        passSearchObjectToAPI={passSearchObjectToAPI}
                      />
                    </QueryTablePopUp>
                    <QueryTableContainer
                      Loader={Loader}
                      allChecked={allChecked}
                      someChecked={someChecked}
                      handleParentChange={handleParentChange}
                      searchObject={searchObject}
                      queryLogData={queryLogData}
                      checked={checked}
                      handleChildChange={handleChildChange}
                      convertFirstLetterToCapital={convertFirstLetterToCapital}
                      handleHyperLink={handleHyperLink}
                      handleGenerateQueryMessage={handleGenerateQueryMessage}
                      setShowChatModal={setShowChatModal}
                      handleGenerateQuery={handleGenerateQuery}
                      setShowRolesModal={setShowRolesModal}
                      QuestionMarkRoundedIcon={QuestionMarkRoundedIcon}
                      visitNames={visitNames}
                      setSearchObject={setSearchObject}
                      setShowModal={setShowModal}
                      role={role}
                      setInputType={setInputType}
                      showQueryTableModal={showQueryTableModal}
                      passSearchObjectToAPI={passSearchObjectToAPI}
                    />
                    <UpdateStatusPopUp
                      showModal={showModal}
                      setShowModal={setShowModal}
                      blocksSelected={blocksSelected}
                      QueryLogData={QueryLogData}
                      inputType={inputType}
                    />
                    <GenerateQueryPopUp
                      showRolesModal={showRolesModal}
                      setShowRolesModal={setShowRolesModal}
                      answerJsonId={generateQueryObject?.answerJsonId}
                      QueryLogData={QueryLogData}
                    />
                    {showChatModal && (
                      <ChatRoom
                        showChatModal={showChatModal}
                        setShowChatModal={setShowChatModal}
                        generateQueryMessageObject={generateQueryMessageObject}
                        setGenerateQueryMessageObject={
                          setGenerateQueryMessageObject
                        }
                        setQueryLogData={setQueryLogData}
                      />
                    )}
                    {showEcrfBlock && (
                      <>
                        <EcrfBlocks
                          visit_number={hyperLinkObject.visit_number}
                          trialId={trialId}
                          selectedBlock={hyperLinkObject.selectedBlock}
                          patient_id={_id_}
                          fromQueryTable={true}
                          showEcrfBlock={showEcrfBlock}
                          setShowEcrfBlock={setShowEcrfBlock}
                          isOpen={hyperLinkObject.isOpen}
                          selectedSessionId={((hyperLinkObject?.visit_number === -2) || (hyperLinkObject?.visit_number === -1)) ?  hyperLinkObject.visit : 0}
                        />
                      </>
                    )}
                  </>
                }
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryTable;
