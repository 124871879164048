import React, { useState } from "react";
import {__API_URL__} from "../../SERVER_URL";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageCompare = ({Images}) => {
  const [scale, setScale] = useState(1);
  const replaceImage = (src) => {
    document.getElementsByClassName('replaceImage')[0].src = src;
  }
  const divHide = () => {
    document.getElementsByClassName('imageCompare')[0].style.display = "none";
  }
  return (
      <div className="imageCompare">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h5>Select Image To Compare <span onClick={divHide}>x</span></h5>
            </div>
            <div className="col-md-4">
              {Images?.map((row)=>(
                <div className="comLeft" key={row.id}>
                  <p>{new Date(row.created_at+'Z').toDateString()},&nbsp;
                    {new Date(row.created_at+'Z').toLocaleTimeString()}</p>
                  {
                    row?.photo?.map((data)=>(
                      <img src={__API_URL__+data.thumbnail} alt="" onClick={((e)=>replaceImage(e.target.src))}/>
                    ))
                  }
                </div>
              ))}
            </div>
            <div className="col-md-8 d-flex justify-content-center">
                <div className="imagecompare">
                      <div id ='comRight' className="comRight d-flex">
                      <div className="comRight">
                <TransformWrapper
                  defaultScale={1}
                  defaultPositionX={0}
                  defaultPositionY={0}
                >
                  {({ zoomIn, zoomOut, ...rest }) => (
                    <>
                      <div className="image-zoom">
                        <TransformComponent>
                          <div className="image-zoom ">
                            <img
                              src=""
                              alt=""
                              width="100%"
                              className="fixImage"
                              style={{ transform: `scale(${scale})` }}
                            />
                          </div>
                        </TransformComponent>
                      </div>
                      <div className="controls" color="primary" class="right">
                        <div className="container text-center mt-3 buttonsz-u">
                          <button
                            className="btn btn-primary btn-sm mx-2"
                            onClick={() => zoomIn()}
                          >
                            ZOOM IN
                          </button>
                          <button
                            className="btn btn-primary btn-sm mx-2"
                            onClick={() => zoomOut()}
                          >
                            ZOOM OUT
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </TransformWrapper>
              </div>
            <div className="comRight">
                <TransformWrapper
                  defaultScale={1}
                  defaultPositionX={0}
                  defaultPositionY={0}
                >
                  {({ zoomIn, zoomOut, ...rest }) => (
                    <>
                    
                      <div className="image-zoom">
                        <TransformComponent>
                          <div className="image-zoom">
                            <img
                              src="https://stagingdashboard.appiell.ai/static/placeholder.png"
                              alt=""
                              width="100%"
                              className="replaceImage"
                              style={{ transform: `scale(${scale})` }}
                            />
                          </div>
                        </TransformComponent>
                      </div>
                      <div className="controls" color="primary" class="right">
                        <div className="container text-center mt-3 buttonsz-u">
                          <button
                            className="btn btn-primary btn-sm mx-2"
                            onClick={() => zoomIn()}
                          >
                            ZOOM IN
                          </button>
                          <button
                            className="btn btn-primary btn-sm mx-2"
                            onClick={() => zoomOut()}
                          >
                            ZOOM OUT
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </TransformWrapper>
              </div>
            </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
};
export default ImageCompare;