import React from "react";
import ReactPaginate from "react-paginate";

const QueryChangeLogTable = ({ queryLogs, handlePageClick, pageItemCount }) => {
  
  return (
    <>
    <table className="table table-bordered table-striped mb-0 table-lg">
      <div className="row">
        <div>
          <h6> Query Change Logs </h6>
        </div>
      </div>
      <thead>
        <tr>
          <th>Trial Name</th>
          <th>Visit Name</th>
          <th>Visit Type</th>
          <th>Block Name</th>
          <th>Who filled it</th>
          <th>Role</th>
          <th>Query Requester/Respondent</th>
          <th>Role</th>
          <th>Timestamp</th>
          <th>Query Type</th>
          <th>Action</th>
          <th style={{ backgroundColor: "lightblue" }}>Query Content</th>
          <th>Query Status</th>
          <th>Recipients</th>
        </tr>
      </thead>
      <tbody>
          {
            queryLogs?.results?.map(log => {
              return <tr>
                <td>{log.visit_data?.trial_area}</td>
                <td>{log.visit_data?.visit_name}</td>
                <td>{log.visit_data?.visit_type}</td>
                <td>{log.visit_data?.block_name}</td>
                <td>{log.visit_data?.who_filled_it}</td>
                <td>{log.visit_data?.who_filled_it_role}</td>
                <td>{log.visit_data?.action_requester}</td>
                <td>{log.visit_data?.action_requester_role}</td>
                <td>
                  {new Date(log.created_at + "Z").toDateString()},&nbsp;
                  {new Date(log.created_at + "Z").toLocaleTimeString()}
                </td>
                <td></td>
                <td>{log.action}</td>
                <td style={{ backgroundColor: "lightblue" }}>{log.query_content}</td>
                <td>{log.visit_data?.query_status}</td>
                <td>{log.visit_data?.recipients}</td>
              </tr>
            })
          }
      </tbody>
    </table>
        {/* Adding Pagination for Change Logs */}
        <div className="reactPagination col-md-6">
        <ReactPaginate
            breakLabel="..."
            nextLabel="next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={10}
            pageCount={Math.ceil(queryLogs?.count / pageItemCount)}
            previousLabel="previous"
        />
      </div>
   </>
  );
};

export default QueryChangeLogTable;
