import React, { useRef, useState } from "react";
import { __API_URL__ } from "../../SERVER_URL";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Avatar from "../../assets/img/avatar.png";

const ExternalImageCompare = ({ show, onHide, Images ,zoomPhoto }) => {
  const [scale, setScale] = useState(1);
  const replaceImageRef = useRef(null);
  const defaultAvatar = Avatar; 

  const replaceImage = (src) => {
    if (replaceImageRef.current) {
      replaceImageRef.current.src = src || defaultAvatar;
    } else {
      console.error('Reference to the image element is not available');
    }
  };
  const divHide = () => {
    if (onHide) onHide();
  };
  return (
    <div className="imageCompare" style={{ display: show ? 'block' : 'none' }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h5>Select Image To Compare <span onClick={divHide}>x</span></h5>
          </div>
          <div className="col-md-4">
            {Images.map((row) => (
              <div className="comLeft" key={row.id}>
                <p>{new Date(row.capture_datetime + 'Z').toDateString()},&nbsp;
                  {new Date(row.capture_datetime + 'Z').toLocaleTimeString()}</p>
                  <img src={__API_URL__ + row.image} alt="" onClick={(e) => replaceImage(e.target.src)} />
              </div>
            ))}
          </div>
          <div className="col-md-8 d-flex justify-content-center">
          <div className="imagecompare">
          <div id='comRight' className="comRight d-flex">
          <div className="comRight">
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={0}
              defaultPositionY={0}
            >
              {({ zoomIn, zoomOut, ...rest }) => (
                <>
                  <div className="image-zoom">
                    <TransformComponent>
                      <div className="image-zoom ">
                        <img
                          alt=""
                          width="100%"
                          className="fixImage"
                          style={{ transform: `scale(1)` }}
                          src={zoomPhoto}
                          />
                      </div>
                    </TransformComponent>
                  </div>
                  <div
                    className="controls" 
                    color="primary" 
                    class="right">
                    <div className="container text-center mt-3 buttonsz-u">
                      <button
                        className="btn btn-primary btn-sm mx-2"
                        onClick={() => zoomIn()}
                      >
                        ZOOM IN
                      </button>
                      <button
                        className="btn btn-primary btn-sm mx-2"
                        onClick={() => zoomOut()}
                      >
                        ZOOM OUT
                      </button>
                    </div>
                  </div>
                </>
              )}
            </TransformWrapper>
          </div>
          <div className="comRight">
              <TransformWrapper
                defaultScale={1}
                defaultPositionX={0}
                defaultPositionY={0}
              >
                {({ zoomIn, zoomOut }) => (
                  <>
                    <div className="image-zoom">
                      <TransformComponent>
                        <div className="image-zoom">
                          <img
                            ref={replaceImageRef}
                            src={defaultAvatar}
                            alt=""
                            width="100%"
                            className="replaceImage"
                            style={{ transform: `scale(${scale})` }}
                          />
                        </div>
                      </TransformComponent>
                    </div>
                    <div className="controls " color="primary" class="right">
                      <div className="container text-center mt-3 buttonsz-u">
                        <button
                          className="btn btn-primary btn-sm mx-2"
                          onClick={() => zoomIn()}
                        >
                          ZOOM IN
                        </button>
                        <button
                          className="btn btn-primary btn-sm mx-2"
                          onClick={() => zoomOut()}
                        >
                          ZOOM OUT
                        </button>
                      </div>
                    </div>
                  </>
                )}
                  </TransformWrapper>
                </div>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalImageCompare;

