import React, { useEffect, useState } from 'react';
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "../Patient/PatientMenu";
import Breadcrumbs from "../../components/breadcrumbs";
import { useParams } from "react-router";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import ReactPaginate from "react-paginate";
import moment from 'moment';
import { ApiManager } from "../../servicemanager/apimanager";

const NotificationList = () => {

    const { _id_} = useParams();
    const token = localStorage.getItem("token");
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role
    const [notifications, setNotifications] = useState([])
    const [loader, setLoader] = useState(false)
    const [search, setSearch] = useState({})
    const [infoData, setInfoData] = useState({})
    const [selectedNotification, setSelectedNotification] = useState("Auto")   // Auto OR Rec
    const TOKEN = localStorage.getItem("token"); 

    const NotificationListApi = (params_string) => {
        setLoader(true)
        axios
        .get(`${__API_URL__}/api/appointments/get_auto_notifications/${_id_}/?${params_string}`, {
            headers: {
            Authorization: `Token ${token}`,
            },
        })
        .then((res) => {
            setNotifications(res.data)
            setLoader(false)
        });
    }

    const RecurringNotificationList = async (params_string) => {
        setLoader(true);
        try {
          const response = await ApiManager.get(`${__API_URL__}/api/appointments/get_notifications_logs/?patientId=${_id_}&${params_string}`, TOKEN);
          setNotifications(response.data)
          setLoader(false);
        } catch (error) {
          console?.error(error);
          return []
        }
      };

    const SinglePatient = () => {
        axios
        .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
            headers: {
            Authorization: `Token ${token}`,
            },
            
        })
        .then((res) => {
            setInfoData(res.data)
        });
    }

    useEffect(() => {
        NotificationListApi("")
        SinglePatient()
    }, [])

    useEffect(() => {
        let params_string = ""
        for (const key in search) {
            params_string += `${key}=${search[key]}&`
        }
        if (params_string != "") {
            if (selectedNotification === 'Auto') {
                NotificationListApi(params_string)
            } else if (selectedNotification === 'Rec') {
                RecurringNotificationList(params_string)
            }
        }
    }, [search])

    useEffect(() => {
        if (selectedNotification === "Auto") {
            NotificationListApi("")
        } else if (selectedNotification === "Rec") {
            RecurringNotificationList()
        }
    }, [selectedNotification])

    return (
        <>
            <NavBar active="patient" />
            <div className="container-fluid">
                {(role !== "CRO Admin" && role !== 'Cro_coordinator' && role !== 'Data Manager') && <>
                    <Breadcrumbs B='Auto Notifications Log'
                    F={infoData?.first_name}
                    L={infoData?.last_name}
                    E={infoData?.email}
                    P={infoData?.phone}
                    participant_id={infoData?.participant_id}
                    age={infoData?.age}
                    birth_date={infoData?.birth_date}
                    ></Breadcrumbs>
                </>}
                <div className="patient_table patient_photos">
                    <PatientMenu active="notifications" data={infoData}/>
                    <div className="tableHeader">
                        <div className="row">
                            <div className="col-md-6">
                                <button type="button" class={selectedNotification === 'Auto' ? "btn btn-primary btn-sm" : "btn"} onClick={() => setSelectedNotification('Auto')}>
                                    Auto Notifications
                                </button>
                                <button type="button" class={selectedNotification === 'Rec' ? "btn btn-primary btn-sm" : "btn"} onClick={() => setSelectedNotification('Rec')}>
                                    Recurrent Notifications
                                </button>
                            </div>
                        </div>
                        <table>
                            {loader ? (
                                <div className="loader">
                                    <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                                </div>
                            ) : <>
                                <thead className="caretOrder">
                                    <tr>
                                        {
                                            selectedNotification === 'Auto' && <>
                                                <th>
                                                    <select className="form-control" name="category" value={search?.category} onChange={(e) => setSearch((prev) => ({...prev, category: e.target.value}))}>
                                                        <option value=''>Category</option>
                                                        <option value='GENERAL_MESSAGE'>GENERAL MESSAGE</option>
                                                        <option value='TRIAL_VISIT_NOTIFICATION'>TRIAL VISIT NOTIFICATION</option>
                                                    </select>
                                                </th>
                                            </>
                                        }
                                        <th>
                                            {
                                                selectedNotification === 'Auto' ? <>
                                                    <select className="form-control" name="type" value={search?.type} onChange={(e) => setSearch((prev) => ({...prev, type: e.target.value}))}>
                                                        <option value=''>Type</option>
                                                        <option value='INAPP'>IN APP</option>
                                                        <option value='SMS'>SMS</option>
                                                        <option value='EMAIL'>EMAIL</option>
                                                    </select>
                                                </> : 
                                                "Type"
                                            }
                                        </th>
                                        {
                                            selectedNotification === 'Auto' && <>
                                                <th>
                                                    <select className="form-control" name="status" value={search?.sent} onChange={(e) => setSearch((prev) => ({...prev, sent: e.target.value}))}>
                                                        <option value=''>Send Status</option>
                                                        <option value='True'>True</option>
                                                        <option value='False'>False</option>
                                                    </select>
                                                </th>
                                            </>
                                        }
                                        <th>Sent DateTime</th>
                                        {
                                            selectedNotification === 'Auto' &&
                                            <th>Description</th>
                                        }
                                        {
                                            selectedNotification === 'Rec' && <>
                                                <th>Trial Name</th>
                                            </>
                                        }
                                        {
                                            selectedNotification === 'Rec' && <>
                                                <th>Visit Number</th>
                                            </>
                                        }
                                    </tr>

                                </thead>
                                
                                <tbody>
                                    {
                                        notifications.results?.map((notification) => (
                                            <tr>
                                                {
                                                    selectedNotification === "Auto" && <>
                                                        <td>{notification.notification_type}</td>
                                                    </>
                                                }
                                                <td>{selectedNotification === 'Auto' ? notification.type : "Recurrent"}</td>
                                                {
                                                    selectedNotification === "Auto" && <>
                                                        <td>{notification.sent ? "True" : "False"}</td>
                                                    </>
                                                }
                                                <td>{moment(`${notification.created_at}Z`).format("YYYY-MM-DD hh:mm A")}</td>
                                                {
                                                    selectedNotification === "Auto" && <>
                                                        <td>{notification.description}</td>
                                                    </>
                                                }
                                                {
                                                    selectedNotification === "Rec" && <>
                                                        <td>{notification.trial?.name}</td>
                                                    </>
                                                }
                                                {
                                                    selectedNotification === "Rec" && <>
                                                        <td>{notification.visit_number}</td>
                                                    </>
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </>
                            }
                        </table>
                        <div className="tableHeader">
                            <div className="row">
                                <div className="col-md-6">
                                <p>
                                    Showing {notifications ? notifications.results?.length : 0} out
                                    of {notifications ? notifications.count : 0}
                                </p>
                                </div>

                                <div className="reactPagination col-md-6">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next"
                                    onPageChange={(e) => setSearch((prev) => ({...prev, page: e.selected + 1}) )}
                                    pageRangeDisplayed={5}
                                    pageCount={Math.ceil(notifications.count) / 10}
                                    previousLabel="previous"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default NotificationList;
