import React, { useState, useEffect } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";

const Global_Severity_Score_General = (props) => {

const token = localStorage.getItem("token");
const user_data = JSON.parse(localStorage.getItem("user_data"));
const role = user_data.role
const setMessage = props.setMessage
const setTypeOfMessage = props.setTypeOfMessage
const [trialType, setTrialType] = useState();
let disable=false
const [selectedBlock, setSelectedBlock] = useState({})
const [scoreOption, setScoreOption] = useState([])
const user = useUser();
const OTHER_CHOICES = [
    { label: '0: None', value: 'None' },
    { label: '1: Mild ', value: 'Mild' },
    { label: '2: Moderate ', value: 'Moderate' },
    { label: '3: Severe ', value: 'Severe' },
    ]
    const handleGeneralScoreChange = (event) => {
        const { name, value } = event.target;
        const updatedGeneralScore = {
          ...selectedBlock.general_score,
          [name]: value,
        };
        setSelectedBlock(prevSelectedBlock => ({
          ...prevSelectedBlock,
          general_score: updatedGeneralScore
        }));
      };
      
    const AllGlobalSeverityGeneral = () => {
        const createData = {
          'patient_id': props?.patient_id,
          'visit_number':props?.visit_number,
          'sessionId': props.selectedSessionId,
          ...selectedBlock?.general_score
        }
        axios
          .patch(`${__API_URL__}/api/ecrf/update_general_main/${props.trialId}/`, createData, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((res) => {
            props.setModalVisitChangeShow(false)
            props.setHasChanged(false)
            setSelectedBlock(res.data);
            setMessage("Block updated successfully");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        }).catch((err) => {
            if(err == 'Error: Request failed with status code 401'){
            user.actions.logout()
            }
            setMessage("Something Went Wrong");
            setTypeOfMessage("success");
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
            }, 100);
            setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
            }, 3000);
        });
      };
      const getTrialTypes = () => {
        axios
            .get(`${__API_URL__}/api/photos/get_trial_types/`,{
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                res = res.data
                setTrialType(res);
            });
        }
        const getScoreOption = () => {
            axios
            .get(`${__API_URL__}/api/ecrf/get_score_options/${props?.trialId}/`, {
                headers: {
                        Authorization: `Token ${token}`,
                    },
                })
                .then((res) => {
                    res = res.data
                    setScoreOption(res);
                });
            }
      const getGeneralMain = () => {
        axios
            .get(`${__API_URL__}/api/ecrf/get_general_main/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                res = res.data
                setSelectedBlock(res);
            });
        }
        useEffect(() => {
            if((props?.patient_id && props?.photo_session)){
            getTrialTypes()
            getGeneralMain()
        }
        }, [])
        useEffect(()=>{
            if (selectedBlock?.general_score?.trial_type !== undefined && props?.patient_id){
                getScoreOption()
            }
        },[selectedBlock])
        if (role === 'CRO Admin' && !selectedBlock?.general_score?.trial_type) {
            const updatedGeneralScore = {
              ...selectedBlock.general_score,
              trial_type: true,
            };
            setSelectedBlock(prevSelectedBlock => ({
              ...prevSelectedBlock,
              general_score: updatedGeneralScore
            }));
        }
            return <>
                    {
                        role==='CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined) ?
                            disable=true:''
                    }
                    <fieldset disabled={disable}>
                    {
                         (props.photo_session || props.patient_id == undefined ) ?
                         <>
                                       {
                                        (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                                            <div className="col-md-12 text-end">
                                                <button onClick={() => AllGlobalSeverityGeneral()} className='btn btn-primary'>Update</button>
                                            </div>
                                        )
                                     }
                        <div className="col-md-9">
                            <label>Trial Type</label>
                            <div className="col-md-4 mb-3">
                                <select
                                    className="form-control"
                                    name="trial_type"
                                    value={selectedBlock?.general_score?.trial_type}
                                    onChange={(e) => handleGeneralScoreChange(e)}
                                >
                                    <option value="">Select</option>
                                    {trialType?.map((type) => (
                                        <option value={type.id} key={type.id}>
                                            {type.title}
                                        </option>
                                    ))}
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div
                                className="d-flex justify-content-center mx-1 my-3"
                                style={{ marginTop: "1em" }}
                            >
                                {selectedBlock?.general_score?.trial_type && selectedBlock?.general_score?.trial_type !== "Other" && (
                                    <>
                                        <div className="col-md-4 mb-3">
                                            <label>TLE:</label>
                                            <select
                                                className="form-control"
                                                name="tle_score"
                                                value={selectedBlock?.general_score?.tle_score}
                                                style={{ borderWidth: "1px" }}
                                                onChange={(e) => handleGeneralScoreChange(e)}
                                            >
                                                <option value="" defaultValue>Select</option>
                                                {scoreOption?.map((item) => (item?.name?.includes("Target Lesion Erythema Assessment") && <>
                                                {
                                                    item?.option?.map((opt) => {
                                                    return <>
                                                                <option value={opt.title}>{opt.title}</option>
                                                            </>
                                                            })
                                                            }
                                                </>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label>TLI:</label>
                                            <select
                                                className="form-control"
                                                name="tli_score"
                                                value={selectedBlock?.general_score?.tli_score}
                                                style={{ borderWidth: "1px" }}
                                                onChange={(e) => handleGeneralScoreChange(e)}
                                            >
                                            <option value="" defaultValue>Select</option>
                                                {scoreOption?.map((item) => (item?.name?.includes("Target Lesion Improvement Assessment") && <>
                                                {
                                                    item?.option?.map((opt) => {
                                                    return <>
                                                                <option value={opt.title}>{opt.title}</option>
                                                            </>
                                                            })
                                                            }
                                                </>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label>TLS:</label>
                                            <select
                                                className="form-control"
                                                name="tls_score"
                                                value={selectedBlock?.general_score?.tls_score}
                                                style={{ borderWidth: "1px" }}
                                                onChange={(e) => handleGeneralScoreChange(e)}
                                            >
                                               <option value="" defaultValue>Select</option>
                                                {scoreOption?.map((item) => (item?.name?.includes("Target Lesion Severity Assessment") && <>
                                                {
                                                item?.option?.map((opt) => {
                                                return <>
                                                            <option value={opt.title}>{opt.title}</option>
                                                        </>
                                                        })
                                                        }
                                                        </>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                )}
                            </div>
                            {selectedBlock?.general_score?.trial_type === "Other" && (
                                <div className="col-md-4 mb-3">
                                    <select
                                        className="form-control"
                                        name="other_score"
                                        style={{ borderWidth: "1px" }}
                                        value={selectedBlock?.general_score?.other_score}
                                        onChange={(e) => handleGeneralScoreChange(e)}
                                    >
                                        <option value="" selected>Select</option>
                                        {OTHER_CHOICES.map((other) => (
                                            <option value={other.value}>{other.label}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
    </>:'Visit is not started'
}
</fieldset>
    </>
}
export default Global_Severity_Score_General;