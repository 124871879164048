import React, { useState, useEffect } from "react";
import NavBar from "../../components/navBar/navBar";
import "bootstrap/dist/css/bootstrap.min.css";
import { __API_URL__ } from "../../SERVER_URL";
import { toast, ToastContainer } from "react-toastify";
import ReportColumns from "../../components/AdhocReport/ReportColumns";
import CustomToolbar from "../../components/AdhocReport/CustomToolbar";
import { ApiManager } from "../../servicemanager/apimanager";
import ReportDropdown from "../../components/AdhocReport/ReportDropdown";
import axios from "axios";
import useUser from "../../hooks/user";
import { SurveyPDF } from "survey-pdf";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Select from "react-select";

const AdHocReport = () => {
  const [allInvestigators, setAllInvestigators] = useState([]);
  const [AllCroData, setAllCroData] = useState([]);
  const [AllTrialData, setAllTrialData] = useState([]);
  const [AllSubjectEmail, setAllSubjectEmail] = useState([]);
  const [AllSubjectId, setAllSubjectId] = useState([]);
  const [AllPhotoData, setAllPhotoData] = useState([]);
  const [blocksData, setBlocskData] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [ecrfData, setEcrfData] = useState([]);
  const [specialData, setSpecialData] = useState([]);
  const [visitReportData, setVisitReportData] = useState([]);
  const [siteIdData, setsiteIdData] = useState([]);
  const [participantIdData, setParticipantIdData] = useState([]);
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const [Loader, setLoader] = useState(true);
  const [showError, setShowError] = useState(false);
  const [reportData, setReportData] = useState([]);
  const TOKEN = localStorage.getItem("token");
  const user = useUser();
  const token = localStorage.getItem("token");
  const [
    filterConfigurationLastStateSaved,
    setFilterConfigurationLastStateSaved,
  ] = useState(true);
  const [trialChanged, setTrialChanged] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsLoadingButton, setIsLoadingButton] = useState(false);
  const [IsLoadingDownloadButton, setIsLoadingDownloadButton] = useState(false);
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false);
  const [reportName, setReportName] = useState("");
  const [IsDisabledDownload, setIsDisabledDownload] = useState(false);
  const [reportTableConfigurations, setReportTableConfigurations] = useState({
    noOfRows: 300,
  });
  const [trialDetails, setTrialDetails] = useState({
    investigators: [],
    cro: [],
    trials: [],
    subject_emails: [],
    participant_number: [],
    subject_id_super: [],
    visit_status: [],
    blocks: [],
    block_name: [],
    site_number: [],
    visit: [],
    activity_type: [],
    selected_report: "",
  });
  const [data, setData] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: reportTableConfigurations.noOfRows,
  });
  <CustomToolbar
    reportTableConfigurations={reportTableConfigurations}
    setReportTableConfigurations={setReportTableConfigurations}
    filterConfigurationLastStateSaved={filterConfigurationLastStateSaved}
  />;

  const handleRefresh = () => {
    window.location.reload();
  };
  const onSelectedReportChange = (e) => {
    const { name, value } = e.target;
    setTrialDetails((trialDetails) => ({
      ...trialDetails,
      [name]: value,
    }));
  };
  //conditions
  if (
    user_data.role === "Investigator" ||
    user_data.role === "Sub_investigator" ||
    user_data.role === "Inv_coordinator"
  ) {
    trialDetails.investigators = [allInvestigators[0]];
  }
  if (
    user_data.role === "CRO Admin" ||
    user_data.role === "Cro_coordinator" ||
    user_data.role === "Data Manager"
  ) {
    trialDetails.cro = [AllCroData[0]];
  }
  //APIS
  const AllInvestigatorsData = () => {
    setLoader(true);
    const InvestigatorParams = {
      trial_Ids: trialDetails?.trials,
    };
    axios
      .post(
        `${__API_URL__}/api/photos/report_investigatorslist`,
        InvestigatorParams,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setAllInvestigators(res.data);
        setLoader(false);
      });
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: "185px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "185px",
    }),
  };

  const onChange = (field, newValue, actionMeta) => {
    let updatedValue;
    switch (actionMeta.action) {
      case "remove-value":
        updatedValue = trialDetails[field].filter(
          (v) => v.value !== actionMeta.removedValue.value
        );
        break;
      case "pop-value":
        break;
      case "clear":
        updatedValue = trialDetails[field].filter((v) => v.isFixed);
        break;
      case "select-option":
        if (field === "trials") {
          const latestChangedObject = newValue.find(
            (option) =>
              !trialDetails[field]?.some((v) => v.value === option.value)
          );
          updatedValue = latestChangedObject ? [latestChangedObject] : [];
        } else {
          updatedValue = newValue;
        }
        break;
      default:
        return;
    }
    setTrialDetails((trial) => ({
      ...trial,
      [field]: updatedValue,
    }));
  };

  const REPORT_TYPE = [
    { value: "key_value", label: "Ecrf Report Key Value" },
    { value: "key_column", label: "Ecrf Report Key as Column" },
  ];
  if (trialDetails?.selected_report === "ECRF") {
    REPORT_TYPE.push({
      value: "filled_block",
      label: "Ecrf Filled Block Report",
    });
  }
  const AllCroList = () => {
    const CroParams = {
      trial_Ids: trialDetails?.trials,
    };
    setLoader(true);
    axios
      .post(`${__API_URL__}/api/photos/report_crolist`, CroParams, {
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
      })
      .then((res) => {
        setAllCroData(res.data);
        setLoader(false);
      })
      .catch(() => {});
  };

  const AllPhotoType = () => {
    const PhotoParams = {
      trial_Ids: trialDetails?.trials,
    };
    setLoader(true);
    axios
      .post(`${__API_URL__}/api/photos/report-photo-types/web/`, PhotoParams, {
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
      })
      .then((res) => {
        setAllPhotoData(res.data);
        setLoader(false);
      })
      .catch(() => {});
  };
  const AllSubjectEmailList = () => {
    const BackendParams = {
      trial_Ids: trialDetails?.trials,
    };
    setLoader(true);
    axios
      .post(`${__API_URL__}/api/photos/report_patientlist/`, BackendParams, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAllSubjectEmail(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console?.error(err);
        return [];
      });
  };
  const AllSubjectIdList = () => {
    const BackendParams = {
      trial_Ids: trialDetails?.trials,
    };
    setLoader(true);
    axios
      .post(`${__API_URL__}/api/photos/report_subjectid_list/`, BackendParams, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAllSubjectId(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console?.error(err);
        return [];
      });
  };

  const GetReportFileName = () => {
    const trialId =
      trialDetails.trials && trialDetails.trials.length > 0
        ? trialDetails.trials[0]?.id
        : null;
    const siteId =
      trialDetails.site_number && trialDetails.site_number.length > 0
        ? trialDetails.site_number[0]?.value
        : 999;

    let blockName = null;
    if (trialDetails.blocks && trialDetails.blocks.length === 1) {
      blockName = trialDetails.blocks[0]?.label;
    }

    const urlParams = {
      trial: trialId,
      site_id: siteId,
      report_type: trialDetails?.selected_report,
      blocks: blockName ? blockName : "", // Append block name only if it exists
    };

    axios
      .get(`${__API_URL__}/api/photos/create_report_filename/`, {
        params: urlParams,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setReportName(res.data?.file_name);
      });
  };
  const getTrialsList = async () => {
    setLoader(true);
    try {
      const response = await ApiManager.get(
        `${__API_URL__}/api/photos/report_trials`,
        TOKEN
      );
      setAllTrialData(response.data);
      setLoader(false);
    } catch (error) {
      console?.error(error);
      return [];
    }
  };
  const Blocks = () => {
    const GetTrialId = {
      trials: trialDetails?.trials,
      visits: trialDetails?.visit,
      special_visit_number:
        trialDetails?.selected_report === "UNSCHEDULED EVENT REPORT"
          ? -2
          : trialDetails?.selected_report === "ADVERSE EVENT REPORT"
          ? -1
          : 0,
    };
    axios
      .post(`${__API_URL__}/api/ecrf/get_allblocks/`, GetTrialId, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        const oldBlocks = res.data.old_blocks.map((block) => ({
          ...block,
          isNew: false,
        }));
        const newBlocks = res.data.new_blocks.map((block) => ({
          ...block,
          isNew: true,
        }));
        setBlocskData([...oldBlocks, ...newBlocks]);
        setTrialChanged(true);
      })
      .catch(() => {});
  };
  const GetAllVisits = () => {
    const GetTrialId = {
      trials: trialDetails?.trials,
      special_visit_value:
        trialDetails?.selected_report === "UNSCHEDULED EVENT REPORT"
          ? -2
          : trialDetails?.selected_report === "ADVERSE EVENT REPORT"
          ? -1
          : 0,
    };
    axios
      .post(`${__API_URL__}/api/ecrf/get_allvisits/`, GetTrialId, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setVisitData(res.data);
      })
      .catch(() => {});
  };
  const AllSiteIds = () => {
    const SiteParams = {
      trial_Ids: trialDetails?.trials,
    };
    setLoader(true);
    axios
      .post(`${__API_URL__}/api/photos/site_id`, SiteParams, {
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
      })
      .then((res) => {
        setsiteIdData(res.data);
        setLoader(false);
      })
      .catch(() => {});
  };

  const AllParticipantIds = () => {
    const BackendParams = {
      trial_Ids: trialDetails?.trials,
    };
    setLoader(true);
    axios
      .post(`${__API_URL__}/api/photos/participant_id/`, BackendParams, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setParticipantIdData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console?.error(err);
        return [];
      });
  };

  const getDownloadDataView = (e) => {
    setIsLoadingButton(true);
    if (!trialDetails?.selected_report) {
      toast.error("Select a Type of Report!");
      setIsLoadingButton(false);
      return;
    }
    if (
      (trialDetails.selected_report === "GENERAL REPORT" ||
        trialDetails.selected_report === "POEM REPORT" ||
        trialDetails.selected_report === "SCORES" ||
        trialDetails.selected_report === "ECRF" ||
        trialDetails.selected_report === "PHOTO REPORT" ||
        trialDetails.selected_report === "VIDEO REPORT" ||
        trialDetails.selected_report === "ADVERSE EVENT REPORT" ||
        trialDetails.selected_report === "UNSCHEDULED EVENT REPORT" ||
        trialDetails.selected_report === "VISIT REPORT") &&
      !trialDetails?.trials?.length
    ) {
      toast.error("Please Select Trial First.");
      setIsLoadingButton(false);
      return;
    }
    let url;
    switch (trialDetails.selected_report) {
      case "GENERAL REPORT":
        url = `api/photos/download_data/`;
        break;
      case "POEM REPORT":
        url = `api/photos/download_poem_data/`;
        break;
      case "SCORES":
        url = `api/photos/score_report`;
        break;
      case "ECRF":
        url = `api/ecrf/download_ecrf_data/`;
        break;
      case "PHOTO REPORT":
        url = `api/photos/download_photo_data/`;
        break;
      case "VIDEO REPORT":
        url = `api/photos/download_video_data/`;
        break;
      case "VISIT REPORT":
        url = `api/photos/download_visit_report/`;
        break;
      case "ADVERSE EVENT REPORT":
        url = `api/ecrf/download_ecrf_data/`;
        break;
      case "UNSCHEDULED EVENT REPORT":
        url = `api/ecrf/download_ecrf_data/`;
        break;
    }
    axios
      .post(`${__API_URL__}/${url}`, trialDetails, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setReportData(res.data.results);
        if (res.data && res.data.results && res.data.results.length > 0) {
          setData(res.data);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch(() => {
        toast.error("Something Went Wrong!");
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
    setLoader(false);
  };
  useEffect(() => {
    AllSubjectEmailList();
    if (role === "Super Admin") {
      AllSubjectIdList();
    }
    getTrialsList();
    AllParticipantIds();
    AllInvestigatorsData();
    AllCroList();
    GetAllVisits();
    AllSiteIds();
    if (
      trialDetails.selected_report === "SCORES" ||
      trialDetails.selected_report === "PHOTO REPORT"
    ) {
      AllPhotoType();
    }
  }, [trialDetails?.trials]);

  useEffect(() => {
    if (
      trialDetails.selected_report === "SCORES" ||
      trialDetails.selected_report === "PHOTO REPORT"
    ) {
      AllPhotoType();
    }
  }, [trialDetails.selected_report]);

  useEffect(() => {
    if (trialDetails.trials && trialDetails.trials.length > 0) {
      GetReportFileName();
    }
  }, [
    trialDetails.selected_report,
    trialDetails.trials,
    trialDetails.site_number,
    trialDetails.blocks,
  ]);

  useEffect(() => {
    if (trialDetails?.selected_report === "ECRF") {
      Blocks();
    }
  }, [trialDetails.selected_report, trialDetails?.trials, trialDetails?.visit]);
  useEffect(() => {
    if (trialDetails?.selected_report === "VIDEO REPORT") {
      AllSiteIds();
    }
  }, [trialDetails.selected_report]);

  useEffect(() => {
    if (
      trialDetails.selected_report === "ECRF" ||
      trialDetails.selected_report === "PHOTO REPORT"
    ) {
      setTrialDetails((prevTrialDetails) => ({
        ...prevTrialDetails,
        trials: [],
        visit: [],
        subject_emails: [],
      }));
    }
  }, [trialDetails.selected_report]);

  useEffect(() => {
    if (trialChanged) {
      setTrialDetails((prev) => ({
        ...prev,
        blocks: blocksData,
      }));
    }
  }, [trialChanged, blocksData]);

  useEffect(() => {
    if (reportData.length > 0) {
      setIsDisabledDownload(false);
    } else {
      setIsDisabledDownload(true);
    }
  }, [reportData]);

  useEffect(() => {
    setReportData((prevReportData) => []);
  }, [trialDetails.selected_report]);

  useEffect(() => {
    if (
      trialDetails.selected_report === "ADVERSE EVENT REPORT" ||
      trialDetails.selected_report === "UNSCHEDULED EVENT REPORT"
    ) {
      Blocks();
      GetAllVisits();
    }
  }, [trialDetails.selected_report, trialDetails?.trials, trialDetails?.visit]);

  const DownloadEcrfReportExtra = () => {
    if (trialDetails.selected_report === "ECRF" && !trialDetails.reportType) {
      toast.error("Select a Report Type");
    } else if (
      trialDetails?.reportType?.value === "filled_block" &&
      trialDetails.participant_number.length === 0
    ) {
      toast.error("Select atleast one participant ID");
      return;
    } else if (trialDetails.reportType?.value !== "filled_block") {
      setIsLoadingDownloadReport(true);
      axios
        .post(`${__API_URL__}/api/ecrf/download_data/`, trialDetails, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setEcrfData(res.data);
          setIsLoadingDownloadReport(false);
          window.open(res.data.link, "_blank");
        })
        .catch(() => {
          setIsLoadingDownloadButton(false);
          toast.error("Something Went Wrong");
        });
    } else {
      setIsLoadingDownloadReport(true);
      axios
        .post(
          `${__API_URL__}/api/ecrf/download_ecrf_filled_data/`,
          trialDetails,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((res) => {
          const filledSurveyData = res?.data;
          savePdf(filledSurveyData);
          setIsLoadingDownloadReport(false);
        })
        .catch(() => {
          setIsLoadingDownloadReport(false);
          toast.error("Something Went Wrong");
        });
    }
  };

  const DownloadVisitReport = () => {
    setIsLoadingDownloadButton(true);
    axios
      .post(`${__API_URL__}/api/photos/download_visit_report/`, trialDetails, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        const visitReportData = res?.data?.results || [];
        const visitReportLink = res?.data?.link;
        setVisitReportData(visitReportData);
        setIsLoadingDownloadButton(false);
        if (visitReportLink) {
          // Opening the link in a new tab if available
          window.open(visitReportLink, "_blank");
        } else {
          toast.error("No link available for download");
        }
      })
      .catch(() => {
        setIsLoadingDownloadButton(false);
        toast.error("Something Went Wrong");
      });
  };

  const savePdf = async function (surveyData) {
    const removeVisibilityConditions = (element) => {
      if (element.type === "panel" && element.elements) {
        return {
          ...element,
          elements: element.elements.map(removeVisibilityConditions),
        };
      } else if (element.type === "matrix" && element.columns) {
        return {
          ...element,
          columns: element.columns.map((column) => ({
            ...column,
            visibleIf: undefined,
          })),
          rows: element.rows.map((row) => ({
            ...row,
            visibleIf: undefined,
          })),
          visibleIf: undefined,
        };
      } else if (
        element.type === "matrixdynamic" ||
        element.type === "matrixdropdown"
      ) {
        return {
          ...element,
          columns: element.columns.map((column) => ({
            ...column,
            visibleIf: undefined,
          })),
          visibleIf: undefined,
        };
      } else {
        return { ...element, visibleIf: undefined };
      }
    };

    const formatElementValue = (element) => {
      if (typeof element === "object" && element !== null) {
        if (element.value && element.value !== "undefined") {
          return element.value;
        } else if (element.title) {
          return element.title;
        } else if (element.name) {
          return element.name;
        } else {
          return "No value available";
        }
      }
      return String(element);
    };

    const formatElements = (element) => {
      if (element.type === "panel" && element.elements) {
        return {
          ...element,
          elements: element.elements.map(formatElements),
        };
      } else if (element.type === "matrix" && element.columns) {
        return {
          ...element,
          columns: element.columns.map((column) => ({
            ...column,
            title: formatElementValue(column),
          })),
          rows: element.rows.map((row) => ({
            ...row,
            text: formatElementValue(row),
          })),
        };
      } else if (
        element.type === "matrixdynamic" ||
        element.type === "matrixdropdown"
      ) {
        return {
          ...element,
          columns: element.columns.map((column) => ({
            ...column,
            title: formatElementValue(column),
          })),
          rows: element.rows
            ? element.rows.map((row) => ({
                ...row,
                value: formatElementValue(row),
              }))
            : [],
        };
      } else {
        return {
          ...element,
          value: formatElementValue(element),
        };
      }
    };

    const ecrfReport = {};

    surveyData?.forEach((survey) => {
      const sessionId = survey.session_id;

      if (!ecrfReport[sessionId]) {
        ecrfReport[sessionId] = {
          pages: [],
          answers: {},
          visitName: survey.visit_name,
        };
      }

      survey.surveyJson?.pages?.forEach((page) => {
        const modifiedPage = {
          ...page,
          elements: page.elements
            ?.map(removeVisibilityConditions)
            .map(formatElements),
        };
        ecrfReport[sessionId].pages.push({
          ...modifiedPage,
          title: survey.surveyJson?.title,
        });
      });

      ecrfReport[sessionId].answers = {
        ...ecrfReport[sessionId].answers,
        ...survey.answer_json_obj,
      };
    });

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");

    const reportName = [...new Set(reportData.map((item) => item.name))];
    const reportParticipantNumbers = [
      ...new Set(reportData.map((item) => item.participant_number)),
    ];
    const reportSiteIds = [...new Set(reportData.map((item) => item.site_id))];

    const zip = new JSZip();
    const pdfFiles = [];

    for (const sessionId of Object.keys(ecrfReport)) {
      const { pages, answers, visitName } = ecrfReport[sessionId];

      const exportToPdfOptions = {
        haveCommercialLicense: true,
      };

      const surveyPdf = new SurveyPDF({ pages }, exportToPdfOptions);
      surveyPdf.data = answers;

      const isFilledBlockReport = REPORT_TYPE.some(
        (report) => report.value === "filled_block"
      );

      let customFileName;

      if (isFilledBlockReport) {
        customFileName = `${year}${month}${day}T${hour}${minute}${second}_ECRF(PDF)_${reportName}_${reportSiteIds}_${reportParticipantNumbers}_${visitName}.pdf`;
      } else {
        customFileName = `${year}${month}${day}T${hour}${minute}${second}_${reportName}__Appiell_${reportParticipantNumbers}__ECRF_REPORT_${reportSiteIds}.xlsx`;
      }
      surveyPdf.onRenderFooter.add((pageIndex, canvas) => {
        const createdAtDate = `${year}${month}${day}T${hour}${minute}${second}`;
        const footerText = `Trial: ${reportName} TimeStamp: ${createdAtDate} Site Id: ${reportSiteIds} Subject ID: ${reportParticipantNumbers} Visit Name: ${visitName}`;

        canvas.drawText({
          text: footerText,
          fontSize: 10,
          isBold: true,
        });
      });

      const surveyPdfBlob = await surveyPdf.raw("blob");
      pdfFiles.push({ name: customFileName, blob: surveyPdfBlob });
    }
    pdfFiles.forEach((file) => {
      zip.file(file.name, file.blob);
    });

    const customZipFileName = `${year}${month}${day}T${hour}${minute}${second}_${reportName}_${reportSiteIds}_${reportParticipantNumbers}_ECRF PDF REPORT.zip`;

    const zipData = await zip.generateAsync({ type: "blob" });
    saveAs(zipData, customZipFileName);
  };

  const DownloadEcrfSpecialReport = () => {
    if (
      trialDetails.selected_report === "ADVERSE EVENT REPORT" &&
      trialDetails.selected_report === "UNSCHEDULED EVENT REPORT" &&
      !trialDetails.reportType
    ) {
      toast.error("Select a Report Type");
    } else {
      setIsLoadingDownloadButton(true);
      axios
        .post(`${__API_URL__}/api/ecrf/download_data/`, trialDetails, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setSpecialData(res.data);
          setIsLoadingDownloadButton(false);
          window.open(res.data.link, "_blank");
        })
        .catch(() => {
          setIsLoadingDownloadButton(false);
          toast.error("Something Went Wrong");
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <NavBar active="adhoc-report" />
      <div className="container-fluid">
        <div className="row patient_table">
          <div className="col-md-12">
            <div className="tableHeader">
              <div className="row">
                <div className="col-md-6">
                  <h5> Report Generation</h5>
                </div>
                <div className="container col-md-6 d-flex justify-content-end">
                  <div className="row">
                    <div className="col-sm">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleRefresh}
                      >
                        Refresh
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <label> Type of Report* </label>
          <select
            value={trialDetails?.selected_report}
            name="selected_report"
            className="basic-multi-select form-control"
            onChange={onSelectedReportChange}
            required
            aria-invalid={showError ? "true" : "false"}
            aria-describedby="selected-report-error"
          >
            <option value="">Select</option>
            <option value="GENERAL REPORT">General Report</option>
            <option value="POEM REPORT">Poem Report</option>
            <option value="SCORES">Score Report</option>
            <option value="ECRF">Ecrf Report</option>
            <option value="UNSCHEDULED EVENT REPORT">
              Unscheduled Visit Report
            </option>
            <option value="ADVERSE EVENT REPORT">Adverse Event Report</option>
            <option value="VISIT REPORT">Visit Report</option>
            <option value="PHOTO REPORT">APP Photo Report</option>
            <option value="VIDEO REPORT">APP Video Report</option>
          </select>
        </div>
        {Loader ? (
          <div className="loader">
            <img src="https://i.gifer.com/Yb3A.gif" alt="" />
          </div>
        ) : (
          <>
            <ReportDropdown
              trialDetails={trialDetails}
              setTrialDetails={setTrialDetails}
              blocksData={blocksData}
              visitData={visitData}
              AllTrialData={AllTrialData}
              AllPhotoData={AllPhotoData}
              allInvestigators={allInvestigators}
              AllCroData={AllCroData}
              AllSubjectEmail={AllSubjectEmail}
              AllSubjectId={AllSubjectId}
              siteIdData={siteIdData}
              participantIdData={participantIdData}
              user_data={user_data}
              getDownloadDataView={getDownloadDataView}
              DownloadEcrfReportExtra={DownloadEcrfReportExtra}
              DownloadEcrfSpecialReport={DownloadEcrfSpecialReport}
              DownloadVisitReport={DownloadVisitReport}
              IsLoadingButton={IsLoadingButton}
              setIsLoadingButton={setIsLoadingButton}
              IsLoadingDownloadButton={IsLoadingDownloadButton}
              setIsLoadingDownloadButton={setIsLoadingDownloadButton}
              toast={toast}
              IsDisabledDownload={IsDisabledDownload}
              REPORT_TYPE={REPORT_TYPE}
              isLoadingDownloadReport={isLoadingDownloadReport}
              setIsLoadingDownloadReport={setIsLoadingDownloadReport}
            />
            <ReportColumns
              CustomToolbar={CustomToolbar}
              reportName={reportName}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              setFilterConfigurationLastStateSaved={
                setFilterConfigurationLastStateSaved
              }
              reportData={reportData}
              setReportData={setReportData}
              data={data}
              setData={setData}
              Loader={Loader}
              setLoader={setLoader}
              trialDetails={trialDetails}
              reportTableConfigurations={reportTableConfigurations}
              setReportTableConfigurations={setReportTableConfigurations}
              IsLoading={IsLoading}
              setIsLoading={setIsLoading}
              role={role}
              toast={toast}
              IsDisabledDownload={IsDisabledDownload}
            />
          </>
        )}
      </div>
    </>
  );
};
export default AdHocReport;
