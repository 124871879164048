import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "../Patient/PatientMenu";
import Breadcrumbs from "../../components/breadcrumbs";
import { Card } from "@mui/material";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import { useParams } from "react-router";

const EconsentDetails = () => {

  const { _id_, caseId } = useParams();
  const token = localStorage.getItem("token");
  const [eConsent, seteConsent] = useState({});
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const [infoData, setInfoData] = useState({})
  const role = user_data.role
  const [Loader, setLoader] = useState(true);

  const getSingleEconsent = () => {
    setLoader(true);
    axios
      .get(`${__API_URL__}/api/photos/get_econsents/${caseId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setLoader(false);
        seteConsent(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }

  const SinglePatient = () => {
    axios
    .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
        headers: {
        Authorization: `Token ${token}`,
        },
        
    })
    .then((res) => {
        setInfoData(res.data)
    });
}

  useEffect(() => {
    getSingleEconsent();
    SinglePatient();
  }, [])

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <div className="patient_table patient_photos">
          <Breadcrumbs B='Econsent'
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
          ></Breadcrumbs>
          <PatientMenu active="econsent" data={infoData}/>
          {Loader ? (
              <div className="loader">
                <img src="https://i.gifer.com/Yb3A.gif" alt="" />
              </div>
            ) : (
              <>
            <Card className="col-md-12" >
            <div className="container">
            <h4 className="my-5">eConsent</h4>

              <h4 className="my-5"></h4> 
                {
                  eConsent?.action ?

              <>
              <div class="form-group">
                <label for="title">Consent Title</label>
                <input type="email" class="form-control" id="title" aria-describedby="emailHelp" value={eConsent?.consent?.title} disabled={true} />
              </div>
              <div class="form-group">
                <label for="content">Consent Content</label>
                <input type="email" class="form-control" id="content" aria-describedby="emailHelp" value={eConsent?.consent?.content} disabled={true} />
              </div>
              <div class="form-group mb-4">
                <label for="action">Action</label>
                <input type="email" class="form-control" id="action" aria-describedby="emailHelp" value={eConsent?.action} disabled={true} />
              </div>
              </>

        :
           <div className="App my-4"> 
            eConsent not available
            </div>

                }

            </div>
            </Card>
            </>)}
        </div>
      </div>
    </>
  )
}
export default EconsentDetails;

