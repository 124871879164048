import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";

const FilterPatientPopUp = (props) => {
  const navigate = useNavigate();

  const routeTo = (id) => {
    const link = "/patient/" + id + "/ecrf";
    const newTab = window.open(link, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      navigate(link);
    }
  };
  return (
    <>
      <Modal size="lg" show onHide={() => props.setFilterPatientPopUp(false)} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
            <Grid xs={12} md={6}>
              <div className="table-responsive" style={{ overflow: 'hidden', maxHeight: '100%', maxWidth: '100%' }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                    <th style={{ height: 'auto', maxWidth: '50px', textAlign: 'center' }}>
                      <b>Patients</b>
                    </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.filterPatient.map((item, index) => (
                          <tr>
                            <td
                              style={{ height: 'auto', textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => routeTo(item.id)}
                            >
                              {item.participant_id || '-'}
                            </td>
                          </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Grid>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterPatientPopUp;
