import { Axios } from "axios";
import { createContext, useContext } from "react";
import useUser from "./user";

let UtitlityContext = createContext(null);


export const Utitlity=({children})=>{
    let {setLoading,customalert} = useUser();
    let axios = new Axios({
        baseURL:'https://staging.appiell.ai/api/',
        headers:{'Content-Type':'application/json'},
    });

    axios.interceptors.request.use((req)=>{
        
        setLoading(true);
        return req;
    },(err)=>{
        setLoading(false);
        return Promise.reject(err);
    });
    axios.interceptors.response.use((resp)=>{
        setLoading(false);
        return  JSON.parse(resp.data||"");
    },(err)=>{
        setLoading(false);
        return Promise.reject(err);
    });



    const ApiManager = {
        get:(url)=>{
          return  axios.get(url)
        },
        put:(url,data)=>{
            
            return axios.put(url,JSON.stringify(data));
        },
        post:(url,data)=>{
            return axios.post(url,JSON.stringify(data));
        },
        patch:(url,data)=>{
            return axios.patch(url,JSON.stringify(data));
        },
        delete:(url,data)=>{
            return axios.delete(url);
        },
    }

    const AlertManager = (message,type)=>{
        customalert.setAlert({isOpen:true,type:type,msg:message})
    }

    return(
        <UtitlityContext.Provider value={{ApiManager:ApiManager,AlertManager:AlertManager}}>
            {children}
        </UtitlityContext.Provider>
    )
}

export const useUtility = ()=> useContext(UtitlityContext);