import axios from "axios";
import useUser from "../../hooks/user";
import { __API_URL__ } from "../../SERVER_URL";

const TLS_FORM = (props) => {
    const selectedBlock = props.selectedBlock;
    const setSelectedBlock = props.setSelectedBlock;
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role
    const extra_photo = props.extra_photo
    const setHasChanged = props.setHasChanged;
    const patient_id = props.patient_id;
    const setMessage = props.setMessage
    const setTypeOfMessage = props.setTypeOfMessage
    const globalVisits = props.globalVisits
    const setModalVisitChangeShow = props.setModalVisitChangeShow
    const token = localStorage.getItem("token");
    const user = useUser();
    const visit_number = props.visit_number;

    const handleIsSeverityAssessed = (index) => {
      setSelectedBlock((prevSelectedBlocks) => {
        const updatedBlocks = [...prevSelectedBlocks];
        const selectedBlock = updatedBlocks[index];
        if (selectedBlock) {
          selectedBlock.is_severity_assessed = !selectedBlock.is_severity_assessed;
        }
        return updatedBlocks;
      });
      setHasChanged(true);
    };
  
    const handleFieldChange = (event, index, id) => {
      const { name, value } = event.target;
      setSelectedBlock(prevState => {
        const updatedBlock = { ...prevState[index], [name]: value };
        const updatedSelectedBlock = [...prevState];
        updatedSelectedBlock[index] = updatedBlock;
        return updatedSelectedBlock;
      });
      if (name === 'lesion_one_score' || name === 'lesion_two_score' || name === 'lesion_three_score') {
        setSelectedBlock(prevState => {
          const updatedSelectedBlock = [...prevState];
          updatedSelectedBlock[index][name] = value;
          updatedSelectedBlock[index][name+'_id'] = id;
          return updatedSelectedBlock;
        });
      }
      setHasChanged(true);
    };

    const AllVisitsData = (index) => {
      const updatedBlock = selectedBlock[index]
      const createData = {
        'patient_id': patient_id,
        'visit_number': visit_number,
        'sessionId': props?.selectedSessionId,
        ...updatedBlock
      }
      axios
        .patch(`${__API_URL__}/api/ecrf/update_severity/${props.trialId}/`, createData, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setSelectedBlock(res.data);
          setModalVisitChangeShow(false)
          setHasChanged(false)
          setMessage("Block updated successfully");
          setTypeOfMessage("success");
          setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
          }, 100);
          setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
          }, 3000);
        }).catch((err) => {
          if (err == 'Error: Request failed with status code 401') {
            user.actions.logout()
          }
          setMessage("Something Went Wrong");
          setTypeOfMessage("success");
          document.addEventListener("DOMContentLoaded", function () {
            var cusMessages = document.getElementById("cusMessages");
            if (cusMessages) {
              setTimeout(function () {
                cusMessages.style.right = "2rem";
              }, 100);
              setTimeout(function () {
                cusMessages.style.right = "-99rem";
              }, 3000);
            }
          });
        });
    };
    const isLesion1 = extra_photo.some(
      (photo) => photo.label === "Lesion 1" || photo.value === "Lesion 1"
    );
    const isLesion2 = extra_photo.some(
      (photo) => photo.label === "Lesion 2" || photo.value === "Lesion 2"
    );
    const isLesion3 = extra_photo.some(
      (photo) => photo.label === "Lesion 3" || photo.value === "Lesion 3"
    );

    return<>
    <div className="col-md-12">
    {(selectedBlock?.length === 0 || selectedBlock?.length === undefined) &&
            <>
                <div className="data-container" style={{ border: "2px solid #ccc", padding: "10px", margin: "10px" }}>
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="col-md-4 mb-3">
                            <label>Accessor First Name:</label>
                            <p style={{ marginLeft: "0em", border: "none" }}></p>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>Accessor Last Name:</label>
                            <p style={{ marginLeft: "0em", border: "none" }}></p>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>Accessor Email:</label>
                            <p style={{ marginLeft: "0em", border: "none" }}></p>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="row">
                            <div className="col-md-9">
                            <p> Was the target lesion severity assessed at this visit?</p>
                                <th scope="row">
                                </th>
                            </div>
                            <div className="col-md-1">
                                <p> Yes
                                    <input
                                        className="form-check-input mx-1"
                                        type="radio"
                                        value={true}
                                    />
                                </p>
                            </div>
                            <div className="col-md-1">
                                <p> No
                                    <input
                                        className="form-check-input mx-1"
                                        type="radio"
                                        value={false}
                                    />
                                </p>
                            </div>
                                <div className='col-md-6'
                                    style={{ marginLeft: "1em" }}
                                >
                                    <label> if No, specify: </label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        style={{ borderWidth: '1px' }}
                                        name='specify_severity_assessed'
                                        id="flexSwitchCheckDefault"
                                        >
                                    </input>
                                </div> 
                                <p> Using the following table, indicate the score that best describes the target lesion severity of each of the target lesions</p>
                            <div className="d-flex m-auto" style={{ marginTop: "1em" }}>
                                {extra_photo.includes("Lesion 1") &&
                                    <>
                                        <div className="col-md-4 mb-3">
                                            <label> Lesion One Score: </label>
                                            <select
                                                className="form-control"
                                                name="lesion_one_score"
                                            >
                                            </select>
                                        </div>
                                    </>
                                }
                                {extra_photo.includes("Lesion 2") &&
                                    <>
                                        <div className="col-md-4 mb-3">
                                            <label> Lesion Two Score: </label>
                                            <select
                                                className="form-control"
                                                name="lesion_two_score"
                                                style={{ borderWidth: "1px" }}
                                            >
                                            </select>
                                        </div>
                                    </>
                                }
                                {extra_photo.includes("Lesion 3") &&
                                    <>
                                        <div className="col-md-4 mb-3">
                                            <label> Lesion Three Score: </label>
                                            <select
                                                className="form-control"
                                                name="lesion_three_score"
                                                style={{ borderWidth: "1px" }}
                                            >
                                            </select>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>

}   
        {Array.isArray(selectedBlock) && selectedBlock.map((data, i) => {
        return (
                                  <>
                          <div key={`data_container_${i}`} className="data-container" style={{ border: "2px solid #ccc", padding: "10px", margin: "10px" }}>
                                {
                              (patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator') && <>
                                  <div className="col-md-12 text-end">
                                  <button onClick={() => AllVisitsData(i)} className='btn btn-primary'>Update</button>
                                  </div>
                              </>
                              }
                               <div className="d-flex flex-wrap justify-content-center">
                                <div className="col-md-4 mb-3">
                                  <label>Accessor First Name:</label>
                                  <p style={{ marginLeft: "0em", border: "none" }}>{data?.first_name}</p>
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Accessor Last Name:</label>
                                  <p style={{ marginLeft: "0em", border: "none" }}>{data?.last_name}</p>
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label>Accessor Email:</label>
                                  <p style={{ marginLeft: "0em", border: "none" }}>{data?.email}</p>
                                </div>
                              </div>
                            <div className='col-md-12'>
                              <div className="row">
                              <div className="col-md-9">
                              <p> Was the target lesion severity assessed at this visit?</p>
                                        <th scope="row">
                                        </th>
                              </div>
                              <div className="col-md-1">                                
                              <p> Yes
                                    <input
                                        className="form-check-input mx-1"
                                        type="radio"
                                        name={`Radio1${data?.id}`}
                                        checked = {data?.is_severity_assessed == true}
                                        id="radio1"
                                        value={true}
                                        onChange={(e) => handleIsSeverityAssessed(i)}
                                    />
                                    </p>
                               </div>
                               <div className="col-md-1">                           
                                <p> No
                                    <input
                                    className="form-check-input mx-1"
                                    type="radio"
                                    name={`Radio1${data?.id}`}
                                    checked = {data?.is_severity_assessed == false}
                                    id="radio1"
                                    value={false}
                                    onChange={(e) => handleIsSeverityAssessed(i)}
                                    />
                                </p>
                                </div>
                        { data?.is_severity_assessed === false ?
                    <div className='col-md-6'
                         style={{marginLeft: "1em"}} 
                    >
                        <label> if No, specify: </label>
                            <input 
                                    class="form-control" 
                                    type="text" 
                                    style={{ borderWidth:'1px'}}
                                    name='specify_severity_assessed' 
                                    id="flexSwitchCheckDefault"
                                    value={data?.specify_severity_assessed}
                                    onChange={(e) => handleFieldChange(e, i)}>
                            </input>
                    </div>:''}
                               
                                <p> Using the following table, indicate the score that best describes the target lesion severity of each of the target lesions</p>
                                <div className="d-flex justify-content-center" style={{ marginTop: "1em" }}>
                                  {isLesion1 &&
                                    <>
                                          <div className="col-md-4 mb-3">
                                          <label> Lesion One Score: </label>
                                            <select
                                              className='form-control'
                                              name='lesion_one_score'
                                              value={data?.lesion_one_score?.score}
                                              style={{ borderWidth: '1px' }}
                                              onChange={(e) => handleFieldChange(e, i, data?.lesion_one_score?.id)}
                                            >
                                             <option disabled={true} selected>Select</option>
                                                        {selectedBlock[0]?.option?.map((lesion) => (
                                                            <option value={lesion.title} key={lesion.id}>
                                                                {lesion.title}
                                                            </option>
                                                        ))}
                                            </select>
                                          </div>
                                    </>
                                  }
                                  {isLesion2 &&
                                    <>
                                          <div className="col-md-4 mb-3">
                                          <label> Lesion Two Score: </label>
                                            <select
                                              className='form-control'
                                              name='lesion_two_score'
                                              style={{ borderWidth: '1px' }}
                                              value={data?.lesion_two_score?.score}
                                              onChange={(e) => handleFieldChange(e, i, data?.lesion_two_score?.id)}
                                            >
                                              <option disabled={true} selected>Select</option>
                                                        {selectedBlock[0]?.option?.map((lesion) => (
                                                            <option value={lesion.title} key={lesion.id}>
                                                                {lesion.title}
                                                            </option>
                                                        ))}
                                            </select>
                                          </div>
                                    </>
                                  }
                                  {isLesion3 &&
                                    <>
                                          <div className="col-md-4 mb-3">
                                          <label> Lesion Three Score: </label>
                                            <select
                                              className='form-control'
                                              name='lesion_three_score'
                                              style={{ borderWidth: '1px' }}
                                              value={data?.lesion_three_score?.score}
                                              onChange={(e) => handleFieldChange(e, i,  data?.lesion_three_score?.id)}
                                            >
                                             <option disabled={true} selected>Select</option>
                                                        {selectedBlock[0]?.option?.map((lesion) => (
                                                            <option value={lesion.title} key={lesion.id}>
                                                                {lesion.title}
                                                            </option>
                                                        ))}
                                            </select>
                                          </div>
                                    </>
                                  }
                                </div>
                              </div>
                        
                        </div>
                  </div>
          </>
     );
                    })}
                    </div>
                    </>
                    }
                    export default TLS_FORM