import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import MessageBox from "../../components/Alert/MessageBox";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import Summary_Trial from "./Summary_Trial";
import AddNewTrial from "../../components/PopUp/AddNewTrial";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from 'react-paginate';

const Trials_List = () => {
    const user = useUser();
    const token = localStorage.getItem("token");
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const calculateStartIndex = () => (currentPage - 1) * pageItemCount;
    const calculateEndIndex = () => calculateStartIndex() + pageItemCount;
    const getCurrentPageTrials = () => filteredTrials.slice(calculateStartIndex(), calculateEndIndex());
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    const navigate = useNavigate();
    const { PatientData, PaginationData, Loader } = useFetch(
        `photos/trials_list_view`
    );
    const defaultTrialInfo = {
        "trial_custom_id": "",
        "name": "",
        "status": "",
        "investigators": []
    }
    const filteredTrials = (PatientData || []).filter(trial =>
        trial && trial.name && trial.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const [isEdit, setIsEdit] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [trialInfo, setTrialInfo] = useState(defaultTrialInfo);
    const [summaryModalShow, setSummaryModalShow] = useState(false);
    const [pageItemCount, setPageItemCount] = useState(25);
    const handleItemCount = (e) => {
        setPageItemCount(e.target.value);
    };
    const handleRowClick = (trial) => {
        if ((role === "Super Admin" || role === "CRO Admin" || role === "Cro_coordinator" || role === "Data Manager" || role === "Inv_coordinator" || role === 'Sub_investigator' || role === "Investigator")) {
            navigate("/summary_trial/" + trial.id)
        } else {
            navigate("/clinic_trials/" + trial.id)
        }
    }
    const handleModalHide = () => {
        setModalShow(false);
        setTrialInfo(defaultTrialInfo)
    }
    const [allInvestigators, setAllInvestigators] = useState([])
    const AllInvestigatorsData = () => {
        axios
            .get(`${__API_URL__}/api/investigatorsList/`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
            .then((res) => {
                let customData = []
                res.data.map((obj) => {
                    customData.push({
                        value: obj.email, label: obj.email
                    })
                })
                setAllInvestigators(customData);
            }).catch((err) => {
                if (err == 'Error: Request failed with status code 401') {
                    user.actions.logout()
                }
            });
    };
    useEffect(() => {
        AllInvestigatorsData();
    }, [])
    const handleAddTrial = () => {
        if (isEdit) {
            axios
                .patch(`${__API_URL__}/api/photos/edit_trial/${trialInfo.id}/`, trialInfo, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                })
                .then(() => {
                    toast.success("Edited Successfully");
                    setModalShow(false);
                    setTrialInfo(defaultTrialInfo);
                    window.location.reload();
                }).catch((err) => {
                    setModalShow(false);
                    setTrialInfo(defaultTrialInfo);
                    if (err == 'Error: Request failed with status code 401') {
                        user.actions.logout()
                    }
                    toast.error(err.response.data.message);
                });

        } else {
            axios
                .post(`${__API_URL__}/api/photos/add_trial/`, trialInfo, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                })
                .then((res) => {
                    toast.success("Added Successfully");
                    setModalShow(false);
                    setTrialInfo(defaultTrialInfo);
                    window.location.reload();
                }).catch((err) => {
                    setModalShow(false);
                    setTrialInfo(defaultTrialInfo);
                    if (err == 'Error: Request failed with status code 401') {
                        user.actions.logout()
                    }
                    toast.error(err.response.data.message);
                });
        }
    };
    return <>
        <ToastContainer />
        <MessageBox />
        <NavBar active="trials" />
        <div className="container-fluid">
            <div className="row patient_table patientList">
                <div className="col-md-12s">
                    <div className="tableHeader px-0">
                        <div className="d-flex">
                            <h5>Trials</h5>
                            <div style={{ display: "flex", marginTop: "8px", marginLeft: "auto" }}>
                                <div className="me-2">
                                    {
                                        ((role == "CRO Admin") || (role == "Cro_coordinator") || (role == "Data Manager")) && (role !== "Investigator") && (role !== "Inv_coordinator") && <>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm"

                                                onClick={() => { setModalShow(true); setIsEdit(false) }}
                                            >
                                                Add Trial
                                            </button>
                                        </>
                                    }
                                </div>
                                <select
                                    className="form-control mb-0 ml-2"
                                    value={pageItemCount}
                                    onChange={handleItemCount}
                                    style={{ width: "180px", height: "32px" }}
                                >
                                    {[10, 25, 50, 100].map((item) => (
                                        <option key={item} value={item}>
                                            Show {item}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table style={{ height: "60vh" }}>
                            {
                                Loader ? (
                                    <div className="loader">
                                        <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                                    </div>
                                ) : (
                                    <>
                                        <thead className="caretOrder">
                                            <tr>
                                                <th>Trial ID</th>
                                                <th style={{ "padding-top": "24px" }}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        style={{
                                                            width: role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' ? "110%" : "55%",
                                                            height: "30px",
                                                        }}
                                                        placeholder="Search by Trial Name"
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                    />
                                                </th>
                                                <th>Status</th>
                                                {
                                                    (role == 'CRO Admin') || (role == 'Cro_coordinator') || (role == 'Data Manager') ? <>
                                                        <th>Investigators</th>
                                                    </> : <>
                                                        <th>CRO Admin</th>
                                                    </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getCurrentPageTrials()?.length > 0 ? (
                                                getCurrentPageTrials()?.map((trial) => (
                                                    <tr onClick={() => handleRowClick(trial)} key={trial.id}>
                                                        <td>{trial.trial_custom_id}</td>
                                                        <td>{trial.name}</td>
                                                        <td>{trial.status}</td>
                                                        {(role === 'CRO Admin' || role === 'Cro_coordinator' || role == 'Data Manager') ? (
                                                            <td>{trial.investigator_details?.map((obj) => `${obj.email}, `)}</td>
                                                        ) : (
                                                            <td>{trial.cro_details?.email}</td>
                                                        )}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td style={{ maxWidth: '50px', textAlign: 'center' }} colSpan={6}>No Matching Trial Name Found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </>
                                )
                            }
                        </table>
                        <div className="reactPagination col-md-7">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next"
                                onPageChange={(selected) => handlePageChange(selected.selected + 1)}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                pageCount={Math.ceil(filteredTrials.length / pageItemCount)}
                                previousLabel="previous"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {summaryModalShow &&
            (
                <>
                    <Summary_Trial
                    />
                </>
            )
        }
        {modalShow && (
            <>
                <AddNewTrial
                    isEdit={isEdit}
                    trialInfo={trialInfo}
                    setTrialInfo={setTrialInfo}
                    handleModalHide={handleModalHide}
                    handleAddTrial={handleAddTrial}
                />
            </>
        )}
    </>
}
export default Trials_List;
