import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "./PatientMenu";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs";
import useFetch from "../../hooks/useFetch";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router";
import MessageBox from "../../components/Alert/MessageBox";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";

const Reward = () => {
  const { _id_} = useParams();
  const token = localStorage.getItem("token");
  const [infoData, setInfoData] = useState({})
  const { PatientData, Loader, PaginationData } = useFetch(`appiell/history_of_rewards/${_id_}/`);
  // const handlePageClick = (event) => {
  //   const no = event.selected + 1;
  //   PaginationData(`/api/patient/patient/all/activity/?PatientId=${_id_}&page=${no}`);
  // };

  const SinglePatient = () => {
    axios
    .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
        headers: {
        Authorization: `Token ${token}`,
        },
        
    })
    .then((res) => {
        setInfoData(res.data)
    });
}

useEffect(() => {
  SinglePatient()
}, [])

  return (
    <>
      <MessageBox msg="Reward Created!" />
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs B="Reward" 
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
        />
        <div className="patient_table">
          <PatientMenu active="reward" data={infoData}/>
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <h5>Rewards</h5>
                <h6>Total points : {PatientData?.total_points}</h6>
              </div>
              <div className="col-md-6 text-end">
                <Link to={"/patient/"+_id_+"/reward/create"}>
                <button type="button" class="btn btn-primary m-2 mt-3">
                  Create
                </button>
                </Link>
              </div>
            </div>
          </div>
          <table>
            {Loader ? (
              <div className="loader">
                <img src="https://i.gifer.com/Yb3A.gif" alt="" />
              </div>
            ) : (
              <>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Points</th>
                    <th>Reason</th>
                    <th>Assigner Email</th>
                    {/* <th>Delete</th> */}
                  </tr>
                </thead>
                <tbody>
                  {PatientData.data.length ? (
                    <>
                  {PatientData.data.map((row)=>(
                    <tr key={row.id}>
                    <td>{new Date(row.created_at+'Z').toDateString()},&nbsp;
                      {new Date(row.created_at+'Z').toLocaleTimeString()}</td>
                    <td>{row.type == "Redeemed"?"- ":""}{row.points}</td>
                    <td>{row.reason}</td>
                    <td>{row.admin.email}</td>
                    {/* <td><button
                              type="button"
                              class="btn btn-danger btn-sm m-2"
                            >
                              Delete
                            </button></td> */}
                  </tr>
                  ))}
                  </>
                  ):(
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  )}

                </tbody>
              </>
            )}
          </table>
          {/* <div className="reactPagination" style={{paddingBottom:"2px"}}>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(PatientData.count / 20)}
                previousLabel="previous"
              />
            </div> */}
        </div>
      </div>
    </>
  );
};

export default Reward;
