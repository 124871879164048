import axios from "axios"

// let axios = new Axios({
//     baseURL:'https://staging.appiell.ai/api/',
// })


export const ApiManager = {
    get:(url, token)=>{
      return  axios.get(url, {
        headers: {
            Authorization: `Token ${token}`
        },
    })
    },
    put:(url,data)=>{
        
        return axios.put(url,JSON.stringify(data));
    },
    post: (url, data, token) => {
      return axios.post(url, JSON.stringify(data), {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
    },
    patch: (url, data, token) => {
        return axios.patch(url, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
      },
    delete:(url,data)=>{
        return axios.delete(url);
    },
}