import React, { useEffect, useState } from "react";
import { __API_URL__ } from "../../SERVER_URL";

const Participant_Assessments_Pre_Injection = (props) => 
{
const user_data = JSON.parse(localStorage.getItem("user_data"));
const role = user_data.role
let diasble=false
                    return <>
                    {
                      (role==='CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ?
                       diasble=true:''
                    }
                    <fieldset disabled={diasble}>
                    {
                         (props.photo_session || props.patient_id == undefined ) ?
                         <>
                                <div className='row'>           
                                    <td> * Activate the participant questionnaires in Dacima (eCRF) or Appiell app and ask the participant to complete them prior to injection(s): </td>
                                    <th><small> ◦ Target Lesion time course</small></th>
                                    <th><small>  ◦ Target Lesion Pain Assessment</small></th>
                                    </div>
                                  </>:'Visit is not started'
                                  }
                                  </fieldset>
                                      </>
                                  }
export default Participant_Assessments_Pre_Injection;