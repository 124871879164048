import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
const MessagePopUp = (props) => {
return (
    <>
{
     <>
        <Modal size="md" show onHide={() => props.setPopMessage(false)}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Are You Sure?               
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
            <p className="text-center">
                {props.isTrialNotification
                    ? "You Want to Delete this Notification?"
                    : "You Want to Delete this File?"}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(e) => {props.setPopMessage(false)}}>No</Button>
                <Button variant="primary" onClick={props.confirmDelete}>Yes</Button>
            </Modal.Footer>
        </Modal>
    </>
}
</>
    )
}
export default MessagePopUp;