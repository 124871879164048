import React, { useState,useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { __API_URL__ } from "../../SERVER_URL";
import "react-datetime/css/react-datetime.css";

const ClinicAdmin = (props) => {
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const handleFieldChange = (e, fieldName) => {
    props.setClinicData({
      ...props.clinicData,
      [fieldName]: e.target.value,
    });
    const requiredFields = ["email", "confirm_email", "first_name", "last_name", "phone"];
    const isAllFilled = requiredFields.every((field) => props.clinicData[field]);
    setAllFieldsFilled(isAllFilled);
  };
  const handlePaste = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (role === "Super Admin" || role === "Clinic Admin") {
      props.GetClinics();
    }
  }, []);
  return (
    <>
      <div>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          size="md"
          centered
          show
          onHide={() => {
            props.clinicModalShow(false);
          }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Clinic Patient
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <div>
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                onChange={(e) => handleFieldChange(e, "email")}
                name="email"
                onPaste={handlePaste}
                value={props.clinicData?.email}
              ></input>
              <label>Confirm Email</label>
              <input
                type="email"
                className="form-control"
                onChange={(e) => handleFieldChange(e, "confirm_email")}
                name="confirm_email"
                onPaste={handlePaste}
                value={props.clinicData?.confirm_email}
              ></input>
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleFieldChange(e, "first_name")}
                name="first_name"
                value={props.clinicData?.first_name}
              ></input>
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleFieldChange(e, "last_name")}
                name="last_name"
                value={props.clinicData?.last_name}
              ></input>
              <label>Phone Number</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleFieldChange(e, "phone")}
                name="phone"
                value={props.clinicData?.phone}
              ></input>
              {role === "Super Admin" ? (
                <>
                  <label> Clinics: </label>
                  <select
                    className="form-control"
                    name="clinics"
                    value={props.clinicData?.clinics}
                    onChange={(e) => handleFieldChange(e, "clinics")}
                  >
                    <option value="" disabled={true} selected>
                      Select
                    </option>
                    {props.clinics.map((data) => (
                      <option value={data.value} key={data}>
                        {data.label}{" "}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                ""
              )}
              <div className="col-md-12" style={{ textAlign: "end" }}>
                <button
                  type="button"
                  className="btn btn-primary m-2"
                  onClick={() => props.AddClinicPatient()}
                  disabled={!allFieldsFilled} 
                >
                  Add
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ClinicAdmin;
