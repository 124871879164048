import React from 'react'
import ManualInvestigatorSIteId from '../Trials/ManualInvestigatorSIteId'
import MainAccordion from '../Accordion/accordion'

const Investigator_SiteId_Accordion = ({trialDetails, setChangeTrialSetting, investigatorChanged, setInvestigatorChanged, siteIdManual, setTrialDetails, setChangeSetting, setHasChanged, allInvestigators, onInvestigatorChange, handleBasicInfoUpdate, setInfoSettingChanged, AllInvestigatorsData, setAllInvestigators, isDrug}) => {

  return (
    <MainAccordion
    title="Sites & Investigators"
    data={
        <ManualInvestigatorSIteId 
        trialDetails={trialDetails}
        setTrialDetails={setTrialDetails}
        setChangeSetting={setChangeSetting}
        setHasChanged={setHasChanged}
        allInvestigators={allInvestigators}
        onInvestigatorChange={onInvestigatorChange}
        handleBasicInfoUpdate={handleBasicInfoUpdate}
        setChangeTrialSetting={setChangeTrialSetting}
        siteIdManual={siteIdManual}
        investigatorChanged={investigatorChanged}
        setInvestigatorChanged={setInvestigatorChanged}
        setInfoSettingChanged={setInfoSettingChanged}
        AllInvestigatorsData={AllInvestigatorsData}
        setAllInvestigators={setAllInvestigators}
        isDrug={isDrug}
        />}
    />
  )
}
export default Investigator_SiteId_Accordion