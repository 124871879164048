import React, {useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "./PatientMenu";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import Breadcrumbs from "../../components/breadcrumbs";
import MessageBox from "../../components/Alert/MessageBox";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router";

const PatientMessages = () => {
  const { _id_ } = useParams();
  const [isExist , setIsExist] = useState(false)
  const token = localStorage.getItem("token");
  const [infoData, setInfoData] = useState({})
  const { PatientData, CreateMessage, DeleteMessage, Loader ,handleRefresh } = useFetch(
    `patient/patient/all/msgs/?PatientId=${_id_}`
  );

  const [sendMessage, setsendMessage] = useState({
    patient: _id_,
    title: "",
    message: "",
    time: "",
    typeSms: "",
    typeApp: "",
    typeEmail: ""
  });
  const resetstate = () => {
    setsendMessage({
      ...sendMessage,
      title: "",
      message: "",
      time: "",
      typeSms: "",
      typeApp: "",
      typeEmail: ""
    })
    setIsExist(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setsendMessage((sendMessage) => ({
      ...sendMessage,
      [name]: value,
    }));
  };

  const CreateInstance = (e) => {
    e.preventDefault();
    CreateMessage(sendMessage);
  };

  const DeleteInstance = (e) => {
    var instance = "patient/patient/all/msgs/" + e.target.value + "/?PatientId=" + _id_;
    DeleteMessage(instance);
  };

  const PopupOpen = () =>{
    document.getElementById("messageSend").style.display = "block";
  }
  const ReplyPopupOpen = (id) =>{
    document.getElementById("messageSend").style.display = "block";
    const token = localStorage.getItem("token");
    axios.get(`${__API_URL__}/api/patient/patient/update_message_read_status/?message_id=${id} `, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
    });
  }
  const PopupClose = () =>{
    document.getElementById("messageSend").style.display = "none";
    resetstate()
  }
  const handleSchedule =() =>{
    setIsExist(true)
  }
  const handleNow =() => {
    setIsExist(false)
  }

  const SinglePatient = () => {
    axios
    .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
      headers: {
        Authorization: `Token ${token}`,
      },

    })
    .then((res) => {
      setInfoData(res.data)
    });
  }

  useEffect(() => {
    SinglePatient()
  }, [])
  return (
    <>
      <MessageBox msg="Message has been sent successfully" />
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs B="Messages"
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
        />
        <div className="patient_table">
          <PatientMenu active="messages" data={infoData}/>
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <h5>Patient Messages</h5>
              </div>
              <div className=" container col-md-6 d-flex justify-content-end">
                <div className="row">
                  <div className="col-sm">
                    <button type="button" class="btn btn-primary btn-sm m-2" onClick={PopupOpen}>
                      Message
                    </button>
                  </div>
                  <div className="col-sm">
                    <button type="button" class="btn btn-primary btn-sm m-2" onClick={handleRefresh}>Refresh</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table>
            { Loader ? (
              <div className="loader">
                <img src="https://i.gifer.com/Yb3A.gif" alt="" />
              </div>
            ) : (
              <>
                <thead>
                  <tr>
                    <th>MESSAGE</th>
                    <th>DATE & TIME</th>
                    <th>SEND</th>
                    <th>DELETE</th>
                  </tr>
                </thead>
                <tbody>
                  {PatientData.length ? (
                    <>
                      {PatientData.map((data) => (
                        <tr key={data.id}>
                          <td>{data.message}</td>
                          <td>
                            {new Date(data.created_at+'Z').toDateString()},&nbsp;
                            {new Date(data.created_at+'Z').toLocaleTimeString()}
                          </td>
                          <td>
                            <button type="button" class="btn btn-primary btn-sm m-2" onClick={() => ReplyPopupOpen(data.id)}>
                              Reply
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={DeleteInstance}
                              value={data.id}
                              class="btn btn-danger btn-sm m-2"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </table>

          <div id="messageSend" style={{ zIndex: '999999', marginTop: '8em'}}>
            {!Loader && (
              <form onSubmit={CreateInstance}>
                <h5>Send Message to Patient</h5>
                <label>Notification</label>
                <div className="form-check">
                  <input type="checkbox" name="typeApp" value="App" onChange={handleChange} className="form-check-input" />
                  <label className="form-check-label">In App</label>
                </div>
                <div className="form-check">
                  <input type="checkbox" name="typeSms" value="SMS" onChange={handleChange} className="form-check-input" />
                  <label className="form-check-label">SMS</label>
                </div>
                <div className="form-check">
                  <input type="checkbox" name="typeEmail" value="Email" onChange={handleChange} className="form-check-input" />
                  <label className="form-check-label">Email</label>
                </div>

                <label>Schedule Delivery Time</label>
                <div className="d-flex">
                  <div className="form-check">
                    <input type="radio" name="time" value="now" onChange={handleChange} onClick={handleNow} className="form-check-input" />
                    <label className="form-check-label">Now</label>
                  </div>
                  <div className="form-check mx-3">
                    <input type="radio" name="time" value="schedule" onClick={handleSchedule} className="form-check-input" />
                    <label className="form-check-label">Schedule</label>
                  </div>
                </div>

                {isExist && (
                  <div>
                    <label className="form-label">Schedule</label>
                    <input type="datetime-local" className="form-control" name="time" onChange={handleChange}/>
                  </div>
                )}
                <label>Title</label>
                <input type="text"
                  onChange={handleChange}
                  name="title"
                  className="form-control"
                />
                <label>Message</label>
                <textarea
                  onChange={handleChange}
                  name="message"
                  className="form-control"
                  rows="4"
                />
                <div className="d-flex justify-content-between mt-3">
                  <span className="btn btn-danger" onClick={PopupClose}>Close</span>
                  <button type="submit" className="btn btn-primary">Send</button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientMessages;
