import Modal from "react-bootstrap/Modal";
const QueryTablePopUp = (props) => {
  return (
    <>
      {
        <>
          <Modal
            size="xxl"
            show={props.showQueryTableModal}
            fullscreen={true}
            onHide={() => props.setShowQueryTableModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Query Table
              </Modal.Title>
            </Modal.Header>
            <br />
            <Modal.Body className="show-grid">{props.children}</Modal.Body>
          </Modal>
        </>
      }
    </>
  );
};
export default QueryTablePopUp;
