import React from 'react'
import TrialParticipantId from '../Trials/TrialParticipantId'
import MainAccordion from '../Accordion/accordion'

const TrialParticipantIdAccordion = ({trialDetails, setSiteIdManual, infoSettingChanged, setInfoSettingChanged,setTrialDetails, trialSetting, setHasChanged, hasChanged, setChangeTrialSetting, changeTrialSetting, handleBasicInfoUpdate, ParticipantIdChanged, setParticipantIdChanged}) => {

  return (
    <MainAccordion
    title="Participant ID Assignment"
    data={
        <TrialParticipantId 
        setChangeTrialSetting={setChangeTrialSetting}
        changeTrialSetting={changeTrialSetting}
        trialDetails={trialDetails}
        trialSetting={trialSetting}
        hasChanged={hasChanged}
        setHasChanged={setHasChanged}
        setTrialDetails={setTrialDetails}
        setSiteIdManual={setSiteIdManual}
        infoSettingChanged={infoSettingChanged}
        setInfoSettingChanged={setInfoSettingChanged}
        handleBasicInfoUpdate={handleBasicInfoUpdate}
        ParticipantIdChanged={ParticipantIdChanged}
        setParticipantIdChanged={setParticipantIdChanged}
        />}
    />
  )
}
export default TrialParticipantIdAccordion