import React from 'react'
import MainAccordion from '../Accordion/accordion'
import { Grid, TextField, InputLabel } from '@mui/material';

const VisitScheduleAccordion = ({ role, handleDaysChange, handleHoursChange, handleDaysChangeEnd, days, hours, endDays, handleHoursChangeEnd, endHours, number, baseline_name }) => {
    return (
        <MainAccordion
            title="Visit Schedule"
            isOpen={true}
            data={
                <>
                    <Grid container spacing={1}>
                        {(number !== 0 && number !== -3) ? (
                        <Grid item xs={6}>
                            <InputLabel htmlFor="visit-days">
                                {`Visit Schedule (Hour(s) after ${baseline_name || "Baseline Visit"})`}
                            </InputLabel>
                        </Grid>
                        ): 
                        <Grid item xs={6}>
                        <InputLabel htmlFor="visit-days">
                        Duration of Visit
                        </InputLabel>
                        </Grid>
                        }
                        {(number !== 0 && number !== -3) ? (
                        <Grid item xs={6}>
                            <InputLabel htmlFor="visit-hours">
                                Duration of Visit
                            </InputLabel>
                        </Grid>
                        ):
                        <Grid item xs={6}>
                        <InputLabel htmlFor="visit-hours">
                        </InputLabel>
                    </Grid>
                        }
                        {(number !== 0 && number !== -3) && (
                            <>
                        <Grid item xs={3}>
                            <TextField
                                id="filled-number"
                                label="Days"
                                type="number"
                                min="0"
                                disabled={role === 'Investigator' || role === 'Inv_coordinator'}
                                className="form-control"
                                onChange={handleDaysChange}
                                value={days}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                inputProps={{
                                    min: 0,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="filled-number"
                                label="Hours"
                                type="number"
                                min="0"
                                max="23"
                                disabled={role === 'Investigator' || role === 'Inv_coordinator'}
                                className="form-control"
                                onChange={handleHoursChange}
                                value={hours}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                inputProps={{
                                    min: 0,
                                    max: 23,
                                }}
                            />
                        </Grid>
                        </>
                        )}
                        {
                            <>
                                <Grid item xs={3}>
                                    <TextField
                                        id="filled-number"
                                        label="Days"
                                        type="number"
                                        min="0"
                                        disabled={role === 'Investigator' || role === 'Inv_coordinator'}
                                        className="form-control"
                                        onChange={handleDaysChangeEnd}
                                        value={endDays}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                        inputProps={{
                                            min: 0,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="filled-number"
                                        label="Hours"
                                        type="number"
                                        disabled={role === 'Investigator' || role === 'Inv_coordinator'}
                                        className="form-control"
                                        onChange={handleHoursChangeEnd}
                                        value={endHours}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                        inputProps={{
                                            min: 0,
                                            max: 23,
                                        }}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </>
            }
        />
    )
}
export default VisitScheduleAccordion