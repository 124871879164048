import NavBar from "../../components/navBar/navBar";
import PatientMenu from "../Patient/PatientMenu";
import Breadcrumbs from "../../components/breadcrumbs";
import { Card } from "@mui/material";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

const PoemDetail = () => {
  const { _id_, caseId } = useParams();
  const token = localStorage.getItem("token");
  const [questionList, setQuestionList] = useState([]);
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const [Loader, setLoader] = useState(true);
  const [infoData, setInfoData] = useState({})

  const getPoemList = () => {
    setLoader(true);
    axios
      .get(`${__API_URL__}/api/survey/get_question_answers/?PatientId=${_id_}&sessionId=${caseId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setQuestionList(res.data);
        setLoader(false);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }

  const SinglePatient = () => {
    axios
    .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
        headers: {
        Authorization: `Token ${token}`,
        },
        
    })
    .then((res) => {
        setInfoData(res.data)
    });
}

  useEffect(() => {
    getPoemList();
    SinglePatient()

  }, [])

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <div className="patient_table patient_photos">
            <Breadcrumbs B='POEM/ePRO/eDiary'
            F={infoData?.first_name}
            L={infoData?.last_name}
            E={infoData?.email}
            P={infoData?.phone}
            participant_id={infoData?.participant_id}
            age={infoData?.age}
            birth_date={infoData?.birth_date}
            ></Breadcrumbs>
          <PatientMenu active="poem" />
            <Card className="col-md-12" >
            <div className="container">
              <h4 className="my-5">Poems</h4>
              {Loader ? (
              <div className="loader">
                <img src="https://i.gifer.com/Yb3A.gif" alt="" />
              </div>
            ) : (
              <>
              {
                questionList.length > 0 ?
              <>
              {
                questionList.map((question) => {
                  return <>
                    <div className="row my-4">
                      <div className="col-md-12">
                        <label>Question</label>
                        <textarea
                          type="text"
                          value={question?.question}
                          disabled={true}
                          name="question"
                          className="form-control"
                        ></textarea>
                        </div>
                        {
                          question.is_multi_choice
                            ? (
                                <div className="col-md-12">
                                  <label>Answer</label>
                                  <textarea
                                    type="text"
                                    value={question?.answer?.map(answer_obj => answer_obj?.choice).join(', ')}
                                    name="answer"
                                    disabled={true}
                                    className="form-control"
                                  ></textarea>
                                </div>
                              )
                            : (
                                <div className="col-md-12">
                                  <label>Answer</label>
                                  <textarea
                                    type="text"
                                    value={question?.answer}
                                    name="answer"
                                    disabled={true}
                                    className="form-control"
                                  ></textarea>
                                </div>
                              )
                        }

                      { 
                      question.extra_text ?
                        <div className="col-md-12">
                          <label>Extra Text</label>
                          <textarea
                            type="text"
                            value={question?.extra_text}
                            name="extra_text"
                            disabled={true}
                            className="form-control"
                          ></textarea>
                        </div>
                    :''}
                    </div>

                  </>
                })
              }
              </> 
              : <p className="text-center">POEM not available</p>
            }
            </>
                  )}
            </div>
            </Card>
        </div>
      </div>
    </>
  )
}

export default PoemDetail;

