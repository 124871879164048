import React, { useState } from 'react';
import NavBar from '../components/navBar/navBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { __API_URL__ } from '../SERVER_URL';
import useUser from '../hooks/user';

const DeleteAccount = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('token');
  const email = localStorage.getItem('email');
  const user = useUser();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDeleteAccount = async () => {
    setLoading(true);

    axios
      .get(`${__API_URL__}/api/patient/auth/delete-account/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success('Your account has been deleted successfully.');
          user.actions.logout();
        } else {
          toast.error('Failed to delete account. Please try again.');
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  return (
    <>
      <ToastContainer />
      <NavBar active="home" />

      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ height: '50vh' }}
      >
        <div
          className="p-4 border rounded shadow"
          style={{
            width: '600px',
            textAlign: 'center',
            backgroundColor: '#fff',
          }}
        >
          <h1 style={{ marginBottom: '30px', fontWeight: 'bold', color: '#d32f2f' }}>
            Delete Your Account?
          </h1>

          <p style={{ marginBottom: '30px', fontSize: '16px', color: '#777' }}>
            <strong>Email:</strong> {email}
          </p>

          <Button
            onClick={handleOpen}
            style={{
              display: 'inline-block',
              padding: '12px 24px',
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#ffffff',
              backgroundColor: '#d32f2f',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
          >
            DELETE
          </Button>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will delete your account permanently. Are you sure you
            want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No, Keep My Account
          </Button>
          <Button
            onClick={handleDeleteAccount}
            color="error"
            autoFocus
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Yes, Delete My Account'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteAccount;
