import React from "react";
import logo from "../assets/img/smartTrail.svg"

const LoginHeader = () => {
    return(
        <div className="loginHeader">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <img src={logo} alt=""/>
                    </div>
                    {/* <div class="col-md-8">
                        <ul>
                            <li>HOME</li>
                            <li>PLATFORM</li>
                            <li>KEY FEATURES</li>
                            <li>NEWS</li>
                            <li>CONTACT</li>
                            <li>PRACTITIONERS</li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>
    )
}


export default LoginHeader;