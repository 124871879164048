import { useState, useEffect, useRef, React } from "react";
import { toast, ToastContainer } from "react-toastify";
import AddDrug from "../../components/Drug/AddDrug";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import ReactPaginate from "react-paginate";
import DeleteMessage from "../../components/Modals/DeleteMessage";
import DrugMin from "../../components/Drug/DrugMin";

const AllDrugKits = ({ setTypeOfMessage, setMessage, trial, manual_drug, unassign_drug_count, trialDetailApi }) => {
  const hiddenFileInput = useRef(null);

  const [drugKits, setDrugKits] = useState([]);
  const [addDrug, setAddDrug] = useState(false);
  const [deleteObj, setDeleteObj] = useState(false);
  const [miniDrug, setMiniDrug] = useState(false);
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const [pageItemCount, setPageItemCount] = useState(10);
  const [deleteObjid, setDeleteObjid] = useState(0);
  const [drugFile, setDrugFile] = useState("");
  const [newDrug, setNewDrug] = useState({
    is_real_drug: true,
  });
  const GetDrugKitsAPI = () => {
    axios
      .get(`${__API_URL__}/api/get_drug_kits/?trial=${trial}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setDrugKits(res.data);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
        }
      });
  };

  const handlePageClick = (event) => {
    const no = event.selected + 1;
    axios
      .get(`${__API_URL__}/api/get_drug_kits/?page=${no}&trial=${trial}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setDrugKits(res.data);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
        }
      });
  };

  const handleOnclick = (id) => {
    setDeleteObjid(id);
  };

  const handleUploadFile = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setDrugFile(selectedFile);
    }
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }

  };
  
  

  const confirmDelete = () => {
    if (deleteObjid) {
      axios
        .delete(`${__API_URL__}/api/delete_drug_kit/${deleteObjid}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then(() => {
          setDeleteObj(false);
          GetDrugKitsAPI();
          setMessage("Drug Deleted Successfully!");
          setTypeOfMessage("success");
          setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
          }, 100);
          setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
          }, 3000);
        })
        .catch((err) => {
          setMessage(err.response.data.message);
          setTypeOfMessage("error");
          setTimeout(() => {
            document.getElementById("cusMessages").style.right = "2rem";
          }, 100);
          setTimeout(() => {
            document.getElementById("cusMessages").style.right = "-99rem";
          }, 5000);
        });
    }
  };

  const getSingleDrug = () => {
    axios
      .get(`${__API_URL__}/api/get_single_drug/${deleteObjid}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setNewDrug(res.data);
      });
  };

  const UploadDrugFileApi = () => {
    const formData = new FormData();
    formData.append("filename", drugFile);
    formData.append("trail", trial);
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    fetch(`${__API_URL__}/api/upload_drug_file/`, requestOptions)
      .then((res) => {
        GetDrugKitsAPI();
        setDrugFile("");
        setMessage("Drug file uploaded Successfully!");
        trialDetailApi()
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setTypeOfMessage("error");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 5000);
      });
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };


  useEffect(() => {
    GetDrugKitsAPI();
  }, []);

  useEffect(() => {
    if (drugFile) {
      UploadDrugFileApi();
    }
  }, [drugFile]);

  useEffect(() => {
    if (deleteObjid) {
      getSingleDrug();
    } else {
      setNewDrug({ is_real_drug: true });
    }
  }, [deleteObjid]);

  return (
    <div>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row patient_table">
          <div className="col-md-12">

            <div className="tableHeader">
              <div className="row d-flex align-items-end">
                <div className="col-md-6">
                  <h5>Drugs</h5>
                </div>
                <div className="col-md-6 text-end d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary  btn-sm mx-2  mt-2"
                  onClick={() => GetDrugKitsAPI()}
                >
                  Refresh
                </button>
                  &nbsp;
                <button
                  type="button"
                  className="btn btn-primary btn-sm mx-2  mt-2"
                  onClick={() => setMiniDrug(true)}
                >
                  Drug Value (min-value)
                </button>
                  &nbsp;
              <>
            <button className="btn btn-primary  btn-sm mx-2  mt-2" onClick={handleClick}>
              Upload csv
            </button>
              <input
                type="file"
                id="file-input"
                style={{ display: "none" }}
                accept=".csv"
                onChange={(e) => handleUploadFile(e)}
                ref={hiddenFileInput}
              />
                  &nbsp;
                  <button
                  type="button"
                  className="btn btn-primary btn-sm mx-2 mt-2"
                  onClick={() => setAddDrug(true)}>Add Drug</button>
                        &nbsp;
                  </>
                </div>
              </div>
            </div>
              
            <div class="watermark">Remaining Drug kits {unassign_drug_count}</div>

            <table className="mx-4 my-5" style={{ height: "50vh" }}>
              {/* {Loader ? (
                <div className="loader">
                  <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                </div>
              ) : ( */}
              <>
                <thead className="caretOrder">
                  <tr>
                    <th>Name</th>
                    <th>Serial Number</th>
                    <th>Units/Dosage</th>
                    <th>Real Drug</th>
                    <th>Kit Id</th>
                    <th>Patient</th>
                    <th>Participant Id</th>
                    <th>Created at</th>
                    <th>Delete</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {drugKits?.results?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ whiteSpace: "pre-wrap" }}>{data?.name}</td>
                        <td style={{ whiteSpace: "pre-wrap" }}>
                          {data?.serial_number}
                        </td>
                        <td>{data?.dosage}</td>
                        <td>{data?.is_real_drug ? "True" : "False"}</td>
                        <td>{data?.custom_id ? data.custom_id : "-"}</td>
                        <td>{data?.patient ? data.patient : "-"}</td>
                        <td>{data?.participant_number ? data.participant_number : "-"}</td>
                        <td>
                          {new Date(data.created_at + "Z").toDateString()}
                          ,&nbsp;
                          {new Date(data.created_at + "Z").toLocaleTimeString()}
                          &nbsp;&nbsp;
                        </td>
                        {data.patient ? (
                          <span className="mx-3"></span>
                        ) : (
                          <td>
                            <a
                              onClick={() => {
                                handleOnclick(data.id);
                                setDeleteObj(true);
                              }}
                            >
                              <i
                                class="fa fa-trash mx-auto"
                                title="delete"
                                style={{ color: "inherit" }}
                                aria-hidden="true"
                              ></i>
                            </a>
                          </td>
                        )}

                        <td>
                          <a
                            onClick={() => {
                              handleOnclick(data.id);
                              setAddDrug(true);
                              getSingleDrug();
                            }}
                            type="button"
                            title="duplicate"
                          >
                            <i class="fa fa-copy"></i>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
              {/* )} */}
            </table>
          </div>
          <div className="reactPagination col-md-2 mx-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={10}
              pageCount={Math.ceil(drugKits?.count / pageItemCount)}
              previousLabel="previous"
            />
          </div>
        </div>
      </div>
      {addDrug && (
        <AddDrug
          addDrug={addDrug}
          setAddDrug={setAddDrug}
          setMessage={setMessage}
          setTypeOfMessage={setTypeOfMessage}
          trial={trial}
          GetDrugKitsAPI={GetDrugKitsAPI}
          setDeleteObjid={setDeleteObjid}
          deleteObjid={deleteObjid}
          newDrug={newDrug}
          setNewDrug={setNewDrug}
          manual_drug={manual_drug}
          trialDetailApi={trialDetailApi}
        />
      )}
      {miniDrug && (
        <DrugMin
          miniDrug={miniDrug}
          setMiniDrug={setMiniDrug}
          setMessage={setMessage}
          setTypeOfMessage={setTypeOfMessage}
          trial={trial}
        />
      )}
      {deleteObj && (
        <DeleteMessage
          deleteObj={deleteObj}
          setDeleteObj={setDeleteObj}
          confirmDelete={confirmDelete}
        />
      )}
    </div>
  );
};

export default AllDrugKits;
