import * as React from "react";
import { Link } from "react-router-dom";
import useUser from "../hooks/user";
import LoginHeader from "../components/loginHeader";
import LoginFooter from "../components/loginFooter";
import { toast, ToastContainer } from "react-toastify";

const ForgotPassword = () => {
  const [msg, setmsg] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");

  const user = useUser();

  const handleSubmit = (e) => {
    e.preventDefault();
    const re =
      /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
    if (email.match(re)) {
      setError("");
      user.actions.forgot_password(email);
      setTimeout(() => {
      console.log('Email sent')
        }, 1000);
    } else {
      toast.error('Invalid Email Address')
    }
  };

  return (
   <div style={{backgroundColor:"#fcf6f6", height:"100vh"}}>
     <LoginHeader />
     <ToastContainer/>
   <div className="loginBox">
     <h1>Forgot Password</h1>
     <form onSubmit={handleSubmit}>
       <input type="text" placeholder="Email" onChange={(e) => {setEmail(e.target.value);}} />
       <span style={{textAlign:"left",display:"block"}}>{msg}</span>
       <span style={{color:"red",textAlign:"left",display:"block"}}>{error}</span>
       <button type="submit">Send</button>
     </form>
   </div>
   <LoginFooter />
   </div>

    );
};

export default ForgotPassword;
