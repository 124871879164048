import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const ChangeLogTable = ({ changeLog, convertFirstLetterToCapital, role, handlePageClick, pageItemCount }) => {
  
  return (
    <>
    <table className="table table-bordered table-striped mb-0 table-sm">
      <div className="row">
        <div>
          <h6> Change Logs </h6>
        </div>
      </div>
      <thead>
        <tr>
          <th>Visit Name</th>
          <th>Block Name</th>
          {role !== "CRO Admin" && role !== "Cro_coordinator" && role !== "Data Manager" && <th>First Name</th>}
          {role !== "CRO Admin" && role !== "Cro_coordinator" && role !== "Data Manager" && <th>Last Name</th>}
          {role !== "CRO Admin" && role !== "Cro_coordinator" && role !== "Data Manager" && <th>Email</th>}
          <th>Description</th>
          <th>New Data</th>
          <th>Previous Data</th>
          <th>Trial</th>
          <th>Updated By</th>
          <th>Date / Time</th>
          <th>Role</th>
        </tr>
      </thead>
      <tbody>
        {changeLog?.results?.map((item, index) => (
          <tr key={index}>
            <td className="text-center">
              {item.visit_name && item.visit_name !== ""
                ? item.visit_name
                : parseInt(item?.visit_number) === 0
                ? "Baseline Visit"
                : parseInt(item?.visit_number) === -3
                ? "Screening Visit"
                : parseInt(item?.visit_number) === -4
                ? "Other Ecrfs"
                : parseInt(item?.visit_number) === -1
                ? "Adverse Event"
                : parseInt(item?.visit_number) === -2
                ? "Unscheduled Event"
                : parseInt(item?.visit_number)
                ? `Visit ${item?.visit_number}`
                : "-"}
            </td>
            <td style={{ whiteSpace: "pre-wrap" }}>{convertFirstLetterToCapital(`${item.block_name}`)}</td>
            {role !== "CRO Admin" && role !== "Cro_coordinator" && <td style={{ whiteSpace: "pre-wrap" }}>{convertFirstLetterToCapital(`${item.first_name}`)}</td>}
            {role !== "CRO Admin" && role !== "Cro_coordinator" && <td style={{ whiteSpace: "pre-wrap" }}>{convertFirstLetterToCapital(`${item.last_name}`)}</td>}
            {role !== "CRO Admin" && role !== "Cro_coordinator" && <td style={{ whiteSpace: "pre-wrap" }}>{convertFirstLetterToCapital(`${item.email}`)}</td>}
            <td style={{ whiteSpace: "pre-wrap" }}>{convertFirstLetterToCapital(`${item.description}`)}</td>
            <td style={{ whiteSpace: "pre-wrap" }}>{convertFirstLetterToCapital(`${item.new_data}`)}</td>
            <td style={{ whiteSpace: "pre-wrap" }}>{convertFirstLetterToCapital(`${item.previous_data}`)}</td>
            <td style={{ whiteSpace: "pre-wrap" }}>{item.trial}</td>
            <td style={{ whiteSpace: "pre-wrap" }}>{item.updated_by}</td>
            <td>
              {new Date(item.updated_at + "Z").toDateString()},&nbsp;
              {new Date(item.updated_at + "Z").toLocaleTimeString()}
            </td>
            <td style={{ whiteSpace: "pre-wrap" }}>
              {item.role
                ? item.role == "Inv_coordinator"
                  ? "CRC"
                  : item.role == "Cro_coordinator"
                  ? "Sub_CRO"
                  : item.role == "Sub_investigator"
                  ? "Sub_INV"
                  : item.role == "Investigator"
                  ? "INV"
                  : item.role
                : "-"}
            </td>
          </tr>
        ))}
      </tbody>
      <br></br>
      <br></br>
    </table>
    {/* Adding Pagination for Change Logs */}
    <div className="reactPagination col-md-6">
    <ReactPaginate
        breakLabel="..."
        nextLabel="next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={Math.ceil(changeLog?.count / pageItemCount)}
        previousLabel="previous"
    />
   </div>
   </>
  );
};

export default ChangeLogTable;
