import React from 'react';
import {FormControl, Select, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';

const CustomDensitySelector = (props) => {
  const densityOptions = [
    { label: 'Comfortable', value: 'comfortable' },
    { label: 'Compact', value: 'compact' },
    { label: 'Standard', value: 'standard' },
  ];

  const handleDensityChange = (event) => {
  props.setFilterConfigurationLastStateSaved(false)

    const newDensity = event.target.value;
    props.setPatientTableConfigurations((prevState) => ({
      ...prevState,
      density: newDensity
    }));
  };


  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Density</InputLabel>

      <Select
       style={{ height: '30px' }}
        value={props.patientTableConfigurations?.density} 
        onChange={handleDensityChange}
        label="Density"
        inputProps={{ 'aria-label': 'Density' }}
      >
        {densityOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomDensitySelector;

