import React, { useEffect, useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";
import { useParams } from "react-router";
import moment from 'moment';



const Birth_Control = (props) => 
{
const [consentFormOption, setConsentFormOption] = React.useState("list")
const token = localStorage.getItem("token");
const user = useUser();
const user_data = JSON.parse(localStorage.getItem("user_data"));
const role = user_data.role
const setMessage = props.setMessage
const setTypeOfMessage = props.setTypeOfMessage
let disable=false
const { _id_ } = useParams();
const [dateTime, changeDateTime] = useState();
const [selectedBlock, setSelectedBlock] = useState({})
const SPECIFY_POTENIAL = [
    { label: '0: Postmenopausal', value: 'Postmenopausal' },
    { label: '1: Without a uterus and/or both ovaries', value: 'Without a uterus and/or both ovaries'},
    { label: '2: Surgically sterile', value: 'Surgically sterile'},
    ]

const METHOD_CHOICES = [
    { label: '0: Hormonal methods or intrauterine device in use >90 days', value: 'Hormonal method' },
    { label: '1: Barier Methods plus spermicide in use at least 14 days prior', value: 'Barier method'},
    { label: '2: Vasectomized partner', value: 'Vasectomized partner'},
    { label: '3: Abstinence', value: 'Abstinence'},
    ]

const UPT_RESULTS = [
    { label: '0: Negative', value: 'Negative' },
    { label: '1: Positive', value: 'Positive'},
    ]
const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedBlock((selectedBlock) => ({
    ...selectedBlock,
    [name]: value,
    }));
    props.setHasChanged(true)
  };
  const handleIsDisable = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_disable: e.target.checked,
    });
    props.setHasChanged(true)
  };
  const handleIsPotential = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_potential: e.target.checked,
    });
    props.setHasChanged(true)
  };
  const handleIsPartnerMethod = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      is_partner_method: e.target.checked,
    });
    props.setHasChanged(true)
  };
  const handleUrinePregnacyTest = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      urine_pregnancy_test: e.target.checked,
    });
    props.setHasChanged(true)
  };
  const handleExpiryDate = (e) => {
    setSelectedBlock({
      ...selectedBlock,
      upt_expiry_date: e.target.value,
    });
  props.setHasChanged(true)
  };

  const GetSelectedBlockData = () => {
    axios
    .get(`${__API_URL__}/api/ecrf/get_upt/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
    headers: {
    Authorization: `Token ${token}`,
    },
    })
    .then((res) => {
      setSelectedBlock(res.data);
    }).catch((err) => {
    if (err == 'Error: Request failed with status code 401') {
    }
    });
    }

  const AllVisitsData = () => {
      const createData = {
              'visit_number':props?.visit_number,
              'patient_id': props?.patient_id,
              ...selectedBlock,
              'is_potential': selectedBlock?.is_potential === null ? false : selectedBlock?.is_potential,
              'specify_potential': selectedBlock?.specify_potential,
              'is_applicable': selectedBlock?.is_applicable === null ? false : selectedBlock?.is_applicable,
              'urine_pregnancy_test':selectedBlock?.urine_pregnancy_test  === null ? false : selectedBlock?.urine_pregnancy_test,
              'upt_result':selectedBlock?.upt_result === null ? 'Positive' : selectedBlock?.upt_result,
              'upt_expiry_date': selectedBlock?.upt_expiry_date? moment(selectedBlock.upt_expiry_date).format('YYYY-MM-DD'): moment().format('YYYY-MM-DD'),
              'lot_number':selectedBlock?.lot_number === null ? 0 : selectedBlock?.lot_number,
              'is_disable':selectedBlock?.is_disable  === null ? false : selectedBlock?.is_disable,
              'sessionId': props.selectedSessionId,
          }
          axios
            .patch(`${__API_URL__}/api/ecrf/update_upt/${props.trialId}/`,createData, {
              headers: {
                Authorization: `Token ${token}`,
              },
            })
            .then((res) => {
              setSelectedBlock(res.data);
              props.setModalVisitChangeShow(false)
              props.setHasChanged(false)
              props.globalVisits(false);
              setMessage("Block updated successfully");
              setTypeOfMessage("success");
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "2rem";
              }, 100);
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "-99rem";
              }, 3000);
          }).catch((err) => {
              if(err == 'Error: Request failed with status code 401'){
              user.actions.logout()
              }
              setMessage(err.response.data.message);
              setTypeOfMessage("success");
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "2rem";
              }, 100);
              setTimeout(() => {
              document.getElementById("cusMessages").style.right = "-99rem";
              }, 3000);
          });
        };

        const GetBirthControl = () => {
          axios
          .get(`${__API_URL__}/api/ecrf/get_upt/?id=${selectedBlock?.id}`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((res) => {
            setSelectedBlock(res.data);
          }).catch((err) => {
            if(err == 'Error: Request failed with status code 401'){
              user.actions.logout()
              }
          });
        };


        if (selectedBlock.is_applicable == true && selectedBlock.is_disable == false) {
          selectedBlock.is_disable = true;
        }
        
        React.useEffect(() => {
          if((props?.patient_id && props?.photo_session)){
            GetSelectedBlockData();
          }
        }, [])

      useEffect(() => {
        props.setSelectedVisit((prev) => ({
          ...prev,
          'upt': selectedBlock,
        }));
      }, [selectedBlock]);

                    return <>
                    {
                           (role ==='CRO Admin' || role==='Cro_coordinator' || role==='Data Manager' || (props.patient_id === undefined)) ? 
                              disable=true : ""
                           
                          }
                          <fieldset disabled={disable}>
                    {
                        (props.photo_session || props.patient_id == undefined ) ?
                        <>
                            {
                                (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator') && selectedBlock.new_block === false && (
                                <div className="col-md-12 text-end">
                                    <button onClick={() => AllVisitsData()} className='btn btn-primary'>Update</button>
                                </div>
                                )
                          }       
                
                              <div className="col-md-10">
                                  <div className='row'>
                                        <div className='col-md-8'>
                                          <label> Not Applicable: </label>
                                          <input 
                                                      style={{marginLeft: "1em"}}
                                                      className="form-check-input mx-3" 
                                                      type="checkbox" 
                                                      name="is_disable"
                                                      disabled={false} 
                                                      checked = {selectedBlock?.gender === 'male' || false}
                                                      value={selectedBlock?.is_disable} 
                                                      /> 
                                          </div>
                      {
                      selectedBlock?.gender == 'female' ?
                      <>
                                        <div className='col-md-8'>
                                          <label> Is the participant of childbearing potential: </label>
                                          <input 
                                                      style={{marginLeft: "1em"}}
                                                      className="form-check-input mx-3" 
                                                      type="checkbox" 
                                                      name="is_potential" 
                                                      checked = {selectedBlock?.is_potential || false}
                                                      value={selectedBlock?.is_potential} 
                                                      onChange={(e) => handleIsPotential(e)}/> 
                                          </div>
                                          {selectedBlock?.is_potential === false ?
                                              <div className='col-md-5'>
                                                  <label> Specify Potential: </label>
                                                      <select 
                                                        className='form-control'
                                                        name = 'specify_potential'
                                                        value={selectedBlock?.specify_potential}
                                                        style={{ borderWidth:'1px'}}
                                                        onChange={(e) => handleFieldChange(e)}
                                                        >
                                                      <option value="" selected>Select</option>
                                                      {
                                                        SPECIFY_POTENIAL.map((specify_potential) => {
                                                          return <>
                                                            <option value ={specify_potential.value}>{specify_potential.label} </option>
                                                              </>
                                                            })
                                                        }
                                                  </select>
                                                </div>:''}
                                        <div className='col-md-8'>
                                          <label>If yes, is the participant and/or their partner using a reliable method of birth control? </label>
                                          <input 
                                                      style={{marginLeft: "1em"}}
                                                      className="form-check-input mx-3" 
                                                      type="checkbox" 
                                                      name="is_partner_method" 
                                                      checked = {selectedBlock?.is_partner_method || false}
                                                      defaultChecked={true}
                                                      value={selectedBlock?.is_partner_method} 
                                                      onChange={(e) => handleIsPartnerMethod(e)}/> 
                                          </div>
                                          <div className='col-md-5'>
                                      {selectedBlock.is_partner_method === true ?
                                          <>
                                              <label> if yes, specify the method used below: </label>
                                                    <select 
                                                          className='form-control'
                                                          name = 'specify_method'
                                                          value={selectedBlock.specify_method}
                                                          style={{ borderWidth:'1px'}}
                                                          onChange={(e) => handleFieldChange(e)}
                                                          >
                                                                              <option value="" selected>Select</option>
                                                                      {
                                                          METHOD_CHOICES.map((specify_method) => {
                                                              return <>
                                                              <option value ={specify_method.value}>{specify_method.label} </option>
                                                              </>
                                                          })
                                                      }
                                                  </select>
                                                  </>:''}
                                              </div>
                                                <div className='col-md-8'>
                                                    <label> Urine Pregnancy Test: </label>
                                                    <input 
                                                            style={{marginLeft: "1em"}}
                                                            className="form-check-input mx-3" 
                                                            type="checkbox" 
                                                            name="urine_pregnancy_test" 
                                                            checked = {selectedBlock?.urine_pregnancy_test || false}
                                                            value={selectedBlock?.urine_pregnancy_test} 
                                                            onChange={(e) => handleUrinePregnacyTest(e)}/> 
                                                </div>
                                    {selectedBlock.urine_pregnancy_test === true ?
                                            <>
                                                <div className='col-md-6'>
                                                    <label> Specify brand of test: </label>
                                                    <input 
                                                            style={{ borderWidth:'1px'}}
                                                            class="form-control"
                                                            type="text" 
                                                            name='specify_urine_pregnancy' 
                                                            id="flexSwitchCheckDefault" 
                                                            value={selectedBlock.specify_urine_pregnancy} 
                                                            onChange={handleFieldChange}
                                                    ></input>
                                                </div>
                                                <div className='col-md-6 calendarIcon'>
                                                    <label> UPT Expiry Date: </label>
                                                    <input
                                                        onChange={handleExpiryDate}
                                                        class='form-control'
                                                        type="date"
                                                        value={selectedBlock?.upt_expiry_date}
                                                        timeFormat={false}
                                                        inputProps={{placeholder:'mm/dd/yy 00:00 AM', disabled: (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? true : false || (props.patient_id === undefined) }}
                                                      />
                                                </div>
                                                <div className='col-md-6'>
                                                    <label> Lot Number: </label>
                                                    <input 
                                                            style={{ borderWidth:'1px'}}
                                                            class="form-control"
                                                            type="number" 
                                                            name='lot_number' 
                                                            checked={consentFormOption} 
                                                            id="flexSwitchCheckDefault" 
                                                            value={selectedBlock.lot_number} 
                                                            onChange={handleFieldChange}
                                                    ></input>
                                                </div>
                                                <div className='col-md-6'>
                                                        <label> UPT Results: </label>
                                                            <select className='form-control'
                                                                    name = 'upt_result'
                                                                    value={selectedBlock.upt_result}
                                                                    style={{ borderWidth:'1px'}}
                                                                    onChange={(e) => handleFieldChange(e)}
                                                                    >
                                                                <option value="" selected>Select</option>
                                                                            {
                                                                UPT_RESULTS.map((upt_result) => {
                                                                    return <>
                                                                    <option value ={upt_result.value}>{upt_result.label} </option>
                                                                    </>
                                                                })
                                                            }
                                                        </select>
                                                        </div>
                                                        
                                                        </>
                                                        
                                    :''}
                                    </>:''}
                                    </div>            
                                    
                                    </div>
                                    </> :'Visit is not started' 
    }
                          </fieldset>

     </>
     
} 
export default Birth_Control;