import {useEffect} from "react";
import { Card } from "@mui/material";
import { __API_URL__ } from "../../SERVER_URL";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import AllBlocksAccordion from "../TrialAccordions/AllBlocksAccordion"
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Datetime from "react-datetime";
import moment from 'moment';
import SpeicalEventDeletePopUp from "../PopUp/SpeicalEventDeletePopUp";

const SpecialVisitSession = ({ photoSessions, role, patientId, selectedVisit, handleTrialTypeChange, selectedBlock, setSelectedBlock, localBlocks, handleSelectBlock, handleBlockChange, surveyFormBool, handleBoolOperation, setSurveyFormOption, handleVisitFieldChange, surveyFormOption, allSurveys, setModalShow, consentFormBool, setConsentFormOption, trialId, disable, consentFormOption, photoTypes, allConsents, handlePhotoTypeChange, setHasChanged, hasChanged, infoData, setMessage, patientDetailApi, setSelectedVisit, setTypeOfMessage, AllVisitsData, modalVisitChangeShow, setModalVisitChangeShow, selectedSessionId, setSelectedSessionId, searchTrailType, setSearchTrailType, searchBlockName, setSearchBlockName, newForm, setNewForm, SpecialVisitsPhotoSessions }) => {
  const token = localStorage.getItem("token");
  const { _id_ } = useParams();
  const [popMessage, setPopMessage] = useState(false);
  const [documentToDeleteId, setDocumentToDeleteId] = useState();

  const DeleteEvent = (id) => {
    setDocumentToDeleteId(id);
    setPopMessage(true);
  };
  
  const routeTo = (id) => {
    setSelectedSessionId(id);
    setSelectedVisit((preVisit)=>({
      ...preVisit,
      photo_session: true,
    }));
    const blockNames = selectedVisit.trial_block.map(block => block.name);
    if (blockNames.includes("Adverse Event Log")) {
      setSelectedBlock("Adverse Event Log");
    } else {
      setSelectedBlock("Patient Activity in this Visit");
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setStatusChangePayload((statusChangePayload) => ({
      ...statusChangePayload,
      [name]: value,
    }));
  };

  const [open, setOpen] = React.useState(false);
  const [statusChangePayload, setStatusChangePayload] = useState({
    patientStartDate: moment(),
    patientEndDate: moment(),
    status: '',
    visitNumber: 0,
    trialId: 0,
    sessionId: 0,
    user_selected_name: ''
  })

  const createAdverseEvent = () => {
    axios
      .post(
        `${__API_URL__}/api/photos/session/init`, {
          visit_type: "Adverse Event",
          selected_areas: [],
          patient_id: patientId
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success("Adverse Event created")
        setStatusChangePayload((prev) => {return {...prev, status: "InComplete", visitNumber: res.data?.visit_type === "Adverse Event" ? -1 : -2}})
        setOpen(true)
        SpecialVisitsPhotoSessions()
      })
      .catch((err) => {
        toast.error(err.response.data.error)
      });
  }

  const createUnscheduledEvent = () => {
    axios
      .post(
        `${__API_URL__}/api/photos/session/init`, {
          visit_type: "Unscheduled Event",
          selected_areas: [],
          patient_id: patientId
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success("Unscheduled Event created")
        setStatusChangePayload((prev) => {return {...prev, status: "InComplete", visitNumber: res.data?.visit_type === "Adverse Event" ? -1 : -2, sessionId: res.data.session_data.id}})
        setOpen(true)
        SpecialVisitsPhotoSessions()
      })
      .catch((err) => {
        toast.error(err.response.data.error)
      });
  }
const confirmDelete = () => {
    axios
      .delete(
        `${__API_URL__}/api/photos/session/init`, 
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          data: {
            session_id: documentToDeleteId,
          },
        }
      )
      .then((res) => {
        toast.success("Event deleted successfully");
        SpecialVisitsPhotoSessions();
        AllVisitsData()
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      })
      .finally(() => {
        setPopMessage(false);
      });
}


  const handleSaveVisitStatus = () => {
    if (!checkStartEndDates()) {
      toast.error("Start Date should be less than End Date!")
    } else {
      axios
        .post(`${__API_URL__}/api/photos/change_visit_status/`, {
          ...statusChangePayload,
          patientId: _id_,
          trialId: trialId,
          user_selected_name:statusChangePayload.user_selected_name
        }, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setOpen(false)
          toast.success("Visit Status Changed Successfully");
          SpecialVisitsPhotoSessions()
          AllVisitsData()
        })
        .catch((err) => {
          toast.error(err.response.data.error);
        });
    }
  }

  const checkStartEndDates = () => {
    if (statusChangePayload.status !== "InComplete" && statusChangePayload.patientStartDate > statusChangePayload.patientEndDate) return false
    else return true
  }

  useEffect(() => {
    if (selectedSessionId != 0) AllVisitsData(false)
  }, [selectedSessionId])


  return (
    <>
      <ToastContainer />
      {selectedSessionId ? (
        <AllBlocksAccordion
          role={role}
          patientId={patientId}
          selectedVisit={selectedVisit}
          handleTrialTypeChange={handleTrialTypeChange}
          selectedBlock={selectedBlock}
          setSelectedBlock={setSelectedBlock}
          localBlocks={localBlocks}
          handleSelectBlock={handleSelectBlock}
          handleBlockChange={handleBlockChange}
          surveyFormBool={surveyFormBool}
          handleBoolOperation={handleBoolOperation}
          setSurveyFormOption={setSurveyFormOption}
          handleVisitFieldChange={handleVisitFieldChange}
          surveyFormOption={surveyFormOption}
          allSurveys={allSurveys}
          setModalShow={setModalShow}
          consentFormBool={consentFormBool}
          setConsentFormOption={setConsentFormOption}
          handlePhotoTypeChange={handlePhotoTypeChange}
          trialId={trialId}
          disable={disable}
          consentFormOption={consentFormOption}
          allConsents={allConsents}
          photoTypes={photoTypes}
          setHasChanged={setHasChanged}
          hasChanged={hasChanged}
          infoData={infoData}
          setMessage={setMessage}
          patientDetailApi={patientDetailApi}
          setSelectedVisit={setSelectedVisit}
          setTypeOfMessage={setTypeOfMessage}
          AllVisitsData={AllVisitsData}
          modalVisitChangeShow={modalVisitChangeShow}
          setModalVisitChangeShow={setModalVisitChangeShow}
          selectedSessionId={selectedSessionId}
          setSelectedSessionId={setSelectedSessionId}
          searchTrailType={searchTrailType}
          setSearchTrailType={setSearchTrailType}
          searchBlockName={searchBlockName}
          setSearchBlockName={setSearchBlockName}
          newForm={newForm}
          setNewForm={setNewForm}
        />
      ):(
        <Card className="col-md-12 my-3">
          <>
            <div className="col-md-12 text-end">
              {(selectedVisit.visit_number === -1) ?
                <button onClick={() => createAdverseEvent()} className="btn btn-primary btn-sm mx-3 my-2">
                  Create Adverse Event
                </button>
                :""
              }
              {(selectedVisit.visit_number === -2) ?
                <button onClick={() => createUnscheduledEvent()} className="btn btn-primary btn-sm mx-3 my-2">
                  Create Unscheduled Event
                </button>
                :""
              }
            </div>
            {photoSessions?.length === 0 ?
              <p className="text-center my-4">There is no existing visit</p>
              :
              <table>
                <thead>
                  <tr>
                    <th>
                      {role === "Super Admin" ||
                        role === "Investigator" ||
                        role === 'Sub_investigator' ||
                        role === "CRO Admin" ||
                        role === "Cro_coordinator" ||
                        role === "Data Manager" ||
                        role === "Inv_coordinator"
                        ? "Trial Name"
                        : "Session Area"}
                    </th>
                    <th>Visit Type</th>
                    <th>Visit Name</th>
                    <th>Patient Start Date</th>
                    <th>Patient Completed Date</th>
                    <th>Status</th>
                    <th>Delete</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>

                  {photoSessions?.map((visit) => (
                    <tr
                      key={visit?.id}
                    >

                      <td>{visit?.trial_area}</td>
                      <td>{visit?.visit_type ? visit?.visit_type : "-"}</td>
                      <td>{visit?.user_selected_name ? visit?.user_selected_name : "Unnamed Unscheduled Visit"}</td>

                      {(visit?.start_date && visit?.status !== "Skipped") ? (
                        <td style={{ textAlignLast: "left", textAlign: "center" }}>
                          {new Date(visit?.start_date + "Z").toDateString()},&nbsp;
                          {new Date(visit?.start_date + "Z").toLocaleTimeString()}
                        </td>
                      ) : (
                        <td style={{ textAlignLast: "center", textAlign: "center" }}>
                          -
                        </td>
                      )}
                      {visit?.is_skipped ? (
                        <td style={{ textAlignLast: "center", textAlign: "center" }}>
                          -
                        </td>
                      ) : (visit?.completed_date && visit?.status !== "Skipped") ? (
                        <td style={{ textAlignLast: "left", textAlign: "center" }}>
                          {new Date(visit?.completed_date + "Z").toDateString()}
                          ,&nbsp;
                          {new Date(visit?.completed_date + "Z").toLocaleTimeString()}
                        </td>
                      ) : (
                        <td style={{ textAlignLast: "center", textAlign: "center" }}>
                          -
                        </td>
                      )}
                      <td>{visit?.status}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>
                        <a
                          style={{
                            backgroundColor: 'red',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            textDecoration: 'none',
                            display: 'inline-block',
                          }}
                          onClick={() => DeleteEvent(visit?.id)}
                        >
                          <i className="fa fa-trash"></i>
                        </a>
                      </td>
                      <td>
                        <button type="button" class="btn btn-primary btn-sm m-2"
                          onClick={visit?.id ? (e) => routeTo(visit?.id) : null}
                        >
                          View Case / Add Log
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm m-2"
                          onClick={() => {
                            setOpen(true);
                            if (visit?.status === "Completed") {
                              setStatusChangePayload((prev) => ({
                                ...prev,
                                status: visit?.status,
                                visitNumber: visit?.visit_type === "Adverse Event" ? -1 : -2,
                                patientStartDate: moment.utc(visit?.start_date).local(),
                                patientEndDate: moment.utc(visit?.completed_date).local(),
                                sessionId: visit?.id,
                                user_selected_name: visit?.user_selected_name
                              }));
                            } else {
                              setStatusChangePayload((prev) => ({
                                ...prev,
                                status: visit?.status,
                                visitNumber: visit?.visit_type === "Adverse Event" ? -1 : -2,
                                patientStartDate: visit?.start_date ? moment.utc(visit?.start_date).local() : moment(),
                                patientEndDate: moment(),
                                sessionId: visit?.id,
                                user_selected_name: visit?.user_selected_name
                              }));
                            }
                          }
                          }
                        >
                          Update Status
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </>
          {popMessage && (
            <SpeicalEventDeletePopUp
              setPopMessage={setPopMessage}
              confirmDelete={confirmDelete}
            >
            </SpeicalEventDeletePopUp>
          )}
          
          {open && (
            <Modal
              show
              onHide={() => setOpen(false)}
              centered
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Status</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <label>Status</label>
                <select disabled={["Completed", "Skipped"].includes(statusChangePayload.status)} className="form-control" value={statusChangePayload.status} onChange={(e) => {setStatusChangePayload((prev) => {return {...prev, status: e.target.value}})}}>
                  <option value="InComplete">InComplete</option>
                  <option value="Skipped">Skipped</option>
                  <option value="Completed">Completed</option>
                </select>
                {
                  ['Completed', 'InComplete'].includes(statusChangePayload.status) && <>
                    <div className="col-md-12 calendarIcon">
                      <label>Patient Start Date</label>
                      <i className="fa fa-calendar-o" aria-hidden="true"></i>
                      <Datetime
                        onChange={(e) => {setStatusChangePayload((prev) => {return {...prev, patientStartDate: e}})}}
                        value={statusChangePayload.patientStartDate}
                        inputProps={{ placeholder: "dd/mm/yy 00:00 AM" }}
                      />
                    </div>
                    <div className='col-md-12'>
                      <label> Visit Name: </label>
                      <input
                        style={{ marginLeft: "0em" }}
                        placeholder="Unnamed Unscheduled Visit"
                        class="form-control"
                        type="text"
                        name='user_selected_name'
                        onChange={(e) => handleFieldChange(e)}
                        value={statusChangePayload.user_selected_name}
                      >
                      </input>
                    </div>
                    {
                      statusChangePayload.status === "Completed" &&
                      <div className="col-md-12 calendarIcon">
                        <label>Patient Complete Date</label>
                        <i className="fa fa-calendar-o" aria-hidden="true"></i>
                        <Datetime
                          onChange={(e) => {setStatusChangePayload((prev) => {return {...prev, patientEndDate: e}})}}
                          value={statusChangePayload.patientEndDate}
                          inputProps={{ placeholder: "dd/mm/yy 00:00 AM" }}
                        />
                      </div>
                    }
                  </>
                }
                <div className="col-md-12" style={{ textAlign: "end" }}>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm m-2"
                    onClick={() => {handleSaveVisitStatus()}}
                  >
                    Save
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </Card>
      )}
    </>
  );
};

export default SpecialVisitSession;
