import React from 'react'
import MainAccordion from '../Accordion/accordion'
import MaximumPhotoTypes from '../Trials/MaximumPhotoTypes'

const MaximumPhotoTypesAccordion = ({trialDetails, hasChanged, setHasChanged, setTrialDetails, handleBasicInfoUpdate, setMaximumPhototypes, maximumPhototypes}) => {

  return (
    <MainAccordion
    title="Maximum Photo Types"
    data={
        <MaximumPhotoTypes 
        trialDetails={trialDetails}
        setTrialDetails={setTrialDetails}
        handleBasicInfoUpdate={handleBasicInfoUpdate}
        maximumPhototypes={maximumPhototypes}
        setMaximumPhototypes={setMaximumPhototypes}
        />}
    />
  )
}
export default MaximumPhotoTypesAccordion