import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "./PatientMenu";
import Breadcrumbs from "../../components/breadcrumbs";
import "react-datetime/css/react-datetime.css";
import { useParams } from "react-router";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import MessageBox from "../../components/Alert/MessageBox";
import Avatar from "../../assets/img/avatar.png";
import useUser from "../../hooks/user";
import { toast, ToastContainer } from "react-toastify";
import MainAccordion from "../../components/Accordion/accordion";
import moment from 'moment';
import UploadFilesModal from "../../components/Modals/FileUploadModal";
import PhotoPopUpMessage from "../../components/PopUp/PhotoPopUpMessage";
import ExternalImageCompare from "../../Pages/Patient/ExternalImageCompare";

const ExternalMediaPatientCase = () => {
  const { _id_, caseId } = useParams();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const token = localStorage.getItem("token");
  const role = user_data.role;
  const user = useUser();

  const [infoData, setInfoData] = useState({});
  const now = moment(); 
  const [captureDateTime, setCaptureDateTime] = useState(now);
  const [loading, setLoading] = useState(false);
  const [toastrMessage, setToastrMessage] = useState("Evaluation Updated!");
  const [typeOfMessage, setTypeOfMessage] = useState("");
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [showVideoUploadModal, setShowVideoUploadModal] = useState(false);
  const [popMessage, setPopMessage] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [photoTypeList, setPhotoTypeList] = useState([]);
  const [videoTypeList, setVideoTypeList] = useState([]);
  const [files, setFiles] = useState([]);
  const [externalMedia, setExternalMedia] = useState([]);
  const [zoomPhoto, setZoomPhoto] = useState();
  const [trialId, setTrialId] = useState(0)
  const [showCompare, setShowCompare] = useState(false);
  const fixImageRef = useRef(null);
  const getSrc = (src) => {
    if (fixImageRef.current) {
      fixImageRef.current.src = src;
      document.getElementsByClassName("imageCompare")[0].style.display = "block";
    } else {
      console.error('fixImageRef is not set');
    }
  };
  const [deleteItemType, setDeleteItemType] = useState(null);

  const SinglePatient = () => {
    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      });
  };

  const getExternalMedia = () => {
    axios
      .get(
        `${__API_URL__}/api/photos/get_external_media/?photo_session_id=${caseId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setExternalMedia(res.data.data);
      })
      .catch((err) => {
        console.warn(err, "Unable to retrieve external media");
      });
  };

  const trialDetailApi = () => {
    axios
    .get(`${__API_URL__}/api/photos/get_trial_for_patient/${_id_}/`, {
        headers: {
        Authorization: `Token ${token}`,
        },
        
    })
    .then((res) => {
        res = res.data
        setTrialId(res.trial.id)
    });
}

useEffect(() => {
    trialDetailApi()
}, [])

  const deleteExternalMedia = (id, type) => {
    setDeleteItemId(id);
    setDeleteItemType(type);
    setPopMessage(true);
  };
  const confirmDelete = (id) => {
    if (id);
    {
      axios
        .delete(`${__API_URL__}/api/photos/delete_external_media/${id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then(() => {
          toast.success("Media Deleted Successfully");
          getExternalMedia();
        })
        .catch(() => {
          toast.error("Something Went Wrong");
        })
        .finally(() => {
          setPopMessage(false);
        });
    }
  };
  useEffect(() => {
    setFiles([]);
  }, [showImageUploadModal, showVideoUploadModal]);

  useEffect(() => {
    SinglePatient();
    getExternalMedia();
  }, []);

  useEffect(() => {
    if (trialId !== 0) {
      getExternalMediaTypeApi("videos");
      getExternalMediaTypeApi("photos");
    }
  }, [trialId])

  const getExternalMediaTypeApi = (type) => {
    axios
      .get(`${__API_URL__}/api/photos/get_external_media_types/${type}/?trial=${trialId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        if (type === "photos") setPhotoTypeList(res.data);
        else if (type === "videos") setVideoTypeList(res.data);
      })
      .catch((err) => {
        toast.error("Something Went Wrong!");
      });
  }

  const downloadFile = (url, filename) => {
    axios(url, {
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'image/jpg'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  // Generate File Name
  const generateFileName = (data) => {
    const { capture_datetime, trial_name, visit_number, photo_type_name, participant_id, site_id, name_initials } = data;
    return `${capture_datetime}__Appiell__${trial_name}__${site_id}__${participant_id}(${name_initials})__${visit_number}__${photo_type_name}`;
  };
  return (
    <>
      <ToastContainer />
      <MessageBox msg={toastrMessage} type={typeOfMessage} />
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs
          B="Photos"
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
        />
        <PatientMenu active="externalMedia" data={infoData} />
        <UploadFilesModal
          uploadType="photos"
          showModal={showImageUploadModal}
          setShowModal={setShowImageUploadModal}
          files={files}
          setFiles={setFiles}
          captureDateTime={captureDateTime}
          setCaptureDateTime={setCaptureDateTime}
          acceptedMimeTypes={[
            "image/gif",
            "image/jpeg",
            "image/png",
            "image/svg+xml",
          ]}
          typeList={photoTypeList}
          setTypeList={setPhotoTypeList}
          caseId={caseId}
          getExternalMedia={getExternalMedia}
          trialId = {trialId}
        />

        <UploadFilesModal
          uploadType="videos"
          showModal={showVideoUploadModal}
          setShowModal={setShowVideoUploadModal}
          files={files}
          setFiles={setFiles}
          captureDateTime={captureDateTime}
          setCaptureDateTime={setCaptureDateTime}
          acceptedMimeTypes={[
            "video/mp4",
            "video/mpeg",
            "video/webm",
            "video/quicktime",
          ]}
          typeList={videoTypeList}
          setTypeList={setVideoTypeList}
          caseId={caseId}
          getExternalMedia={getExternalMedia}
          trialId = {trialId}
        />
        <div className="caseSection">
          {loading ? (
            <div className="loader">
              <img src="https://i.gifer.com/Yb3A.gif" alt="" />
            </div>
          ) : (
            (role === "Investigator" || role === 'Sub_investigator' || role === "Inv_coordinator" || role === "CRO Admin" || role === "Cro_coordinator" || role === "Data Manager") && (
              <div className="container-fluid" style={{ marginTop: "30px" }}>
                <div className="row patient_table">
                  <MainAccordion
                    title="Uploaded Images"
                    isOpen={true}
                    data={
                      <>
                        <div className="row">
                        <div className="col-md-11 text-end">
                            {role !== "CRO Admin" && role !== "Cro_coordinator"  && role !== "Data Manager" && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => setShowImageUploadModal((prev) => !prev)}
                                >
                                  Upload Images
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="caseSection">
                          {loading ? (
                            <div className="loader">
                              <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                            </div>
                          ) : (
                            (role === "Investigator" ||
                              role === 'Sub_investigator' ||
                              role === 'CRO Admin' ||
                              role === 'Cro_coordinator' ||
                              role === 'Data Manager' ||
                              role === "Inv_coordinator") && (
                              <div
                                className="container-fluid"
                                style={{ margin: "30px" }}
                              >
                                <div className="row patient_table">
                                  {externalMedia?.data
                                    ?.external_session_media_photo?.length ===
                                  0 ? (
                                    <>NO EXTERNAL PHOTOS</>
                                  ) : (
                                    <>
                                      {Array.isArray(externalMedia) &&
                                        externalMedia.map((media) =>
                                          media?.external_session_media_photo?.map(
                                            (data) => (
                                              <div
                                                className="col-md-3"
                                                key={data.id}
                                              >
                                                <div className="caseBox">
                                                  {data.image ? (
                                                    <img
                                                      src={
                                                        __API_URL__ + data.image
                                                      }
                                                      onClick={() => {
                                                        getSrc(__API_URL__ + data.image);
                                                        setZoomPhoto(__API_URL__ + data.image);
                                                        setShowCompare(true);
                                                      }}
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <img src={Avatar} alt="" />
                                                  )}
                                                  <br></br>
                                                  <br></br>
                                                  <>
                                                    Photo Type: {data?.photo_type_name}
                                                  </>
                                                  <br></br>
                                                  <>
                                                  Visit Number: {data?.visit_number}
                                                  </>
                                                  <label>
                                                    Captured At -{" "}
                                                    {new Date(
                                                      data?.capture_datetime +
                                                        "Z"
                                                    ).toDateString()}
                                                    ,&nbsp;
                                                    {new Date(
                                                      data?.capture_datetime +
                                                        "Z"
                                                    ).toLocaleTimeString()}
                                                  </label>
                                                    <a
                                                      href="#"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        downloadFile(__API_URL__ + data.image, `${generateFileName(data)}.jpg`);
                                                      }}
                                                      style={{ float:"right"}}
                                                    >
                                                      <i className="fa fa-arrow-down"></i>
                                                    </a>
                                                  {
                                                    (role !== 'CRO Admin' && role !== 'Cro_coordinator' && role !== 'Data Manager') &&
                                                  <a
                                                    type="button"
                                                    style={{ width: "30px", height: "35px", float:"right"}}
                                                    onClick={() =>
                                                      deleteExternalMedia(
                                                        data?.id,'photo'
                                                      )
                                                    }
                                                  >
                                                    <i className="fa fa-trash fa-md text-danger"></i>
                                                  </a>
                                                }
                                                </div>
                                              </div>
                                            )
                                          )
                                        )}
                                        {showCompare && (
                                          <ExternalImageCompare
                                            show={showCompare}
                                            onHide={() => setShowCompare(false)}
                                            Images={externalMedia[0].external_session_media_photo}
                                            zoomPhoto={zoomPhoto} 
                                          />
                                        )}
                                  </>
                                  )}
                                    {popMessage && (
                                      <PhotoPopUpMessage
                                        setPopMessage={setPopMessage}
                                        confirmDelete={confirmDelete}
                                        id={deleteItemId}
                                        type={deleteItemType}
                                      >
                                      </PhotoPopUpMessage>
                                    )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </>
                    }
                  />
                  <MainAccordion
                    title="Uploaded Videos"
                    isOpen={true}
                    data={
                      <>
                        <div className="row">
                        {role !== "CRO Admin" && role !== "Cro_coordinator" && role !== 'Data Manager' && (
                          <div className="col-md-11 text-end">
                            <button
                              type="button"
                              class="btn-primary"
                              className="btn btn-primary"
                              onClick={() =>
                                setShowVideoUploadModal((prev) => !prev)
                              }
                            >
                              Upload Videos
                            </button>
                          </div>
                        )}
                        </div>
                        <div className="caseSection">
                          {loading ? (
                            <div className="loader">
                              <img src="https://i.gifer.com/Yb3A.gif" alt="" />
                            </div>
                          ) : (
                            (role === "Investigator" ||
                              role === 'Sub_investigator' ||
                              role === 'CRO Admin' ||
                              role === 'Cro_coordinator' || 
                              role === 'Data Manager' ||
                              role === "Inv_coordinator") && (
                              <div
                                className="container-fluid"
                                style={{ margin: "30px" }}
                              >
                                <div className="row patient_table">
                                  {externalMedia?.data
                                    ?.external_session_media_video?.length ===
                                  0 ? (
                                    <>NO EXTERNAL VIDEOS</>
                                  ) : (
                                    <>
                                      {Array.isArray(externalMedia) &&
                                        externalMedia.map((media) =>
                                          media?.external_session_media_video?.map(
                                            (data) => (
                                              <div
                                                className="col-md-6 my-2"
                                                key={data.id}
                                              >
                                                {data.video ? (
                                                  <video
                                                    src={
                                                      __API_URL__ + data.video
                                                    }
                                                    style={{
                                                      maxWidth: "50%",
                                                      width: "600px",
                                                      margin: "0 auto",
                                                    }}
                                                    playsInline
                                                    controls
                                                    alt="All the devices"
                                                  />
                                                ) : (
                                                  "No video is attached with this session!"
                                                )}
                                                <div
                                                  className="caseBox"
                                                  style={{
                                                    maxWidth: "50%",
                                                  }}
                                                >
                                                  <>
                                                    Video Type: {data?.video_type_name}
                                                  </>
                                                  <br></br>
                                                  <>
                                                  Visit Number: {data?.visit_number}
                                                  </>
                                                  <label>
                                                    Captured At -{" "}
                                                    {new Date(
                                                      data?.capture_datetime +
                                                        "Z"
                                                    ).toDateString()}
                                                    ,&nbsp;
                                                    {new Date(
                                                      data?.capture_datetime +
                                                        "Z"
                                                    ).toLocaleTimeString()}
                                                  </label>
                                                  {
                                                  (role !== 'CRO Admin' && role !== 'Cro_coordinator' && role !== 'Data Manager') &&
                                                    <div
                                                      style={{ width: "25px", height: "35px", float:"right"}}
                                                    >
                                                
                                                    <a
                                                      type="button"
                                                      
                                                      onClick={() =>
                                                        deleteExternalMedia(
                                                          data?.id,'video'
                                                        )
                                                      }
                                                    >
                                                    <i className="fa fa-trash fa-md text-danger mx-3"></i>
                                                  </a>
                                                </div>                           
                                              }
                                                </div>
                                              </div>
                                            )
                                          )
                                        )}
                                    </>
                                  )}
                                   {popMessage && (
                                        <PhotoPopUpMessage
                                          setPopMessage={
                                            setPopMessage
                                          }
                                          confirmDelete={
                                            confirmDelete
                                          }
                                          id={deleteItemId}
                                          type={deleteItemType}
                                        >
                                        </PhotoPopUpMessage>
                                      )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </>
                    }
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default ExternalMediaPatientCase;
