import Modal from "react-bootstrap/Modal";
import "react-datetime/css/react-datetime.css";


const AddNewModal = ({title, setTitle, modalShow, formSubmissionApi}) => {

  return (
    <div>
      <Modal aria-labelledby="contained-modal-title-vcenter" size="md" centered show onHide={() => { modalShow(false) }}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div>
            <label>Title</label>
            <input
              row = {10}
              type="text"
              className="form-control"
              onChange={(e) => setTitle(e.target.value)}
              name="title"
              value={title}
            ></input>
            <div className="col-md-12" style={{textAlign: 'end'}}>
              <button
                type="button"
                className="btn btn-primary m-2"
                onClick={() => formSubmissionApi()}
              >
               Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddNewModal
