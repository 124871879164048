import React, { useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";


const Visit_Comments_Stem_Cell = (props) => {
  const token = localStorage.getItem("token");
  const user = useUser();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role
  const setMessage = props.setMessage
  const setTypeOfMessage = props.setTypeOfMessage
  let diasble = false
  const [selectedBlock, setSelectedBlock] = useState({})

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setSelectedBlock((selectedBlock) => ({
      ...selectedBlock,
      [name]: value,
    }));
    props.setHasChanged(true)
  };
  const VisitCommentData = () => {
    const createData = {
      'visit_number': props?.visit_number,
      'patient_id': props?.patient_id,
      'sessionId': props.selectedSessionId,
      ...selectedBlock

    }
    axios
      .patch(`${__API_URL__}/api/ecrf/update_visitcomment_stemcell/${props.trialId}/`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
        props.globalVisits(false);
        props.setModalVisitChangeShow(false)
        props.setHasChanged(false)
        setMessage("Block updated successfully");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout()
        }
        setMessage("Something Went Wrong");
        setTypeOfMessage("success");
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
        }, 100);
        setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
        }, 3000);
      });

  };

  const get_visit_comment = () => {
    axios
      .get(`${__API_URL__}/api/ecrf/get_visitcomment_stemcell/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setSelectedBlock(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }
  React.useEffect(() => {
    if((props?.patient_id && props?.photo_session)){
        get_visit_comment();
    }
  }, [])
  return <>
    {
      (role ==='CRO Admin' || role==='Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined)) ?
        diasble = true : ''
    }
    <fieldset disabled={diasble}>
      {
        (props?.photo_session || props?.patient_id == undefined) ?
          <>
            {
              (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                // (props.patient_id != undefined) && (role === 'Investigator' || role === 'Inv_coordinator') && (selectedBlock.new_block === false) && (
                <div className="col-md-12 text-end" >
                  <button onClick={() => VisitCommentData()} className='btn btn-primary'>Update</button>
                </div>
              )
            }
            <div className="col-md-12">
              <div> Comment: </div>
              <textarea class='form-control border-1' 
              name='description' 
              rows={10} type='text' 
              value={selectedBlock?.description} 
              onChange={(e)=>handleFieldChange(e)}></textarea>
            </div>
          </> : 'Visit is not started'
      }
    </fieldset>
  </>
}

export default Visit_Comments_Stem_Cell;