import React, { useEffect, useState } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from "../../hooks/user";


const Intralesional_Injection = (props) => {
    const token = localStorage.getItem("token");
    const user = useUser();
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    const role = user_data.role
    const setMessage = props.setMessage
    const setTypeOfMessage = props.setTypeOfMessage
    const extra_photo = props.extra_photo
    const [selectedBlock, setSelectedBlock] = React.useState({})
    let disable = false
    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setSelectedBlock((selectedBlock) => ({
        ...selectedBlock,
        [name]: value,
        }));
        props.setHasChanged(true)
      };
    const handleLesion1 = (e) => {
      setSelectedBlock({
        ...selectedBlock,
        lesion1: e.target.checked,
      });
      props.setHasChanged(true)
    };
    const handleLesion2 = (e) => {
      setSelectedBlock({
        ...selectedBlock,
        lesion2: e.target.checked,
      });
      props.setHasChanged(true)
    };
    const handleLesion3 = (e) => {
      setSelectedBlock({
        ...selectedBlock,
        lesion3: e.target.checked,
      });
      props.setHasChanged(true)
    };
    const handleIsLesionPerformed = (e) => {
    setSelectedBlock({
        ...selectedBlock,
        is_lesion_performed:!selectedBlock?.is_lesion_performed,
    });
    props.setHasChanged(true)
    };
    const handleIsTrained = (e) => {
    setSelectedBlock({
        ...selectedBlock,
        is_trained:!selectedBlock?.is_trained,
    });
    props.setHasChanged(true)
    };
    const handleIsAnyIssue = (e) => {
    setSelectedBlock({
        ...selectedBlock,
        is_any_issue:!selectedBlock?.is_any_issue,
    });
    props.setHasChanged(true)
    };

    const GetSelectedBlockData = () => {
      axios
        .get(`${__API_URL__}/api/ecrf/get_injection/${props?.trialId}/?patient_id=${props?.patient_id}&visit_number=${props?.visit_number}&sessionId=${props?.selectedSessionId}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setSelectedBlock(res.data);
        }).catch((err) => {
          if (err == 'Error: Request failed with status code 401') {
          }
        });
    }

      const AllVisitsData = () => {
        const createData = {
                'patient_id':props?.patient_id,
                'visit_number':props?.visit_number,
                ...selectedBlock,
                'is_lesion_performed': selectedBlock?.is_lesion_performed === undefined ? true : selectedBlock?.is_lesion_performed,
                'is_trained': selectedBlock?.is_trained === undefined ? true : selectedBlock?.is_trained,
                'is_any_issue': selectedBlock?.is_any_issue === undefined ? true : selectedBlock?.is_any_issue,
                'sessionId': props.selectedSessionId,
            }
            axios
              .patch(`${__API_URL__}/api/ecrf/update_injection/${props.trialId}/`,createData, {
                headers: {
                  Authorization: `Token ${token}`,
                },
              })
              .then((res) => {
                setSelectedBlock(res.data);
                props.setModalVisitChangeShow(false)
                props.setHasChanged(false)
                setMessage("Block updated successfully");
                setTypeOfMessage("success");
                setTimeout(() => {
                document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            }).catch((err) => {
                if(err == 'Error: Request failed with status code 401'){
                user.actions.logout()
                }
                setMessage("Something Went Wrong");
                setTypeOfMessage("success");
                setTimeout(() => {
                document.getElementById("cusMessages").style.right = "2rem";
                }, 100);
                setTimeout(() => {
                document.getElementById("cusMessages").style.right = "-99rem";
                }, 3000);
            });
          };
          React.useEffect(() => {
            if((props?.patient_id && props?.photo_session)){
              GetSelectedBlockData();
            }
          }, [])
return <>  
{
              role==='CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || (props.patient_id === undefined) ?
              disable=true:''
            }
            <fieldset disabled={disable}>
            {
                        (props.photo_session || props.patient_id == undefined ) ?
                        <>
              {
            (props.patient_id != undefined && role === 'Investigator' || role === 'Inv_coordinator' || selectedBlock.new_block === false) && (
                <div className="col-md-12 text-end">
                <button onClick={() => AllVisitsData()} className='btn btn-primary'>Update</button>
                </div>
             )
            }

                            <div className="col-md-10">
                              <div className="row">
                                      <div className="col-md-8">
                                              <p><small>• Was the participant trained on the use of the injection device with the needle-fee training device?</small></p>
                                              </div>   
                                              <div className="col-md-1">
                                              <p><small>Yes
                                            <input
                                                    class="form-check-input mx-1"
                                                    type="radio"
                                                    name="is_trained"
                                                    checked = {selectedBlock?.is_trained == true}
                                                    id="radio1"
                                                    value={selectedBlock?.is_trained == true}
                                                    onChange={handleIsTrained}
                                                />
                                                </small>
                                                </p>
                                              </div>
                                             <div className="col-md-1">
                                              <p><small>No 
                                            <input
                                                    class="form-check-input mx-1"
                                                    type="radio"
                                                    name="is_trained"
                                                    checked = {selectedBlock?.is_trained == false}
                                                    id="radio1"
                                                    value={selectedBlock?.is_trained == false}
                                                    onChange={handleIsTrained}
                                            />
                                            </small>
                                            </p>
                                            </div>
                                  {selectedBlock.is_trained === false ?
                                    <div className='col-md-5'>
                                      <label> if No, indicates Reason: </label>
                                        <input 
                                                class="form-control" 
                                                type="text" 
                                                name='trained_reason' 
                                                id="flexSwitchCheckDefault"
                                                value={selectedBlock?.trained_reason}
                                                onChange={(e) => handleFieldChange(e)}>
                                        </input>
                                  </div>
                                :''}
                                            <div className="col-md-8">
                                              <p><small>•	Were Intralesional Injection(s) of each target lesion performed at this visit?</small></p>
                                              </div>   
                                              <div className="col-md-1">
                                              <p><small>Yes
                                            <input
                                                    className="form-check-input mx-1"
                                                    type="radio"
                                                    name="is_lesion_performed"
                                                    checked = {selectedBlock?.is_lesion_performed == true}
                                                    id="radio1"
                                                    value={selectedBlock?.is_lesion_performed == true}
                                                    onChange={handleIsLesionPerformed}
                                                />
                                                </small>
                                                </p>
                                            </div>
                                            <div className="col-md-1">
                                            <p><small>No 
                                            <input
                                                    className="form-check-input mx-1"
                                                    type="radio"
                                                    name="is_lesion_performed"
                                                    checked = {selectedBlock?.is_lesion_performed == false}
                                                    id="radio1"
                                                    value={selectedBlock?.is_lesion_performed == false}
                                                    onChange={handleIsLesionPerformed}
                                            />
                                            </small>
                                            </p>
                                            </div>
                                        {selectedBlock.is_lesion_performed === false ?
                                      <>
                                        <div className='col-md-5'>
                                        <label> if No, indicates Reason: </label>
                                        <input 
                                                class="form-control " 
                                                type="text" 
                                                name='reason' 
                                                id="flexSwitchCheckDefault"
                                                value={selectedBlock?.reason}
                                                onChange={(e) => handleFieldChange(e)}>
                                        </input>
                                        </div>
                                        </>          
                                        :''
}
                                    <div className='col-md-8'>
                                    <label> Concentration of Triamcinolone injected (%):</label>
                                        <input 
                                                class="form-control" 
                                                style={{marginLeft: "0em", borderWidth:'1px'}} 
                                                type="number" 
                                                step="0.1"
                                                name='concentration_of_triamcinolone' 
                                                id="flexSwitchCheckDefault"
                                                value={selectedBlock.concentration_of_triamcinolone}
                                                onChange={(e) => handleFieldChange(e)}>
                                        </input>
                                        </div>
                                    <div className='col-md-8'>
                                    <label>Volume of Triamcinolone injected (mL):</label>
                                        <input 
                                                class="form-control" 
                                                style={{marginLeft: "0em", borderWidth:'1px'}} 
                                                type="number" 
                                                step="0.1"
                                                name='volume_of_triamcinolone' 
                                                id="flexSwitchCheckDefault"
                                                value={selectedBlock.volume_of_triamcinolone}
                                                onChange={(e) => handleFieldChange(e)}>
                                        </input>
                                        </div>
          <div className="col-md-10">
            <p><small>•	Were there any issues with the delivery of the injection (ie device misfire, participant did not hold device to face for 5 seconds after injection, drug dose not delivered)?</small></p>
                  </div>   
                    <div className="col-md-1">
                               <p><small> Yes
                                  <input
                                      className="form-check-input mx-1"
                                      type="radio"
                                      name="is_any_issue"
                                      checked = {selectedBlock?.is_any_issue == true}
                                      id="radio1"
                                      value={selectedBlock?.is_any_issue == true}
                                      onChange={handleIsAnyIssue}
                                  />
                                  </small>
                                  </p>
                                    </div>
                                      <div className="col-md-1">
                                      <p><small>No 
                                            <input
                                                    className="form-check-input mx-1"
                                                    type="radio"
                                                    name="is_any_issue"
                                                    checked = {selectedBlock?.is_any_issue == false}
                                                    id="radio1"
                                                    value={selectedBlock?.is_any_issue == false}
                                                    onChange={handleIsAnyIssue}
                                            />
                                            </small>
                                            </p>
                                            </div>
                                        {selectedBlock.is_any_issue === true ?
                                      <>
                                        <div className='col-md-5'>
                                        <label> if Yes, indicates Reason: </label>
                                        <input 
                                                class="form-control" 
                                                type="text" 
                                                name='issue_reason' 
                                                id="flexSwitchCheckDefault"
                                                value={selectedBlock?.issue_reason}
                                                onChange={(e) => handleFieldChange(e)}>
                                        </input>
                                      </div>
                        <div className="col-md-8">
                            <p> If Yes, select the lesion this applied to: </p>
                            <div className="col-md-4">
                            <label> Target Lesion 1 </label>
                                  { (extra_photo[0] === 'Lesion 1'  || extra_photo[1] === 'Lesion 1' || extra_photo[2] === 'Lesion 1')?
                                        <input 
                                              className="mx-1"
                                              type="checkbox" 
                                              name="lesion1" 
                                              checked = {selectedBlock?.lesion1  || false} 
                                              onChange={(e) => handleLesion1(e)}
                                              /> :''}
                                          </div>
                              <div className="col-md-4">
                                  <label>  Target Lesion 2 </label>
                                  { (extra_photo[0] === 'Lesion 2'  || extra_photo[1] === 'Lesion 2' || extra_photo[2] === 'Lesion 2')?
                                        <input 
                                              className="mx-1"
                                              type="checkbox" 
                                              name="lesion2" 
                                              checked = {selectedBlock?.lesion2  || false} 
                                              onChange={(e) => handleLesion2(e)}
                                              /> :''}
                                          </div>
                              <div className="col-md-4">
                                  <label>  Target Lesion 3 </label>
                                  { (extra_photo[0] === 'Lesion 3'  || extra_photo[1] === 'Lesion 3' || extra_photo[2] === 'Lesion 3')?
                                        <input 
                                              className="mx-1" 
                                              type="checkbox" 
                                              name="lesion3" 
                                              checked = {selectedBlock?.lesion3  || false} 
                                              onChange={(e) => handleLesion3(e)}
                                              /> :''}
                                    </div>
                            </div>
                                        </>
                                        :''
                                     }
                                     
                <div className='row'style={{marginLeft: "0em", borderWidth:'1px'}} >  
                  
                  <p> Procedure: </p>
                        <td> Prepare the 1% triamcinolone solution with sterile safety needle.</td>
                        <td>  *BD luer lock syringe has deadspace of ~85-100uL</td>
                        <td>  *Triamcinolone 10 needs to be diluted 1:10 in saline for 1% solution</td>
                        <td></td>
                        <td> 1. Agitate Triamcinolone 10 bottle and sterilize rubber surface of saline and triamcinolone bottles</td>
                        <td> 2. Attach 20 gauge needle for draw</td>
                        <td> 3. Draw saline solution to the 0.9mL line</td>
                        <td> 4. Point needle upwards and backdraw/flick to bring air bubbles to top</td>
                        <td> 5. Eject air and saline until the syringe reads 0.8mL.</td>
                        <td> 6. Draw up 0.1mL of Triamcinolone 10, stopping when the syringe reads 0.9mL. Remove needle from Triamcinolone 10 bottle.</td>
                        <td> 7. With needle pointed upwards, draw back air into syringe to 1mL to pull liquid from syringe hub. Gently invert syringe 3x to mix solution.</td>
                        <td> 8. With the syringe pointed upwards, remove the draw needle and dispose in a sharps container.</td>
                        <td> 9. If more than one lesion is to be injected, attach a sterile luer-to-luer adapter.</td>
                        <td> 10. Attach a new sterile BD syringe to the open side of the luer-to-luer adapter. Transfer 0.3mL of 1% triamcinolone solution to the new syringe.</td>
                        <td> 11. Detach new syringe from luer-to-luer adapter. Attach a sterile safety needle.</td>
                        <td> 12. If a 3rd lesion is to be injected, repeat steps 10 and 11 with a 3rd sterile BD syringe.</td>
                        <td> 13. Remove luer-to-luer adapter from original syringe and attach a sterile safety needle.</td>
                        <td> 14. Prime each syringe for injection: with safety needle pointed up, push plunger until air bubbles and a small volume of 1% triamcinolone solution is ejected.</td>
                        <td> 15. Eject until syringe has 0.19mL working volume.</td>
                        <td> 16. Syringe-needle unit is now ready to use. Insert into the durable casing.</td>
                        </div>
                        </div>
</div>
</>:'Visit is not started'}
</fieldset>  
</>

}

export default Intralesional_Injection;