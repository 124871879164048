import React, { useState, useEffect } from 'react'

const TrialParticipantId = ({ trialDetails, setTrialDetails, trialSetting, setHasChanged, hasChanged, setSiteIdManual, setChangeTrialSetting, infoSettingChanged, setInfoSettingChanged, handleBasicInfoUpdate, ParticipantIdChanged, setParticipantIdChanged}) => {
  const trial_settings = trialDetails?.participant_id_setting
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;

  const handleSettingTypeChange = (event) => {
    const { name, value } = event.target;
    setTrialDetails((prevDetails) => ({
      ...prevDetails,
      participant_id_setting: {
        ...prevDetails.participant_id_setting,
        [name]: value,
        'id_change':true,
      },
    }));
    setInfoSettingChanged(true);
    setParticipantIdChanged(true)
  };
  
    const handleparticipantType = (e) => {
      setTrialDetails({
        ...trialDetails,
        automatic_participant_type: !trialDetails.automatic_participant_type,
        participant_id_setting: {
          ...trialDetails.participant_id_setting,
          'id_change':true,
        },
      });
      setParticipantIdChanged(true)
    };

  if (trialSetting === 'Site Id' && trial_settings?.setting_type === 'Manual') {
    setSiteIdManual(true)
  } else {
    setSiteIdManual(false)
  }

  useEffect(() => {
    if (trialDetails?.automatic_participant_type === false) {
      setTrialDetails((prevTrialDetails) => ({
        ...prevTrialDetails,
        participant_id_setting: {
          ...prevTrialDetails.participant_id_setting,
          setting_type: 'Manual',
        },
      }));
    }
  }, [trialDetails?.automatic_participant_type]);
  

  return (

    <div class="row mx-5">
      <div className="tableHeader">
              <div className="row d-flex align-items-end">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-end d-flex justify-content-end">
                
                  <>
                   {
                  (role !== "Investigator" && role !== "Inv_coordinator" && role !== 'Reviewer') && <>
                <button
                  type="button"
                  className="btn btn-primary btn-sm mx-2  mt-2"
                  onClick={() => handleBasicInfoUpdate()}
                  disabled={!ParticipantIdChanged}
                >
                  Save
                </button>
                   </>
                  }
                </>

                </div>
              </div>
            </div>
          <div class="col-md-6">
            <label for="automatic_participant_type" class="form-label">Automatic</label>
            <input
              type="radio"
              name='automatic_participant_type'
              style={{ textAlign: 'right', width: '25%'}}
              id="automatic_participant_type"
              onChange={handleparticipantType}
              checked={trialDetails?.automatic_participant_type === true}
              value={true}
            />
          </div>

          <div class="col-md-6">
            <label for="automatic_participant_type" class="form-label">Manual</label>
            <input
              type="radio"
              name='automatic_participant_type'
              style={{ textAlign: 'right', width: '20%'}}
              id="automatic_participant_type"
              onChange={handleparticipantType}
              checked={trialDetails?.automatic_participant_type === false}
              value={true}
            />
          </div>

      <div className='col-md-12 mt-4'>
        <div className="row">

          {(trialDetails?.automatic_participant_type) &&

            <div class="col-md-6">
                <label for="setting_type" class="form-label">Sequential</label>
                <input
                  type="radio"
                  name='setting_type'
                  style={{ textAlign: 'right', width: '24%'}}
                  id="setting_type"
                  onChange={handleSettingTypeChange}
                  checked={trial_settings?.setting_type === 'Sequence'}
                  value='Sequence'
                />
            </div>
          }


              {(trialDetails?.automatic_participant_type) &&

                <div class="col-md-6">
                    <label for="Sequence" class="form-label">Randomize</label>
                    <input
                      type="radio"
                      name='setting_type'
                      style={{ textAlign: 'right', width: '11%'}}
                      id="setting_type"
                      onChange={handleSettingTypeChange}
                      checked={trial_settings?.setting_type === 'Randomize'}
                      value='Randomize' />
                </div>
              }

          <div class="w-100"></div>
            <div className="col my-3">
              <div className='row'>

                  <div class="col-md-6">
                      <label for="lower_value" class="form-label ">Lower Value</label>
                      <input
                        type="number"
                        border
                        class="form-control"
                        id="lower_value"
                        name='lower_range'
                        min="1"
                        value={trial_settings?.lower_range}
                        onChange={handleSettingTypeChange}
                        placeholder="Lower Value" />
                  </div>


                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="upper_value" class="form-label">Upper Value</label>
                    <input
                      type="number"
                      class="form-control"
                      id="upper_value"
                      name='upper_range'
                      min="1"
                      value={trial_settings?.upper_range}
                      onChange={handleSettingTypeChange}
                      placeholder="Upper Value"
                      required
                    />
                  </div>
                </div>

              </div>
            </div>


        </div>

      </div>
    </div>

  )
}

export default TrialParticipantId
