import {React} from 'react'
import Modal from "react-bootstrap/Modal";

const DeleteMessage = ({setDeleteObj, confirmDelete}) => {

  return (
    <div>
       <Modal size="md" show 
       onHide={() => setDeleteObj(false)} 
        backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Delete
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">

        <p className='mx-auto'>Are you sure you want to delete this?</p> 
            <div className="container MX-5 d-flex justify-content-end">
              <div className="row">
              <div className="col-sm">
                  <button 
                  onClick={() => setDeleteObj(false)}
                  className="btn btn-secondary my-2">
                    Cancel
                  </button>
                </div>
                <div className="col-sm">
                  <button 
                  onClick={()=>confirmDelete()}
                  className="btn btn-danger my-2">
                    Delete
                  </button>
                </div>
                
              </div>
            </div>
        </Modal.Body>
    </Modal>
    </div>
  )
}

export default DeleteMessage
