import React, { useState, useEffect } from "react";
import MainAccordion from "../Accordion/accordion";
import Select from "react-select";
import TrialCrc from "../PopUp/TrialCrc";
import { toast, ToastContainer } from "react-toastify";

const TrialReviewersAccordion = ({
  role,
  trialDetails,
  allReviewers,
  onReviewerChange,
  AllReviewerData,
  setAllReviewers,
  handleBasicInfoUpdate,
  setReviewerChanged,
  reviewerChanged,
  setTrialDetails,
  errorMsg,
  seterrorMsg
}) => {
  const reviewers = trialDetails?.reviewers;
  const settingToUpdate = trialDetails?.reviewer_site_id_setting;
  const [showModal, setShowModal] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const [focusedInputIndex, setFocusedInputIndex] = useState(null);

  const handleAddReviewer = () => {
    AllReviewerData();
  };

  const handleSiteIdChange = (e, index) => {
    const newSiteNumber = e.target.value;
    setFocusedInputIndex(index);

    setTrialDetails((prevState) => {
      const updatedReviewers = [...prevState?.reviewers];
      const isSiteNumberExists = updatedReviewers.some(
        (reviewer, i) => i !== index && reviewer.site_number === newSiteNumber
      );
  
      if (isSiteNumberExists) {
        seterrorMsg('This Site already exists!') 
        setReviewerChanged(false);
      }else{
        seterrorMsg('')
        setReviewerChanged(true);
      }
  
      updatedReviewers[index] = {
        ...updatedReviewers[index],
        site_number: newSiteNumber,
      };
      
      return { ...prevState, reviewers: updatedReviewers };
    });
  };
  

  const handleBooleanFields = (e) => {
    setTrialDetails({
      ...trialDetails,
      manual_reviewer_id: !trialDetails.manual_reviewer_id,
      reviewer_site_id_setting: {
        ...trialDetails.reviewer_site_id_setting,
        'id_change': true,
      },
    });
    setReviewerChanged(true);
  };

  const handleSettingTypeChange = (event) => {
    const { name, value } = event.target;
    setTrialDetails((prevDetails) => ({
      ...prevDetails,
      reviewer_site_id_setting: {
        ...prevDetails.reviewer_site_id_setting,
        [name]: value,
        id_change: true,
      },
    }));
    setReviewerChanged(true);
  };

  useEffect(() => {
    if (trialDetails?.manual_reviewer_id === true) {
      setIsDisable(false);
      setTrialDetails((prevTrialDetails) => ({
        ...prevTrialDetails,
        reviewer_site_id_setting: {
          ...prevTrialDetails.reviewer_site_id_setting,
          setting_type: "Manual",
        },
      }));
    } else {
      setIsDisable(true);
      setTrialDetails((prevTrialDetails) => ({
        ...prevTrialDetails,
        reviewer_site_id_setting: {
          ...prevTrialDetails.reviewer_site_id_setting,
          setting_type: "Sequence",
        },
      }));
    }
  }, [trialDetails?.manual_reviewer_id]);

  const handleSave = () => {
    const isEmpty = trialDetails?.reviewers.some((reviewer) => !reviewer?.site_number  && !isDisable);
    if (isEmpty) { 
      const firstEmptyIndex = trialDetails?.reviewers.findIndex((reviewer, index) => {
        const isEmpty = !reviewer?.site_number && !isDisable;
        return isEmpty;
      });
      setFocusedInputIndex(firstEmptyIndex);
      seterrorMsg('Please fill this field');
    } else {
      handleBasicInfoUpdate();
    }
  };
  

  return (
    <MainAccordion
      title="Reviewers"
      data={
        <>
          <ToastContainer />
          <>
            <div className="row mx-5">
              <div className="tableHeader">
                <div className="row d-flex align-items-end">
                  <div className="col-md-6"></div>
                  <div className="col-md-6 text-end d-flex justify-content-end">

                      <>
                        {role !== "Investigator" &&
                          role !== "Inv_coordinator" &&
                          role !== "Reviewer" && (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm mx-2 mt-2"
                                onClick={() => handleSave()}
                                disabled={!reviewerChanged}
                              >
                                Save
                              </button>
                            </>
                          )}
                      </>

                  </div>
                </div>
              </div>
              {trialDetails.is_reviewer_setting && (
                <>
                  <div class="col-md-6 my-3">
                    <label for="manual_reviewer_id" class="form-label">
                      Automatic
                    </label>
                    <input
                      type="radio"
                      name="manual_reviewer_id"
                      style={{ textAlign: "right", width: "25%" }}
                      id="manual_reviewer_id"
                      onChange={(e)=>{handleBooleanFields(e)}}
                      checked={trialDetails?.manual_reviewer_id === false}
                      value={false}
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="manual_reviewer_id" class="form-label">
                      Manual
                    </label>
                    <input
                      type="radio"
                      name="manual_reviewer_id"
                      style={{ textAlign: "right", width: "20%" }}
                      id="manual_reviewer_id"
                      onChange={(e)=>{handleBooleanFields(e)}}
                      checked={trialDetails?.manual_reviewer_id === true}
                      value={true}
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="lower_value" class="form-label">
                        Lower Value
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="lower_value"
                        name="lower_range"
                        min="1"
                        value={settingToUpdate?.lower_range}
                        onChange={handleSettingTypeChange}
                        placeholder="Lower Value"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="upper_value" class="form-label">
                        Upper Value
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="upper_value"
                        name="upper_range"
                        min="1"
                        value={settingToUpdate?.upper_range}
                        onChange={handleSettingTypeChange}
                        placeholder="Upper Value"
                        required
                      />
                    </div>
                  </div>
                </>
              )}

              {role !== "Investigator" &&
                role !== "Inv_coordinator" &&
                role !== "Reviewer" && (
                  <>
                    <div className="col-md-6 mb-5">
                      <label>Reviewers</label>
                      <Select
                        value={trialDetails.reviewers}
                        isMulti
                        className="basic-multi-select"
                        options={allReviewers}
                        onChange={onReviewerChange}
                      />
                    </div>
                    {trialDetails?.is_reviewer_setting && (
                      <>
                        {reviewers?.map((rev, index) => {
                          return (
                            <div class="row">
                              <div class="col-md-6">
                                <div class="mb-3">
                                  <label for="upper_value" class="form-label">
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="upper_value"
                                    name="upper_range"
                                    placeholder="Upper Value"
                                    value={rev?.label}
                                    disabled={true}
                                    required
                                  />
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="mb-3">
                                  <label for="site_id" class="form-label">
                                    Site Id
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    id="site_id"
                                    name="site_id"
                                    min='1'
                                    value={rev?.site_number}
                                    onChange={(e) =>
                                      handleSiteIdChange(e, index)
                                    }
                                    disabled={isDisable}
                                    required
                                  />
                            <span className={`text-danger text-sm ${focusedInputIndex === index ? 'd-block' : 'd-none'}`}>
                              {errorMsg}
                            </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {showModal === true ? (
                      <TrialCrc
                        setShowModal={setShowModal}
                        toast={toast}
                        type="reviewer"
                        handleAddReviewer={handleAddReviewer}
                        trialDetails={trialDetails}
                        onReviewerChange={onReviewerChange}
                        setAllReviewers={setAllReviewers}
                        allReviewers={allReviewers}
                        setReviewerChanged={setReviewerChanged}
                      />
                    ) : (
                      ""
                    )}
                    <div className="col-md-12 mx-1 mb-3">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => setShowModal(true)}
                      >
                        Invite New Reviewer
                      </button>
                    </div>
                  </>
                )}
            </div>
          </>
        </>
      }
    />
  );
};
export default TrialReviewersAccordion;
