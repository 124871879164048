import { React, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";

const AddChoice = ({
  setNewChoice,
  toast,
  AllChoices,
  setEditChoice,
  editChoice,
  choiceIndex,
  handleChoices
}) => {
  const [data, setData] = useState({});
  const token = localStorage.getItem("token");

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleCheckbox = (e) => {
    setData({
      ...data,
      has_extra_text: !data.has_extra_text,
    });
  };

  const CreateChoice = (e) => {
    e.preventDefault();
    if (editChoice?.value) {
      axios
        .patch(
          `${__API_URL__}/api/survey/edit_choice/${editChoice?.value}/`,
          data,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success("Choice updated");
          setNewChoice(false);
          AllChoices();
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    } else {
      axios
        .post(`${__API_URL__}/api/survey/create_choice/`, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          toast.success("Choice Added");
          setNewChoice(false);
          AllChoices();
          handleChoices({label: res.data.choice, value: res.data.id}, choiceIndex)
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    }
  };

  const getSingleChoice = () => {
    axios
      .get(`${__API_URL__}/api/survey/get_single_choice/${editChoice?.value}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
      });
  };

  useEffect(() => {
    if (editChoice?.value) {
      getSingleChoice();
    }
  }, [editChoice]);

  return (
    <div>
      <Modal
        size="md"
        show
        onHide={() => {
          setNewChoice(false);
          setEditChoice({});
        }}
        centered
        backdrop="static" 
        keyboard={false}
      >
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            {editChoice?.value ? "Edit Choice" : "Add Choice"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <form onSubmit={CreateChoice}>
            <div class="form-group">
              <label for="question">Choice</label>
              <input
                type="text"
                class="form-control"
                id="question"
                name="choice"
                aria-describedby="emailHelp"
                placeholder="Enter Choice"
                value={data?.choice}
                onChange={(e) => {
                  handleFieldChange(e);
                }}
                required
              />
            </div>
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
                name="has_extra_text"
                value={data?.has_extra_text == true ? true : false}
                onChange={(e) => {
                  handleCheckbox(e);
                }}
              />
              <label class="form-check-label" for="exampleCheck1">
                Has extra text
              </label>
            </div>

            <div className="container MX-5 d-flex justify-content-end">
              <div className="row">
                <div className="col-sm">
                  <button
                    class="btn btn-secondary mx-2 my-2"
                    onClick={() => {
                      setNewChoice(false);
                      setEditChoice({});
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddChoice;
