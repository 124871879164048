import * as React from 'react'
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';


const MyMapComponent = withScriptjs(withGoogleMap((props) => {
    const onPlaceSelected = ( place ) => {
        let latValue = place.geometry.location.lat()
        let lngValue = place.geometry.location.lng();
        let formattedAddress = place.formatted_address;
        props.changeClinicDetails(latValue,lngValue,formattedAddress)
        // props.setClinicDetails({...props.clinicDetails, lat:latValue, lng: lngValue, address: formattedAddress})

    }

    return <>
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{ lat: props.clinicDetails.lat, lng: props.clinicDetails.lng }}
            center={{lat: props.clinicDetails.lat, lng: props.clinicDetails.lng}}
            >
            <Autocomplete
            style={{ marginTop: '10px' }}
            apiKey='AIzaSyBykNn93LjLI2MEv2PGOnSfpD_11zi1q30'
            className="form-control" 
            onPlaceSelected={ (place) => onPlaceSelected(place) }
            options={{
                types: ["geocode", "establishment"],
            }}
            />
            {props.isMarkerShown && <Marker position={{ lat: props.clinicDetails.lat, lng: props.clinicDetails.lng }} />}
            <div style={{height: '2em'}} />
        </GoogleMap>
    </> 
  }))

  export default MyMapComponent